import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { Container, Modal, Row } from "react-bootstrap";
import { invoiceReport } from "./reports/invoice-3.report";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

const PrintInvoice = (props) => {
  const user = parsedUser();
  const [url, setUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const bindDataRemote = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@GUIDBillingDocument='${props.selectedFolioDetails.GUIDBillingDocument}'`,
      };
      let request = await API.postAction(
        `api/cashier/PrintInvoice?${query}`,
        queryData
      );
      const invoiceAllData = JSON.parse(request?.data[0]?.JSONData)[0];
      console.log("🚀 ~ bindDataRemote ~ invoiceAllData:", invoiceAllData)
      const invoiceHeader = JSON.parse(request?.data[0]?.JSONData)[0]?.JSONData[0];
      const invoiceDetails = JSON.parse(request?.data[0]?.JSONData)[0].JSONDataDet;
      const invoicePaymentDetails = JSON.parse(request?.data[0]?.JSONData)[0].JSONDataDetPayment;
      console.log("🚀 ~ bindDataRemote ~ invoicePaymentDetails:", invoicePaymentDetails)
      // if (!invoiceHeader || !invoiceDetails) {
      //   return;
      // }
      const docDefinition = invoiceReport(invoiceHeader, invoiceDetails, invoicePaymentDetails);
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        setUrl(URL.createObjectURL(blob));
      });

      // console.log(request);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Invoice</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{height: '85vh'}}>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            {url ? (
              <div className="h-100 w-100">
                <iframe src={url} width="100%" style={{minHeight: '80vh'}}></iframe>
              </div>
            ) : (
              <div
                className="text-center"
                style={{ width: "100%", height: "550px" }}
              >
                {isLoading ? <h4>Loading...</h4> : <h4>No records found</h4>}
              </div>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrintInvoice;
