import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React from "react";

//Init Translation Library
import "./i18n";

import { BrowserRouter as Router } from "react-router-dom";
import "./dx-styles.scss";

import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { parsedUser } from "./utils/GetCurrentUser";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPageComponent from "./components/ErrorPageComponent";
import { ToastProvider } from "./contexts/toast";
import AxiosInterceptor from "./contexts/axios";
function App() {
  const user = parsedUser();

  // if (loading) {
  //   return <LoadPanel visible={true} />;
  // }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  const myErrorHandler = async (error, info) => {
    console.log("OH NO ERROR HAPPEND", error, info);
    console.error(error, info.componentStack);
    /*const request = await api.post(
    `/General/SendEmail/?EmailAddress=support@plnify.com&title=Error customerbackend`,
    error.toString() + info.componentStack
  );
 */

    // Do something with the error
    // E.g. log to an error logging client here
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPageComponent}
      onError={myErrorHandler}
    >
      <Router>
        <AuthProvider>
          <NavigationProvider>
            <ToastProvider>
              <AxiosInterceptor>
                <div className={`app ${screenSizeClass}`}>
                  <App />
                </div>
              </AxiosInterceptor>
            </ToastProvider>
          </NavigationProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}
