import React from "react";
import NightClosingContainer from "../../components/NightClosing/NightClosingContainer";

const NightClosing = () => {
  return (
    <NightClosingContainer routeName={"Front-Desk / Cashier / Night Audit"} />
  );
};

export default NightClosing;
