import React, { useState } from "react";
import { Container, Form, Modal, Row } from "react-bootstrap";
import { GenericFields } from "./GenericFields";
import { useFormik } from "formik";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useToast } from "../../contexts/toast";
import API from "../../api/api";

import { useTranslation } from "react-i18next";

const UndoCheckIn = ({ show, toggle, reservation }) => {
  const { t } = useTranslation();

  const [validationsSchema, setValidationSchema] = useState(null);

  const user = parsedUser();

  const [generalError, setGeneralError] = useState(null);

  const toast = useToast();

  const fields = [
    {
      IdAppForm: 63,
      Id: 6300004,
      FieldName: "Comments",
      FieldType: "varchar",
      FieldTitle: t("Comments"),
      Position: 4,
      cssClasss: "col-12",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
    },
  ];

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj);
    try {
      let objSPVersion = "";

      console.log(reservation);

      /* let queryString = `IdUser=${user.IdUser}&Token=${
        user.Token
      }&ActionMode=U&GUIDReservation=${reservation.GUID}&IdUnCancelationType=${
        obj.IdUnCancelationType
      }&UncancelDescription=${obj.UnCancelDescription ?? ""}`; */

      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&RecordId=${reservation.GUID}&Comment=${obj.Comments}`;

      console.log(queryString);

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.getAction(
        `/api/reservations/UndoCheckin`,
        queryString
      );
      console.log("REQUEST", request);
      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: true,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Undo Check-In")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="mt-0 pt-0">
          <Container>
            <Row>
              <GenericFields
                errors={formik.errors}
                fields={fields}
                touched={formik.touched}
                updateFields={updateField}
                values={formik.values}
              />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn me-2" type="button" onClick={toggle}>
            {t("Close")}
          </button>
          <button className="btn btn-primary me-2" type="submit">
            {t("Save")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UndoCheckIn;
