export const RoomsFields = [
  {
    IdAppForm: 5555,
    Id: 444,
    Group: 0,
    FieldName: "IdBusinessUnit",
    FieldType: "bigint",
    FieldTitle: "Business Unit",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 4785745,
    Id: 65464564,
    Group: 0,
    FieldName: "Room",
    FieldType: "varchar",
    FieldTitle: "Room",
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: "Write room number",
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
