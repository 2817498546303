/**
 * Defines the style for a custom cell highlight. 
 * Note: The cell must have badge and w-100 classes.
 *
 * @param {string} background - The background color of the cell.
 * @param {string} textColor - The text color of the cell.
 * @returns {object} - The style object with background and text color properties.
 */
export const CellHighlight = ( background, textColor ) => {
  return {
      backgroundColor: background,
      color: textColor,
  };
};

export const reservationColor = (item) => {
  return {
    // backgroundColor: item.ReservationStatusBackgroundColor,
    backgroundColor: item.ReservationStatusBackgroundColor,
    color: item.ReservationStatusTextColor,
  };
};
export const roomColor = (item) => {
  return {
    // backgroundColor: item.ReservationStatusBackgroundColor,
    backgroundColor: item.RoomStatusBackgroundColor,
    //borderLeft: "solid 5px " + item.RoomStatusBackgroundColor,
    color: item.RoomStatusTextColor,
  };
};