export const RowSuplementsTable = ({ dataRow }) => {

  const {
    start_date,
    end_date,
    adult,
    children,
    room_type
  } = dataRow;

  return (
    <tr>
      <td className="text-center">{start_date}</td>
      <td className="text-center">{end_date}</td>
      <td className="text-center">{adult}</td>
      <td className="text-center">{children}</td>
      <td className="text-center">{room_type}</td>
    </tr>
  )
}
