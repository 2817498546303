export const FilterFieldset = [
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "StartDate",
    "FieldType": "varchar",
    "FieldTitle": "Start Date",
    "Position": 1,
    "cssClasss": "col-md-2",
    "HtmlType": "date",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "EndDate",
    "FieldType": "varchar",
    "FieldTitle": "End Date",
    "Position": 1,
    "cssClasss": "col-md-2",
    "HtmlType": "date",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "IdBusinessUnit",
    "FieldType": "bigint",
    "FieldTitle": "Business Unit",
    "Position": 1,
    "cssClasss": "col-md-3",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": '{"HashID":"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "Room",
    "FieldType": "bigint",
    "FieldTitle": "Room",
    "Position": 1,
    "cssClasss": "col-md-2",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "IdRoomBlockType",
    "FieldType": "bigint",
    "FieldTitle": "Type",
    "Position": 1,
    "cssClasss": "col-md-2",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
]

export const RoomBlockTableFields = [
  {
    FieldName: "Room",
    FieldTitle: "Room",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "StartDate",
    FieldTitle: "Start Date",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "EndDate",
    FieldTitle: "End Date",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Type",
    FieldTitle: "Type",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Department",
    FieldTitle: "Department",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "User",
    FieldTitle: "User",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Comment",
    FieldTitle: "Comment",
    FieldType: "varchar",
    HtmlType: "text",
  }
]

export const RoomBlockFields = [
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "StartDate",
    "FieldType": "varchar",
    "FieldTitle": "Start Date",
    "Position": 1,
    "cssClasss": "col-md-6",
    "HtmlType": "date",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "EndDate",
    "FieldType": "varchar",
    "FieldTitle": "End Date",
    "Position": 1,
    "cssClasss": "col-md-6",
    "HtmlType": "date",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "IdBusinessUnit",
    "FieldType": "bigint",
    "FieldTitle": "Business Unit",
    "Position": 1,
    "cssClasss": "col-md-12",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": '{"HashID":"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "Room",
    "FieldType": "bigint",
    "FieldTitle": "Room",
    "Position": 1,
    "cssClasss": "col-md-12",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": '{"HashID":"f87533aec9d8c87783b6102c0719441412e9927695fbcafd44c0691e92265747"}',

    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "IdRoomBlockType",
    "FieldType": "bigint",
    "FieldTitle": "Block Type",
    "Position": 1,
    "cssClasss": "col-md-12",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "IdDepartment",
    "FieldType": "bigint",
    "FieldTitle": "Department",
    "Position": 1,
    "cssClasss": "col-md-12",
    "HtmlType": "select",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": '{"HashID":"1d09f3e1745aa57503217edfa59c5665bd8ea5d0f1d19e3427538ac67b854080"}',
    "Required": true,
    "Visible": true,
    "ReadOnly": false
  },
  {
    "IdAppForm": 64,
    "Id": 6400005,
    "FieldName": "Comment",
    "FieldType": "varchar",
    "FieldTitle": "Comment",
    "Position": 1,
    "cssClasss": "col-md-12",
    "HtmlType": "textarea",
    "PlaceHolder": "",
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "Required": false,
    "Visible": true,
    "ReadOnly": false
  },
]