import { t } from "i18next";

export const HeaderTasksAssignmentPlanningFields = [
  {
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "TaskDate",
    FieldType: "date",
    FieldTitle: t("Assignment Date"),
    Position: 8,
    cssClasss: "col-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdAutomaticTaskType",
    FieldType: "spinner",
    FieldTitle: t("Tasks Types"),
    Position: 3,
    cssClasss: "col-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"c1d4248fe04838a86624d924bfda46644d70769cff4a9001337bb580062b81df"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuilding",
    FieldType: "bigbit",
    FieldTitle: t("Building"),
    Position: 3,
    cssClasss: "col-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"d373bb8a3e97f3e5c2ab80d8a82bf83c1db0463cc440bf5a00995fcb0706bbf3"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBusinessUnit", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "bigbit",
    FieldTitle: t("Business Unit"),
    Position: 3,
    cssClasss: "col-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuildingFloor",
    FieldType: "bit",
    FieldTitle: t("Floor"),
    Position: 3,
    cssClasss: "col-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"e2258e57a645a2e471803b3cf8997c4d318c86812e491072f27a883deee2a2ab"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "TotalAttendants",
    FieldType: "bigint",
    FieldTitle: t("Total Collaborators"),
    cssClasss: "col-4",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: null,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
    min: 1,
  },
];
