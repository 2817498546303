import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FieldSelector from "../customComponents/FieldSelector";
import { useFormik } from "formik";
import { RoomsTypesFields } from "./FieldsGroup/RoomsTypesFields";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import * as yup from "yup";
import yupTypeValidator from "../../utils/YupTypeValidator";

import { useTranslation } from "react-i18next";

const RoomsTypes = (props) => {
  const { t } = useTranslation();

  // PROPERTIES
  const [fields, setFields] = useState(RoomsTypesFields);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [record, setRecord] = useState({});
  const toast = useToast();
  const user = parsedUser();
  const [formikInitialObject, setFormikInitialObject] = useState({});

  // METHODS

  const bindDataDetailsRemote = async () => {
    console.log("Bind Data Detail Remote");
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${props.selectedItem?.GUID}&ParentGUID=${props.parent?.GUID}`;
      let request = await API.getAction(
        "api/GroupReservations/ReservationsGroupsDetailsTotalDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;

      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      console.log(recordResult);

      setRecord(recordResult);
      fillFormikObject(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const fillFormikObject = (record) => {
    console.log(record);
    if (record && fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  const handleSave = async (obj) => {
    console.log(obj);
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      console.error(formik.errors);
      return;
    }

    let actionMode = props.selectedItem ? "U" : "I"; // this is a workaround
    // if (actionMode === "I") {
    //   actionMode = "U";
    // }

    let objSPVersion = "";

    Object.keys(obj).forEach((item) => {
      console.log(
        "field",
        item,
        record["Id" + item],
        record[item],
        typeof record[item]
      );
      if (
        item === "IdBusinessUnit" ||
        item === "IdUserAdd" ||
        item === "DateAdd" ||
        item === "IdUserMod" ||
        item === "DateMod" ||
        item === "Id" ||
        (item && record["Id" + item]) ||
        item === "Customer"
      ) {
        return;
      }
      objSPVersion += (objSPVersion.length > 0 ? ", " : "") + `@${item}=`;
      if (obj[item] === null || obj[item] === undefined) {
        if (item === "GUID") {
          objSPVersion += `'${record.GUID}'`;
        } else {
          objSPVersion += "null";
        }
      } else if (typeof obj[item] === "number") {
        objSPVersion +=
          obj[item] === undefined || obj[item] === null ? null : obj[item];
      } else if (typeof [obj[item] === "string"]) {
        objSPVersion += "'" + obj[item] + "'";
      } else {
        objSPVersion += obj[item];
        //   "null";
      }
    });

    objSPVersion +=
      ", @GUIDReservationGroup='" +
      props.parent.GUID +
      "', @GUID=" +
      (record.GUID ? `'${record.GUID}'` : "null");

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

    let queryData = {
      Data: objSPVersion,
    };

    let uri = "api/GroupReservations/ReservationsGroupsDetailsTotalAddEdit?";

    let request = await API.postAction(uri + queryString, queryData);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        return;
      }
    }
    toast({
      type: "success",
      message: t("Record saved successfully"),
    });
    props.toggle();
    // props.toggle(null, true)
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (
          item.FieldName === "Id" ||
          item.FieldName === "File" ||
          item.FieldName === "IdBusinessUnit"
        )
          return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject);
    setValidationSchema(yup.object(initialObjectValidation));
  };

  // EVENTS
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (props.selectedItem && props.selectedItem.GUID.length > 0) {
      bindDataDetailsRemote();
    }
    console.log(props.selectedItem);
  }, [props.selectedItem]);
  useEffect(() => {
    updateField("IdBusinessUnit", props.parent.IdBusinessUnit);
    prepareFormikObject();
  }, []);
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.toggle()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Room Type")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Row>
              {fields.map((item, index) => (
                <Col
                  className={
                    item.cssClasss +
                    (!item.Visible &&
                    item.FieldName !== "Active" &&
                    item.FieldName !== "ExecutionDate"
                      ? " d-none"
                      : "")
                  }
                  key={index}
                >
                  <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                  {item.Required ? (
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  ) : null}
                  <br />

                  <FieldSelector
                    actionMode={props.actionMode}
                    model={item}
                    key={index}
                    obj={formik.values}
                    updateField={updateField}
                    value={formik.values[item.FieldName]}
                  ></FieldSelector>
                  {formik.errors[item.FieldName] ? (
                    <div className="invalid text-sm">
                      {formik.errors[item.FieldName]}
                    </div>
                  ) : null}
                </Col>
              ))}
            </Row>
          </Modal.Body>

          <Modal.Footer style={{ maxHeight: "7vh" }} className="py-0">
            <Button variant="primary" className="ms-2" type="submit">
              <i className="fa-solid fa-floppy-disk me-2" /> {t("Save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RoomsTypes;
