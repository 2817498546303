export const URLToFile = async (url, fileName) => {
    try {
        const response = await fetch(url);

        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        
        const file = new File([blob], fileName, { contentType })
        return file;
    } catch (error) {
        console.error(error)
    }
}