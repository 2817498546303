import {
  CleaningServices,
  Search,
} from "@mui/icons-material";
import "devextreme/data/odata/store";
import React, { useCallback, useEffect, useState } from "react";

import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";
import { Accordion, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useLocation, useParams } from "react-router-dom";
import API from "../../api/api";
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useToast } from "../../contexts/toast";
import useFavorite from "../../hooks/useFavorite";
import { parsedUser } from "../../utils/GetCurrentUser";
import { exportToExcel as GenerateXLS } from '../../utils/export-to-excel';
import { exportToPDF as GeneratePDF } from '../../utils/export-to-pdf';
import Sha256 from "../../utils/sha256";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../utils/YupTypeValidator";

export default function ReportSimple() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [generalError, setGeneralError] = useState(null);

  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  // const [isPinned, setIsPinned] = useState(false);

  const checkAudit = localStorage.getItem('AuditInformation')

  const [records, setRecords] = useState([]);
  const [hash, setHash] = useState(null);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];

  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite()
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (cleanFilter) => {
    if (!hash) {
      return;
    }
    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `id=${hash}&token=${user.Token}&IdUser=${user.IdUser}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;

      }

      let request = await API.getAction(`api/reports/Records`, query);
      let results = request.data;
      console.log(results)

      setRecords(results)


    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => { bindDataRemote() }, [checkAudit])


  //TODO This method will be used to submit the filter data for the grid report.
  const handleSubmit = async (obj) => {
    try {
      console.log("🚀 ~ file: ReportSimple.js:87 ~ handleSubmit ~ obj:", obj)
    } catch (error) {
      console.log(error)
    }
  };

  const addFavorite = async () => {

    const newFavorite = {
      icon: `${formIcon}`,
      path: pathname,
      formPath: formPath,
      title: formTitle,
    }
    UpdateFavorites(newFavorite)
  }

  const [formIcon, setFormIcon] = useState(null);
  const [formPath, setFormPath] = useState(null);
  const [formTitle, setFormTitle] = useState('')

  const exportToExcel = useCallback(async () => {
    GenerateXLS(records, `${formTitle}.xlsx`);
  }, [records, formTitle])

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, [])

  // END METHODS

  // UI METHODS
  const [validationsSchema, setValidationSchema] = useState(null);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (filterFields) {
      filterFields.forEach((item) => {
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const loadGRIDUI = () => {
    return (
      <>
        <Accordion className="mb-4 mt-0 p-0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="my-0">Filters</Accordion.Header>
            <Accordion.Body>
              <Form
                onSubmit={formik.handleSubmit}
              // onSubmit={async (e) => {
              //   e.preventDefault();
              //   await bindDataRemote();
              // }}
              >
                <Row>
                  {filterFields && filterFields.map((item, index) => {
                    // console.log(item)
                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={index}
                      >
                        <label className="me-2 mt-2">{item.FieldTitle}</label>
                        {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                        <br />

                        <FieldSelector
                          //actionMode={props.actionMode}
                          model={item}
                          key={index}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    )
                  })}

                  <Col className="col-12 d-flex justify-content-end gap-2">
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title="Clear"
                      disabled={loading}
                      onClick={() => {
                        setSearch("");
                        setTimeout(async () => {
                          await bindDataRemote(true);
                        }, 1000);
                      }}
                    >
                      <i className="fas fa-eraser"></i>
                    </button>
                    <button className="btn btn-sm btn-primary" title="Search" type="submit" disabled={loading}>
                      <Search />
                    </button>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion >
        <DataGrid
          dataSource={records}
          columns={reportFields && reportFields.map((item, index) => {
            return ({
              caption: item.ListTitle,
              dataField: item.FieldName
            })
          })}
          columnAutoWidth={true}
          showBorders={true}
          className="grid"

          loadPanel={{
            enabled: true,
            showIndicator: true,
            text: "Loading...",
          }}
        >
          <Selection mode="single" />
          {/* {reportFields && reportFields.map((item, index) => {
            <Column key={index} caption={item.ListTitle} dataField={item.FieldName}></Column>
          })} */}
          < Paging
            defaultPageSize={10}
          />
          <Pager
            showInfo={true}
            infoText={"Page:"}
            visible={true}
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      </>
    );
  };

  //EVENTS

  const [filterFields, setFilterFields] = useState([]);
  const [reportFields, setReportFields] = useState([]);
  const bindDataModel = async () => {

    let query = `id=${params.id}&token=${user.Token}&IdUser=${user.IdUser}`;
    let request = await API.getAction("api/reports/header", query)
    let firstResponse = request.data[0].JSONData
    let secondResponse = JSON.parse(firstResponse);
    let thirdResponse = secondResponse[0].JSONData[0];
    console.log(thirdResponse, secondResponse, firstResponse, request);
    setFormTitle(thirdResponse.Description);
    setFormIcon(thirdResponse.FormIcon);
    setFormPath(thirdResponse.FormPath);
    setFilterFields(thirdResponse.ReportFilter)
    setReportFields(thirdResponse.ReportFields);
    setHash(thirdResponse.HashID)
  }
  useEffect(() => {
    bindDataModel();
    checkIfPinned();
    prepareFormikObject();
  }, [params.id]);
  useEffect(() => {
    bindDataRemote();
  }, [hash])
  // END EVENTS

  return (
    <React.Fragment>

      {formPath && <Breadcrumbs pathTitle={formPath} />}
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className={formIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{formTitle}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                {actionMode === null ? (
                  <>
                    <button className="btn btn-lg my-1 me-2" title="Export to Excel" disabled={loading} onClick={() => exportToExcel()}>
                      <i class="fa-solid fa-file-excel"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Export to PDF" disabled={loading} onClick={() => exportToPDF()}>
                      <i class="fa-solid fa-file-pdf"></i>
                    </button>
                  </>
                ) : null}
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => addFavorite()}>{isPinned ? 'Remove from' : 'Add to'} favorites</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
