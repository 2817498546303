import React, { useEffect, useState } from "react";
import ColumnsModelsSettings from "../settings/model.settings";
import Table from "../table";
import ModalModel from "./ModelModal";
import { confirmAlert } from "react-confirm-alert";
import { actionsModel } from "../../enum/actionsModel.enum";
import ModalSample from "./ModalSample";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

import { useTranslation } from "react-i18next";

const ModelTable = ({ ManagementModel, name, requestData, selected }) => {
  const { t } = useTranslation();

  const { Token } = parsedUser();
  const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = React.useState({});
  const [action, setAction] = React.useState("");
  const [showSample, setshowSample] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleSample = () => {
    setshowSample(!showSample);
  };
  const [formSize, setFormSize] = useState();

  const getFormSize = async () => {
    setLoading(true);
    const idForm = ManagementModel[0]?.IdAppForm;
    try {
      let result = await API.getAction(
        `api/CRUDModelManagement/FormInfo?token=${Token}&id=${idForm}`,
        null,
        null
      );
      setFormSize(await result?.data[0]?.FormSize);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFormSize = async () => {
    setLoading(true);
    const idForm = ManagementModel[0]?.IdAppForm;
    try {
      let request = await API.getAction(
        `/api/CRUDModelManagement/UpdateFormSize?token=${Token}&id=${idForm}&FormSize=${formSize}`,
        null
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFormSize = (e) => {
    setFormSize(e.target.value);
  };

  useEffect(() => {
    if (typeof formSize === "undefined") return;
    if (!ManagementModel) return;
    console.log("Updating formsize to: ", formSize);
    updateFormSize();
  }, [formSize]);

  const actionSelected = (action, data) => {
    setAction(action);
    setData(data);

    setShowModal(
      action === actionsModel.EDIT || action === actionsModel.CREATE
    );

    if (action === actionsModel.DELETE) {
      confirmAlert({
        message: t("Are you sure to delete this item?"),
        buttons: [
          {
            label: t("Yes"),
            onClick: () => console.log("Click Yes"),
          },
          {
            label: t("No"),
            onClick: () => console.log("Click No"),
          },
        ],
      });
    }
  };
  useEffect(() => {
    console.log(showModal);
    if (!showModal) {
      requestData();
    }
  }, [showModal]);

  useEffect(() => {
    if (!ManagementModel) return;
    getFormSize();
  }, [ManagementModel]);
  return (
    <>
      <ColumnsModelsSettings actionSelected={actionSelected}>
        {({ columns }) => (
          <Table
            headerTop={
              <div className="d-flex justify-content-between ">
                <h4 className="font-bold">
                  {t("Model")} - {name}
                </h4>
                <div className="d-flex gap-2">
                  {/* <button className="btn btn-secondary me-2" onClick={() => {
                                        console.log(selected);
                                        debugger;
                                    }
                                    }>
                                        <i className="fas fa-link"></i>
                                    </button> */}
                  <div className="d-flex gap-2">
                    <label className="form-label align-self-end fw-bold">
                      {t("Form Size")}
                    </label>
                    <select
                      className="form-select"
                      value={formSize}
                      onChange={handleChangeFormSize}
                      disabled={isLoading}
                    >
                      <option value={"sm"}>sm</option>
                      <option value={"md"}>md</option>
                      <option value={"lg"}>lg</option>
                      <option value={"xl"}>xl</option>
                    </select>
                  </div>
                  <button
                    className="btn btn-secondary"
                    onClick={() => toggleSample()}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </button>
                  <button
                    className="btn btn-primary "
                    onClick={() => actionSelected(actionsModel.CREATE, {})}
                    disabled={isLoading}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            }
            Empty={() => <p style={{ fontSize: "1.5rem" }}>{t("No data")}</p>}
            keyName="model-table"
            title={t("Model table")}
            header={{ columns }}
            data={ManagementModel}
            stylesCustom={{ containerOverflow: "max-h-96 overflow-y-auto" }}
          />
        )}
      </ColumnsModelsSettings>
      <ModalModel
        show={showModal}
        setShow={setShowModal}
        data={data}
        action={action}
      />
      {showSample ? (
        <ModalSample
          show={showSample}
          toggle={toggleSample}
          data={ManagementModel}
          formSize={formSize}
        ></ModalSample>
      ) : null}
    </>
  );
};

export default ModelTable;
