import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../breadcrumb";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import RoomOverviewFilter from "./RoomOverviewFilter";
import CollaboratorsRoomsAssignment from "../Component/CollaboratorsRoomsAssignment";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";

import { useTranslation } from "react-i18next";

const RoomOverviewContainer = ({ routeName }) => {
  const { t } = useTranslation();
  const user = parsedUser();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);

  const [showTasksPlanning, setShowTasksPlanning] = useState(false);

  const toggleAddEdit = () => setShowTasksPlanning(!showTasksPlanning);

  const deleteConfirm = () => {};

  const requestRecords = async (filter) => {
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${
        user.Token
      }&ActionMode=${"L"}`;

      let queryData = {
        Data: filter,
      };

      console.log(queryData);
      let requestAPI = await API.postAction(
        "/api/AutomaticTasksAssigments/HousekeepingRoomCleaning?" + queryString,
        queryData
      );

      let newData = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).Records;

      console.log(requestAPI);

      console.log(newData);

      if (newData && newData.length > 0) {
        console.log("ENTRO");
        setRecords(newData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    requestRecords(null);
  }, []);

  return (
    <>
      <CustomBreadcrumbs pathTitle={routeName} />

      <Container className="mt-0 pt-0">
        <div className="row mx-3">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fas fa-broom me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{t("Room Overview")}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <RoomOverviewFilter
            filterRecord={requestRecords}
            count={recordCount}
          />

          <Card>
            <Card.Body>
              <Row>
                <Col xs={6}>
                  <h6>{t("Rooms Details")}</h6>
                </Col>
                <Col xs={6} className="text-end">
                  <Button className="px-5  py-2" variant="primary" size="sm">
                    {t("APPLY")}
                  </Button>
                </Col>
                <Col xs={12}>
                  <Tabs defaultActiveKey={"tableDetails"}>
                    <Tab eventKey={"tableDetails"} title={t("Table Details")}>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>{t("Room")}</th>
                            <th>{t("ST")}</th>
                            <th>{t("Type")}</th>
                            <th>{t("Guest")}</th>
                            <th>{t("Guest Status")}</th>
                            <th>{t("Floor")}</th>
                            <th>{t("View")}</th>
                            <th>{t("Section")}</th>
                            <th>{t("Building")}</th>
                            <th>{t("VIP")}</th>
                            <th>{t("Arr/Dep")}</th>
                            <th>{t("Last Update")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {roomOverview.map((item, index) => (
                            <tr>
                              <td>{item.Room}</td>
                              <td>{item.ST}</td>
                              <td>{item.Type}</td>
                              <td>{item.Guest}</td>
                              <td>{item.GuestStatus}</td>
                              <td>{item.Floor}</td>
                              <td>{item.View}</td>
                              <td>{item.Section}</td>
                              <td>{item.Building}</td>
                              <td>{item.Vip}</td>
                              <td>{item.ArrDep}</td>
                              <td>{item.LastUpdate}</td>
                            </tr>
                          ))} */}
                          <tr>
                            <td className="border-1 text-center" colSpan={12}>
                              {t("No Records Found")}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey={"listDetails"} title={t("List")}>
                      <div className="mt-3 px-0 w-100">
                        {records && records.length < 0 ? (
                          <CollaboratorsRoomsAssignment
                            tasksDetail={[]}
                            tasksCard={records}
                          />
                        ) : (
                          <div className=" text-center fs-bolder">
                            {t("No Collaborators")}
                          </div>
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default RoomOverviewContainer;
