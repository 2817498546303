import React, { useEffect, useState } from "react";

//components
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";

import IncidentStatus from "../../components/DashboardGuestComplain/IncidentStatus";
import IncidentByPriority from "../../components/DashboardGuestComplain/IncidentByPriority";
import DailyResolvedIncident from "../../components/DashboardGuestComplain/DailyResolvedIncident";
import IncidentCategory from "../../components/DashboardGuestComplain/IncidentCategory";
import DailyIncidents from "../../components/DashboardGuestComplain/DailyIncidents";
import ReportedIncidents from "../../components/DashboardGuestComplain/ReportedIncidents";
import DailyIncidentsLine from "../../components/DashboardGuestComplain/DailyIncidentsLine";
import { SelectBox } from "devextreme-react";

import "./DashboardGuestComplain.css";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

const DashboardGuestComplain = () => {
  const [data, setData] = useState({
    incidentStatus: null,
    incidentpriority: [],
    incidentcategory: [],
    dailyincidents: [],
    reportedincidents: [],
    dailyincidentsline: [],
    dailyresolvedincident: [],
  });
  const user = parsedUser();

  const request = async () => {
    try {
      let objSPVersion = ` `;

      // if (actionMode === 'U') {

      //   objSPVersion += `, @GUID='${selectedItem.GUID}'`
      // }

      let queryData = {
        Data: objSPVersion,
      };

      let query = `Idbusinessid=${6}&token=${user.Token}&IdUser=${user.IdUser}`;

      let requestAPI = await API.postAction(
        "/api/DashboardFoodAndBeverage/All?" + query,
        queryData
      );

      console.log(requestAPI);

      if (requestAPI.status === 200) {
        let onlyData = JSON.parse(requestAPI.data[0].JSONData);
        let final = onlyData ? onlyData[0] : {};
        console.log("ALL DATA",final);

        setData({
          ...data,
          incidentStatus: final.incidentstatus,
          incidentpriority: final.incidentpriority,
          incidentcategory: final.incidentcategory,
          dailyincidents: final.dailyincidents,
          reportedincidents: final.reportedincidents,
          dailyincidentsline: final.reportedincidentsline,
          dailyresolvedincident: final.dailyresolvedincident,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <>
      <Breadcrumbs pathTitle={"Food and Beverage / Dashboard"} />
      <div className="container px-3">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="row">
              <div className="col-sm-12 col-md-6 mb-3">
                <h4 style={{ fontWeight: 800 }}>Dashboard</h4>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div className="d-flex justify-content-end">
                  <div
                    className="dx-field-label text-end "
                    style={{ fontSize: "18px" }}
                  >
                    Business unit
                  </div>
                  <SelectBox
                    id="custom-templates"
                    dataSource={{}}
                    displayExpr="Name"
                    //inputAttr={{}}
                    valueExpr="ID"
                    //fieldRender={{}}
                    //itemRender={""}
                  />
                  <button className="btn ms-2">
                    <i
                      className="fa-solid fa-rotate"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <IncidentStatus title={"Request Status"} data={data.incidentStatus} viewTotalValue={true} />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <IncidentByPriority
              title={"Request By Priority"}
              data={data.incidentpriority}
              viewTotalValue={false}
            />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <DailyResolvedIncident title={"Daily Resolved Request"} data={data.dailyresolvedincident} />
          </div>
          <div className="col col-md-6 col-lg-6 col-xl-3 my-2">
            <IncidentCategory
              title={"Request Category"}
              data={data.incidentcategory}
              viewTotalValue={false}
            />
          </div>
          <div className="col col-md-6 col-lg-6 my-2">
            <DailyIncidents title={"Daily Requests"} data={data.dailyincidents} />
          </div>
          <div className="col col-md-6 col-lg-12 col-xl-6 my-2">
            <ReportedIncidents title={"Reported Requests"} showRequest={true} data={data.reportedincidents} />
          </div>
          <div className="col-12 my-2">
            <DailyIncidentsLine title={"Daily Requests"} data={data.dailyincidentsline} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardGuestComplain;
