import React, { useEffect, useState } from "react";
import { HeaderFields } from "./FieldsGroup/HeaderFields";
import { Col, Form, Row } from "react-bootstrap";
import FieldSelector from "../customComponents/FieldSelector";

import { useTranslation } from "react-i18next";

export const GroupReservationHeaderDetails = ({
  selectedReservation,
  count,
}) => {
  const { t } = useTranslation();

  const [fields] = useState(HeaderFields);
  const [record, setRecord] = useState(null);
  const { Rooms, Adults, Children } = count;

  const updateField = (fieldName, fieldValue) => {};

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selectedReservation:", selectedReservation);
    console.log(selectedReservation);
    setRecord({
      ...selectedReservation,
      Rooms,
      Adults,
      Children,
    });
  }, [selectedReservation, count]);

  useEffect(() => {
    if (record) {
      console.log("🚀 ~ useEffect ~ record:", record);
    }
  }, [record, count]);

  if (!record) {
    return <></>;
  }
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
      }}
      className="mb-4"
    >
      <Row>
        {fields.map((item, index) => {
          // console.log(item, record);
          return (
            <Col
              className={item.cssClasss + (!item.Visible ? " d-none" : "")}
              key={index}
            >
              <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
              {item.Required ? (
                <i className="required-asterisk ms-1 text-danger">*</i>
              ) : null}
              <br />

              <FieldSelector
                actionMode={"I"}
                model={item}
                key={index}
                obj={record}
                updateField={updateField}
                value={record[item.FieldName]}
                IsReadOnly
              />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};
