export const calculateAutoSize = (scale, width, height) => {
  let result = 0;
  result = height / width;
  result = result * scale;

  return Math.round(result);
};
const AutoSize = (xsm, sm, md, lg, xl, xxl) => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let scale;

  if (width < 375) {
    scale = xsm;
  } else if (width > 374 && width < 577) {
    scale = sm; //Controls the pin size scale
  } else if (width > 576 && width < 769) {
    scale = md; //Controls the pin size scale
  } else if (width > 768 && width < 1025) {
    scale = lg; //Controls the pin size scale
  } else if (width > 1024 && width < 1441) {
    scale = xl; //Controls the pin size scale
  } else {
    scale = xxl; //Controls the pin size scale
  }

  return calculateAutoSize(scale, width, height);
};

export default AutoSize;
