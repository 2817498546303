import { TextBox } from "devextreme-react";
import React, { useState } from "react";
import RestaurantReservationContainer from "../../components/restaurant-reservation/RestaurantReservationContainer";




const RestaurantReservationsPage = (props) => {
  

  return (
    <>
    <RestaurantReservationContainer routeName={"Restaurant Reservation / Process / Restaurant Reservation"} />
    </>
  );
};

export default RestaurantReservationsPage;
