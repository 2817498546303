export const RestaurantFilterFields = [
  {
    IdAppForm: 1,
    Id: 1,
    Group: 0,
    FieldName: "ReservationNumber",
    FieldType: "bigint",
    FieldTitle: "Restaurant Reservation No.",
    Position: 1,
    cssClasss: "col-6 text-nowrap",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 1,
    Id: 2,
    Group: 0,
    FieldName: "IdBusinessUnit",
    FieldType: "bigint",
    FieldTitle: "Business Unit",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 1,
    Id: 3,
    Group: 0,
    FieldName: "IdRestaurant",
    FieldType: "bigint",
    FieldTitle: "Restaurant",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID":"087854a518ffe92c940dee83080a5816ef4e7d5d46354d831f71953799b467ca"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 1,
    Id: 4,
    Group: 0,
    FieldName: "RoomNumber",
    FieldType: "bigint",
    FieldTitle: "Room",
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    FieldName: "ReservationDate",
    FieldType: "date",
    FieldTitle: "Reservation Date",
    cssClasss: "col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 1,
    Group: 0,
    FieldName: "GuestName",
    FieldType: "bigint",
    FieldTitle: "Primary Guest Name",
    Position: 1,
    cssClasss: "col-6 ",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    FieldName: "IdRestaurantSeatting",
    FieldType: "bigint",
    FieldTitle: "Reservation Time",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"445ca44a74fbc31e069cc6b74b8320ddf77f78cfc4d0a8fe2387d394422e4766"}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: 'IdRestaurant'
  },
  {
    Group: 1,
    FieldName: "AllStatus",
    FieldType: "boolean",
    FieldTitle: "All",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "ReservedStatus",
    FieldType: "boolean",
    FieldTitle: "Reserved",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "CanceledStatus",
    FieldType: "boolean",
    FieldTitle: "Canceled",
    Position: 2,
    cssClasss: "col-6",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
