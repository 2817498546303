import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { CustomBreadcrumbs as Breadcrumbs } from '../../components';
import RoomMapContainer from "../../components/room-map/RoomMapContainer";
import RoomMapFilter from "../../components/room-map/RoomMapFilter";
import useFavorite from "../../hooks/useFavorite";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useToast } from "../../contexts/toast";
import API from "../../api/api";

const RoomMapPage = () => {
  const { pathname } = useLocation();
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const [actionMode, setActionMode] = useState(null);
  const [objSPVersion, setObjSPVersion] = useState({
    Data: null,
  }); //This object will be used to filter the data
  const user = parsedUser();
  const toast = useToast();
  const [records, setRecords] = useState([]); //This will hold the data from the database
  const [isLoading, setIsLoading] = useState(false);

  const bindDataRemote = async (filter) => {
    setIsLoading(true);
    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: null,
      };
      let request = await API.postAction(
        "/api/Rooms/RoomMap?" + queryString,
        queryData
      );
      
      let results = request.data[0];
      // console.log("🚀 ~ bindDataRemote ~ results:", results.JSONData)
      let parsedResults = JSON.parse(results.JSONData)[0];
      // I need to extract from the first to the third element of the array Records.
      let recordResult = parsedResults.Records;
      // let recordResult = parsedResults.Records.slice(0, 3); //TODO - This is temporary, we need to change this to the correct object
      console.log(recordResult);
      setRecords(recordResult);
      if (recordResult === null) {
        toast({
          title: "No data found",
          status: "error",
        })
        throw new Error("No data found");
      }
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote(objSPVersion);
  }, []);


  const addFavorite = async () => {
    const newFavorite = {
      icon: 'fa-solid fa-home',
      path: pathname,
      formPath: 'Front-Desk / Front-Desk / Room Map',
      title: 'Room Map',
    }
    UpdateFavorites(newFavorite)
  }

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    await bindDataRemote(obj);
  };

  return (
    <React.Fragment>
      <Breadcrumbs pathTitle={'Front-Desk / Front-Desk / Room Map'} />
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Room Map</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">

                {actionMode === null ? (
                  <>
                    <button className="btn btn-lg my-1 me-2" title="Export to PDF" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-file-pdf"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Export to Excel" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-file-excel"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send email" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-envelope"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send to print" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-print"></i>
                    </button>
                  </>
                ) : null}
              </div>
            </div>
            {/* <RoomMapFilter 
                // count={recordCount}
                handleFilter={(obj) => {
                  handleFilter(obj)
                  setObjSPVersion(obj)
                }}
                isLoading={isLoading}
            /> */}
            <RoomMapContainer
              records={records}
            />
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default RoomMapPage
