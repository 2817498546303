
import React from "react";


const TBodyTable = (props) => {
    const columns = props.header?.columns;

    return (

        <React.Fragment>

            {!props.data && (
                "-".repeat(props.limit || 0).split("").map((_, i) => (
                    <tr className="bg-white border-b dark:bg-gray-800  " key={`${props.keyName}-tr-load-${i}`}>
                        {columns?.map((_, e) => (
                            <td key={`${props.keyName}-td-load-${e}`}>
                            </td>
                        ))}
                    </tr>
                ))
            )}

            {(typeof (props.loading) !== undefined) && props.loading ? (
                <tr className="bg-white border-b dark:bg-gray-800  " >
                    <td colSpan={15} >
                        <div className="text-center flex align-items-center justify-content-center mt-4" style={{ height: "15rem" }}>
                            <div className="flex flex-column w-100 h-100 align-items-center justify-content-center">
                                <div className="spinner-grow" style={{ width: "5rem", height: "5rem" }} role="status">  <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            ) :
                props.data && !props.data.length && typeof props.Empty == "function" ? (
                    <tr className="bg-white border-b dark:bg-gray-800  " >
                        <td className="px-6 py-4" colSpan={15} >
                            <div className="text-center">
                                {props.Empty()}
                            </div>
                        </td>
                    </tr>
                ) :
                    props.data && props.data?.length ? (
                        props.data?.map((item, i) => {

                            return (
                                <tr className="bg-white border-b dark:bg-gray-800   " key={`${props.keyName}-tr-body-${i}`} >
                                    {columns.map((column, z) => (
                                        <td
                                            style={{ verticalAlign: "middle", position: "relative" }}

                                            className={`px-6 ${props.stylesCustom?.td || ""}`.trim()} key={`${props.keyName}-td-body-${i}-${z}`} >
                                            {column.render ? (
                                                <>{column?.render({ data: item, indexData: i, isHover: false, indexColumn: z })}</>
                                            ) : null}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                    ) : ''
            }
        </React.Fragment>
    )

}

export default TBodyTable;