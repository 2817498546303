/* eslint-disable react/prop-types */
import { SelectBox } from "devextreme-react";
import { useEffect, useState } from "react";
// import { Col, Form, FormSelect, Row } from "react-bootstrap"

export const CashierCustomerSelector = ({ records, customerType, selectedCustomerData, isLoading }) => {
  const [dataSelected, setDataSelected] = useState(null);

  useEffect(() => {
    selectedCustomerData(dataSelected);
  }, [dataSelected])

  const handleChange = (e) => {
    setDataSelected(null);
    const ItemId = e.value.Id;
    let data = records.find(item => item.Id == ItemId);
    setDataSelected(data);
  }

  if (customerType === "G") return;

  return (
    <div className="col-3 col-md-3 mt-2">
      <label className="me-2 mt-2">Customer</label>
      <br />
        <SelectBox
        dataSource={records}
        itemRender={(data) => <span>{data.Name}</span>}
        onValueChanged={handleChange}
        displayExpr="Name"
        searchEnabled={true}
        disabled={isLoading}
      />
    </div>
  )
}
