export const RoomOccupationJsonFile = {
  "rooms": [
    {
      "text": "101",
      "id": "101",
      "color": "#cb6bb2",
      "roomType": "CPJS",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "102",
      "id": "102",
      "color": "#56ca85",
      "roomType": "CPJS",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "103",
      "id": "103",
      "color": "#1e90ff",
      "roomType": "CPJS",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "104",
      "id": "104",
      "color": "#ff9747",
      "roomType": "CPJS",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "105",
      "id": "105",
      "color": "#ff0000",
      "roomType": "CPJS",
      "roomColorStatus": "VC Clean",
    },
    {
      "text": "201",
      "id": "201",
      "color": "#00ff00",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "202",
      "id": "202",
      "color": "#0000ff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "203",
      "id": "203",
      "color": "#ffff00",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "204",
      "id": "204",
      "color": "#ff00ff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "205",
      "id": "205",
      "color": "#00ffff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    },
    {
      "text": "301",
      "id": "301",
      "color":"#ff8000",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "302",
      "id": "302",
      "color": "#008000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "303",
      "id": "303",
      "color":"#800080",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "304",
      "id": "304",
      "color":"#808080",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "305",
      "id": "305",
      "color": "#800000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Clean",
    },
    {
      "text": "401",
      "id": "401",
      "color":"#ff8000",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "402",
      "id": "402",
      "color": "#008000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "403",
      "id": "403",
      "color":"#800080",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "404",
      "id": "404",
      "color":"#808080",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "405",
      "id": "405",
      "color": "#800000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Clean",
    },
  ],
  "dataChart": [{
    "text": "Stephenie Thurling",
    "startDate": "2024-04-20T12:58:45Z",
    "endDate": "2024-04-24T06:14:02Z",
    "room": "404",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Britni Yakutin",
    "startDate": "2024-04-04T10:31:36Z",
    "endDate": "2024-04-24T21:22:07Z",
    "room": "101",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Avrom Casperri",
    "startDate": "2024-04-03T02:32:21Z",
    "endDate": "2024-12-22T04:41:48Z",
    "room": "104",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Annadiane Gimblett",
    "startDate": "2024-04-25T13:05:50Z",
    "endDate": "2024-12-20T03:46:25Z",
    "room": "101",
    "reservationColor": "#fe8a02",
    "reservationStatus": "Tentative"

  }, {
    "text": "Gregoire Petzold",
    "startDate": "2024-04-06T04:26:27Z",
    "endDate": "2024-12-25T04:00:41Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Kingsley Sommerville",
    "startDate": "2024-04-14T07:00:26Z",
    "endDate": "2024-12-26T00:51:21Z",
    "room": "304",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Linn Novkovic",
    "startDate": "2024-04-01T04:36:07Z",
    "endDate": "2024-12-23T20:12:28Z",
    "room": "102",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Lothaire Halsey",
    "startDate": "2024-04-14T18:39:48Z",
    "endDate": "2024-12-23T02:39:22Z",
    "room": "202",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Inesita Pochin",
    "startDate": "2024-04-06T02:04:27Z",
    "endDate": "2024-12-21T21:58:38Z",
    "room": "205",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Granger Cathee",
    "startDate": "2024-04-07T02:01:46Z",
    "endDate": "2024-12-24T02:08:21Z",
    "room": "405",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Heidie Fagge",
    "startDate": "2024-04-09T09:05:12Z",
    "endDate": "2024-12-17T21:33:03Z",
    "room": "103",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Timothee Kingaby",
    "startDate": "2024-04-07T10:08:31Z",
    "endDate": "2024-12-26T04:31:45Z",
    "room": "301",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Joleen Cosin",
    "startDate": "2024-04-07T23:07:19Z",
    "endDate": "2024-04-16T06:47:47Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Jarvis MacMeanma",
    "startDate": "2024-04-04T15:07:49Z",
    "endDate": "2024-04-19T02:55:23Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Averil Bang",
    "startDate": "2024-04-01T00:17:02Z",
    "endDate": "2024-04-16T04:29:23Z",
    "room": "402",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Janene Danes",
    "startDate": "2024-04-26T22:51:47Z",
    "endDate": "2024-04-28T19:03:52Z",
    "room": "401",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Roldan Simanenko",
    "startDate": "2024-04-24T07:41:34Z",
    "endDate": "2024-04-27T04:12:04Z",
    "room": "404",
    "reservationColor": "#fe8a02",
    "reservationStatus": "Tentative"
  }, {
    "text": "Roxine Chmiel",
    "startDate": "2024-04-21T07:39:12Z",
    "endDate": "2024-04-24T06:32:56Z",
    "room": "403",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Karie Killeley",
    "startDate": "2024-04-02T04:40:13Z",
    "endDate": "2024-04-17T19:38:08Z",
    "room": "301",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }]
}