import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";

const ListRooms = ({ rooms, toggle, reloadTables, selectedReservation }) => {
  let actionMode = "";
  const user = parsedUser();
  const [selectedItem, setSelectedItem] = useState(null);
  let deleteConfirmRoom = () => {
    confirmAlert({
      message: "Are you sure you want to remove the room?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&GUID=${selectedItem.GUID}&GUIDRestaurantsReservation=${selectedReservation.GUID}`;

            await API.getAction(`api/RestaurantReservation/DeleteRoom`, query);
            // await bindre();
            setSelectedItem(null);
            reloadTables();
          },
        },
        { label: "No" },
      ],
    });
  };

  return (
    <>
      <Row>
        <Col lg={11} xxl={11}>
          <div className="table-responsive">
            <table className="table table-bordered shadow-sm table-striped">
              <thead>
                <tr>
                  <th className="sticky-col first-col">Business Unit.</th>
                  <th className="sticky-col second-col">Room</th>
                </tr>
              </thead>
              <tbody>
                {rooms && rooms.length > 0 ? (
                  rooms.map((item) => {
                    return (
                      <tr
                        key={item.room}
                        className={` clickable ${
                          item === selectedItem ? " active" : ""
                        }`}
                        onClick={() => setSelectedItem(item)}
                      >
                        <td>{item.BusinessUnit}</td>
                        <td>{item.Room}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="border-1 text-center" colSpan={12}>
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
        <Col lg={1}>
          <div className="d-flex flex-column gap-2 align-content-center align-items-baseline">
            {actionMode === "K" ? null : (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  toggle();
                }}
                type="button"
              >
                <i className="fa-solid fa-plus" />
              </button>
            )}

            {actionMode === "K"
              ? null
              : selectedItem &&
                rooms.length > 0 && (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      deleteConfirmRoom();
                    }}
                    type="button"
                  >
                    <i className="fa-solid fa-trash" />
                  </button>
                )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ListRooms;
