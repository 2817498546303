import React, { useEffect, useRef, useState } from "react";

import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { parsedUser } from "../../../../utils/GetCurrentUser";
import API from "../../../../api/api";
import { useToast } from "../../../../contexts/toast";
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import FieldSelector from "../../../customComponents/FieldSelector";

import { useTranslation } from "react-i18next";

const SpecialRatePolicies = (props) => {
  const { t } = useTranslation();

  // PROPERTIES
  const selectedItemRef = useRef(null);
  const user = parsedUser();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);

  const [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [records, setRecords] = useState([]);
  const [generalError, setGeneralError] = useState(null);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [model, setModel] = useState(props.page);
  const [validationsSchema, setValidationSchema] = useState(null);
  // METHODS

  const toggleSelectItem = (obj) => {
    console.log(obj);
    setSelectedItem(obj);
    setPolicyTypeSelected(obj.PolicyType);
    formik.setFieldValue("PolicyType", obj.PolicyType);
    formik.setFieldValue("IdContractPolicy", obj.IdContractPolicy);
    bindDataPoliciesAvailableRemote(obj.PolicyType);
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
    console.log(policiesAvailable, fieldName, fieldValue);
    var item = policiesAvailable.filter((x) => x.Id === fieldValue)[0];

    setSelectedItem(item);
  };
  // useEffect(() => {
  // console.log(selectedItem)
  // }, [selectedItem])

  const updateFieldRadio = (fieldName, fieldValue) => {
    console.log(fieldValue);
    formik.setFieldValue(fieldName, fieldValue);
    setPolicyTypeSelected(fieldValue);
    setSelectedItem(null);
    bindDataPoliciesAvailableRemote(fieldValue);
  };

  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `token=${user.Token}&RecordId=${selectedItem.GUID}&ParentGUID=${props.ParentGUID}`;
              await API.getAction(
                "api/contracts/SpecialRatePoliciesDelete",
                query
              );
              await bindDataRecordRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  // THIS METHOD IS USED TO BRING THE DETAILS OF THE SECTION USED
  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `token=${user.Token}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      let request = await API.getAction(
        "api/contracts/SpecialRatePoliciesDetails",
        query
      );
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData;
      // fillFormikObject(finalResult);
      //setSelectedItem(null)
      //Fill object with new values
    } catch (error) {
      toast({ type: "error", message: t("Error loading data") });
      return;
    }
  };

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRecordRemote = async (cleanFilter) => {
    // First start loading the model
    setLoading(true);

    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.SpecialRate.GUID}`;
      // if (search.length > 0 && cleanFilter == null) {
      //   query += "&Search=" + search;
      // }

      let request = await API.getAction(
        "api/contracts/SpecialRatePoliciesRecord",
        query
      );
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      setLoading(false);
    }
  };

  const [policyTypeSelected, setPolicyTypeSelected] = useState(null);
  const [policiesAvailable, setPoliciesAvailable] = useState([]);
  const bindDataPoliciesAvailableRemote = async (obj) => {
    console.log(obj, props);

    // First start loading the model
    setLoading(true);

    //First load records
    try {
      if (!obj) {
        return;
      }
      let query = `token=${user.Token}&ParentGUID=${props.SpecialRate.GUID}&PolicyType=${obj}`;
      // if (search.length > 0 && cleanFilter == null) {
      //   query += "&Search=" + search;
      // }

      let request = await API.getAction(
        "api/contracts/SpecialRatePoliciesAvailable",
        query
      );
      let results = request.data[0];
      if (!results.JSONData) {
        setPoliciesAvailable([]);
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setPoliciesAvailable([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setPoliciesAvailable(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      setLoading(false);
    }
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj);

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    // confirmAlert({
    //   closeOnClickOutside: false,
    //   message: "Are you sure you want to save this record?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: async () => {
    try {
      let objSPVersion = ` @IdContractPolicy=${obj.IdContractPolicy}, @IdContractsToSpecialsRate=${props.SpecialRate.Id}`;

      if (actionMode === "U") {
        console.log("selected item", selectedItem);
        objSPVersion += `, @GUID='${selectedItem.GUID}'`;
      }
      console.log(props.SpecialRate);
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}&ParentGUID=${props.SpecialRate.GUID}`;

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.postAction(
        "api/contracts/SpecialRatePoliciesAddEdit" + "?" + queryString,
        queryData
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      toggleOpen(null, true);
    } catch (error) {
      console.error(error);
    }
  };

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      setTableHeader([
        {
          FieldName: "PolicyTypeDesc",
          FieldType: "varchar",
          FieldTitle: t("Policy Type"),
          HtmlType: "text",
        },
        {
          FieldName: "Description",
          FieldType: "varchar",
          FieldTitle: t("Description"),
          HtmlType: "text",
        },
      ]);
      return;
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}`;
      let requestSections = await API.getAction(
        `api/contracts/SpecialRatePoliciesHeader`,
        querySections
      );
      console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      console.log(recordResultSection);
      //setFormSize(recordResultSection[0].FormSize ?? 'md');

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      console.log(recordResultSection);

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    try {
      setActionMode(action);

      if (action === null) {
        setSelectedItem(null);
        formik.resetForm();
      } else {
        bindDataPoliciesAvailableRemote();
      }

      if (action === "I") {
        setSelectedItem(null);
      }
      if (action === "U" || action === "C" || action === "D") {
        await bindDataDetailsRemote(selectedItem.GUID);
      }

      if (selectedItemRef.current) {
        selectedItemRef.current.clearSelection();
      }

      setGeneralError(null);
      setShowAddEdit(!showAddEdit);
    } catch (E) {
      console.error(E);
    } finally {
      //if (reload) {
      await bindDataRecordRemote();
      //}
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const updateFieldEmpty = () => {};

  useEffect(() => {
    bindDataHeaderRemote();
    bindDataRecordRemote();
  }, []);
  // console.log(records)
  return (
    <Container id="tab-grid-body" fluid className=" ">
      <Modal
        show={showAddEdit}
        onHide={() => toggleOpen(null, true)}
        backdrop="static"
        keyboard={false}
        size={props.modalSize ?? "md"}
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Policy")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Container fluid className="overflow-auto">
              <Row className="gy-5 mb-4">
                <Col xs={12}>
                  <label className="me-2 mt-2">
                    {t("Policy Type")}
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  </label>
                  <br />
                  <FieldSelector
                    model={{
                      IdAppForm: 62,
                      Id: 6200009,
                      FieldName: "PolicyType",
                      FieldType: "varchar",
                      FieldTitle: t("Policy Type"),
                      Position: 9,
                      cssClasss: "col-12 col-md-4",
                      HtmlType: "radio",
                      PlaceHolder: "",
                      DefaultValue: "",
                      OptionValues: `[{"id": "C","text": 
                        "Cancelation"
                      },{"id": "D","text": 
                        "Deposit"
                      },{"id": "N","text": "No Show"}]`,
                      DataSource: null,
                      Required: true,
                      Visible: true,
                      ReadOnly: false,
                    }}
                    updateField={updateFieldRadio}
                    value={formik.values["PolicyType"]}
                  ></FieldSelector>
                </Col>
                <Col xs={12} className="mt-0">
                  <label className="me-2 mt-2">
                    {t("Policy")}
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  </label>
                  <br />
                  <FieldSelector
                    model={{
                      IdAppForm: 62,
                      Id: 6200009,
                      FieldName: "IdContractPolicy",
                      FieldType: "bigint",
                      FieldTitle: t("Contract Policy"),
                      Position: 9,
                      cssClasss: "col-12 ",
                      HtmlType: "select",
                      PlaceHolder: "",
                      DefaultValue: "",
                      OptionValues: JSON.stringify(
                        policiesAvailable.map((item) => {
                          console.log(item);
                          return { id: item.Id, text: item.Description };
                        })
                      ), //"[{\"id\": \"C\",\"text\": \"Cancelation\"},{\"id\": \"D\",\"text\": \"Discount\"},{\"id\": \"N\",\"text\": \"No Show\"}]",
                      //   "DataSource": null,
                      Required: true,
                      Visible: true,
                      ReadOnly: false,
                    }}
                    updateField={updateField}
                    value={formik.values["IdContractPolicy"]}
                  ></FieldSelector>
                </Col>
              </Row>

              {generalError ? (
                <Row>
                  <Col xs={12}>
                    <div className="alert alert-danger" role="alert">
                      {generalError}
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gx-3 align-items-center justify-content-end">
              {Object.values(formik.errors).length > 0 && (
                <span className="invalid me-2">
                  {t("Please check the forms for errors")}
                </span>
              )}
              <button
                className="btn me-2"
                type="button"
                onClick={() => toggleOpen(null, true)}
              >
                {t("Close")}
              </button>
              <button
                className="btn btn-primary me-2"
                type="button"
                onClick={() => [formik.submitForm()]}
              >
                {t("Save")}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card>
        <Card.Body className="p-2">
          <Row className="mx-0">
            <Col>
              <table className=" table table-bordered table-striped">
                <thead>
                  <tr>
                    {tableHeader &&
                      tableHeader.map((item, index) => {
                        // console.log(item)
                        return (
                          <th
                            key={index}
                            className={`fw-bold ${
                              index === 0 && "text-center"
                            }`}
                          >
                            {item.FieldTitle ?? item.FieldName}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {
                    // console.log(records)
                  }
                  {records && records.length === 0 ? (
                    <tr>
                      <td
                        colSpan={tableHeader.length}
                        className="text-center py-4"
                      >
                        {t("No data")}
                      </td>
                    </tr>
                  ) : (
                    records &&
                    records.length > 0 &&
                    records.map((item, index) => {
                      console.log(item);
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            toggleSelectItem(item);
                          }}
                          className={
                            selectedItem && selectedItem.GUID === item.GUID
                              ? "active"
                              : ""
                          }
                        >
                          {tableHeader &&
                            tableHeader.map((column, index2) => {
                              return (
                                <td
                                  key={index2}
                                  className={` ${
                                    index2 === 0 && "text-center"
                                  }`}
                                >
                                  {typeof item[column.FieldName] ===
                                  "boolean" ? (
                                    <input
                                      type="checkbox"
                                      checked={item[column.FieldName]}
                                      className=""
                                    />
                                  ) : (
                                    item[column.FieldName]
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={"auto"} className=" gap-2">
              <button
                className={`btn btn-primary btn-sm ${
                  selectedItem === "null" && "disabled"
                }`}
                onClick={() => {
                  toggleOpen("I");
                }}
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
              {selectedItem && (
                <>
                  <br />
                  <button
                    className={`btn btn-sm text-danger `}
                    onClick={() => deleteConfirm(selectedItem)}
                    type="button"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SpecialRatePolicies;
