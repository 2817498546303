const maintenanceUserRolesData = {
  title: "User Role",
  type: "form",
  editable: true,
  records: {
    fieldsTitles: [],

    fieldsRows: [],
  },
  fields: [
    {
      title: "Description",
      value: "",
      htmlType: "text",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "jdoe",
      name: "lastName",
    },

    {
      title: "Active",
      value: "",
      htmlType: "radio",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "NO" },
        { id: true, text: "YES" },
      ], //"false;NO|true;YES",
    },
    {
      title: "Comments",
      value: "",
      htmlType: "textarea",
      required: false,
      cssClass: "col-12 ",
      placeholder: "",
      name: "comments",
    },
  ],
};

export default maintenanceUserRolesData;
