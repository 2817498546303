import { t } from "i18next";

const CONTRACTPOLICIESFIELDS = [
  {
    FieldName: "",
    FieldType: "",
    FieldTitle: t("General Information"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: true,
  },

  {
    FieldName: "ChargeForm",
    FieldType: "char",
    FieldTitle: t("Charge Form"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "textCustomReadOnly",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "P","text": "Pax"},{"id":  "R","text": $
      "Room"
    }]`,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "ChargeType",
    FieldType: "char",
    FieldTitle: t("Charge Type"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "textCustomReadOnly",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id":  "A","text": "Amount"},{"id":  "P","text": 
      "Percentage"
    }]`,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "ChargeValue",
    FieldType: "money",
    FieldTitle: t("Charge Value"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "NightsToCharge",
    FieldType: "int",
    FieldTitle: t("Nights To Charge"),
    cssClasss: "col-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "PriorArrivalDays",
    FieldType: "int",
    FieldTitle: t("Prior Arrival Days"),
    cssClasss: "col-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    cssClasss: "col-12",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: '{"1"}',
    OptionValues: `[{"id": 1,"text": "Yes"},{"id": 0,"text": 
      "No"
    }]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    FieldName: "",
    FieldType: "",
    FieldTitle: t("Audit Information"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },

  {
    FieldName: "DateAdd",
    FieldType: "varchar",
    FieldTitle: t("Date Added"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },

  {
    FieldName: "DateMod",
    FieldType: "varchar",
    FieldTitle: t("Last Modified"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-12 col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
];

export default CONTRACTPOLICIESFIELDS;
