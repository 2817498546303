import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

export const CommentsTable = ({commentData}) => {

  const [data, setData] = useState([])

  useEffect(() => {
    setData(commentData)
  }, [])
  
  return (
    <>
      <Table bordered className='border-black border-opacity-100 border-1' style={{ fontSize: '.8rem' }}>
      <thead>
      <tr>
        <th className='text-center'>Description</th>
        <th className='text-center'>Date</th>
      </tr>
    </thead>
        <tbody>
        {data.length > 0 ? data.map((comment) => (<tr key={comment.id}>
          <td className="text-start">{comment.description}</td>
          <td className="text-end">{comment.date}</td></tr>))
          : <tr><td colSpan="2" className="text-center">No data</td></tr>}
        </tbody>
      </Table>
    </>
  )
}
