import { t } from "i18next";

export const RoomsTypesFields = [
  {
    FieldName: "IdBusinessUnit",
    FieldType: "bigbit",
    FieldTitle: t("Business Unit"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: '{"HashID":""}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdRoomType",
    FieldType: "bigbit",
    FieldTitle: t("Rate Room Type"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"6cbf801d549b261dd16d9a34c6cfb96f77dddc04846999ece71d62cbef864851"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
    dependOf: "IdBusinessUnit",
  },
  //COMENTADO POR QUE LA DOCUMENTACION NO TIENE ESTA OPCION
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "RoomTypeQuantity",
    FieldType: "int",
    FieldTitle: t("Room Type Quantity"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: null,
    minValue: "1",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "PaxQuantity",
    FieldType: "int",
    FieldTitle: t("Pax Quantity"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: null,
    minValue: "1",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];
