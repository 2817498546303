import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import './fixed-table-style.css'
// import { RoomMapData } from './RoomMapExampleData'

const RoomMapContainer = ({ records }) => {
  const [RoomMapData, setRoomMapData] = useState([])

  useEffect(() => {
    if (records) {
      console.log(records)
      setRoomMapData(records)
    }
  }, [records])

  if (!records) {
    return <div>Loading...</div>
  }

  return (
    <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 mt-2 w-auto">
      {RoomMapData.length > 0 && RoomMapData?.map((item, index) => {
        const {Building} = item
        // console.log("🚀 ~ {RoomMapData.length>0&&RoomMapData?.map ~ item:", item)
        console.log(Building)
        return (
          <Card className="w-100 mb-4" key={`${index}-${Building.Name}`}>
            <Card.Body style={{overflow: 'auto'}}>
              <div 
                className='wrapper w-auto'
              >
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th colSpan={100} className="text-center">{Building?.Name}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Building?.RoomTypes ? Building?.RoomTypes.map((suite, suiteIndex) => {
                      // console.log("🚀 ~ {item.Building.RoomTypes.map ~ suite:", suite)
                      return (
                        <tr key={`${suiteIndex}-${suite.Id}`}>
                          <td className='sticky-col first-col'>{suite.Id}</td>
                          {suite.Room.length > 0 && suite.Room.map((room, index) => <td key={`${index}-${room.Number}`} className={`text-center text-white`} style={{ backgroundColor: room.Color }}>{room.Number}</td>)}
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={100} className="text-center">No rooms found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        )
      })}
    </div>
  )
}

export default RoomMapContainer
