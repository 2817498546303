import React, { Fragment, useState } from "react";
import { CustomBreadcrumbs as Breadcrumbs } from '../../components'
import { Container } from 'react-bootstrap'
import useFavorite from "../../hooks/useFavorite";
import { useLocation } from "react-router-dom";

const CheckInPage = () => {
  const { pathname } = useLocation();
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const addFavorite = async () => {
    const newFavorite = {
      icon: 'fa-solid fa-home',
      path: pathname,
      formPath: 'Front-Desk / Front-Desk / Check In',
      title: 'Room Ocupation',
    }
    UpdateFavorites(newFavorite)
  }

  return (
    <React.Fragment>
      <Breadcrumbs pathTitle={'Front-Desk / Front-Desk / Check In'} />
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Check In</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">

                {actionMode === null ? (
                  <>
                    <button className="btn btn-lg my-1 me-2" title="Export to PDF" disabled={isLoading} onClick={() => { }}>
                      <i className="fa-solid fa-file-pdf"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Export to Excel" disabled={isLoading} onClick={() => { }}>
                      <i className="fa-solid fa-file-excel"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send email" disabled={isLoading} onClick={() => { }}>
                      <i className="fa-solid fa-envelope"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send to print" disabled={isLoading} onClick={() => { }}>
                      <i className="fa-solid fa-print"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-sm  my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
              </div>
            </div>

          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default CheckInPage
