import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import TreeView from "devextreme-react/tree-view";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import "./SideNavigationMenu.scss";

import * as events from "devextreme/events";
import { db } from "../../utils/db";

import { useTranslation } from "react-i18next";

export default function SideNavigationMenu(props) {
  const { t } = useTranslation();

  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const { isLarge } = useScreenSize();
  const normalizePath = async () => {
    let menuObj = await db.menu.toCollection().first();
    if (!menuObj) {
      return [];
    }
    //debugger;

    let finalMenu = menuObj.map((item) => {
      // console.log("menu option", item);
      return {
        ...item,
        expanded: isLarge,
        path: null, //item.ItemPath && !/^\//.test(item.ItemPath)? `/${item.ItemPath}`: item.ItemPath,
      };
    });
    // console.log(finalMenu);
    return finalMenu;
  };

  const [items, setItems] = useState([]);
  useMemo(async () => {
    let menu = await normalizePath();

    setItems(menu);
    setTimeout(() => {
      const treeView = treeViewRef.current && treeViewRef.current.instance;
      if (treeView) {
        treeView.collapseAll();
      }
    }, 500);
  }, []);
  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  /* const changeText = () => {
    let result = items;

    console.log("lista", JSON.stringify(result));

    for (const item in items) {
      result = [{ ...items, [item]: t(items[item].text) }];

      setItems({ ...items, [item]: t(items[item].text) });

      console.log(t(items[item].text));
      console.log(items[item]);
    }

    console.log(result);
  };

  console.log(changeText()); */

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"} style={{ maxHeight: "100%" }}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"index"}
          selectionMode={"single"}
          selectByClick={true}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
          style={{}}
        />
      </div>
    </div>
  );
}
