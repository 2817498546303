import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";

export const RequestHash = async (
  id,
  subArrayNameParsed = "",
  parsed = false
) => {
  let user = parsedUser();

  let query = `token=${user.Token}&id=${id}`;
  let requestAPI = await API.getAction("api/selector", query);

  let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);

  if (subArrayNameParsed.length > 0) {
    let resultParsed = parsed
      ? JSON.parse(result[subArrayNameParsed])
      : result[subArrayNameParsed];

    return resultParsed;
  } else {
    return parsed ? JSON.parse(result) : result;
  }
};
