import { useEffect, useState } from 'react'
import { parsedUser } from '../../../utils/GetCurrentUser'
import API from '../../../api/api';

const useCashierCustomerData = (props) => {
  const user = parsedUser();
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)

  useEffect(() => {
    getCashierCustomer(props);
  },[props])

  const getCashierCustomer = async (Accion) => {
    setIsLoading(true);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@Accion=${Accion}`,
      };
      let request = await API.postAction(
        `api/cashier/CashierCustomer?` + query,
        queryData ?? ""
      );
      let record = request.data[0].JSONData;
      let parsedRecord = JSON.parse(record)[0].Record;
      // console.log(parsedRecord);
      setData(parsedRecord);
      return parsedRecord;
    } catch (error) {
      console.log(error);
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { data, isLoading, isError, getCashierCustomer }
}

export default useCashierCustomerData
