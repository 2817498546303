import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../breadcrumb";
import { Button, Container } from "react-bootstrap";
import TasksAssigmentDetailsFilter from "./TasksAssigmentDetailsFilter";
import Pagination from "../../Pagination/Pagination";
import moment from "moment/moment";
import TasksAssignmentPlanning from "./TasksAssignmentPlanning/TasksAssignmentPlanning";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";

const TasksAssigmentDetailsContainer = ({ routeName }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);

  const user = parsedUser();

  const [action, setAction] = useState("");

  const [showTasksPlanning, setShowTasksPlanning] = useState(false);

  const principalButtonList = [
    {
      id: "editBtn",
      title: t("Edit"),
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B"],
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: "newBtn",
      title: t("New"),
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleAddEdit("P"),
    },
    {
      id: "cancelBtn",
      title: t("Cancel"),
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => deleteConfirm(),
    },
  ];

  const requestRecords = async (filter) => {
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&PageNumber=1&Quantity=20&SortBy=Id&SortType=DESC`;

      let queryData = {
        Data: filter,
      };

      console.log(queryData);
      let requestAPI = await API.postAction(
        "/api/AutomaticTasksAssigments/Records?" + queryString,
        queryData
      );

      let newData = GetDataOfJSON(
        requestAPI,
        "data",
        0,
        "JSONData",
        0
      ).JSONData;

      console.log(newData);

      if (newData && newData.length > 0) {
        setRecords(newData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    requestRecords();
  }, [showTasksPlanning]);

  const toggleAddEdit = (action) => {
    setShowTasksPlanning(!showTasksPlanning);

    setAction(action);

    if (action === "P") setSelectedItem(null);
  };

  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `IdUser=${user.IdUser}token=${user.Token}&RecordId=${selectedItem.GUID}`;
              await API.getAction(
                "api/AutomaticTasksAssigments/Delete?" + query
              );
              await requestRecords();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  return (
    <>
      {showTasksPlanning ? (
        <TasksAssignmentPlanning
          show={showTasksPlanning}
          toggle={toggleAddEdit}
          selectedItem={selectedItem}
          action={action}
          title={selectedItem ? t("Edit") : t("New")}
        />
      ) : null}

      <CustomBreadcrumbs pathTitle={routeName} />

      <Container className="mt-0 pt-0">
        <div className="row mx-3">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fas fa-broom me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>
                        {t("Automatic Task Assigment")}
                      </h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div
              className="py-2 z-2"
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#f2f2f2",
              }}
            >
              <div className="d-flex w-10 mt-0 justify-content-start gap-1">
                {principalButtonList.map((item, index) => {
                  // console.log(selectedItem);
                  if (item.ifSelectedItem && selectedItem) {
                    //Validate if checking is pressed

                    return (
                      <Button
                        key={index}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    );
                  }
                  if (!item.ifSelectedItem) {
                    return (
                      <Button
                        key={index}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
          <TasksAssigmentDetailsFilter
            count={recordCount}
            filterRecord={requestRecords}
          />

          <div
            className="table-responsive wrapper mb-1"
            style={{ overflowY: "auto", maxHeight: "400px" }}
          >
            <table className="table table-bordered shadow-sm table-striped">
              <thead>
                <tr>
                  <th className="text-start text-nowrap">
                    {t("Automatic Tasks Type")}
                  </th>
                  <th className="sticky-col second-col">{t("Start Date")}</th>
                  <th>{t("Business Unit")}</th>
                  <th>{t("Total Collaborators")}</th>
                </tr>
              </thead>
              <tbody>
                {records && records.length > 0 ? (
                  records.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={` clickable ${
                          item === selectedItem ? " active" : ""
                        }`}
                        onClick={() => setSelectedItem(item)}
                      >
                        <td className="text-start">{item.AutomaticTaskType}</td>
                        <td>
                          {item.TaskDate
                            ? moment(item.TaskDate).format("MM/DD/YYYY")
                            : null}
                        </td>
                        <td className="text-nowrap">{item.BusinessUnit}</td>
                        <td>{item.TotalAttendants}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="border-1 text-center" colSpan={12}>
                      {t("No Records Found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoading}
            RecordsQuantity={RecordsQuantity}
            recordCount={recordCount}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setRecordsQuantity={setRecordsQuantity}
          />
        </div>
      </Container>
    </>
  );
};

export default TasksAssigmentDetailsContainer;
