import React from 'react'
import { IncidentTrackingContainer } from '../../components'


const IncidentTrackingHouseKeeping = () => {
  return (
    <>
      <IncidentTrackingContainer routeName={'Housekeeping / Process / Incident Tracking'}
        module="houseKeeping" />
    </>
  )
}

export default IncidentTrackingHouseKeeping
