import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import FieldSelector from "../../customComponents/FieldSelector";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import { GuestGeneratorFields } from "../fields/GuestGeneratorFIelds";
import { GenericFields } from "../../group-reservation/GenericFields";
import { useToast } from "../../../contexts/toast";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";

import { useTranslation } from "react-i18next";

export const GuestGenerator = ({
  show,
  toggle,
  selectedReservation,
  currentGuestList,
}) => {
  const { t } = useTranslation();

  const fields = GuestGeneratorFields;
  const toast = useToast();
  const [validationsSchema, setValidationSchema] = useState(null);

  const user = parsedUser();

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (obj) => {
    try {
      console.log(obj);
      // formik.validateForm();
      if (formik.values.Adults === 0) {
        toast({
          type: "error",
          message: t("Please enter number of adults"),
        });
        return;
      }
      // if (!formik.isValid) {
      //   return;
      // }
      toast({
        type: "success",
        message: t("Guests generated successfully"),
      });

      let objSPVersion = "";
      fields.forEach((item) => {
        if (item.ReadOnly || item.HtmlType === "separator") return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });

      objSPVersion += `, @GUIDReservation='${selectedReservation.GUID}'`;

      let queryData = {
        Data: objSPVersion,
      };

      console.log(queryData);

      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let requestAPI = await API.postAction(
        "api/Reservations/ReservationsInsertGuest?" + query,
        queryData
      );

      let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
      console.log(requestAPI);
      console.log(result);

      if (!result.error) toggle(true);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, item.DefaultValue);
      });
    }
    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.DefaultValue;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    console.log(initialObject);
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    if (selectedReservation) {
      console.log("prueba");
      fillFormikObject();
    }
  }, [selectedReservation]);

  console.log(formik.values);

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4>{t("Guest Generator")}</h4>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Row className="py-0">
              <Col xs={12}>
                <Row className="mx-0">
                  <GenericFields
                    fields={fields.filter((x) => x.GroupBy === 0)}
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                    updateFields={updateField}
                  />
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => toggle(false)}>
              {t("Cancel")}
            </button>
            <button
              className="btn btn-primary"
              /*  onClick={() => formik.handleSubmit()} */
              type="submit"
            >
              <i className="fa fa-gears me-2"></i>
              {t("Generate")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
