import { t } from "i18next";

const VirtualRoomFields = [
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Name",
    FieldType: "bigint",
    FieldTitle: t("Name"),
    Position: 1,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdCustomerType",
    FieldType: "bigint",
    FieldTitle: t("Customer Type"),
    Position: 2,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountry",
    FieldType: "bigint",
    FieldTitle: t("Country"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdParent",
    FieldType: "bigint",
    FieldTitle: t("Parent"),
    Position: 4,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountryState",
    FieldType: "bigint",
    FieldTitle: t("State"),
    Position: 5,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address1",
    FieldType: "bigint",
    FieldTitle: t("Address 1"),
    Position: 6,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address2",
    FieldType: "bigint",
    FieldTitle: t("Address 2"),
    Position: 7,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "City",
    FieldType: "bigint",
    FieldTitle: t("City"),
    Position: 8,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "ZipCode",
    FieldType: "bigint",
    FieldTitle: t("Zip Code"),
    Position: 9,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "WebSite",
    FieldType: "bigint",
    FieldTitle: t("Web Site"),
    Position: 10,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export default VirtualRoomFields;

export const ContractPolicesCancelationFields = [
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "IdContractPolicy",
    FieldType: "bigint",
    FieldTitle: t("Contract Policy"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeForm",
    FieldType: "char",
    FieldTitle: t("Charge Form"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "P","text": 
      "Per Person"
    ,{"id": "N","text": "Per Night"}]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeType",
    FieldType: "char",
    FieldTitle: t("Charge Type"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "A","text": "Amount"},{"id": "P","text": "Percentage"}]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeValue",
    FieldType: "money",
    FieldTitle: t("Charge Value"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "PriorArrivalDays",
    FieldType: "char",
    FieldTitle: t("Prior Arrival Days"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "B","text": 
      "Days After Booking"
    },{"id": "A","text": "Days Before Arrival"}]`,
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "NightsToCharge",
    FieldType: "int",
    FieldTitle: t("Prior Arrival Days"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "1",
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": 
      "Yes"
    }]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
];

export const ContractPolicesDepositFields = [
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "IdContractPolicy",
    FieldType: "bigint",
    FieldTitle: t("Contract Policy"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeForm",
    FieldType: "char",
    FieldTitle: t("Deposit Type"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "P","text": 
      "Per Person"
    },{"id": "N","text": "Per Night"}]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeType",
    FieldType: "char",
    FieldTitle: t("Charge Type"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "A","text": "Amount"},{"id": "P","text": 
      "Percentage"
    }]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeValue",
    FieldType: "money",
    FieldTitle: t("Charge Value"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "PriorArrivalDays",
    FieldType: "char",
    FieldTitle: t("Days"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "B","text": 
      "Days After Booking"
    )}},{"id": "A","text": "Days Before Arrival"}]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "NightsToCharge",
    FieldType: "int",
    FieldTitle: t("Days Quantity"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "1",
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": 
      "Yes"
    }]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
];
export const ContractPolicesNoShowFields = [
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "IdContractPolicy",
    FieldType: "bigint",
    FieldTitle: t("Contract Policy"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeForm",
    FieldType: "char",
    FieldTitle: t("Charge Form"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "P","text": 
      "Per Person"
    },{"id": "N","text": "Per Night"}]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeType",
    FieldType: "char",
    FieldTitle: t("Charge Type"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "A","text": "Amount"},{"id": "P","text": 
      "Percentage"
    }]`,
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "ChargeValue",
    FieldType: "money",
    FieldTitle: t("Charge Value"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "PriorArrivalDays",
    FieldType: "char",
    FieldTitle: t("Days"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": "B","text": 
      "Days After Booking"
    },{"id": "A","text": "Days Before Arrival"}]`,
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "NightsToCharge",
    FieldType: "int",
    FieldTitle: t("Nights To Charge"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: true,
    Visible: true,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "1",
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": 
      "Yes"
    }]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    dependOf: null,
  },
];
