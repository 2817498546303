import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { HeaderSpecialRateOccupationTable } from './HeaderSpecialRateOccupationTable' 
import { RowSpecialRateOccupationTable } from './RowSpecialRateOccupationTable' 

export const CustomSpecialRateOccupationTable = ({SpecialRateBUTable}) => {

  const [data, setData] = useState([])
  const [maxChildren, setMaxChildren] = useState(0)
  const [maxRooms, setMaxRooms] = useState(0)
  const [maxOccupation, setMaxOccupation] = useState(0)

  const getMaxRooms = () => {
    let max = 0
    data.forEach((element) => {
      if (element.roomData.length > max) {
        max = element.roomData.length
      }
    })
    setMaxRooms(max)
    // console.log({Rooms: max})
  }

  const getMaxOccupation = () => {
    let max = 0
    data.forEach((element) => {
      element.roomData.forEach((room) => {
        if (room.occupation_type.length > max) {
          max = room.occupation_type.length
        }
      })
    })
    setMaxOccupation(max)
    // console.log({Occupation: max})
  }

  const getMaxChildren = () => {
    let max = 0
    data.forEach((element) => {
      element.roomData.forEach((room) => {
        if (room.children.length > max) {
          max = room.children.length
        }
      })
    })
    setMaxChildren(max)
    // console.log({Occupation: max})
  }

  useEffect(() => {
    // console.log('SpecialRateBUTable', SpecialRateBUTable)
    setData(SpecialRateBUTable)
  }, [SpecialRateBUTable])

  useEffect(() => {
    getMaxRooms()
    getMaxChildren()
    getMaxOccupation()
  },[data])
  
  
  return (
    <Table bordered className='border-black border-opacity-100 border-1' style={{ fontSize: '.8rem' }}>
      <HeaderSpecialRateOccupationTable maxChildren={maxChildren} maxOccupation={maxOccupation} />
      <tbody>
        {data ? data.map((dataRow, index) => <RowSpecialRateOccupationTable key={index} dataRow={dataRow} maxOccupation={maxOccupation} maxChildren={maxChildren} />) : <tr><td colSpan="6" className="text-center">No data</td></tr>}
      </tbody>
    </Table>
  )
}
