import React, { useEffect, useState } from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Title,
  Font,
  Margin,
  Size,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import API from "../../api/api";
import Loading from "../Loading";

const palleteColor = ["#00b500", "#993399"];

const dataTest = [
  { title: "Completed", total: 85 },
  { title: "Pending", total: 15 },
];

const HousekeepingTaskState = ({ data, viewTotalValue }) => {
  const customizeLabel = (e) => {
    return `${e.valueText}`;
  };

  const pies = () => (
    <PieChart
      id="pie-chart"
      dataSource={dataTest}
      resolveLabelOverlapping="shift"
      innerRadius={0.65}
      centerRender={(e) => CenterTemplate(e, viewTotalValue)}
      palette={palleteColor}
    >
      <Series argumentField="title" valueField="total">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
        >
          <Font size={16} />
          <Connector visible={true} />
        </Label>
      </Series>

      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Title
        text="Housekeeping Task State"
        horizontalAlignment="center"
        verticalAlignment="top"
      >
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </PieChart>
  );

  return (
    <div className="bg-white shadow p-3 text-center" style={{ borderRadius: 25 }}>
     {/*  {data && data.length > 0 ? pies() : <Loading />} */}
     {pies()}
    </div>
  );
};

export default HousekeepingTaskState;
