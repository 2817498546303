import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { HeaderTable } from './HeaderBusinessUnitTable'
import { RowTable } from './RowBusinessUnitTable'

export const CustomBusinessUnitTable = ({businesssUnitData}) => {

  const [data, setData] = useState([])
  const [maxChildren, setMaxChildren] = useState(0)
  const [maxRooms, setMaxRooms] = useState(0)
  const [maxOccupation, setMaxOccupation] = useState(0)

  const getMaxRooms = () => {
    let max = 0
    data.forEach((element) => {
      if (element.roomData.length > max) {
        max = element.roomData.length
      }
    })
    setMaxRooms(max)
  }

  const getMaxOccupation = () => {
    let max = 0
    data.forEach((element) => {
      element.roomData.forEach((room) => {
        if (room.occupation_type.length > max) {
          max = room.occupation_type.length
        }
      })
    })
    setMaxOccupation(max)
  }

  const getMaxChildren = () => {
    let max = 0
    data.forEach((element) => {
      element.roomData.forEach((room) => {
        if (room.children.length > max) {
          max = room.children.length
        }
      })
    })
    setMaxChildren(max)
  }

  useEffect(() => {
    setData(businesssUnitData)
  }, [])

  useEffect(() => {
    getMaxRooms()
    getMaxChildren()
    getMaxOccupation()
  }, [data])


  return (
    <Table bordered className='border-black border-opacity-100 border-1' style={{ fontSize: '.8rem' }}>
      <HeaderTable maxChildren={maxChildren} maxOccupation={maxOccupation} />
      <tbody>
        {data && data.map((dataRow, index) => <RowTable key={index} dataRow={dataRow} maxOccupation={maxOccupation} maxChildren={maxChildren} />)}
      </tbody>
    </Table>
  )
}
