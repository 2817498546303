import { CleaningServices, Search } from "@mui/icons-material";
import { DateBox, SelectBox } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";

import React, { useMemo, useState } from "react";
import { Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const IncidentTaskFilter = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState([]);

  const textButton = useMemo(
    () => ({
      icon: "search",
      elementAttr: { class: "me-0" },
      stylingMode: "text",
    }),
    []
  );
  return (
    <>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <h6>{t("Filters")}</h6>
            </div>
            <div
              className="col-12 p-4 py-3 shadow rounded"
              style={{ backgroundColor: "rgb(245 245 245)" }}
            >
              <div className="row ms-1 me-2">
                <div className="col-sm-12 col-md-2">
                  <label>{t("Department")}</label>
                  <SelectBox
                    items={["Example1", "Example2"]}
                    inputAttr={{ "aria-label": "Simple Product" }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Priority")}</label>
                  <SelectBox
                    items={["Example1", "Example2"]}
                    inputAttr={{ "aria-label": "Simple Product" }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Status")}</label>
                  <SelectBox
                    items={["Example1", "Example2"]}
                    inputAttr={{ "aria-label": "Simple Product" }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Business Unit")}</label>
                  <SelectBox
                    items={["Example1", "Example2"]}
                    inputAttr={{ "aria-label": "Simple Product" }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Room")}</label>

                  <TextBox stylingMode="filled">
                    <TextBoxButton
                      name="room"
                      location="after"
                      options={textButton}
                    />
                  </TextBox>
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Ticket #")}</label>

                  <TextBox>
                    <TextBoxButton
                      name="ticket"
                      location="after"
                      options={textButton}
                    />
                  </TextBox>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label>{t("Description")}</label>
                  <TextBox />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("Start Date")}</label>
                  <DateBox inputAttr={{ "aria-label": "Date" }} type="date" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("End Date")}</label>
                  <DateBox inputAttr={{ "aria-label": "Date" }} type="date" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <label>{t("By time")}</label>
                  <SelectBox
                    items={["Example1", "Example2"]}
                    inputAttr={{ "aria-label": "Simple Product" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="text-end">
          <button
            className="btn btn-sm btn-secondary me-2"
            type="button"
            title={t("Clear")}
            onClick={() => console.log("Clear")}
          >
            <i className="fas fa-eraser"></i>
          </button>
          <button
            className="btn btn-sm btn-primary"
            title={t("Search")}
            type="submit"
          >
            <Search />
          </button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default IncidentTaskFilter;
