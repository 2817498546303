import { t } from "i18next";

export const GroupTabFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "bit",
    FieldTitle: t("General Information"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdReservationCustomer",
    FieldType: "bigint",
    FieldTitle: t("Billing Reservation Customer"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"7ccc653eabfbb0850bb1b03cbe29c47399e1103ad8124bf28ae4e6b4b50ef3e2"}',

    Required: false, // Debe ser true, pero para fines practicos se ha puesto false.
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "GroupName",
    FieldType: "varchar",
    FieldTitle: t("Group Name"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "null",
    FieldTitle: t("Address Information"),
    Position: 2,
    cssClasss: "col-12 ",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Address",
    FieldType: "varchar",
    FieldTitle: t("Address"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdCountry",
    FieldType: "int",
    FieldTitle: t("Country"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"b5aa6f64a52e815bafe085d4364b48b2c23779dcd503a53781aa04e618a621c9"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdCountryState",
    FieldType: "varchar",
    FieldTitle: t("State"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"8936cd0f50ea6d0c6d4793b36bc79e93cd62260ec560d505181ae8856a1f0e06"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
    dependOf: "IdCountry",
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "City",
    FieldType: "varchar",
    FieldTitle: t("City"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "ZipCode",
    FieldType: "varchar",
    FieldTitle: t("Zip Code"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "varchar",
    FieldTitle: t("Contact Information"),
    Position: 4,
    cssClasss: "col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "ContactName",
    FieldType: "varchar",
    FieldTitle: t("Contact Name"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Phone1",
    FieldType: "varchar",
    FieldTitle: t("Phone 1"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Phone2",
    FieldType: "varchar",
    FieldTitle: t("Phone 2"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Email",
    FieldType: "varchar",
    FieldTitle: t("Email"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "email",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];
