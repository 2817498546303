import { t } from "i18next";

export const RateTabFields = [
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBusinessUnit", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "spinner",
    FieldTitle: t("Billing Business Unit"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCustomer",
    FieldType: "varchar",
    FieldTitle: t("Customer"),
    Position: 1,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "ArrivalDate",
    FieldType: "datetime",
    FieldTitle: t("Arrival"),
    Position: 2,
    cssClasss: "col-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Nights",
    FieldType: "int",
    FieldTitle: t("Nights"),
    Position: 2,
    cssClasss: "col-3",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "DepartureDate",
    FieldType: "datetime",
    FieldTitle: t("Departure"),
    Position: 2,
    cssClasss: "col-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
    minValueDependOf: "ArrivalDate",
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdMealPlan",
    FieldType: "bit",
    FieldTitle: t("Meal Plan"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"4bf2a611eaf1b54c557e672a9976de2d180972861db45c396f5a7c95f2819576"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdSpecialCode",
    FieldType: "bigint",
    FieldTitle: t("Special Code"),
    Position: 3,
    cssClasss: " col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"78b1523e6fa39d70f4a0333d0d13722d00025027ceffe056d5677ec1d8b67331"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "BookingDate",
    FieldType: "date",
    FieldTitle: t("Booking Date"),
    Position: 3,
    cssClasss: "col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    FieldName: "IdRateCategoryType",
    FieldType: "int",
    FieldTitle: t("Rate Category Type"),
    Position: 3,
    cssClasss: "col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"97cf9a0606f82ed6e8885961e5fbce5cdd82909e2343e3f5b2e38fc31807ae95"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "",
    FieldType: "",
    FieldTitle: t("Warranty Information"),
    Position: 3,
    cssClasss: " col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: "RateAssigned",
    FieldName: "RateAssigned",
    FieldType: "int",
    FieldTitle: t("Rate Assigned"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Voucher",
    FieldType: "varchar",
    FieldTitle: t("Voucher"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];
