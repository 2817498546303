import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AxiosInstance } from "../api/api";
import { useAuth } from "./auth";

const AxiosInterceptor = ({ children }) => {

    const { signOut } = useAuth();

    const isRunning = useRef(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({
        title: "",
        body: "",
    });

    const toggleModal = ({ title = "Error with some request", body }, closeCallback = () => { }) => {
        setModalMessage({ title, body });
        setShowModal(true);
        closeCallback();
    }

    useEffect(() => {
        // if(isRunning.current) return;
        console.log("axios!!!")
        const resInterceptor = response => {

            const { data } = response;
            if (data.status === "error") {
                // activeModal({ "body": data.response.replace(/^\s+|\s+$/g, '') })
            }

            const parsedData = (data && data[0]) ? (data[0].JSONData ? JSON.parse(data[0].JSONData)[0] : data[0]) : null;

            if (parsedData) {

                if (parsedData?.Error) {
                    console.log("The response error ==== ", parsedData.Error)
                    console.log("The response error message ==== ", parsedData.Msg)

                    // activeModal({ "body": parsedData.Msg })
                }
                if (parsedData?.Expired || parsedData?.Logout || parsedData?.Msg === 'MsgSessionTokenNoExiste' || parsedData?.Msg === 'MsgSessionExpired') {
                    
                    if(showModal) return;
                    toggleModal({ "body": parsedData.Msg }, () => {
                        setTimeout(() =>  [setShowModal(false), signOut()], 2500)
                    })
                }
            }
            return response;
        }

        const errResInterceptor = error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("❗ ~ file: axios.js:60 ~ errResInterceptor ~ error.response:", error.response)

                toggleModal({ "body": error.response.data.replace(/[\r\n]/gm, '')})
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("❗ ~ file: axios.js:67 ~ errResInterceptor ~ error.request:", error.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("❗ ~ file: axios.js:70 ~ errResInterceptor ~ error.message:", error.message)
              }
              console.log(error.config);
            return Promise.reject(error)
        }

        AxiosInstance.interceptors.response.use(resInterceptor, errResInterceptor, { synchronous: true })

        isRunning.current = true

        return () => {
            console.log("axios closed!!!")
            AxiosInstance.interceptors.response.clear();
        }
    }, [window.location.pathname])


    const ErrorModal = () => {
        return (
            <Modal backdrop="static" className="error-modal" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <i className="fa-solid fa-triangle-exclamation"></i>

                    <Modal.Title>{modalMessage.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="w-100">{modalMessage.body}</p>
                </Modal.Body>
            </Modal>
        )
    }

    return isRunning && (
        <>
            {children}
            {<ErrorModal />}
        </>
    );
}

export default AxiosInterceptor;