export const HeadReservationCardFields = [
  {
    IdAppForm: 1,
    Id: 1,
    FieldName: "IdRestaurant",
    FieldType: "bigint",
    FieldTitle: "Restaurant Selection",
    Position: 2,
    cssClasss: "col-8",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID":"087854a518ffe92c940dee83080a5816ef4e7d5d46354d831f71953799b467ca"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 2,
    Id: 2,
    FieldName: "ReservationDate",
    FieldType: "datetime",
    FieldTitle: "Date",
    Position: 2,
    cssClasss: "col-4",
    HtmlType: "date",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
];
