import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, GroupItem, GroupPanel, Grouping, Pager, Paging, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import React, { forwardRef } from 'react';
import { dateFormater } from '../../../utils/date-formater';

const allowedPageSizes = [10, 20, 50, 100, "All"];

const CustomReportsDataGrid = forwardRef((props, ref) => {
  const { records, summary, headers } = props;
  // const [records, setRecords] = useState([]);
  // const [summary, setSummary] = useState([]);
  // const [headers, setHeaders] = useState([]);

  const GroupCell = options => <div>{options.value}</div>;

  return (
    <DataGrid
      ref={ref}
      dataSource={records}
      columnAutoWidth={true}
      showBorders={true}
      className="grid"
      loadPanel={{
        enabled: true,
        showIndicator: true,
        text: "Loading...",
      }}
    >
      <ColumnFixing enabled={true} />
      <Grouping autoExpandAll={true} />
      <GroupPanel visible={false} />
      <Summary>
        {summary?.Group &&
          summary?.Group.map((item, index) => {
            return (
              <GroupItem
                key={index}
                column={item?.FieldName}
                summaryType={item?.SummaryType}
                displayFormat={item?.DisplayFormat}
                valueFormat={item?.FormatValue}
                showInColumn={item?.DisplayInColumn}
                showInGroupFooter={item?.DisplayInGroupFooter}
              />
            );
          })}
        {summary?.Total &&
          summary?.Total.map((item, index) => {
            return (
              <TotalItem
                key={index}
                column={item?.FieldName}
                summaryType={item?.SummaryType}
                displayFormat={item?.DisplayFormat}
                valueFormat={item?.FormatValue}
                showInColumn={item?.DisplayInColumn}
              />
            );
          })}
      </Summary>
      <Selection mode="single" />
      {headers && headers.map((item, index) => {
        if (item.SubColumns) {
          return (
            <Column
              key={index}
              caption={item?.FieldTitle}
              dataField={item?.FieldName}
              wordWrapEnabled={true}
              groupIndex={item?.groupIndex}
              groupCellRender={GroupCell}

              format={item?.FormatValue}
              alignment={"center"}
            >
              {item.SubColumns.map((subItem, subIndex) => {
                return (
                  <Column
                    key={subIndex}
                    caption={subItem?.FieldTitle}
                    dataField={subItem?.FieldName}
                    wordWrapEnabled={true}
                    groupIndex={subItem?.groupIndex}
                    groupCellRender={GroupCell}
                    format={subItem?.FormatValue}
                  // alignment={"center"}
                  />
                );
              })}
            </Column>
          );
        }
        if (item.FieldType === "timestamp") {
          return (
            <Column
              key={index}
              caption={item?.FieldTitle}
              dataField={item?.FieldName}
              dataType={'date'}
              wordWrapEnabled={true}
              groupIndex={item?.groupIndex}
              groupCellRender={GroupCell}

              format={dateFormater}
            />
          );
        }
        return (
          <Column
            caption={item?.FieldTitle}
            key={index}
            dataField={item?.FieldName}
            wordWrapEnabled={true}
            groupIndex={item?.groupIndex}
            groupCellRender={GroupCell}

            format={item?.FormatValue}
          />
        );
      })}
      <Paging defaultPageSize={50} />
      <Pager
        showInfo={true}
        infoText={"Page:"}
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
      />
    </DataGrid>
  )
})

export default CustomReportsDataGrid;