import React from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const Pagination = ({
  isLoading,
  RecordsQuantity,
  recordCount,
  totalPages,
  currentPage,
  setCurrentPage,
  setRecordsQuantity,
}) => {
  const { t } = useTranslation();

  const allowedPageSizes = [
    { value: 10, text: 10 },
    { value: 20, text: 20 },
    { value: 50, text: 50 },
    { value: 100, text: 100 },
    { value: 0, text: t("All") },
  ];

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-2">
        <p className="m-0">{t("Records per page")}</p>
        <div>
          <select
            className="form-control form-control-sm"
            value={allowedPageSizes.find(
              (item) => item.value === RecordsQuantity
            )}
            onChange={(e) => {
              if (e.target.value < 1) {
                setRecordsQuantity(recordCount);
              } else {
                setRecordsQuantity(e.target.value);
              }

              setCurrentPage(1);
            }}
          >
            {allowedPageSizes.map((item, index) => (
              <option key={index} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        </div>
      </div>

      <BootstrapPagination className="my-0">
        <BootstrapPagination.First
          disabled={isLoading}
          onClick={() => setCurrentPage(1)}
        />
        <BootstrapPagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage <= 1 || isLoading}
        />
        <BootstrapPagination.Item disabled={isLoading} active>
          {currentPage}
        </BootstrapPagination.Item>
        {currentPage + 1 > totalPages ? null : (
          <BootstrapPagination.Item
            disabled={isLoading}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </BootstrapPagination.Item>
        )}
        {currentPage + 2 >= totalPages ? null : (
          <BootstrapPagination.Item
            disabled={isLoading}
            onClick={() => setCurrentPage(currentPage + 2)}
          >
            {currentPage + 2}
          </BootstrapPagination.Item>
        )}
        <BootstrapPagination.Next
          disabled={currentPage >= totalPages || isLoading}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
        <BootstrapPagination.Last
          disabled={isLoading}
          onClick={() => setCurrentPage(totalPages)}
        />
      </BootstrapPagination>
    </div>
  );
};

export default Pagination;
