import React, { useCallback, useState } from "react";
import { AddBox, Search, Edit, Delete } from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Maintenance from "../../components/customComponents/Maintenance";
import { Card, Col, Row } from "react-bootstrap";

import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import FieldSelector from "../../components/customComponents/FieldSelector";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

export default function Users() {
  const user = parsedUser();
  const [initialModel, setInitialModel] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [model, setModel] = useState({
    title: "Users",
    sections: [],
    routeForList: "api/CRUD/Records",
    routeForModel: "api/CRUD/Model",
    routeForAddingUpdate: "api/CRUD/AddEdit",
    routeForDelete: "api/CRUD/Delete",
    data: {
      Filters: [
        {
          title: "Parent Reservation #",
          value: "",
          htmlType: "text",
          required: true,
          cssClass: "col-12 col-md-4",
          placeholder: "eMail",
          name: "customer",
        },
        {
          title: "Reservation #",
          value: "",
          htmlType: "text",
          required: true,
          cssClass: "col-12 col-md-4",
          placeholder: "Username",
          name: "reservationId",
        },
      ],

      Rows: [],
    },
  });

  const allowedPageSizes = [8, 12, 20];
  const dataUI = () => {
    return (
      <div className="">
        <Card className="mb-4">
          <Card.Header>
            <Card.Title>Filters</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              {model.data.Filters.map((item, index) => {
                return (
                  <Col className={item.cssClass} key={index}>
                    <FieldSelector model={item} key={index}></FieldSelector>
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
          <Card.Footer className="text-end">
            <button className="btn btn-sm btn-primary">
              <Search />
            </button>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title>{model.data.Title}</Card.Title>
          </Card.Header>
          <Card.Body>
            <DataGrid
              dataSource={model.data.Rows}
              columnAutoWidth={true}
              showBorders={true}
              onSelectionChanged={selectItem}
            >
              <Selection mode="single" />
              <Paging defaultPageSize={12} />
              <Pager
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </div>
    );
  };

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async () => {
    //First load records
    let query = `id=user&token=${user.Token}`;
    let request = await API.getAction("api/CRUD/Records", query);
    console.log(request);
    let results = request.data[0];

    let parsedResults = JSON.parse(results.JSONData)[0];

    if (parsedResults.Error) {
      alert(parsedResults.Msg);
      return;
    }
    let recordResult = JSON.parse(parsedResults.JSONData);
    //Now let's load sections

    let querySections = `id=user&token=${user.Token}`;
    let requestSections = await API.getAction("api/CRUD/Model", querySections);

    let resultsSections = requestSections.data[0];

    let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
    if (parsedResultsSections.Error) {
      alert(parsedResultsSections.Msg);
      return;
    }

    let recordResultSection = JSON.parse(parsedResultsSections.JSONData);

    setModel({
      ...model,
      sections: recordResultSection,
      data: {
        ...model.data,
        Rows: recordResult,
      },
    });
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      setSelectedItem(item);
    });
  }, []);

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    let query = `id=user&token=${user.Token}&RecordId=${recordId}`;
    let request = await API.getAction("api/CRUD/Details", query);
    let results = JSON.parse(request.data.response);

    // setModel({
    //   ...model,
    //   sections: resultsSections,
    //   data: {
    //     ...model.data,
    //     Rows: results,
    //   },
    // });
  };

  const toggleOpen = (action, reload) => {
    setActionMode(action);
    if (action === "I") {
      setSelectedItem(null);
    }
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  const deleteConfirm = async (obj) => {
    let currentUser = parsedUser();

    confirmAlert({
      // title: "Confirm",
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let query = `id=user&token=${user.Token}&RecordId=${selectedItem.Id}`;
            let request = await API.getAction("api/CRUD/Delete", query);
            await bindDataRemote();
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    });
  };

  useState(() => {
    bindDataRemote();
  }, []);

  return (
    <React.Fragment>
      <div className="row mx-0">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2">
            <div className="row">
              <div className="col">
                <h5 className={"mt-2"}>{model.title}</h5>
              </div>
              <div className="col text-end">
                <button className="btn">
                  <Search />
                </button>
                <button className="btn" onClick={() => toggleOpen("I")}>
                  <AddBox />
                </button>
                {selectedItem ? (
                  <>
                    <button className="btn" onClick={() => toggleOpen("U")}>
                      <Edit />
                    </button>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => deleteConfirm()}
                    >
                      <Delete />
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {open ? (
            <div className="card">
              <div className="card-header">
                <h4>{model.title}</h4>
              </div>
              <div className="card-body p-4">
                {/* TABS OPTIONS */}
                <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                  {model.sections.map((item, index) => {
                    return (
                      <li key={index} className="nav-item">
                        <button
                          className={
                            "nav-link " +
                            (index === selectedTab ? "active" : "")
                          }
                          onClick={() => {
                            setSelectedTab(index);
                          }}
                        >
                          {item.title}
                        </button>
                      </li>
                    );
                  })}
                </ul>
                {/* CONTENT */}
                <div className="tab-content" id="myTabContent">
                  {model.sections.map((item, index) => {
                    return (
                      <div
                        className={
                          "tab-pane fade " +
                          (index === selectedTab ? "show active" : "")
                        }
                        key={index}
                      >
                        <Maintenance
                          model={item}
                          toggle={toggleOpen}
                          actionMode={actionMode}
                          section="user"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            dataUI()
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
