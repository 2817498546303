import { t } from "i18next";

export const TasksAssigmentDetailsFilterFields = [
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBusinessUnit", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "spinner",
    FieldTitle: t("Business Unit"),
    Position: 3,
    cssClasss: "col-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdAutomaticTaskType", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "bigint ",
    FieldTitle: t("Tasks Types"),
    Position: 3,
    cssClasss: "col-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuilding",
    FieldType: "bigint",
    FieldTitle: t("Building"),
    Position: 3,
    cssClasss: "col-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"d373bb8a3e97f3e5c2ab80d8a82bf83c1db0463cc440bf5a00995fcb0706bbf3"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuildingFloor",
    FieldType: "bigint",
    FieldTitle: t("Floor"),
    Position: 3,
    cssClasss: "col-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"e2258e57a645a2e471803b3cf8997c4d318c86812e491072f27a883deee2a2ab"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "StartTaskDate",
    FieldType: "date",
    FieldTitle: t("Start date"),
    Position: 8,
    cssClasss: "col-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "EndTaskDate ",
    FieldType: "date",
    FieldTitle: t("End date"),
    Position: 9,
    cssClasss: "col-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,

    minValueDependOf: "DateStart",
  },
];
