import React, { useCallback, useEffect, useRef, useState } from "react";

import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import API, { Settings } from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useParams } from "react-router-dom";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import { useToast } from "../../contexts/toast";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { FileUploader } from "react-drag-drop-files";
import { URLToFile } from "../../utils/URLToFile";

import { useTranslation } from "react-i18next";

const CRUDPageGRID = (props) => {
  // PROPERTIES
  const { t } = useTranslation();
  const user = parsedUser();

  const params = useParams();
  const toast = useToast();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showAddFile, setShowAddFile] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  let [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [isPartOfParent, setIsPartOfParent] = useState(false); //This state is used to know if we have to sent the
  const [isSearching, setIsSearching] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [model, setModel] = useState(props.page);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const selectedItemRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [zoomRange, setZoomRange] = useState(100);
  //TODO: FIX THIS CONDITION !!!!!!
  const isFile = model.Fields
    ? model.Fields.some((x) => x.HtmlType === "file")
    : null;

  // END PROPERTIES

  //METHODS

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRecordRemote = async (cleanFilter) => {
    // First start loading the model
    setLoading(true);

    //First load records
    let modelDataGridColumns =
      model.Fields &&
      model.Fields.reduce((acc, x) => {
        if (
          (!x.FieldName.startsWith("Id") && x.FieldName !== "File") ||
          x.FieldName === "IdBusinessUnit"
        )
          acc.push({ dataField: x.FieldName, caption: x.FieldTitle });
        return acc;
      }, []);

    try {
      //!WHY ARE WE STOPPING REQUESTING MODEL AND RECORDS IF IS NOT MULTI-RECORD?
      // if (props.page.MultiRecord === false) {
      //   return;
      // }
      let query = `id=${params.id}&token=${user.Token}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }

      let request = await API.getAction("api/CRUDM/Records", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      let configInfo = recordResult[0].JSONConfig
        ? recordResult[0].JSONConfig[0]
        : null;
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult);

      //TODO: | Use JSONConfig to show specific fields
      let gridData = [];
      let gridColumns = modelDataGridColumns;

      //* If there is a config, then we need to sort the fields to show them in the grid
      // if (configInfo && configInfo.Fields) {

      //   const sortedFields = configInfo.Fields.sort((a, b) => a.Position - b.Position);

      //   //? Here I reduce the fields to only the ones that are visible
      //   gridColumns = sortedFields.reduce((acc, item) => {
      //     (item.Visible) && (acc.push(item.FieldName))
      //     return acc;
      //   }, [])

      //   gridData = dataFromAPI.map((item) => {
      //     return gridColumns.reduce((acc, key) => {
      //       acc[key] = item[key];
      //       acc["Id"] = item["Id"];
      //       return acc;
      //     }, {})
      //   })
      // }

      //! If grid data stays null, we use the data from the API
      setModel({
        ...model,
        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: dataFromAPI || gridData,
        },
      });
    } catch (error) {
      console.error(error);
      setModel({
        ...model,
        data: {
          ...model.data,
          Columns: modelDataGridColumns,
          Rows: [],
        },
      });
    } finally {
      setLoading(false);
    }
  };

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS

  // THIS METHOD IS USED TO BRING THE DETAILS OF THE SECTION USED
  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `id=${params.id}&token=${user.Token}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      let request = await API.getAction("api/CRUDM/Details", query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData[0];
      fillFormikObject(finalResult);
      setSelectedItem(null);
      //Fill object with new values
    } catch (error) {
      toast({ type: "error", message: t("Error loading data") });
      return;
    }
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedFile(null);
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      if (isFile) {
        const imageFile = await URLToFile(
          selectedItem.FilePath.replace("/api", ""),
          selectedItem.OriginalFileName
        );
        setSelectedFile(imageFile);
      }
      await bindDataDetailsRemote(selectedItem.Id);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);

    console.log(
      "❗ ~ file: CRUDPageGRID.js:189 ~ toggleOpen ~ isFile:",
      isFile
    );
    if (isFile) {
      setShowAddFile(!showAddFile);
    } else {
      setShowAddEdit(!showAddEdit);
    }
    if (reload) {
      await bindDataRecordRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              if (isFile) {
                const [, , , filePath] = selectedItem.FilePath.split("/");
                const fileName =
                  filePath.split("/")[1] ?? filePath.split("\\")[1];

                await API.getAction("api/file/delete", `filename=${fileName}`);
              }

              let query = `id=${params.id}&token=${user.Token}&RecordId=${selectedItem.Id}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
              await API.getAction("api/CRUDM/Delete", query);
              await bindDataRecordRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);

    console.log("formik values", formik.values);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("fillFormikObject", "record", record, "model", model);
    if (model && model.Fields && model.Fields.length > 0) {
      model.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model);
    let initialObject = {};
    let initialObjectValidation = {};

    if (model.Fields) {
      model.Fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj);
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    //obj['ParentGUID'] = props.ParentGUID; ?

    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to save this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let objSPVersion = "";
              model.Fields.forEach((item) => {
                if (
                  item.ReadOnly ||
                  item.HtmlType === "separator" ||
                  item.FieldName === "IdCustomer"
                )
                  return;

                if (item.HtmlType !== "separator") {
                  objSPVersion +=
                    (objSPVersion.length > 0 ? ", " : "") +
                    `@${item.FieldName}=`;
                }
                if (
                  item.FieldType === "int" ||
                  item.FieldType === "bool" ||
                  item.FieldType === "bit" ||
                  item.FieldType === "bigint"
                ) {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";
                  //objSPVersion +=
                  //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
                } else if (item.HtmlType === "password") {
                  let passwordEncrypted = null;
                  if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                    passwordEncrypted =
                      obj["UserName"].toUpperCase() + obj[item.FieldName];
                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                  }
                  objSPVersion +=
                    obj[item.FieldName] === undefined
                      ? "null"
                      : "'" + passwordEncrypted + "'";
                } else if (item.HtmlType === "radio") {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";

                  // } else if(item.HtmlType==="separator"){
                  //   //DO NOTHING, CONTINUE NEXT RECORD
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
              });
              let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}&PageIndex=${props.page.PageIndex}`;
              objSPVersion += `, @ParentGUID='${props.ParentGUID}'`;
              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/CRUDM/AddEdit?" + queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                message: t("Record saved successfully"),
              });

              toggleOpen(null, true);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleUploadFile = async (obj, event) => {
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to save this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              const result = await API.postAction(
                "/api/file/upload",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              const response = result.data?.response;

              obj = {
                ...obj,
                FilePath: `${Settings.APIPath}${response?.relativePath}`,
                OriginalFileName: selectedFile.name || response?.fileName,
              };

              let objSPVersion = "";
              model.Fields.forEach((item) => {
                if (
                  item.ReadOnly ||
                  item.HtmlType === "separator" ||
                  item.FieldName === "IdCustomer" ||
                  item.FieldName === "File"
                )
                  return;

                if (item.HtmlType !== "separator") {
                  objSPVersion +=
                    (objSPVersion.length > 0 ? ", " : "") +
                    `@${item.FieldName}=`;
                }
                if (
                  item.FieldType === "int" ||
                  item.FieldType === "bool" ||
                  item.FieldType === "bit" ||
                  item.FieldType === "bigint"
                ) {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";
                  //objSPVersion +=
                  //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
                } else if (item.HtmlType === "password") {
                  let passwordEncrypted = null;
                  if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                    passwordEncrypted =
                      obj["UserName"].toUpperCase() + obj[item.FieldName];
                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                  }
                  objSPVersion +=
                    obj[item.FieldName] === undefined
                      ? "null"
                      : "'" + passwordEncrypted + "'";
                } else if (item.HtmlType === "radio") {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";

                  // } else if(item.HtmlType==="separator"){
                  //   //DO NOTHING, CONTINUE NEXT RECORD
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                    obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
              });
              let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}&PageIndex=${props.page.PageIndex}`;
              objSPVersion += `, @ParentGUID='${props.ParentGUID}'`;
              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/CRUDM/AddEdit?" + queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                message: t("Record saved successfully"),
              });
              toggleOpen(null, true);
            } catch (error) {
              console.error(error);
              toast({
                type: "error",
                message: t("Error saving record"),
              });
              return;
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  // END METHODS

  // UI METHODS

  const loadGRIDUI = () => {
    if (!model) {
      return <></>;
    }
    return (
      <Container id="tab-grid-body" fluid className="formBackground p-4 pt-6">
        <Row>
          {model?.data && model?.data?.Rows && (
            <Col className="col-table">
              <Card>
                <Card.Body>
                  <DataGrid
                    dataSource={model?.data?.Rows || []}
                    columns={model?.data?.Columns || []}
                    columnAutoWidth={true}
                    showBorders={true}
                    onSelectionChanged={selectItem}
                    loadPanel={{
                      enabled: true,
                      showIndicator: true,
                      text: t("Loading") + "...",
                    }}
                  >
                    <Selection mode="single" />
                    <Paging defaultPageSize={10} />
                    <Pager
                      showInfo={true}
                      infoText={t("Page") + ":"}
                      visible={true}
                      showPageSizeSelector={true}
                      allowedPageSizes={allowedPageSizes}
                    />
                  </DataGrid>
                </Card.Body>
              </Card>
            </Col>
          )}
          <div style={{ width: "50px" }} className="px-0">
            <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => toggleOpen("I")}
                title={t("Add")}
                disabled={actionMode === "C"}
              >
                <i className="fa fa-plus"></i>
              </button>
              {selectedItem && actionMode !== "C" && (
                <>
                  <button
                    id="grid-edit-button"
                    type="button"
                    className="btn btn-primary mb-2"
                    title={t("Update")}
                    onClick={() => toggleOpen("U")}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button
                    id="grid-delete-button"
                    type="button"
                    className="btn btn-secondary mb-2"
                    title={t("Delete")}
                    onClick={() => deleteConfirm()}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    );
  };

  //EVENTS

  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      selectedItemRef.current = e.component;
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    if (!props.active) return;
    console.log("section updated", model);
    prepareFormikObject();
    bindDataRecordRemote();
  }, [props.active]);

  // END EVENTS

  if (props.active === false) return <></>;

  return (
    <>
      {!isFile ? (
        <Modal
          show={showAddEdit}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size="xl"
          className="p-3"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t(model.PageTitle)}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Container fluid className="overflow-auto">
                <Row className="gy-5 mb-4">
                  {model.Fields &&
                    model.Fields.filter(
                      (x) =>
                        x.FieldTitle !== "Audit Information" &&
                        x.FieldName !== "Id" &&
                        x.FieldName !== "IdCustomer" &&
                        x.FieldName !== "DateAdd" &&
                        x.FieldName !== "File" &&
                        x.FieldName !== "DateMod"
                    ).map((item) => {
                      return (
                        <Col
                          className={
                            item.cssClasss + (!item.Visible ? " d-none" : "")
                          }
                          key={`col-${item.FieldName}`}
                        >
                          <label className="me-2 mt-2">
                            {t(item.FieldTitle)}
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                          </label>
                          <br />

                          <FieldSelector
                            actionMode={actionMode}
                            model={item}
                            key={`field-${item.FieldName}`}
                            updateField={updateField}
                            value={formik.values[item.FieldName]}
                          ></FieldSelector>
                          {formik.errors[item.FieldName] ? (
                            <div className="invalid text-sm my-1">
                              {t(formik.errors[item.FieldName])}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
                </Row>

                {generalError ? (
                  <Row>
                    <Col xs={12}>
                      <div className="alert alert-danger" role="alert">
                        {t(generalError)}
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    {t("Please check the forms for errors")}
                  </span>
                )}
                <button
                  className="btn btn-secondary me-2"
                  type="button"
                  onClick={() => toggleOpen(null)}
                >
                  {t("Close")}
                </button>
                <button
                  className="btn btn-primary me-2"
                  type="button"
                  onClick={() => [formik.submitForm()]}
                >
                  {t("Save")}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : (
        <Modal
          show={showAddFile}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size="xl"
          className="p-6 file-upload-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t(model.PageTitle)}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleUploadFile}>
            {!selectedFile ? (
              <Modal.Body className="file-upload-container">
                <FileUploader
                  classes="file-uploader"
                  required
                  name="myFile"
                  fileOrFiles={selectedFile}
                  types={["png", "jpg", "jpeg", "pdf"]}
                  handleChange={(file) => setSelectedFile(file)}
                  onTypeError={() => {
                    toast({
                      type: "error",
                      message: t("File type not allowed"),
                    });
                  }}
                  children={
                    <div className="file-upload-body">
                      <div>
                        <i className="fa-solid fa-file-circle-plus my-3 fs-1"></i>
                        <br />
                        <span style={{ fontWeight: "600" }}>
                          {t("Drag and drop your file here")}
                        </span>
                      </div>

                      <span>{t("OR")}</span>

                      <div>
                        <label
                          id="upload-button"
                          className="btn btn-primary px-3 py-2 "
                          type="button"
                        >
                          <i className="fa-solid fa-upload me-2 fs-6"></i>
                          <input
                            htmlFor="upload-button"
                            className="hidden"
                            type="file"
                            name="myFile"
                            multiple={false}
                            accept="image/*, application/pdf"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          <span>{t("Choose file from your computer")}</span>
                        </label>
                      </div>
                    </div>
                  }
                />
              </Modal.Body>
            ) : (
              <Modal.Body>
                <Container fluid className="file-form overflow-auto">
                  <div className="d-flex flex-column mb-4">
                    <div className="files-container">
                      <h6>{t("Your selected file")}</h6>

                      <div className="selected-files">
                        {selectedFile && (
                          <div className="selected-files-item">
                            <i className="fa-solid fa-image me-2"></i>
                            <span>{selectedFile.name}</span>

                            <button
                              type="button"
                              className="btn py-0 ps-3"
                              onClick={() => setSelectedFile(null)}
                            >
                              <i className="fa-solid fa-x"></i>
                            </button>
                          </div>
                        )}
                        <label
                          className="btn selected-files-clear pointer"
                          id="another-file-button"
                          type="button"
                        >
                          <i className="fa-solid fa-upload me-2 fs-6"></i>
                          <span>{t("Choose different file")}</span>
                          <input
                            htmlFor="another-file-button"
                            type="file"
                            name="anotherFile"
                            multiple={false}
                            accept="image/*, application/pdf"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                        </label>
                      </div>
                    </div>
                    {model.Fields &&
                      model.Fields.filter(
                        (x) =>
                          x.FieldTitle !== "Audit Information" &&
                          x.FieldName !== "Id" &&
                          x.FieldName !== "IdCustomer" &&
                          x.FieldName !== "DateAdd" &&
                          x.FieldName !== "File" &&
                          x.FieldName !== "DateMod"
                      ).map((item) => {
                        return (
                          <div
                            className={
                              item.cssClasss +
                              (!item.Visible ? " d-none" : "") +
                              " w-100 my-2"
                            }
                            key={`col-${item.FieldName}`}
                          >
                            <label className="me-2 mt-2">
                              {t(item.FieldTitle)}
                              {item.Required ? (
                                <i className="required-asterisk ms-1 text-danger">
                                  *
                                </i>
                              ) : null}
                            </label>
                            <br />

                            <FieldSelector
                              actionMode={actionMode}
                              model={item}
                              key={`field2-${item.FieldName}`}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            ></FieldSelector>
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm my-1">
                                {t(formik.errors[item.FieldName])}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>

                  <div className="file-preview">
                    <h6>{t("File Preview")}</h6>

                    <Card>
                      <Card.Body>
                        <img
                          alt="file preview"
                          style={{ transform: `scale(${zoomRange / 100})` }}
                          className="file-preview-img"
                          src={URL.createObjectURL(selectedFile)}
                        />
                      </Card.Body>
                      <Card.Footer>
                        <div className="d-flex gx-3 align-items-center justify-content-center">
                          <button
                            className="btn py-0"
                            type="button"
                            onClick={() =>
                              zoomRange - 10 >= 10 &&
                              setZoomRange((range) => range - 10)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </button>
                          <input
                            type="range"
                            min="10"
                            max="100"
                            value={zoomRange}
                            onChange={(e) => setZoomRange(+e.target.value)}
                          />
                          <button
                            className="btn py-0"
                            type="button"
                            onClick={() =>
                              zoomRange + 10 <= 100 &&
                              setZoomRange((range) => range + 10)
                            }
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                </Container>
                {generalError ? (
                  <Row>
                    <Col xs={12}>
                      <div className="alert alert-danger" role="alert">
                        {t(generalError)}
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Modal.Body>
            )}

            {selectedFile && (
              <Modal.Footer>
                <div className="d-flex gx-3 align-items-center justify-content-end">
                  {Object.values(formik.errors).length > 0 && (
                    <span className="invalid me-2">
                      {t("Please check the forms for errors")}
                    </span>
                  )}
                  <button
                    className="btn btn-secondary me-2"
                    type="button"
                    onClick={() => toggleOpen(null)}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn-primary me-2"
                    type="button"
                    onClick={() => {
                      formik.validateForm().then((errors) => {
                        if (Object.values(errors).length > 0) {
                          toast({
                            type: "error",
                            message: t("Please check the forms for errors"),
                          });
                          return;
                        }
                        handleUploadFile(formik.values);
                      });
                    }}
                  >
                    {t("Upload")}
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Form>
        </Modal>
      )}
      {/* DISPLAY THE TYPE OF FORM */}

      {loading ? (
        <React.Fragment>
          <div className="">
            <div className="w-25 mx-auto my-1 p-5 text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">{t("Loading")}...</span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>{loadGRIDUI()}</>
      )}
    </>
  );
};

export default CRUDPageGRID;
