import { t } from "i18next";

export const HeaderInfoFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "BusinessUnit",
    FieldType: "varchar",
    FieldTitle: t("Business Unit"),
    Position: 1,
    cssClasss: "col-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "RoomType",
    FieldType: "varchar",
    FieldTitle: t("Room Type"),
    Position: 1,
    cssClasss: "col-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReservationGroupNumber",
    FieldType: "varchar",
    FieldTitle: t("Res. Group #"),
    Position: 1,
    cssClasss: "col-2",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "GroupName",
    FieldType: "varchar",
    FieldTitle: t("Group Name"),
    Position: 1,
    cssClasss: "col-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "7654321",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
];
