import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest } from "../api/auth";
import ClearUser from "../utils/ClearUser";
import ROUTES from "../utils/routes";
import { parsedUser } from "../utils/GetCurrentUser";
import { redirect } from "react-router-dom";

const initialUser = {
  "IdUser": "",
  "Token": "",
  "Expiration": "",
  "Username": "",
  "Loginname": "",
  "Access": "",
  "Active": "",
  "LockedAccess": "",
  "exp": 0,
  "iss": "",
  "aud": ""
}

function AuthProvider(props) {
  const [user, setUser] = useState(initialUser);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async function () {
      const result = parsedUser();
      if (result) {
        setUser(result);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    console.log("signOut")
    ClearUser();
    window.location = ROUTES.Default.Clean;
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext({ user: initialUser, loading: false, signOut: () => { } });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
