import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { RowSuplementsTable } from './RowSuplementsTable'
import { HeaderSuplementsTable } from './HeaderSuplementsTable'

export const SuplementsTable = ({supplementsData}) => {

  const [data, setData] = useState([])

  useEffect(() => {
    setData(supplementsData)
  }, [])
  
  return (
    <>
      <Table bordered className='border-black border-opacity-100 border-1 w-50' style={{ fontSize: '.8rem' }}>
        <HeaderSuplementsTable />
        <tbody>
          {data && data.map((dataRow, index) => <RowSuplementsTable key={index} dataRow={dataRow.Suplements} />)}
        </tbody>
      </Table>
    </>
  )
}
