import React, { useEffect, useState } from 'react';
import { Card, Col, Tab, Tabs } from 'react-bootstrap';

export const TabsGroup = ({ tabList }) => {
  const [key, setKey] = useState(tabList[0].eventKey);

  // useEffect(() => {
  //   console.log({TabSgroupEffect: tabList})
  // },[tabList])

  return (
    // <Card style={{minHeight:'450px'}}>
    //   <Card.Body className='bg-dark-subtle '>

    // </Card.Body>
    // </Card>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className=""
    >
      {tabList.length > 0 && tabList.map(({ content, eventKey, title }) => (
        <Tab eventKey={eventKey} title={title} key={eventKey} className='border-end border-bottom border-start'>
          <Col xs={12} className='' >
            {content}
          </Col>
        </Tab>))}
    </Tabs>
  );
}