export const RestaurantReservationTableFields = [
  {
    FieldName: "ReservationNumber",
    FieldTitle: "Rsv. No.",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Restaurant",
    FieldTitle: "Restaurant",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ReservationState",
    FieldTitle: "Reservation State",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ReservationDate",
    FieldTitle: "Reservation Date",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "RestaurantsSeattings",
    FieldTitle: "Reservation Time",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "PaxQuantity",
    FieldTitle: "Pax Quantity",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Room",
    FieldTitle: "Room No.",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "GuestName",
    FieldTitle: "Guest Name",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Comment",
    FieldTitle: "Comments",
    FieldType: "varchar",
    HtmlType: "text",
  },
];
