import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";

import { useFormik } from "formik";
import yupTypeValidator from "../../utils/YupTypeValidator";
import * as yup from "yup";
import FieldSelector from "../customComponents/FieldSelector";
import { parsedUser } from "../../utils/GetCurrentUser";

import API from "../../api/api";
import { useToast } from "../../contexts/toast";

import { useTranslation } from "react-i18next";

const UnCancelReservation = (props) => {
  const { t } = useTranslation();

  const user = parsedUser();

  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);

  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = [
    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "IdUnCancelationType",
      FieldType: "bigint",
      FieldTitle: t("Un-Cancelation Type"),
      Position: 2,
      cssClasss: "col-12 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"2b868d0982957ef9246670e793308c8cc04ad373d1355554308e6e2e189ab505"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "UnCancelDescription",
      FieldType: "varchar",
      FieldTitle: t("Comment"),
      Position: 2,
      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];
  const bindDataRemote = async () => {};
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj);
    // console.log("Props", props);

    try {
      let objSPVersion = "";

      let queryString = `IdUser=${user.IdUser}&Token=${
        user.Token
      }&ActionMode=U&GUIDReservation=${
        props.reservation.GUID
      }&IdUnCancelationType=${obj.IdUnCancelationType}&UncancelDescription=${
        obj.UnCancelDescription ?? ""
      }`;

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.getAction(
        `api/GroupReservations/UnCancelReservation`,
        queryString
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);
  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "sm"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Un-Cancel Reservation")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            <Col>
              <label className="me-2 mt-2">
                {t("Group Reservation Number")}
              </label>
              <br />
              <Form.Control
                type="text"
                readOnly
                value={props.reservation?.ReservationGroupNumber}
                disabled
              />
              <label className="me-2 mt-2">{t("Group Name")}</label>
              <br />
              <Form.Control
                type="text"
                readOnly
                value={props.reservation?.GroupName}
                disabled
              />
            </Col>
          </Row>
          <hr />
          <Row>
            {fields &&
              fields
                .filter(
                  (x) =>
                    x.FieldTitle !== "Audit Information" &&
                    x.FieldName !== "Id" &&
                    x.FieldName !== "IdCustomer" &&
                    x.FieldName !== "DateAdd" &&
                    x.FieldName !== "File" &&
                    x.FieldName !== "DateMod"
                )
                .map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={`col-${item.FieldName}-${index}`}
                    >
                      <label className="me-2 mt-2">
                        {t(item.FieldTitle)}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={""}
                        model={item}
                        obj={formik.values}
                        key={`field-${item.FieldName}`}
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm my-1">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              {t("Please check the forms for errors")}
            </span>
          )}
          <button className="btn me-2" type="button" onClick={props.toggle}>
            {t("Close")}
          </button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >
            {t("Save")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UnCancelReservation;
