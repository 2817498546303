export const DATAMODEL = {
  "Id": "",
  "GUID": "",
  "IdCustomer": 0,
  "IdCurrency": 0,
  "ContractNumber": "",
  "Customer": "",
  "StartDate": "",
  "EndDate": "",
  "Comments": "",
  "Status": "",
  "Delete": false,
  "BusinessUnits": [
    {
      "GUID": "",
      "IdBusinessUnit": "",
      "Delete": false,
      "RatePeriods": [
        {
          "GUID": "",
          "Description": "",
          "StartDate": "",
          "EndDate": "",
          "Delete": false,
          "RoomType": [
            {
              "GUID": "",
              "IdRoomType": "",
              "Allotments": "",
              "Release": "",
              "Delete": false,
              "Ocupations": [
                {
                  "GUID": "",
                  "IdAgeGroup": 0,
                  "IdOccupationType": 0,
                  "Rate": 0
                }
              ]
            }
          ],
          "SpecialRate": [],
          "ChargesDiscount": [],
          "RatePolicies": []
        }
      ]
    }
  ],
  "Policies": [
  ],
  "Comment": [
  ]
}
