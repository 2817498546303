import React from "react";

import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="w-100">
      <div className="w-100 mx-auto my-1" style={{ paddingTop: "" }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">{t("Loading")}...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
