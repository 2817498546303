import React from "react";

import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Title,
  Tooltip,
  Font,
  Margin,
} from "devextreme-react/chart";
import Loading from "../Loading";

const dataTest = [
  { name: "Category1", guestIncident: 10, hotelAreaIncident: 15 },
  { name: "Category2", guestIncident: 20, hotelAreaIncident: 25 },
  // Agrega más objetos según sea necesario
];


const dataTestGuest = [
  { name: "Category1", areaRequest: 10, businessUnitRequest: 15 },
  { name: "Category2", areaRequest: 20, businessUnitRequest: 25 },
  // Agrega más objetos según sea necesario
];

const ReportedIncidents = ({ data, title, showRequest }) => {

  console.log(data)

  const customizeTooltip = (arg) => {
    return {
      text: `${arg.seriesName} years: ${arg.valueText}`,
    };
  };
  const pies = () => (
    <Chart id="chart" dataSource={showRequest ? dataTestGuest : dataTest}>
      <CommonSeriesSettings argumentField="name" type="stackedBar" />

        <Series
          valueField={showRequest ? "areaRequest" : "guestIncident"}
          name={showRequest ? "Area Request" : "Guest Incident"}
          color="#98cafd"
        />
        <Series
          valueField={showRequest ? "businessUnitRequest":"hotelAreaIncident"}
          name={showRequest ? "Business Unit Request" : "Hotel Area Incident"}
          color="#218efb"
        />

        

      

      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Tooltip
        enabled={true}
        location="edge"
        customizeTooltip={(e) => customizeTooltip(e)}
      />
      <Title
        text={title ? title : "Reported Incidents"}
        horizontalAlignment="middle"
        verticalAlignment="top"
      >
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </Chart>
  );

  return (
    <>
      <div className="bg-white p-3 shadow text-center" style={{ borderRadius: 25 }}>
        {/* {data && data.length > 0 ? pies() : <Loading />} */}
        {pies()}
      </div>
    </>
  );
};

export default ReportedIncidents;
