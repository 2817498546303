import { DeleteForever, Search } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import Sha256 from "../../../utils/sha256";
import FieldSelector from "../../customComponents/FieldSelector";
import { FilterFieldset } from "../Fields/ContractFilterFields";

import { useTranslation } from "react-i18next";
import { t } from "i18next";

const ContractFilter = (props) => {
  const fields = FilterFieldset;
  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (obj) => {
    // props.filterApplied(obj);
    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType === "date") {
        if (obj[item.FieldName] === "Invalid date") {
          obj[item.FieldName] = null;
        }
      }

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
          obj[item.FieldName] === null ||
          obj[item.FieldName] === ""
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : obj[item.FieldName] ?? "null";
      } else if (item.HtmlType === "password") {
        let passwordEncrypted = null;
        if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
          passwordEncrypted =
            obj["UserName"].toUpperCase() + obj[item.FieldName];
          passwordEncrypted = Sha256.hash(passwordEncrypted);
        }
        objSPVersion +=
          obj[item.FieldName] === undefined
            ? "null"
            : "'" + passwordEncrypted + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });
    props.handleFilter(objSPVersion);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  return (
    <Card className="mb-4">
      <Card.Header className="w-full d-flex justify-content-between align-items-center">
        <Card.Title>{t("Filters")}</Card.Title>

        <button
          className="btn btn-secondary"
          title={t("Close")}
          type="button"
          onClick={props.handleClose}
          style={{
            padding: "0px 8px",
            lineHeight: "23px",
          }}
        >
          <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
        </button>
      </Card.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Card.Body className="bg-light">
          <Row className="px-2 py-2">
            {fields.map((item, index) => (
              <Col
                className={item.cssClasss + (!item.Visible ? " d-none" : "")}
                key={index}
              >
                <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                {item.Required ? (
                  <i className="required-asterisk ms-1 text-danger">*</i>
                ) : null}
                <br />

                <FieldSelector
                  // actionMode={props.actionMode}
                  model={item}
                  key={index}
                  obj={formik.values}
                  updateField={updateField}
                  value={formik.values[item.FieldName]}
                />
                {formik.errors[item.FieldName] && (
                  <div className="invalid text-sm">
                    {formik.errors[item.FieldName]}
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end gap-2">
          <button
            className="btn btn-sm btn-outline-secondary me-2"
            type="button"
            title={t("Clear")}
            onClick={() => {
              formik.resetForm();
              props.handleFilter("");
            }}
          >
            <i
              className="fa-solid fa-filter-circle-xmark"
              style={{ fontSize: "1.2rem" }}
            ></i>
          </button>
          <button
            className="btn btn-sm btn-primary"
            title={t("Search")}
            type="submit"
          >
            <Search />
          </button>
        </Card.Footer>
      </Form>
    </Card>
  );
};

export default ContractFilter;
