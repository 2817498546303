import React from 'react'
import ItemGroup from './itemGroup'

const ListGroup = ({ data, select, setSelect }) => {

  return (

    <ul className="list-group ">
      {
        data?.length ?
          data.map((item, index) => (
            <ItemGroup key={index} item={item} select={select} setSelect={setSelect} />
          ))
          : <li style={{ fontSize: '1.5rem' }} className='d-flex justify-content-center align-items-center mt-4' >
            No data to show
          </li>
      }
    </ul>
  )
}

export default ListGroup