import { useEffect, useState } from "react";

export const RowSpecialRateOccupationTable = ({ dataRow, maxChildren, maxOccupation }) => {

  const {
    start_date,
    end_date,
    roomData
  } = dataRow;

  const [roomDataSet, setRoomDataSet] = useState(roomData);

  useEffect(() => {
    setRoomDataSet(roomData);
  },[dataRow, roomData])

  return (
    <>
      {roomDataSet.map((room, index) => {
        const {percentage, occupation_type, adults, extra_pax, children, infants, room_type} = room;
        return (
          <tr key={index}>
            {index === 0 ? (<>
              <th rowSpan={roomDataSet.length} scope='rowgroup' className='text-center py-4'>{start_date}</th>
              <th rowSpan={roomDataSet.length} scope='rowgroup' className='text-center py-4'>{end_date}</th>
            </>) : null}
            <th scope='row'>{room_type}</th>
            <td>{percentage}</td>
            {occupation_type && occupationTypeRow(occupation_type, maxOccupation)}
            <td>{adults}</td>
            {children && guestTypeRow(children, maxChildren)}
            <td>{infants ? infants : 0}</td>
            <td>{extra_pax}</td>
          </tr>
        )
      })}
    </>
  )
}

const occupationTypeRow = (occupation_type, maxOccupation) => {
  if (occupation_type.length < maxOccupation) {
    const diff = maxOccupation - occupation_type.length;
    for (let i = 0; i < diff; i++) {
      occupation_type.push('0')
    }
  }
  return occupation_type.map((occ, index) => <td key={index}>{occ}</td>)
}

const guestTypeRow = (children, maxChildren) => {
  if (children.length < maxChildren) {
    const diff = maxChildren - children.length;
    for (let i = 0; i < diff; i++) {
      children.push('0')
    }
  }
  return children.map((child, index) => <td key={index}>{child}</td>)
}