import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Pagination, Row, Table } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const IncidentTrackingList = (props) => {
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  console.log("the records", props.records);
  const allowedPageSizes = [
    { value: 10, text: 10 },
    { value: 20, text: 20 },
    { value: 50, text: 50 },
    { value: 100, text: 100 },
    { value: 0, text: t("All") },
  ];
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);

  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [selectedItem, setSelectedItem] = useState(null);
  const headerWithoutAuditFields = () => {
    const listColumns = [
      // {
      // 	FieldName: "TaskType",
      // 	FieldTitle: "Task Type",
      // },
      {
        FieldName: "Sequence",
        FieldTitle: t("#Ticket"),
      },
      {
        FieldName: "Description",
        FieldTitle: t("Description"),
      },
      {
        FieldName: "Room",
        FieldTitle: t("Room"),
      },
      {
        FieldName: "Area",
        FieldTitle: t("Area"),
      },
      {
        FieldName: "Reservation",
        FieldTitle: t("Reservation"),
      },
      {
        FieldName: "Priority",
        FieldTitle: t("Priority"),
      },
      {
        FieldName: "Category",
        FieldTitle: t("Category"),
      },
      {
        FieldName: "Collaborator",
        FieldTitle: t("Collaborator"),
      },
      {
        FieldName: "ReportedUser",
        FieldTitle: t("Created By"),
      },
      {
        FieldName: "CreationDate",
        FieldTitle: t("Creation Date"),
        FieldType: "datetime",
      },
      {
        FieldName: "ExecutionDate",
        FieldTitle: t("Execution Date"),
        FieldType: "datetime",
      },
      {
        FieldName: "FinishDate",
        FieldTitle: t("Finish Date"),
        FieldType: "datetime",
      },
      {
        FieldName: "State",
        FieldTitle: t("State"),
      },
      {
        FieldName: "ReportedByGuest",
        FieldTitle: t("Reported By Guest"),
      },
      {
        FieldName: "BusinessUnit",
        FieldTitle: t("Business Unit"),
      },
      {
        FieldName: "Active",
        FieldTitle: t("Active"),
      },
    ];
    return listColumns;
  };

  useEffect(() => {
    setRecords(props.records);
  }, [props.records]);
  return (
    <Container>
      <Row className="mx-0">
        <div className="table-responsive p-0 ">
          <Table hover size="xl" bordered className="border shadow-sm">
            <thead className="table-secondary">
              <tr>
                {headerWithoutAuditFields().map((item, index) => {
                  return (
                    <th
                      key={index}
                      className={`fw-bold clickable`}
                      onClick={() => {
                        console.log(item, sortBy, sortByOrder);
                        setSortBy(item.FieldName);
                        if (item.FieldName === sortBy) {
                          if (sortByOrder === "ASC") {
                            setSortByOrder("DESC");
                          } else {
                            setSortByOrder("ASC");
                          }
                        } else {
                          setSortByOrder("ASC");
                        }
                      }}
                    >
                      {item.FieldTitle}{" "}
                      {sortBy === item.FieldName && sortByOrder === "ASC" ? (
                        <i className="fas fa-sort-up"></i>
                      ) : sortBy === item.FieldName &&
                        sortByOrder === "DESC" ? (
                        <i className="fas fa-sort-down"></i>
                      ) : null}{" "}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {records.map((item, index) => {
                //console.log("records", records, "single item", item)
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedItem(item);
                      props.setSelectedItem(item);
                    }}
                    className={
                      selectedItem && selectedItem.GUID === item.GUID
                        ? "active"
                        : ""
                    }
                  >
                    {headerWithoutAuditFields().map((column, index2) => {
                      //console.log(column, item)
                      if (
                        column.FieldType === "datetime" &&
                        item[column.FieldName] !== null
                      ) {
                        //console.log(item, column, item[column.FieldName])
                        return (
                          <td key={index2}>
                            {moment(item[column.FieldName]).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                        );
                      }
                      return (
                        <td key={index2}>
                          {typeof item[column.FieldName] === "boolean" ? (
                            <input
                              type="checkbox"
                              checked={item[column.FieldName]}
                              className=""
                            />
                          ) : (
                            item[column.FieldName]
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {/* {(records.length > 0) && (<div className="d-flex align-items-center gap-2">
					<p className="m-0">Records per page</p>
					<div>
						<select
							className="form-control form-control-sm"
							value={allowedPageSizes.find(item => item.value === RecordsQuantity)}
							onChange={(e) => {
								if (e.target.value < 1) {
									setRecordsQuantity(recordCount);
								} else {
									setRecordsQuantity(e.target.value)
								}
								setCurrentPage(1)
							}}
						>
							{allowedPageSizes.map((item, index) => {
								// console.log(item);
								return (
									<option key={index} value={item.value}>
										{item.text}
									</option>
								);
							}
							)}
						</select>
					</div>
				</div>)} */}
          {/* {(records.length > 0) && (<Pagination className="my-0">
					<Pagination.First disabled={props.isLoading} onClick={() => setCurrentPage(1)} />
					<Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1 || props.isLoading} />
					<Pagination.Item disabled={props.isLoading} active>{currentPage}</Pagination.Item>
					{(currentPage + 1 > totalPages) ? null : <Pagination.Item disabled={props.isLoading} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</Pagination.Item>}
					{(currentPage + 2 >= totalPages) ? null : <Pagination.Item disabled={props.isLoading} onClick={() => setCurrentPage(currentPage + 2)}>{currentPage + 2}</Pagination.Item>}
					<Pagination.Next disabled={currentPage >= totalPages || props.isLoading} onClick={() => setCurrentPage(currentPage + 1)} />
					<Pagination.Last disabled={props.isLoading} onClick={() => setCurrentPage(totalPages)} />
				</Pagination>)} */}
        </div>
      </Row>
    </Container>
  );
};

export default IncidentTrackingList;
