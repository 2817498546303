import { t } from "i18next";

export const FilterFieldset = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ContractCode",
    FieldType: "varchar",
    FieldTitle: t("Rate Code"),
    Position: 1,
    cssClasss: "col-md-2",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    Position: 1,
    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Reference",
    FieldType: "varchar",
    FieldTitle: t("Reference"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "IdMarketSegment",
    FieldType: "int",
    FieldTitle: t("Market Segment"),
    Position: 1,
    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"295e14da5b9cdb270caead127ecc4aa64b0e70d32b8e161908da154aa3ae7968"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "StartDate",
    FieldType: "datetime",
    FieldTitle: t("Start Date"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "EndDate",
    FieldType: "datetime",
    FieldTitle: t("End Date"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCurrency",
    FieldType: "int",
    FieldTitle: t("Currency"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID":"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdContractStatus",
    FieldType: "int",
    FieldTitle: t("Rate Status"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID":"dcd725c8de4a7a0ea1c83801bb3a0ac523cfca2d02bddbe1e96d14b243de0391"}',

    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
