import defaultUser from "../utils/default-user";
import Sha256 from "../utils/sha256";
import { v4 as uuidv4 } from "uuid";
import API from "./api";

export async function signIn(username, password) {
  try {
    // Send request
    console.log(username, password);
    let user = Sha256.hash(username);
    let passwordParsed = Sha256.hash(username + password);
    let query = {
      username: user,
      password: (uuidv4() + passwordParsed + uuidv4()).replace("-", ""),
    };

    var request = await API.postAction("api/account/canlogin", query);
    console.log(request);
    debugger;
    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
