const fakeTask = [
  {
    id: 1,
    text: "Call to clarify customer requirements.",
    startDate: "2020-11-27T12:00:00",
    dueDate: "2020-12-10T14:00:00",
    status: "In Progress",
    priority: "Normal",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Arthur Miller",
    progress: 40,
    parentId: 13,
  },
  {
    id: 2,
    text: "Send pictures/brochures of new products.",
    startDate: "2020-11-27T06:26:40",
    dueDate: "2020-12-05T14:00:00",
    status: "New",
    priority: "High",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping',],
    category:'Cleaning Pool',
    manager: "Samantha Bright",
    progress: 0,
    parentId: 15,
  },
  {
    id: 3,
    text: "Follow up and discuss the offer.",
    startDate: "2020-11-26T11:00:00",
    dueDate: "2020-12-09T14:00:00",
    status: "New",
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Greta Sims",
    progress: 0,
    parentId: 16,
  },
  {
    id: 4,
    text: "Obtain CEO contact information.",
    startDate: "2020-11-26T05:26:40",
    dueDate: "2020-12-04T14:00:00",
    status: "In Progress",
    priority: "Normal",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'guestcomplaint'],
    category:'Cleaning Pool',
    manager: "Arthur Miller",
    progress: 25,
    parentId: 14,
  },
  {
    id: 5,
    text: "Create requested product comparison report.",
    startDate: "2020-11-25T10:00:00",
    dueDate: "2020-12-08T14:00:00",
    status: "New",
    priority: "High",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Samantha Bright",
    progress: 45,
    parentId: 16,
  },
  {
    id: 6,
    text: "Generate a quote.",
    startDate: "2020-11-25T04:26:40",
    dueDate: "2020-12-03T14:00:00",
    status: "New",
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Greta Sims",
    progress: 65,
    parentId: 13,
  },
  {
    id: 8,
    text: "Apply discounts and generate a binding offer.",
    startDate: "2020-11-24T09:00:00",
    dueDate: "2020-12-07T14:00:00",
    status: "New",
    priority: "Normal",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Greta Sims",
    progress: 100,
    parentId: 16,
  },
  {
    id: 9,
    text: "Obtain feedback on new equipment.",
    startDate: "2020-11-24T03:26:40",
    dueDate: "2020-12-02T14:00:00",
    status: "Finished",
    priority: "High",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['foodbeverage', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Robert Reagan",
    progress: 100,
    parentId: 14,
  },
  {
    id: 10,
    text: "Send SWAG to customer.",
    startDate: "2020-11-23T08:00:00",
    dueDate: "2020-12-06T14:00:00",
    status: "Finished",
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Samantha Bright",
    progress: 100,
    parentId: 15,
  },
  {
    id: 11,
    text: "Ask if upgrade is required.",
    startDate: "2020-11-23T02:26:40",
    dueDate: "2020-12-01T14:00:00",
    status: "Finished",
    priority: "Normal",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Arthur Miller",
    progress: 100,
    parentId: 14,
  },
  {
    id: 7,
    text: "Follow up and address recent feedback.",
    startDate: "2020-11-26T07:00:00",
    dueDate: "2020-12-05T14:00:00",
    status: "In Progress",
    priority: "High",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'housekeeping', 'guestcomplaint'],
    category:'Cleaning Pool',
    manager: "Robert Reagan",
    progress: 85,
    parentId: 14,
  },
  {
    id: 13,
    text: "Preparation",
    startDate: "2020-11-25T10:00:00",
    dueDate: "2020-12-10T10:00:00",
    status: "Assigned",
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'housekeeping', 'guestcomplaint',],
    category:'Cleaning Pool',
    manager: "Greta Sims",
    progress: 53,
    parentId: 12,
  },
  {
    id: 14,
    text: "Follow-up",
    startDate: "2020-11-22T10:00:00",
    dueDate: "2020-12-05T10:00:00",
    status: null,
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['foodbeverage', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Greta Sims",
    progress: 70,
    parentId: 12,
  },
  {
    id: 15,
    text: "Presentation",
    startDate: "2020-11-23T10:00:00",
    dueDate: "2020-12-06T10:00:00",
    status: null,
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'housekeeping', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Robert Reagan",
    progress: 50,
    parentId: 12,
  },
  {
    id: 16,
    text: "Handling objections",
    startDate: "2020-11-24T10:00:00",
    dueDate: "2020-12-09T10:00:00",
    status: null,
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Robert Reagan",
    progress: 48,
    parentId: 12,
  },
  {
    id: 12,
    text: "Sales Management",
    startDate: "2020-11-23T10:00:00",
    dueDate: "2020-12-10T10:00:00",
    status: null,
    priority: "Low",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'guestcomplaint', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Samantha Bright",
    progress: 55,
    parentId: 0,
  },
  {
    id: 13,
    text: "Cosas",
    startDate: "2023-12-21T10:00:00",
    dueDate: "2023-12-21T13:00:00",
    status: "In Progress",
    priority: "High",
    owner: "Sammy Hill",
    company: "ElectrixMax",
    departments: ['maintenance', 'foodbeverage', 'housekeeping', 'restaurantreservation'],
    category:'Cleaning Pool',
    manager: "Samantha Bright",
    progress: 55,
    parentId: 0,
  },
];

export default fakeTask;