import React from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Title, Legend, Format } from 'devextreme-react/chart';

const dataTest = [
  {
    name: "Edif-1",
    vcClean: 120,
    vcDirty: 145,
    ocClean: 87,
    ocDirty: 31,
  },
];

const palleteColor = ["#00e800", "#008c00", "#ee7623", "#b50000"];

const TaskStatusByArea = () => {
  const pies = () => (
    <Chart
      id="chart"
      dataSource={dataTest}
      palette={palleteColor}
    >
      <CommonSeriesSettings
        valueField="name"
        type="stackedBar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true}>
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>

      <Series valueField="vcClean" name="VC Clean" />
      <Series valueField="vcDirty" name="VC Dirty" />
      <Series valueField="ocClean" name="OC Clean" />
      <Series valueField="ocDirty" name="OC Dirty" />

      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      <Title
        text="Task Status by Area"
        horizontalAlignment="middle"
        verticalAlignment="top"
      />
    </Chart>
  );

  return (
    <div className="bg-white p-3 shadow text-center" style={{ borderRadius: 25 }}>
      {pies()}
    </div>
  );
};

export default TaskStatusByArea;
