import { t } from "i18next";

export const RoomOverviewFilterFields = [
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBusinessUnit", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "bigint",
    FieldTitle: t("Business Unit"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdAutomaticTaskType ", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "bigint",
    FieldTitle: t("Tasks Types"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"c1d4248fe04838a86624d924bfda46644d70769cff4a9001337bb580062b81df"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuilding",
    FieldType: "bigint",
    FieldTitle: t("Building"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"d373bb8a3e97f3e5c2ab80d8a82bf83c1db0463cc440bf5a00995fcb0706bbf3"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 0,
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuildingFloor",
    FieldType: "bigint",
    FieldTitle: t("Floor"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"e2258e57a645a2e471803b3cf8997c4d318c86812e491072f27a883deee2a2ab"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "FilterAllReservation",
    FieldType: "bit",
    FieldTitle: t("All Reservation"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: true,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "FilterArrival ",
    FieldType: "bit",
    FieldTitle: t("Arrival"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "FilterDeparture",
    FieldType: "bit",
    FieldTitle: t("Departure"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 1,
    FieldName: "FilterStayover",
    FieldType: "bit",
    FieldTitle: t("Stayover"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    Group: 2,
    FieldName: "FilterAllRoom",
    FieldType: "bit",
    FieldTitle: t("All Room"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterVCDirty ",
    FieldType: "bit",
    FieldTitle: t("VC Dirty "),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterVCClean ",
    FieldType: "bit",
    FieldTitle: t("VC Clean"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterOCDirty",
    FieldType: "bit",
    FieldTitle: t("OC Dirty"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterOCClean ",
    FieldType: "bit",
    FieldTitle: t("OC Clean"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterDoNotDisturb",
    FieldType: "bit",
    FieldTitle: t("DND"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterSleepingOutside",
    FieldType: "bit",
    FieldTitle: t("SO"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterOutOfOrder",
    FieldType: "bit",
    FieldTitle: t("OOO"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    Group: 2,
    FieldName: "FilterMaintenence",
    FieldType: "bit",
    FieldTitle: t("Maintenence"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
