import moment from "moment";

export function isTimestamp(value) {
  const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  return typeof value === 'string' && timestampRegex.test(value) && !isNaN(Date.parse(value));
}

export function isDate(value) {
  return value instanceof Date && !isNaN(value.valueOf());
}

export function isDateOrTimestamp(value) {
  return isDate(value) || isTimestamp(value);
}

export function formatDate(value) {
  if (isDateOrTimestamp(value)) {
    return moment(value).format('MM/DD/YYYY');
  }

  return value;
}

export function formatTimestamp(value) {
  if (isDateOrTimestamp(value)) {
    return moment(value).format('MM/DD/YYYY HH:mm:ss');
  }

  return value;
}

export function formatTime(value) {
  if (isDateOrTimestamp(value)) {
    return moment(value).format('HH:mm:ss');
  }

  return value;
}

/**
 * @param {Date} date
 * @returns {string} MM/DD/YYYY
 * @example
 * const date = new Date();
 * const formattedDate = dateFormater(date);
 * console.log(formattedDate); // 01/01/2021
 */
export const dateFormater = (date) => {
  return moment(date).format("MM/DD/YYYY");
}