export const NumberReservationsFields = [
  {
    FieldName: "AdultQuantity",
    FieldType: "int",
    FieldTitle: "Adults",
    cssClasss: "col-7 mt-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "Child1Quantity",
    FieldType: "int",
    FieldTitle: "Children 1",
    cssClasss: "col-7 mt-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "Child2Quantity",
    FieldType: "int",
    FieldTitle: "Children 2",
    cssClasss: "col-7 mt-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "Child3Quantity",
    FieldType: "int",
    FieldTitle: "Children 3",
    cssClasss: "col-7 mt-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "InfantQuantity",
    FieldType: "int",
    FieldTitle: "Infant",
    cssClasss: "col-7",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "SeniorQuantity",
    FieldType: "int",
    FieldTitle: "Senior",
    cssClasss: "col-7",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
  {
    FieldName: "PaxQuantity",
    FieldType: "int",
    FieldTitle: "Pax Quantity",
    cssClasss: "col-7 mt-3",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: true,
    dependOf: null,
  },
];
