import { Formik } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { actionsModel } from "../../enum/actionsModel.enum";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

import { useTranslation } from "react-i18next";

const ModalModel = ({ show, setShow, data, action }) => {
  const { t } = useTranslation();

  // INITIAL VALUES FORMIK
  const initialValues = {
    FieldName: data.FieldName ?? "",
    FieldTitle: data.FieldTitle ?? "",
    FieldType: data.FieldType ?? "",
    HtmlType: data.HtmlType ?? "",
    cssClasss: data.cssClasss ?? "",
    IdAppForm: data.IdAppForm ?? "",
    Position: data.Position ?? "",
    ReadOnly: data.ReadOnly ?? "",
    Required: data.Required ?? "",
    Visible: data.Visible ?? "",
    RowStatus: data.RowStatus ?? "",
    ListTitle: data.ListTitle ?? "",
    ListPosition: data.ListPosition ?? 0,
    DefaultValue: data.DefaultValue ?? "",
    OptionValues: data.OptionValues ?? "",
    DataSource: data.DataSource ?? "",
    ListVisible: data.ListVisible ?? "",
    Active: data.Active ?? "",
    PlaceHolder: data.PlaceHolder ?? "",
  };

  // VALIDATION SCHEMA
  const validationSchema = {};
  const requiredErrorMessage = t("Required");
  const fields = [
    "FieldName",
    "FieldTitle",
    "FieldType",
    "HtmlType",
    "cssClasss",
    "IdAppForm",
    "Position",
    "ReadOnly",
    "Required",
    "Visible",
    "RowStatus",
  ];

  fields.forEach((field) => {
    validationSchema[field] = yup.string().required(requiredErrorMessage);
  });
  const validation = yup.object(validationSchema);

  const user = parsedUser();
  // SUBMIT FORM
  const submit = async (values) => {
    const transform = [];
    console.log(values, data);

    let query = `id=${data.Id}&token=${user.Token}`;

    let request = await API.postAction(
      "api/CRUDModelManagement/Update?" + query,
      values
    );
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {action === actionsModel.EDIT ? t("Update") : t("Create")}{" "}
          {t("Model")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => submit(values)}
          validationSchema={validation}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={4} xs={12}>
                  <label>{t("Field Name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.FieldName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="FieldName"
                    id="FieldName"
                  />
                  {errors.FieldName && touched.FieldName ? (
                    <div className="text-danger">{errors.FieldName}</div>
                  ) : null}
                </Col>
                <Col md={4} xs={12}>
                  <label>{t("Field Title")}</label>
                  <input
                    type="text"
                    className=" form-control"
                    value={values.FieldTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="FieldTitle"
                    id="FieldTitle"
                  />
                  {errors.FieldTitle && touched.FieldTitle ? (
                    <div className="text-danger">{errors.FieldTitle}</div>
                  ) : null}
                </Col>
                <Col md={4} xs={12}>
                  <label>{t("Field Type")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.FieldType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="FieldType"
                    id="FieldType"
                  />
                  {errors.FieldType && touched.FieldType ? (
                    <div className="text-danger">{errors.FieldType}</div>
                  ) : null}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={4} xs={12}>
                  <label>{t("Html Type")}</label>
                  <input
                    type="text"
                    className=" form-control"
                    value={values.HtmlType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="HtmlType"
                    id="HtmlType"
                  />
                  {errors.HtmlType && touched.HtmlType ? (
                    <div className="text-danger">{errors.HtmlType}</div>
                  ) : null}
                </Col>
                <Col md={4} xs={12}>
                  <label>{t("Class Name")}</label>
                  <input
                    type="text"
                    className=" form-control"
                    value={values.cssClasss}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="cssClasss"
                    id="cssClasss"
                  />
                  {errors.cssClasss && touched.cssClasss ? (
                    <div className="text-danger">{errors.cssClasss}</div>
                  ) : null}
                </Col>
                <Col md={4} xs={12}>
                  <label>{t("ID App Form")}</label>
                  <input
                    type="text"
                    className=" form-control"
                    value={values.IdAppForm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="IdAppForm"
                    id="IdAppForm"
                  />
                  {errors.IdAppForm && touched.IdAppForm ? (
                    <div className="text-danger">{errors.IdAppForm}</div>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} xs={12}>
                  <label>{t("Position")}</label>
                  <input
                    type="text"
                    className=" form-control"
                    value={values.Position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Position"
                    id="Position"
                  />
                  {errors.Position && touched.Position ? (
                    <div className="text-danger">{errors.Position}</div>
                  ) : null}
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("Placeholder")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.PlaceHolder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="PlaceHolder"
                    id="PlaceHolder"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("List Title")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.ListTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ListTitle"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("List Position")}</label>
                  <input
                    type="number"
                    className="form-control"
                    value={values.ListPosition}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ListPosition"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("Default Value")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.DefaultValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="DefaultValue"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("Option Values")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.OptionValues}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="OptionValues"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label>{t("Data Source")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={values.DataSource}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="DataSource"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={values.ListVisible}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="ListVisible"
                    />
                    <label className="form-check-label" htmlFor="ListVisible">
                      {t("ListVisible")}
                    </label>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={values.Active}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Active"
                    />
                    <label className="form-check-label" htmlFor="Active">
                      {t("Active")}
                    </label>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="CheckDefault"
                      checked={values.ReadOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="ReadOnly"
                    />
                    <label className="form-check-label" htmlFor="CheckDefault">
                      {t("ReadOnly")}
                    </label>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="CheckDefault2"
                      checked={values.Required}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Required"
                    />
                    <label className="form-check-label" htmlFor="CheckDefault2">
                      {t("Required")}
                    </label>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="CheckDefault3"
                      checked={values.Visible}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Visible"
                    />
                    <label className="form-check-label" htmlFor="CheckDefault3">
                      {t("Visible")}
                    </label>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="CheckDefault4"
                      checked={values.RowStatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="RowStatus"
                    />
                    <label className="form-check-label" htmlFor="CheckDefault4">
                      {t("Row Status")}
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} xs={12} className=" d-flex  justify-content-end">
                  <button className="btn btn-primary me-2" type="submit">
                    {t("Save Changes")}
                  </button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalModel;
