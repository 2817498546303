import React from "react";
import "../../styles/walks.css";

import { useTranslation } from "react-i18next";

const Walks = ({ steps, stepLimit }) => {
  const { t } = useTranslation();

  return (
    <div className="walks">
      {steps && steps.length > 0
        ? steps.map((item, index) => {
            return (
              <div
                key={item.step}
                className={`walk ${index < stepLimit ? "active" : ""}`}
                data-walk={item.step}
              >
                <div className="walk-title">{t(item.title)}</div>

                {item.description.length ? (
                  <div className="walk-description">{item.description}</div>
                ) : null}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Walks;
