import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GenericFields } from "./GenericFields";
import { GroupTabFields } from "./FieldsGroup/GroupTabFields";

//import { useTranslation } from "react-i18next";

export const GroupTab = ({ errors, touched, updateFields, values }) => {
  //const { t } = useTranslation();

  const [groupFields] = useState(GroupTabFields);

  const updateField = async (fieldName, fieldValue, optionText) => {
    values[fieldName] = fieldValue;
    updateFields(fieldName, fieldValue);
    if (fieldName === "IdReservationCustomer") {
      updateFields("GroupName", optionText);
    }
  };

  return (
    <Container fluid>
      <Row className="py-0">
        {/* GENERAL INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            <GenericFields
              fields={groupFields.filter((x) => x.GroupBy === 0)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>
        {/* ADDRESS INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            <GenericFields
              fields={groupFields.filter((x) => x.GroupBy === 1)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>

        {/* CONTACT INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            <GenericFields
              fields={groupFields.filter((x) => x.GroupBy === 2)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
