const maintenanceGeneralData = {
  title: "GENERAL",
  type: "form",
  editable: true,

  fields: [
    {
      title: "Reservation #",
      value: "",
      htmlType: "select",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "reservationId",
    },
    {
      title: "Parent Reservation #",
      value: "",
      htmlType: "select",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "parentReservationNo",
    },
    {
      title: "Business Unit",
      value: "",
      htmlType: "select",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "businessUnit",
    },

    {
      title: "Customer",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "customer",
    },
    {
      title: "Currency",
      value: "",
      htmlType: "number",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "currency",
    },
    {
      title: "Folio Type",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "folioType",
    },
    {
      title: "Date Start",
      value: "",
      htmlType: "date",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "dateStart",
    },
    {
      title: "Date End",
      value: "",
      htmlType: "date",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "dateEnd",
    },

    {
      title: "Transaction Date",
      value: "",
      htmlType: "date",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "transactionDate",
    },
    {
      title: "Confirmation Number",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "confirmationNumber",
    },
    {
      title: "Booking Date",
      value: "",
      htmlType: "date",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "bookingDate",
    },
    {
      title: "Booking Number",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "Booking Number",
    },
    {
      title: "Blocked",
      value: "",
      htmlType: "radio",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "NO" },
        { id: true, text: "YES" },
      ], //"false;NO|true;YES",
    },
    {
      title: "Balance Limit",
      value: "",
      htmlType: "number",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "balanceLimit",
    },
    {
      title: "Cancellation Number",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "cancellationNumber",
    },
    {
      title: "Cancel Description",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "cancelDescription",
    },
    {
      title: "Voucher",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "voucher",
    },
    {
      title: "Status",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "status",
    },
  ],
};

export default maintenanceGeneralData;
