
import { Fragment } from "react";
import TBodyTable from "./tbody";

const Table = (props) => {
    const _props = { ...props };
    const { header, headerTop, keyName, stylesCustom, showFooter = true, styleContainer, overflowXHidden, ...restProps } = _props;
    const columns = header?.columns;

    return (

        <Fragment >
            <div className="card p-3">
               { headerTop && (
                    <div className=" card-header flex justify-betweenitems-center px-4  py-3  sm:flex-row">
                        {headerTop}
                    </div>
                )
               } 
                <div>
                    {props?.headerRender &&
                        <div className="card-inner border-bottom">
                            {props.headerRender}
                        </div>
                    }
                    <div id="scroll-table" className={`table-responsive ${stylesCustom?.containerOverflow || ""} `} style={styleContainer}>
                        <table className={`table  table-hover table-striped   ${stylesCustom?.table || ""} `} {...restProps}>
                            <thead style={{ textAlignLast: "left" }}  >
                                <tr className={` ${stylesCustom?.tr || ""} `}>
                                    {columns?.map((column, i) => {
                                        return (
                                            <th key={`${keyName}-th-${i}`}
                                                tabIndex={0} rowSpan={1} colSpan={1}
                                                className={`text-nowrap   ${stylesCustom?.th || ""} `}
                                            >
                                                {column.title}
                                                {column.checkbox && (
                                                    <div className="flex items-center form-check">
                                                        <input id="checkbox-all-search" type="checkbox" className="form-check-input" />
                                                    </div>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody
                            
                            >
                                <TBodyTable {..._props} handleReload={_props.handleReload} />
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default Table;