import { t } from "i18next";

export const GuestGeneratorFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Adults",
    FieldType: "int",
    FieldTitle: t("Adults"),
    Position: 3,
    cssClasss: "col-12 ",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    minValue: 0,
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Children1",
    FieldType: "int",
    FieldTitle: t("Children 1"),
    Position: 3,
    cssClasss: "col-12 ",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    minValue: 0,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Children2",
    FieldType: "int",
    FieldTitle: t("Children 2"),
    Position: 3,
    cssClasss: "col-12 ",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    minValue: 0,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Children3",
    FieldType: "int",
    FieldTitle: t("Children 3"),
    Position: 3,
    cssClasss: "col-12 ",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: 0,
    minValue: 0,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];
