import { t } from "i18next";

export const FilterFieldset = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "GroupName",
    FieldType: "varchar",
    FieldTitle: t("Group Name"),
    Position: 1,
    cssClasss: "col-md-6 col-12",
    HtmlType: "text",
    PlaceHolder: t("Group Name"),
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCustomer",
    FieldType: "varchar",
    FieldTitle: t("Customer"),
    Position: 2,
    cssClasss: "col-md-6 col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "ArrivalDate",
    FieldType: "datetime",
    FieldTitle: t("Arrival"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "DepartureDate",
    FieldType: "datetime",
    FieldTitle: t("Departure"),
    Position: 4,
    cssClasss: "col-md-3 border-right",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "GroupReservationNumber",
    FieldType: "varchar",
    FieldTitle: t("Group Reservation No."),
    Position: 4,
    cssClasss: "col-md-3 border-right",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "AllStatus",
    FieldType: "bit",
    FieldTitle: t("All"),
    Position: 2,
    cssClasss:
      "col-12  d-flex gap-1 align-items-baseline flex-row-reverse justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: true,
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "ReservationActive",
    FieldType: "bit",
    FieldTitle: t("Active"),
    Position: 2,
    cssClasss:
      "col-12  d-flex gap-1 align-items-baseline flex-row-reverse justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: true,
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "ReservationCanceled",
    FieldType: "bit",
    FieldTitle: t("Canceled"),
    Position: 2,
    cssClasss:
      "col-12  d-flex gap-1 align-items-baseline flex-row-reverse justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: true,
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
];
