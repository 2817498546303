import { t } from "i18next";

const GuestInfoFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "bit",
    FieldTitle: t("General Information"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "GUID",
    FieldType: "varchar",
    FieldTitle: t("Guest Type"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",

    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "GUIDGuest",
    FieldType: "varchar",
    FieldTitle: t("Guest Type"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"4d8534f864fae064935e9b1787252d68463e1cd28013ea426fd9a131249bc00e"}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "LastName",
    FieldType: "varchar",
    FieldTitle: t("Last Name"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FirstName",
    FieldType: "varchar",
    FieldTitle: t("First Name"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "DateOfBirth",
    FieldType: "datetime",
    FieldTitle: t("Date of Birth"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdGuestTitle",
    FieldType: "bigint",
    FieldTitle: t("Title"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"4edf03be9ccc99bc45ff3bf9a1b298ba1cdf757c0d10ac209981695fd5e052fa"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdGuestType",
    FieldType: "bigint",
    FieldTitle: t("Guest Type"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"4d8534f864fae064935e9b1787252d68463e1cd28013ea426fd9a131249bc00e"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdIdentificationType",
    FieldType: "bigint",
    FieldTitle: t("Identification Type"),
    Position: 2,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"d8d4d539186b45d5d3650138bcb79b1592fc98837501c2c42ead4a0530622df0"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdentificationNumber",
    FieldType: "varchar",
    FieldTitle: t("Identification Number"),
    Position: 2,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Gender",
    FieldType: "varchar",
    FieldTitle: t("Gender"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues:
      '[{"id": "M","text": "Male"},{"id": "F","text": "Female"},{"id": "N","text": "Not Defined"}]',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    FieldName: "",
    FieldType: "bit",
    FieldTitle: t("Reservation Information"),
    Position: 2,
    cssClasss: "col-12 mt-4",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    FieldName: "IdBraceletType",
    FieldType: "bigint",
    FieldTitle: t("Bracelet Type"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"70dac7f49dac7c213f428facdb0af765ab5b54ac11f21aa31704821ec640d1fd"}',
    Required: true,
    Visible: true,
    ReadOnly: false,

    GroupBy: 1,
  },
  {
    FieldName: "BraceletID",
    FieldType: "varchar",
    FieldTitle: t("Bracelet ID"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",

    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    FieldName: "PrimaryGuest",
    FieldType: "bit",
    FieldTitle: t("Principal Guest"),
    Position: 3,
    cssClasss: "col-12 d-flex align-items-baseline gap-2",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "null",
    FieldTitle: t("Address Information"),
    Position: 2,
    cssClasss: "col-7 mt-4",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    FieldName: "FillBtn",
    FieldType: "bit",
    FieldTitle: t("Use principal guest address"),
    Position: 3,
    cssClasss: "col-5 d-flex align-items-end justify-content-end",
    HtmlType: "button",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Address1",
    FieldType: "varchar",
    FieldTitle: t("Address 1"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Address2",
    FieldType: "varchar",
    FieldTitle: t("Address 2"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdCountry",
    FieldType: "bigint",
    FieldTitle: t("Country"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"b5aa6f64a52e815bafe085d4364b48b2c23779dcd503a53781aa04e618a621c9"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdCountryState",
    FieldType: "bigint",
    FieldTitle: t("State"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"8936cd0f50ea6d0c6d4793b36bc79e93cd62260ec560d505181ae8856a1f0e06"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
    dependOf: "IdCountry",
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "City",
    FieldType: "varchar",
    FieldTitle: t("City"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Zip",
    FieldType: "varchar",
    FieldTitle: t("Zip Code"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "varchar",
    FieldTitle: t("Contact Information"),
    Position: 4,
    cssClasss: "col-12 mt-4",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Phone1",
    FieldType: "varchar",
    FieldTitle: t("Phone 1"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Phone2",
    FieldType: "varchar",
    FieldTitle: t("Phone 2"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Email",
    FieldType: "varchar",
    FieldTitle: t("Email"),
    Position: 4,
    cssClasss: "col-12 ",
    HtmlType: "email",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];

export default GuestInfoFields;
