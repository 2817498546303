import { Button, Col, Modal, Row } from "react-bootstrap";
import { GenericFields } from "../group-reservation/GenericFields";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";
import { parsedUser } from "../../utils/GetCurrentUser";
import { RoomsFields } from "./fields/RoomsFields";
import { useFormik } from "formik";
import { useToast } from "../../contexts/toast";
import * as yup from "yup";
import API from "../../api/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import yupTypeValidator from "../../utils/YupTypeValidator";

const SelectedRooms = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const [validationsSchema, setValidationSchema] = useState(null);
  const [formikInitialObject, setFormikInitialObject] = useState(null);
  const [roomInfo, setRoomInfo] = useState(null);
  const [roomList, setRoomList] = useState([]);

  useEffect(() => {
    if (props.rooms) {
      setRoomList(props.rooms);
    }
  }, [props.rooms]);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (RoomsFields) {
      RoomsFields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    setFormikInitialObject(initialObject);
    console.log("🚀 ~ prepareFormikObject ~ initialObject:", initialObject)
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const handleSubmit = (values) => {
    requestRoom(values);
  }

  const UpdateField = (fieldName, fieldValue) => {
    if (fieldName === "Room") {
      fieldValue = fieldValue.replace(/[^a-zA-Z0-9-]/g, "");
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    prepareFormikObject();
  }, []);

  // const [errors, setErrors] = useState([]);
  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const requestRoom = async (values) => {
    setRoomInfo(null);
    const newValues = {
      ...values,
      Room: values.Room.replace(/[^a-zA-Z0-9-]/g, ""),
    }
    const { IdBusinessUnit, Room} = newValues;
    
    const exist = roomList.map(x=>x.Room).includes(Room);
    if (exist) {
      toast({
        type: "error",
        message: "Room already selected.",
      });
      return;
    }

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&IdBusinessUnit=${IdBusinessUnit}&RoomCode=${Room}`;
      let request = await API.getAction(
        "api/RestaurantReservation/SearchRoom",
        query
      );

      let recordResult = GetDataOfJSON(
        request,
        "data",
        0,
        "JSONData",
        0
      ).JSONData;

      console.log(recordResult);

      if (recordResult) {
          console.log(recordResult);
          setRoomInfo(recordResult);
      } else {
        toast({
          type: "error",
          message: "Room not found.",
        });
      };

    } catch (error) {
      console.error(error);
    }
  };

  const assign = async () => {
    try {
      // IF RECORD IS FOUND then we need to add to the database  the room
      let queryAssign = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${props.GUIDReservation}&GUIDReservation=${roomInfo.GUIDReservation}`;
      let requestAssign = await API.getAction(
        "api/RestaurantReservation/AssignRoom",
        queryAssign
      );
      props.toggle();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.toggle()}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Room</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <GenericFields
                fields={RoomsFields}
                values={formik.values}
                touched={null}
                errors={formik.errors}
                updateFields={UpdateField}
                customLabel={null}
              />
              <Col xs={12} className="mt-3 text-start">
                <Button
                  type="submit"
                  variant="secondary"
                  size="sm"
                >
                  Search
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  className="ms-2"
                  size="sm"
                  onClick={() => {
                    setRoomInfo(null);
                    UpdateField("IdBusinessUnit", null);
                    UpdateField("Room", null);
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </Col>
              <Col xs={12} className="mt-3">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Reservation No.</th>
                        <th className="text-nowrap">Arrival Date</th>
                        <th className="text-nowrap">Departure Date</th>
                        <th className="text-nowrap">Nights</th>
                        <th className="text-nowrap">Guest Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roomInfo ? (
                        <tr>
                          <td>{roomInfo?.ReservationNumber}</td>
                          <td className="text-nowrap">
                            {roomInfo.ArrivalDate
                              ? moment(roomInfo.ReservationDate).format(
                                "MM/DD/YYYY"
                              )
                              : null}
                          </td>
                          <td className="text-nowrap">
                            {roomInfo.DepartureDate
                              ? moment(roomInfo.DepartureDate).format(
                                "MM/DD/YYYY"
                              )
                              : null}
                          </td>
                          <td className="text-nowrap">{roomInfo?.Nights}</td>
                          <td className="text-nowrap">{roomInfo?.GuestName}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center text-nowrap">
                            Select a valid Room
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={roomInfo === null}
            type="button"
            variant="primary"
            size="sm"
            onClick={() => assign()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectedRooms;
