import React, { useEffect, useState } from "react";
import "./Footer.scss";
import { useAuth } from "../../contexts/auth";
import { useFormik } from "formik";
import { db } from "../../utils/db";
import { parsedUser } from "../../utils/GetCurrentUser";
import { Button } from "react-bootstrap";
import LogFilesContainer from "../LogFiles/LogFilesContainer";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const { user } = useAuth();
  const userLogged = parsedUser();
  const [showLogs, setShowLogs] = useState(false);
  const checkAudit = localStorage.getItem("AuditInformation");
  // console.log(user, userLogged);
  const footerItems = [
    {
      icon: <i className="fa-solid fa-user"></i>,
      key: "Username",
    },
    {
      icon: <i className="fa-solid fa-lock"></i>,
      key: "Token",
      format: (token) => token.replace(/(...)(?=.)/gm, "$1-"),
    },
  ];

  const handleChange = async (e) => {
    localStorage.setItem("AuditInformation", e.target.checked);
  };

  const handleCloseLogs = () => setShowLogs(!showLogs);

  const validateTestButton = () => {
    let routes = [
      "dev.lifestyle",
      "localhost",
      "dev.signumpms.signos-framework.com",
    ];
    let status = routes.filter((item) =>
      window.location.toString().includes(item)
    );
    return status.length > 0;
  };

  return (
    <>
      {showLogs ? (
        <LogFilesContainer show={showLogs} handleClose={handleCloseLogs} />
      ) : null}
      <footer className={"footer "}>
        <form>
          <div className="form-check form-switch footer-item">
            <input
              className="form-check-input"
              onChange={handleChange}
              defaultChecked={checkAudit === "true" ? true : false}
              type="checkbox"
              id="CheckDefault3"
              name="AuditInformation"
            />
            <label className="form-check-label" htmlFor="CheckDefault3">
              {t("Audit Information")}
            </label>
          </div>
        </form>

        <div className="footer-item">
          <div className="footer-item-icon">
            <i className="fa fa-database"></i>
          </div>
          <div className="footer-item-text">{user.Database}</div>
        </div>

        {user &&
          footerItems.map(({ icon, key, format }) => (
            <div key={key} className="footer-item">
              <div className="footer-item-icon">{icon}</div>
              <div className="footer-item-text">
                {format ? format(user[key]) : user[key]}
              </div>
            </div>
          ))}
        {validateTestButton() ? (
          <div className="footer-item">
            <div className="footer-item-text">
              <Button
                className="px-0"
                variant="link"
                size="sm"
                onClick={() => handleCloseLogs()}
              >
                {t("View Logs")}
              </Button>
            </div>
          </div>
        ) : null}
      </footer>
    </>
  );
}
