import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";
import RoomsTypes from "./RoomsTypes";
import { confirmAlert } from "react-confirm-alert";
import { useToast } from "../../contexts/toast";

import { useTranslation } from "react-i18next";

const TableRoomsTypes = ({
  selectedTab,
  parent,
  requestReservationTable,
  formValidation,
  formikSetErrors,
}) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [records, setRecords] = useState([]);
  const toast = useToast();
  const user = parsedUser();

  const bindDataRemote = async (filter) => {
    // First start loading the model

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: "@IdReservationGroup=" + parent?.Id,
      };

      if (!parent.Id) {
        return;
      }
      let request = await API.postAction(
        `api/GroupReservations/ReservationsGroupsDetailsTotalRecords?` + query,
        queryData ?? ""
      );
      let results = GetDataOfJSON(request, "data", 0, "JSONData", 0);

      let recordResult = results.JSONData || [];
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];

      setRecords(dataFromAPI);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              debugger;
              let query = `token=${user.Token}&RecordId=${selectedItem.GUID}&IdUser=${user.IdUser}&ParentGUID=${parent.GUID}`;
              await API.getAction(
                "api/GroupReservations/ReservationsGroupsDetailsTotalDelete",
                query
              );
              await bindDataRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };
  const generate = async () => {
    // First start loading the model

    const errors = await formValidation();
    if (Object.keys(errors).length > 0) {
      toast({
        type: "error",
        message: t("Missing fields."),
      });
      console.error(errors);
      return;
    }

    // Validate that ArrivalDate and DepartureDate are not the same
    if (parent.ArrivalDate === parent.DepartureDate) {
      formikSetErrors({
        DepartureDate: t("Dates cannot be the same."),
      });
      toast({
        type: "error",
        message: t("The minimum stay period is 1 night."),
      });
      return;
    }

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservationGroup=${parent?.GUID}`;

      let objSPVersion = "";
      console.table(parent);
      objSPVersion = `@GroupName='${parent.GroupName ?? "null"}', 
      @IdBusinessUnit=${parent.IdBusinessUnit ?? "null"}, 
      @IdCustomer=${parent.IdCustomer ?? "null"},
        @IdCurrency=${parent.IdCurrency}, 
        @IdMealPlan=${parent.IdMealPlan ?? "null"}, 
      @IdRateCategoryType=${parent.IdRateCategoryType},
      @ArrivalDate=${
        parent.ArrivalDate ? "'" + parent.ArrivalDate + "'" : null
      }, 
      @DepartureDate=${
        parent.DepartureDate ? "'" + parent.DepartureDate + "'" : null
      }, 
        
         
      @Nights=${parent.Nights ?? "null"}, 
      @BookingDate=${
        parent.BookingDate ? "'" + parent.BookingDate + "'" : null
      }, 
        @BookingNumber=${
          parent.BookingNumber ? "'" + parent.BookingNumber + "'" : null
        }, 

        @Voucher=${
          parent.VoucherNumber ? "'" + parent.VoucherNumber + "'" : null
        }, 
        @IdSpecialCode=${parent.IdSpecialCode ?? "null"}, 

        @RateAssigned=${parent.RateAssigned ?? "null"}`;

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.postAction(
        `api/GroupReservations/Generate?${query}`,
        queryData
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log(
        "🚀 TABLEROOMSTYPES ~ generate ~ parsedResults:",
        parsedResults
      );

      if (parsedResults.RateError) {
        const widthToast = parsedResults?.Msg.length * 10;
        toast({
          type: "error",
          message: parsedResults?.Msg,
          width: widthToast || 250,
        });
        // return;
        formikSetErrors.setFieldError("IdCustomer", t("No contract found."));
        return {
          Error: true,
        };
      }

      bindDataRemote();
      requestReservationTable();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const [showRoomsType, setShowRoomsType] = useState(false);
  const toggleRoomsType = async () => {
    setShowRoomsType(!showRoomsType);
    if (showRoomsType) {
      await bindDataRemote();
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    if (parent) {
      bindDataRemote();
    }
  }, [parent]);
  return (
    <>
      {showRoomsType ? (
        <RoomsTypes
          show={showRoomsType}
          toggle={toggleRoomsType}
          selectedItem={selectedItem}
          parent={parent}
        />
      ) : null}
      <Container fluid>
        <Row className="text-center">
          <Col xs={12} className="mt-3">
            <Card>
              <Card.Body>
                <Row className="bg-white pe-4 py-2">
                  <Col>
                    <div className="table-responsive ">
                      <table className="table table-bordered shadow-sm table-striped">
                        <thead>
                          <tr>
                            <th>{t("Room Type")}</th>
                            <th>{t("Room Type Quantity")}</th>
                            <th>{t("Pax Quantity")}</th>
                            <th>{t("Generated")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records &&
                            records.map((item, index) => (
                              <tr
                                key={index}
                                className={`clickable ${
                                  item === selectedItem ? "active" : ""
                                }`}
                                onClick={() => {
                                  setSelectedItem(item);
                                }}
                              >
                                <td>{item.RoomType}</td>
                                <td>{item.RoomTypeQuantity}</td>
                                <td>{item.PaxQuantity}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.Generated}
                                    readOnly
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="d-flex flex-column gap-3 align-content-center align-items-baseline">
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => toggleRoomsType()}
                      >
                        <i className="fa-solid fa-plus" />
                      </button>
                      {selectedItem ? (
                        <>
                          <button
                            className={`btn btn-outline-secondary ${
                              selectedItem.Generated && "d-none disabled"
                            }`}
                            disabled={selectedItem.Generated}
                            onClick={() => {
                              /* toggleEditGuest(); */
                              toggleRoomsType();
                            }}
                          >
                            <i className="fa-solid fa-pencil" />
                          </button>
                          <button
                            className={`btn btn-outline-secondary ${
                              selectedItem.Generated && "d-none disabled"
                            }`}
                            disabled={selectedItem.Generated}
                            onClick={() => {
                              deleteConfirm();
                            }}
                          >
                            <i className="fa-solid fa-trash" />
                          </button>
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-start">
                <Row>
                  <Col>
                    {/* <b className="me-2">Rooms : {0}</b>

                  <b className="me-2">Adults : {0}</b>
                  <b className="me-2">Children : {0}</b> */}
                  </Col>
                  <Col className="text-end col-auto">
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={() => {
                        generate();
                      }}
                    >
                      <i className="fa-regular fa-square-check me-2"></i>
                      {t("Generate")}
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TableRoomsTypes;
