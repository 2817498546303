import { t } from "i18next";

const CHARGESDISCOUNTFIELDS = [
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "DateStart",
    FieldType: "datetime",
    FieldTitle: t("Date Start"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "DateEnd",
    FieldType: "datetime",
    FieldTitle: t("Date End"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    minValueDependOf: "DateStart",
  },
  {
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    cssClasss: "col-12 ",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: '{"1"}',
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": "Yes"}]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },

  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
];

export default CHARGESDISCOUNTFIELDS;
