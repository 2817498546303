import React from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Font,
  Margin,
  Title,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import Loading from "../Loading";

const palleteColor = ["#00b500", "#ff3333"];

const dataTest =[{
  title:"Clean Rooms",
  total:"80",
}, {
  title:"Dirty Rooms",
  total:"20"
}]

const RoomCleaning = ({ data, viewTotalValue }) => {
  console.log(data);

  const customizeLabel = (e) => {
    return `${e.valueText}%`;
  };

  const pies = () => (
    <PieChart
      dataSource={dataTest}
      resolveLabelOverlapping="shift"
      palette={palleteColor}
      innerRadius={0.65}
      centerRender={(e) => CenterTemplate(e, viewTotalValue)}
      type="doughnut"
    >
      <Series argumentField="title" valueField="total">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
          backgroundColor="none"
        >
          <Font size={16} />
          <Connector visible={true}></Connector>
        </Label>
      </Series>
      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Title text="Room Cleaning" verticalAlignment="top">
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </PieChart>
  );

  return (
    <div className="bg-white shadow p-3 text-center" style={{ borderRadius: 25 }}>
      {dataTest && dataTest.length > 0 ? pies() : <Loading />}
    </div>
  );
};

export default RoomCleaning;
