import React, { useState, useEffect, useCallback } from "react";

import ScrollView from "devextreme-react/scroll-view";
import Sortable from "devextreme-react/sortable";
import { TaskKanbanCard } from "../task-kanban-card/TaskKanbanCard";

import "./TaskListKanban.scss";

import Reloj from "../../Reloj/Reloj";
import moment from "moment";

//import { useTranslation } from "react-i18next";

const reorder = (items, item, fromIndex, toIndex) => {
  let result = items;
  if (fromIndex >= 0) {
    result = [...result.slice(0, fromIndex), ...result.slice(fromIndex + 1)];
  }

  if (toIndex >= 0) {
    result = [...result.slice(0, toIndex), item, ...result.slice(toIndex)];
  }

  const priorities = ["High", "Normal", "Low"];
  // result.sort(
  //   (a, b) => priorities.indexOf(a.priority) - priorities.indexOf(b.priority)
  // );

  return result;
};

const TaskList = ({
  status,
  index,
  tasks,
  onTaskDragStart,
  onTaskDrop,
  changePopupVisibility,
  openForEdit,
  expanded,
  updateKey,
}) => {
  //const { t } = useTranslation();

  let byTypeStatus =
    status.Description !== "New" && status.Description !== "Finished"
      ? "648px"
      : "438px";

  console.log(status.Description);
  return (
    <div className="list bg-white shadow" style={{ width: byTypeStatus }}>
      <div
        className={`list-title dx-theme-text-color text-center mt-2 px-0`}
        style={{ backgroundColor: status?.BackgroundColor }}
      >
        <h4 style={{ fontWeight: 800, color: status?.TextColor }}>
          {status?.Description}
        </h4>
        <hr className="my-0" style={{ top: "8px", position: "relative" }} />
      </div>

      <ScrollView
        className="scrollable-list"
        direction="vertical"
        showScrollbar="always"
        height={"65vh"}
      >
        <Sortable
          className="sortable-cards"
          group="cardsGroup"
          data={index}
          onDragStart={onTaskDragStart}
          onReorder={onTaskDrop}
          onAdd={onTaskDrop}
        >
          {tasks &&
            tasks.map((task) => (
              <TaskKanbanCard
                key={task.Id + "B"}
                task={task}
                expanded={expanded}
                openForEdit={openForEdit}
                updateKey={updateKey}
              />
            ))}
        </Sortable>
      </ScrollView>
    </div>
  );
};

//AQUI EL TASKLIST KANBAN----------------------------------------------------------------<

export const TaskListKanban = React.forwardRef(
  (
    {
      records,
      statusColumns,
      dataSource,
      changePopupVisibility,
      openForEdit,
      updateKey,
      expanded,
    },
    ref
  ) => {
    const [lists, setLists] = useState([]);
    const [statuses, setStatuses] = useState();

    const validateTask = (column) => {
      const currentDate = new Date(moment());

      switch (column) {
        case "New":
          return records.filter((task) => !task.Collaborator);
        case "In Time":
          return records.filter(
            (task) =>
              new Date(task.ExecutionDate) > currentDate &&
              task.Collaborator &&
              task.Active
          );
        case "Out of Time":
          return records.filter(
            (task) => new Date(task.ExecutionDate) < currentDate && task.Active
          );
        case "Finished":
          return records.filter((task) => !task.Active);
        default:
          return [];
      }
    };

    const reorderListAndStatus = () => {
      if (records && statusColumns) {
        const initialLists = [];
        const priorities = ["Very High", "High", "Medium", "Low"];

        const sortedStatuses = statusColumns;
        console.log(sortedStatuses);

        sortedStatuses.forEach((item) => {
          let tasksWithStatus = validateTask(item.Description);

          if (
            tasksWithStatus.length < 0 &&
            Object.keys(tasksWithStatus[0]).length < 0
          ) {
            tasksWithStatus.sort(
              (a, b) =>
                priorities.indexOf(a.Priority) - priorities.indexOf(b.Priority)
            );
          }

          initialLists.push(tasksWithStatus);
        });

        console.log(initialLists);

        setLists(initialLists);
        setStatuses(sortedStatuses);
      }
    };

    useEffect(() => {
      reorderListAndStatus();
    }, [statusColumns, records]);

    /* const onListReorder = useCallback(
      ({ fromIndex, toIndex }) => {
        setLists(reorder(lists, lists[fromIndex], fromIndex, toIndex));
        setStatuses(statuses); //reorder(statuses, statuses[fromIndex], fromIndex, toIndex));
      },
      [lists, statuses]
    ); */

    /* const onTaskDragStart = useCallback(
      (e) => {
        e.itemData = lists[e.fromData][e.fromIndex];
      },
      [lists]
    ); */

    /* const onTaskDrop = useCallback(
      (e) => {
        const updatedList = [...lists];
        e.itemData.State = statuses[e.toData].Description;
        updatedList[e.fromData] = reorder(
          updatedList[e.fromData],
          e.itemData,
          e.fromIndex,
          -1
        );
        updatedList[e.toData] = reorder(
          updatedList[e.toData],
          e.itemData,
          -1,
          e.toIndex
        );

        setLists(updatedList);
      },
      [lists, statuses]
    ); */

    return (
      <div id="kanban" className="kanban">
        <ScrollView
          className="scrollable-list"
          direction="both"
          showScrollbar="always"
        >
          <Sortable
            ref={ref}
            itemOrientation="horizontal"
            handle=".list-title"
            /* onReorder={onListReorder} */
          >
            {lists.map((tasks, listIndex) => {
              const titleStatus = statuses[listIndex].Description;
              return (
                <TaskList
                  key={listIndex + "A"}
                  status={statuses[listIndex]}
                  index={listIndex}
                  tasks={tasks}
                  onTaskDragStart={null}
                  onTaskDrop={null}
                  changePopupVisibility={changePopupVisibility}
                  openForEdit={openForEdit}
                  expanded={expanded}
                  updateKey={updateKey}
                />
              );
            })}
          </Sortable>
        </ScrollView>
      </div>
    );
  }
);
