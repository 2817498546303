import React from 'react'
import { IncidentTrackingContainer } from '../../components'


const IncidentTrackingMaintenance = () => {
  return (
    <>
      <IncidentTrackingContainer routeName={'Maintenance / Process / Incident Tracking'}
        module="maintenance" />
    </>
  )
}

export default IncidentTrackingMaintenance
