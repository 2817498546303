import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

export const CustomSpecialRateArrivalBookingTable = ({SpecialRateArrivalBookingData}) => {

  const [data, setData] = useState([])

  useEffect(() => {
    setData(SpecialRateArrivalBookingData)
  }, [SpecialRateArrivalBookingData])
  
  return (
    <>
      <Table bordered className='border-black border-opacity-100 border-1 w-75' style={{ fontSize: '.8rem' }}>
        <thead>
      <tr>
        <th colSpan={2} scope="colgroup" className='text-center'>Arrival</th>
        <th colSpan={2} scope="colgroup" className='text-center'>Booking</th>
        <th rowSpan={2} scope="colgroup" className='text-center'>Room Type</th>
        <th rowSpan={2} scope="colgroup" className='text-center'>%</th>
      </tr>
      <tr>
        <th scope="col" className='text-center'>Start Date</th>
        <th scope="col" className='text-center'>End Date</th>
        <th scope="col" className='text-center'>Start Date</th>
        <th scope="col" className='text-center'>End Date</th>
      </tr>
        </thead>
        <tbody>
        {data.length > 0 ? data.map((specialRateData, index) => <tr key={index}>
          <td className="text-center">{specialRateData.arrival_end_date}</td>
          <td className="text-center">{specialRateData.arrival_start_date}</td>
          <td className="text-center">{specialRateData.booking_end_date}</td>
          <td className="text-center">{specialRateData.booking_start_date}</td>
          <td className="text-center">{specialRateData.room_type}</td>
          <td className="text-center">{specialRateData.percentage}</td>
        </tr>): <tr><td colSpan="6" className="text-center">No data</td></tr>}
        </tbody>
      </Table>
    </>
  )
}
