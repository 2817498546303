const maintenanceGuestData = {
  title: "GUESTS",
  type: "grid",
  editable: true,
  records: {
    fieldsTitles: [
      "First Name",
      "Last Name",
      "Title",
      "Identification Type",
      "Identification Number",
      "Gender",
      "Date Of Birth",
      "Guest Type",
      "Country",
      "State",
      "City",
      "Address 1",
      "Address 2",
      "Zip",
      "Phone 1",
      "Phone 2",
      "eMail",
    ],

    fieldsRows: [
      {
        ID: 1,
        FirstName: "JUAN FERNANDO",
        LastName: "PÉREZ DEL CORRAL.",
        Title: "MRS.",
        IdentificationType: "PASSPORT",
        IdentificationNumber: "J12393496",
        Gender: "M",
        DateOfBirth: "20/05/2000",
        GuestType: "CONVENTIONAL VACATIONERS",
        Country: "USA",
        City: "LOS ANGELES",
        State: "CA",
        Address1: "17 WINDSOR RD, SUMMIT, NJ,07901",
        Address2: "",
        Zip: "",
        Phone: "07901",
        Phone2: "",
        Email: "JPEREZ@MAIL.COM",
      },
      // {
      //   fieldValue: [
      //     "VALENTINA",
      //     "LAVERDE DE LA ROSA.",
      //     "MS.",
      //     "IDENTIFICATION CARD",
      //     "004174863",
      //     "F",
      //     "27/10/1967",
      //     "FAMILY TRAVELERS ",
      //     "USA",
      //     "MIAMI, LA",
      //     "MIAMI",
      //     "27TH AVE, QUEENS, NY, ",
      //     "",
      //     "11102",
      //     "",
      //     "",
      //     "VLAVERDE@MAIL.COM",
      //   ],
      // },
      // {
      //   fieldValue: [
      //     "MOISES",
      //     " DE LA RENTA..",
      //     "MRS.",
      //     "IDENTIFICATION CARD",
      //     "104167768",
      //     "M",
      //     "19/05/2002",
      //     "FAMILY TRAVELERS ",
      //     "ESP",
      //     "MIAMI, LA",
      //     "MIAMI",
      //     "27TH AVE, QUEENS, NY, ",
      //     "",
      //     "11102",
      //     "",
      //     "",
      //     "MOISES.DELARENTA@MAIL.COM	",
      //   ],
    ],
  },
  fields: [
    {
      title: "First Name",
      value: "",
      htmlType: "text",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "john",
      name: "firstName",
    },
    {
      title: "Last Name",
      value: "",
      htmlType: "text",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "doe",
      name: "lastName",
    },

    {
      title: "Title",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "title",
    },
    {
      title: "Identification Type",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "identificationType",
    },
    {
      title: "Identification Number",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "identificationNumber",
    },
    {
      title: "Gender",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Date of Birth",
      value: "",
      htmlType: "date",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "dateOfBirth",
    },
    {
      title: "Guest Type",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "guestType",
    },
    {
      title: "Country",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "country",
    },
    {
      title: "State",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "countryState",
    },
    {
      title: "City",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "city",
    },
    {
      title: "Address 1",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "address1",
    },
    {
      title: "Address 2",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "address2",
    },
    {
      title: "Zip",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "zip",
    },
    {
      title: "Phone 1",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "phone1",
    },
    {
      title: "Phone 2",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "phone2",
    },
    {
      title: "eMail",
      value: "",
      htmlType: "email",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "email",
    },
  ],
};

export default maintenanceGuestData;
