import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import "semantic-ui-css/semantic.min.css";

import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import yupTypeValidator from "../../utils/YupTypeValidator";

import * as yup from "yup";
import FieldSelector from "../customComponents/FieldSelector";
import { useFormik } from "formik";

import "./incidentstyle.scss";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

const IncidentDetails = (props) => {
  const { t } = useTranslation();

  const [validationsSchema, setValidationSchema] = useState(null);
  const toast = useToast();
  const user = parsedUser();
  const [fields, setFields] = useState([]);
  const [record, setRecord] = useState({});

  const formatDateTime = (datetime) => {
    const fechaMoment = moment(datetime, "YYYY-MM-DD HH:mm");

    return fechaMoment.isValid()
      ? fechaMoment.format("YYYY-MM-DD HH:mm:ss")
      : "null";
  };

  const bindDataModelRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}`;
      let uri =
        props.module === "guestComplain"
          ? "api/Tasks/GuestComplainModel?"
          : props.module === "houseKeeping"
          ? "api/Tasks/houseKeepingModel?"
          : "api/Tasks/maintenanceModel?";
      let requestSections = await API.getAction(uri, querySections);

      let result = requestSections.data;

      //console.log(requestSections)
      let resultModel = result;
      let resultModel2 = resultModel[0].JSONData;
      let resultModel2Parsed = JSON.parse(resultModel2);
      let resultModel3Parsed = resultModel2Parsed[0].JSONData;
      let resultModel4Parsed = resultModel3Parsed[0].Fields;

      //console.log(resultModel4Parsed, props.actionMode)

      resultModel4Parsed = resultModel4Parsed.filter(
        (x) => x.FieldName !== "IdModule"
      );

      // if (props.actionMode === 'I') {

      // 	resultModel4Parsed = resultModel4Parsed.filter(x => x.Required === true || !x.Visible)
      // } else {

      // }
      setFields(resultModel4Parsed);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const handleSubmit = async (obj) => {
    console.log(obj, props);

    if (props.actionMode === "I") {
    }
    //console.log(obj, record);

    formik.validateForm();
    if (!formik.isValid) {
      return;
    }
    let objSPVersion = "";

    fields.forEach((item) => {
      if (
        item.ReadOnly ||
        item.HtmlType === "separator" ||
        item.FieldName.length === 0
      )
        return;
      console.log("cambios ", item.FieldName, obj[item.FieldName]);
      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (
        item.HtmlType === "datetime" ||
        item.HtmlType === "datetime-local"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + formatDateTime(obj[item.FieldName]) + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${props.actionMode}`;

    let queryData = {
      Data: objSPVersion,
    };

    console.log("DATA", objSPVersion);

    let uri =
      props.module === "guestComplain"
        ? "api/Tasks/GuestComplainAddEdit?"
        : props.module === "houseKeeping"
        ? "api/Tasks/houseKeepingAddEdit?"
        : props.module === "foodAndBeverage"
        ? "api/Tasks/foodAndBeverageAddEdit?"
        : "api/Tasks/maintenanceAddEdit?";

    let request = await API.postAction(uri + queryString, queryData);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        //	setGeneralError(response.Msg);
        return;
      }
      console.log(response.JSONData);
      //	props.setSelectedITem(response.JSONData);
    }
    toast({
      type: "success",
      message: t("Record saved successfully"),
    });

    props.toggle(null, true);
    // props.setStep(props.step + 1)
  };

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const fillFormikObject = (record) => {
    console.log(record);
    if (record && fields && fields.length > 0) {
      fields.forEach((item) => {
        /* console.log(
					"field name",
					item.FieldName,
					"RecordValue ",
					record[item.FieldName]
				); */
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  useEffect(() => {
    prepareFormikObject();
    bindDataModelRemote();
  }, []);

  useEffect(() => {
    setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (
      fields &&
      fields.length > 0 &&
      record &&
      Object.keys(record).length > 0
    ) {
      fillFormikObject(record);
    }
  }, [fields]);
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  //console.log('AQUI LOS FIELDS DE VERDAD',fields)

  return (
    <Modal
      show={props.show}
      onHide={() => props.toggle(null)}
      backdrop="static"
      keyboard={false}
      size="md"
      // className='modalRight'
      // dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <Row>
          <Col>
            <h4>{t("Incident Details")}</h4>
          </Col>
        </Row>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Container fluid>
          <Row>
            {fields.map((item, index) => (
              <Col
                className={
                  item.cssClasss +
                  (!item.Visible && item.FieldName !== "ExecutionDate"
                    ? " d-none"
                    : "")
                }
                key={index}
              >
                {console.log(item.FieldName === "ExecutionDate" ? item : "")}

                <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                {item.Required ? (
                  <i className="required-asterisk ms-1 text-danger">*</i>
                ) : null}
                <br />

                <FieldSelector
                  actionMode={props.actionMode}
                  model={item}
                  key={index}
                  obj={formik.values}
                  updateField={updateField}
                  value={formik.values[item.FieldName]}
                ></FieldSelector>
                {formik.errors[item.FieldName] ? (
                  <div className="invalid text-sm">
                    {formik.errors[item.FieldName]}
                  </div>
                ) : null}
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          <button
            className="btn me-2"
            type={"button"}
            onClick={() => {
              props.toggle(null);
            }}
          >
            {t("Close")}
          </button>
          <button
            className="btn btn-primary me-2"
            type={"button"}
            onClick={formik.submitForm}
          >
            {t("Save")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default IncidentDetails;
