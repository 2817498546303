import { Col, Row } from "react-bootstrap";
import CRUDPageGRIDCustom from "../../../pages/crud/CRUDPageGridCustom";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

const SpecialServicesTab = ({ selectedReservation, actionMode }) => {
  const { t } = useTranslation();
  // const [record, setRecord] = useState(null);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selectedReservation:", selectedReservation);
  }, [selectedReservation]);

  // useEffect(() => {
  //   if(record) {
  //     console.log('Record', record?.ArrivalDate)
  //   }
  // }, [record])

  if (!selectedReservation) {
    return <></>;
  }

  if (
    !selectedReservation?.ArrivalDate ||
    !selectedReservation?.DepartureDate
  ) {
    return (
      <Row className="mx-0 mt-3">
        <Col className="mx-2">
          <label style={{ fontSize: "16px" }}>
            {t("Please fill the Rate Information Tab to add Special Services.")}
          </label>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mx-0">
      <Col>
        <CRUDPageGRIDCustom
          disableEdit={actionMode === "VIEW"}
          disableAdd={actionMode === "VIEW"}
          getEndpoint="api/Reservations/SpecialServicesRecords"
          modelEndpoint="api/Reservations/SpecialServicesModel"
          addEditEndpoint="api/Reservations/SpecialServicesAddEdit"
          deleteEndpoint="api/Reservations/SpecialServicesDelete"
          detailsEndpoint="api/Reservations/SpecialServicesDetails"
          headerEndpoint="api/Reservations/SpecialServicesHeader"
          id="specialServices"
          ParentGUID={selectedReservation?.GUID}
          Parent={selectedReservation}
          ParentField={"GUIDReservation"}
          ParentFieldValue={selectedReservation?.GUID}
          ParentFieldType={"string"}
          page={{
            PageIndex: 2,
            PageTitle: t("Special Services"),
            PageIcon: "fa-solid fa-file-lines",
            MultiRecord: true,
            Active: true,
          }}
        ></CRUDPageGRIDCustom>
      </Col>
    </Row>
  );
};

export default SpecialServicesTab;
