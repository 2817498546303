import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";

const ApplyAdvance = (props) => {
  const user = parsedUser();
  const [records, setRecords] = useState([]);

  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);

  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = [
    {
      FieldName: "IdBillingService",
      FieldType: "varchar",
      FieldTitle: "Billing Service",

      cssClasss: "col-12 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"aacaf57e7a6d5a148af4e42f9255085b7acd51ea174e54c11b5e3c4974331524"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Details",
      FieldType: "varchar",
      FieldTitle: "Details",

      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      //	"DataSource": '{"HashID":"2b868d0982957ef9246670e793308c8cc04ad373d1355554308e6e2e189ab505"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "DetailReferences",
      FieldType: "varchar",
      FieldTitle: "Details",

      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      //	"DataSource": '{"HashID":"2b868d0982957ef9246670e793308c8cc04ad373d1355554308e6e2e189ab505"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "Amount",
      FieldType: "number",
      FieldTitle: "Amount",
      Position: 2,
      cssClasss: "col-12 ",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];
  const bindDataRemote = async () => {};
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj, props.selectedFolio);
    if (obj.Amount <= 0 || !obj.Amount) {
      setGeneralError("Amount must be greater than 0");
      toast({
        type: "error",
        message: "Amount must be greater than 0",
      });
      return;
    }

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@Details=${
          obj.Details ? "'" + obj.Details + "'" : "null"
        },@DetailReferences=${
          obj.DetailReferences ? "'" + obj.DetailReferences + "'" : "null"
        }, @IdBillingService=${
          obj.IdBillingService ? obj.IdBillingService : "null"
        }, @Amount=${obj.Amount}, @GUIDReservationToFolio='${
          props.selectedFolio.GUIDReservationToFolio
        }'`,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/ApplyAdvance?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);
  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "sm"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Advance</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            {fields &&
              fields
                .filter(
                  (x) =>
                    x.FieldTitle !== "Audit Information" &&
                    x.FieldName !== "Id" &&
                    x.FieldName !== "IdCustomer" &&
                    x.FieldName !== "DateAdd" &&
                    x.FieldName !== "File" &&
                    x.FieldName !== "DateMod"
                )
                .map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={`col-${item.FieldName}-${index}`}
                    >
                      <label className="me-2 mt-2">
                        {item.FieldTitle}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={""}
                        model={item}
                        obj={formik.values}
                        key={`field-${item.FieldName}`}
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm my-1">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              Please check the forms for errors
            </span>
          )}
          <button className="btn me-2" type="button" onClick={props.toggle}>
            Close
          </button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyAdvance;
