import React, { useCallback, useEffect, useRef, useState } from "react";
import { TaskListKanban } from "./task-list-kanban/TaskListKanban";

import { Container, Tab, Tabs } from "react-bootstrap";
import IncidentTaskFilter from "./IncidentTaskFilter";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import IncidentTrackingList from "./incidentTrackingList";
import IncidentDetails from "./IncidentDetails";
import CustomBreadcrumbs from "../breadcrumb";
import { confirmAlert } from "react-confirm-alert";

import fakeData from "../../fakeData/fakeTask";
import Reloj from "../Reloj/Reloj";

import { useTranslation } from "react-i18next";

const IncidentTrackingContainer = (props) => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState(fakeData);

  const [popupVisible, setPopupVisible] = useState(false);
  const kanbanRef = useRef(null);
  const gridRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [records, setRecords] = useState([]);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const user = parsedUser();
  const [isSearching, setIsSearching] = useState(false);
  const [updateKey, setUpdateKey] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleUpdateClick = () => {
    // Incrementa el estado para forzar la actualización de todos los componentes Timer
    setUpdateKey(!updateKey);
  };

  const changePopupVisibility = useCallback((isVisible) => {
    setPopupVisible(isVisible);
  }, []);

  useEffect(() => {
    bindDataStatusRemote();
    bindDataCountRemote();
    //bindDataRemote();
  }, []);
  useEffect(() => {
    console.log(recordCount);
    bindDataRemote();
  }, [recordCount]);
  //Bring all the records
  const bindDataRemote = async (filter) => {
    setIsLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = recordCount;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
      let queryData = {
        Data: filter,
      };
      let uri =
        props.module === "guestComplain"
          ? "api/Tasks/GuestComplainRecords?"
          : props.module === "houseKeeping"
          ? "api/Tasks/houseKeepingRecords?"
          : props.module === "foodAndBeverage"
          ? "api/Tasks/foodAndBeverageRecords?"
          : "api/Tasks/maintenanceRecords?";
      let request = await API.postAction(uri + query, queryData ?? "");
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setRecords([]);

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];
      console.log("Results from record", recordResult);

      setRecords(recordResult);

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Bring all the status for task
  const [statuses, setStatuses] = useState([]);
  const bindDataStatusRemote = async (filter) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=PriorityOrder&SortType=ASC`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=100&pageNumber=${currentPage}`;
      let queryData = {
        Data: filter,
      };
      let url =
        props.module === "foodAndBeverage"
          ? "api/Tasks/foodAndBeverageStatus?"
          : props.module === "guestComplain"
          ? "api/Tasks/GuestComplainStatus?"
          : props.module === "houseKeeping"
          ? "api/Tasks/houseKeepingStatus?"
          : "api/Tasks/maintenanceStatus?";
      let request = await API.postAction(url + query, queryData ?? "");
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setStatuses([]);

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];
      console.log("Results from record", recordResult);

      setStatuses(recordResult);

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    }
  };

  //Bring all the details for the selected record
  const bindDataDetailsRemote = async (item) => {
    setIsLoading(true);

    try {
      let theItem = item ? item : selectedItem;
      console.log("selected item", selectedItem);
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${theItem.GUID}`;
      let url =
        props.module === "foodAndBeverage"
          ? "api/Tasks/foodAndBeverageDetails?"
          : props.module === "guestComplain"
          ? "api/Tasks/GuestComplainDetails?"
          : props.module === "houseKeeping"
          ? "api/Tasks/houseKeepingDetails?"
          : "api/Tasks/maintenanceDetails?";
      let request = await API.getAction(url, query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error(t("No data found"));
      }

      let record = selectedItem;

      //Now we need to go over the entire result and assign values to the record
      Object.keys(recordResult).forEach((item) => {
        record[item] = recordResult[item];
      });

      setSelectedItem(record);
      console.log(record);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataCountRemote = async (filter) => {
    setIsLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      // query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: objSPVersion,
      };
      let uri =
        props.module === "foodAndBeverage"
          ? "api/Tasks/foodAndBeverageCount?"
          : props.module === "guestComplain"
          ? "api/Tasks/GuestComplainCount?"
          : props.module === "houseKeeping"
          ? "api/Tasks/houseKeepingCount?"
          : "api/Tasks/maintenanceCount?";
      let request = await API.postAction(uri + query, queryData ?? "");

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedItem.GUID}`;
            let uri =
              props.module === "foodAndBeverage"
                ? "api/Tasks/foodAndBeverageDelete?"
                : props.module === "guestComplain"
                ? "api/Tasks/GuestComplainDelete?"
                : props.module === "houseKeeping"
                ? "api/Tasks/houseKeepingDelete?"
                : "api/Tasks/maintenanceDelete?";
            await API.getAction(uri, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  const toggleAddEdit = (mode, reload) => {
    setActionMode(mode);
    if (mode === "U") {
      bindDataDetailsRemote();
    }

    setShow(!show);

    if (mode === null) {
      setSelectedItem(null);
    }
    if (reload) {
      bindDataRemote();
      setSelectedItem(null);
    }
  };
  const openForEdit = (item) => {
    setSelectedItem(item);

    toggleAddEdit("U");
  };

  return (
    <>
      {show ? (
        <IncidentDetails
          show={show}
          toggle={toggleAddEdit}
          module={props.module}
          record={selectedItem}
          actionMode={actionMode}
        />
      ) : null}
      <CustomBreadcrumbs pathTitle={props.routeName} />
      <Container>
        {/* // <IncidentFilter handleClose={closeFilter} />} */}
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-file-circle-exclamation me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h2 className={"my-1 fw-bolder"}>
                        {t("Incident Tracking")}
                      </h2>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                {/* {actionMode === null ? ( */}
                <>
                  {selectedItem && (
                    <>
                      <button
                        className="btn btn-sm my-1 me-2"
                        title={t("Update")}
                        disabled={isLoading}
                        onClick={() => {
                          toggleAddEdit("U");
                          // actionSelected(actionsModel.EDIT, model.data)
                        }}
                        //</> onClick={() => actionSelected(actionsModel.EDIT, model.data)}
                      >
                        <i className="fa fa-pencil"></i>
                      </button>
                      <button
                        className="btn btn-sm my-1 me-2 d-none"
                        title={t("View")}
                        disabled={isLoading}
                        //onClick={() => actionSelected(actionsModel.VIEW, model.data)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>

                      <button
                        className="btn btn-lg my-1 me-2"
                        title={t("Export to PDF")}
                        disabled={isLoading}
                        onClick={() => {}}
                      >
                        <i className="fa-solid fa-file-pdf"></i>
                      </button>
                      <button
                        className="btn btn-lg my-1 me-2"
                        title={t("Export to Excel")}
                        disabled={isLoading}
                        onClick={() => {}}
                      >
                        <i className="fa-solid fa-file-excel"></i>
                      </button>
                      {/* <button className="btn btn-lg my-1 me-2" title="Send email" disabled={isLoading} onClick={() => { }}>
                    <i className="fa-solid fa-envelope"></i>
                  </button> */}
                      {/* <button className="btn btn-lg my-1 me-2" title="Send to print" disabled={isLoading} onClick={() => { }}>
                        <i className="fa-solid fa-print"></i>
                      </button> */}
                      <button
                        className="btn btn-lg my-1 me-2"
                        title={t("Delete")}
                        disabled={isLoading}
                        onClick={() => {
                          deleteConfirm(selectedItem);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </>
                  )}

                  {/* {!isSearching && ( */}
                  <button
                    className={`btn btn-sm  my-1 me-2`}
                    title={t("Search")}
                    onClick={() => setIsSearching(!isSearching)}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>

                  {/* )} */}
                  <button
                    className="btn btn-secondary my-1 me-2"
                    title={t("Create")}
                    disabled={isLoading}
                    onClick={() => {
                      toggleAddEdit("I");
                    }}
                  >
                    {t("Add Incident")}
                  </button>
                </>
                {/* ) : null} */}
              </div>
            </div>
            <div className="px-2">
              <div className="d-block m-3">
                {isSearching && (
                  <IncidentTaskFilter handleClose={props.closeFilter} />
                )}
              </div>
              <Reloj onClick={handleUpdateClick} />
              <Tabs defaultActiveKey={"list"}>
                <Tab eventKey={"list"} title={t("List View")}>
                  <IncidentTrackingList
                    records={records}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </Tab>
                <Tab eventKey={"kanban"} title={t("Card View")}>
                  <div className="view-wrapper view-wrapper-task-list py-4 px-3 border">
                    <button
                      className={`btn btn-sm fs-4 ms-1  my-0 me-2`}
                      title={t("Read Tasks")}
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? (
                        <i class="fa-solid fa-book"></i>
                      ) : (
                        <i class="fa-solid fa-book-open"></i>
                      )}
                    </button>

                    <TaskListKanban
                      statusColumns={statuses}
                      records={records}
                      dataSource={filteredData}
                      ref={kanbanRef}
                      openForEdit={openForEdit}
                      updateKey={updateKey}
                      expanded={expanded}
                      changePopupVisibility={() =>
                        changePopupVisibility(!popupVisible)
                      }
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default IncidentTrackingContainer;
