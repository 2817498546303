import { t } from "i18next";

const NewFilterFieldset = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ContractCode",
    FieldType: "varchar",
    FieldTitle: "Contract",
    Position: 1,
    cssClasss: "col-md-2",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: "Description",
    Position: 1,
    cssClasss: "col-md-2",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "IdCustomer",
    FieldType: "int",
    FieldTitle: "Customer",
    Position: 1,
    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "StartDate",
    FieldType: "datetime",
    FieldTitle: "Start Date",
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "EndDate",
    FieldType: "datetime",
    FieldTitle: "End Date",
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Reference",
    FieldType: "varchar",
    FieldTitle: "Reference",
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCurrency",
    FieldType: "int",
    FieldTitle: "Currency",
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdContractStatus",
    FieldType: "int",
    FieldTitle: "Contract Status",
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
//LO DE ARRIBA ESTA SIN LA FUNCION DE TRADUCIR

export const SpecialServicesFields = [
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Service",
    FieldType: "varchar",
    FieldTitle: t("Service"),
    Position: 3,
    cssClasss: "col-md-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Price",
    FieldType: "varchar",
    FieldTitle: t("Price"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Frequency",
    FieldType: "varchar",
    FieldTitle: t("Frequency"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "StartDate",
    FieldType: "datetime",
    FieldTitle: t("Start Date"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "EndDate",
    FieldType: "datetime",
    FieldTitle: t("End Date"),
    Position: 3,
    cssClasss: "col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export const SpecialRequestFields = [
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuilding",
    FieldType: "bit",
    FieldTitle: t("Building"),
    Position: 3,
    cssClasss: "col-md-4 col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"d373bb8a3e97f3e5c2ab80d8a82bf83c1db0463cc440bf5a00995fcb0706bbf3"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBuildingFloor",
    FieldType: "bit",
    FieldTitle: t("Floor"),
    Position: 3,
    cssClasss: "col-md-4 col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"e2258e57a645a2e471803b3cf8997c4d318c86812e491072f27a883deee2a2ab"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdRoomView",
    FieldType: "bit",
    FieldTitle: t("View"),
    Position: 3,
    cssClasss: "col-md-4 col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"89f621b8ee4a8b3941f3f355eb4416e30ca0f13b791869839303a1fbcfefcbb8"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export const ReservationTableFields = [
  {
    FieldName: "Room",
    FieldTitle: t("Room"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "RoomType",
    FieldTitle: t("Type"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "memberName",
    FieldTitle: t("Member Name"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "GuestName",
    FieldTitle: t("Guest Name"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Membership",
    FieldTitle: t("Membership"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "memberCat",
    FieldTitle: t("Member Cat"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ReservationNumber",
    FieldTitle: t("Res #"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ArrivalDate",
    FieldTitle: t("Arrival"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "DepartureDate",
    FieldTitle: t("Departure"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "MealPlan", //** */
    FieldTitle: t("Meal Plan"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ReservationDescription",
    FieldTitle: t("Status"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Customer",
    FieldTitle: t("Customer"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "comment",
    FieldTitle: t("Comment"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "BusinessUnit",
    FieldTitle: t("Business Unit"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "BillingBusinessUnit",
    FieldTitle: t("Billing Business Unit"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "BraceletType",
    FieldTitle: t("Bracelet Type"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "BraceletID",
    FieldTitle: t("Bracelet Id"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "GroupName",
    FieldTitle: t("Group Name"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ReservationGroupNumber",
    FieldTitle: t("Res Group #"),
    FieldType: "varchar",
    HtmlType: "text",
  },
];
