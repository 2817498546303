import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import RoomBlockContainer from '../../components/room-block/RoomBlockContainer';
import { RoomBlockDetails } from '../../components/room-block/RoomBlockDetails';

const RoomBlockPage = () => {
  const [actionMode, setActionMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);

  const principalButtonList = [
    {
      id: 1,
      title: "Edit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: 2,
      title: "New",
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      onClick: () => toggleAddEdit("I"),
    },
    {
      id: 3,
      title: "Release",
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-key",
      // onClick: () => toggleCancelReservation(),
    },
    {
      id: 4,
      title: "Print",
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-print",
      // onClick: () => toggleUnCancelReservation(),
    }
  ];

  const toggleAddEdit = (action) => {
    if (action === "I") {
      setSelectedItem(null);
      setActionMode(action);
      setShow(!show);
    } else {
      setActionMode(action);
      setShow(!show);
    }
  };

  return (
    <>
      <Breadcrumbs pathTitle={"Front-Desk / Front-Desk / Room Block"} />
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 ">
              {isLoading ? (
                <>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center my-1">
                    <i className="fa-solid fa-chess-rook me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Room Block</h5>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="ps-3 py-2 z-2"
              style={{ position: "sticky", top: 0, backgroundColor: "#f2f2f2" }}
            >
              <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
                {principalButtonList.map((item) => {
                  if (item.ifSelectedItem && selectedItem) {
                    //Validate if checking is pressed
                    if (item.id === "checkInBtn" && !selectedItem.IdRoom) {
                      return <></>;
                    }
                    if (item.id === "assignRoom" && selectedItem.IdRoom) {
                      return <></>;
                    }

                    return item.reservationCode.length > 0 &&
                      item.reservationCode.includes(
                        selectedItem.ReservationCode
                      ) ? (
                      <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {item.title}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    ) : (
                      <></>
                    );
                  } else if (!item.ifSelectedItem) {
                    return (
                      <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {item.title}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    );
                  } else return <></>;
                })}
              </div>
              {/* <div className="d-flex w-100 my-2 justify-content-start gap-1">
              </div> */}
            </div>
            <RoomBlockDetails
              toggle={toggleAddEdit}
              show={show}
              actionMode={actionMode}
              selectedRoom={selectedItem}
              clearSelection={() => setSelectedItem(null)}
              // handleClose={handleCloseReservationDetails}
            />
            <RoomBlockContainer
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showAddEdit={show}
              reloadData={reloadData}
              setReloadData={setReloadData} />
          </div>
        </div>
      </Container>
    </>
  )
};

export default RoomBlockPage;
