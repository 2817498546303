import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material';
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import FieldSelector from '../customComponents/FieldSelector';
import { useFormik } from 'formik';
import * as yup from 'yup';
import yupTypeValidator from '../../utils/YupTypeValidator';
import { FilterFieldset } from './Fields/RoomBlockFields';
// import ReservationSecFilter from './ReservationSecFilter';
// import './guest-reservation-custom-styles.css';

const RoomBlockFilter = (props) => {
  const fields = FilterFieldset;
  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, [])


  const handleSubmit = async (formikObj) => {

    const obj = { formikObj };
    let objSPVersion = "";
    fields.forEach((item) => {

      if (item.ReadOnly || item.HtmlType === "separator") return;

      // if (item.HtmlType === 'select') {
      //   debugger;
      // }
      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null || obj[item.FieldName] === ''
            ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
            : obj[item.FieldName] ?? "null";

      } else if (typeof item === 'boolean') {
        objSPVersion += obj[item.FieldName] === true ? 1 : 0
      } else
        if (item.HtmlType === 'radio') {

          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null ||
              obj[item.FieldName].length === 0 ||
              obj[item.FieldName] === 'Invalid date'

              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
    });
    // console.log(objSPVersion);
    // props.filterBy(objSPVersion);
    props.handleFilter(objSPVersion);
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};


    if (fields) {
      fields.forEach((item) => {

        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };

  return (
    <Accordion className="mb-4 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0"><span className='h5 m-0' style={{ fontSize: '14px' }}>Filter</span></Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            <Form
              onSubmit={formik.handleSubmit}
            >
              <Card.Body className="bg-light">
                <Row className='mx-0'>
                  <Col md={12}>
                    <Row>
                      {
                        fields.map((item, index) => (
                          <Row className={item.cssClasss + (!item.Visible ? " d-none" : " mb-4")} key={index}>
                            <Col className='d-flex'>
                              <Col>
                              <label className="my-2 me-2">{item.FieldTitle}</label>
                              {/* {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null} */}
                                <FieldSelector
                                  style={{ width: '100%' }}
                                  model={item}
                                  key={index}
                                  obj={formik.values}
                                  updateField={updateField}
                                  value={formik.values[item.FieldName]}
                                />
                                {/* {formik.errors[item.FieldName] && (
                                  <div className="invalid text-sm">
                                    {formik.errors[item.FieldName]}
                                  </div>
                                )} */}
                                </Col>
                            </Col>
                          </Row>
                        ))
                      }
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer >
                <Row>
                  <Col>
                    {/* <div className='display-6 text-success'>
                Room Blocks found: {props.count || 0}
              </div> */}
                  </Col>
                  <Col xs='auto text-end'>
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title="Clear"
                      onClick={() => formik.resetForm()}
                    ><i className="fas fa-eraser"></i></button>
                    <button className="btn btn-sm btn-primary" title="Search" type="submit" disabled={props.isLoading}><Search /></button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default RoomBlockFilter
