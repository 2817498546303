import { t } from "i18next";

export const HeaderFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Id",
    FieldType: "varchar",
    FieldTitle: t("Group Reservation Number"),
    Position: 3,
    cssClasss: "col-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "GroupName",
    FieldType: "varchar",
    FieldTitle: t("Group Name"),
    Position: 3,
    cssClasss: "col-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Rooms",
    FieldType: "varchar",
    FieldTitle: t("Rooms"),
    Position: 3,
    cssClasss: "col-1",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Adults",
    FieldType: "varchar",
    FieldTitle: t("Adults"),
    Position: 3,
    cssClasss: "col-1",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Children",
    FieldType: "varchar",
    FieldTitle: t("Children"),
    Position: 3,
    cssClasss: "col-1",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 1,
  },
];
