import React, { useEffect, useState } from "react";
import { Col, Placeholder, Row } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import RatePeriodDetails from "./RatePeriodDetails";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { useToast } from "../../../contexts/toast";

import { useTranslation } from "react-i18next";
import { CellHighlight } from "../../../utils/custom-cell";

const RatePeriodContractTab = (props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [actionMode, setActionMode] = useState(null);
  const [record, setRecord] = useState(null); //Business Unit
  const [records, setRecords] = useState([]); //Business Units
  const [showAddEditRate, setShowAddEditRate] = useState(false);
  const [ratePeriodRecord, setRatePeriodRecord] = useState(null);
  const [ratePeriodRecords, setRatePeriodRecords] = useState([]);
  const [businessUnitRecords, setBusinessUnitRecords] = useState([]);
  const [businessUnitRecordsSelected, setBusinessUnitRecordsSelected] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [businessUniteSelection, setBusinessUniteSelection] = useState(null);
  const user = parsedUser();

  useEffect(() => {
    // console.log(props.contractGUID);
    if (props.contractGUID) {
      bindDataRemoteBusinessUnit();
      bindDataRemoteBusinessUnitAssigned();
    }
  }, [props.contractGUID]);

  useEffect(() => {
    // console.log("rate period update", record)
    if (record && record.GUID) {
      bindRatePeriods();
    }
  }, [record]);

  useEffect(() => {
    // console.log("rate periods update", records)
    props.update(records);
  }, [records]);

  const toggleRate = (obj, mode, reload) => {
    setShowAddEditRate(!showAddEditRate);
    setActionMode(mode);

    if (reload) {
      bindRatePeriods();
      //bindRatePeriodDetails(obj);
    }
  };

  //Delete the record
  const deleteConfirmRatePeriod = async (obj) => {
    confirmAlert({
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            console.log(obj);
            let queryData = `Token=${user.Token}&RecordId=${obj.GUID}&IdUser=${user.IdUser}`;
            const request = await API.getAction(
              "api/Contracts/RatePeriodDelete",
              queryData
            );
            console.log("the request to add the record", request);
            await bindRatePeriods();
            setRatePeriodRecord(null);
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  const bindDataRemoteBusinessUnit = async () => {
    try {
      const query = `token=${user.Token}&IdUser=${user.IdUser}`;
      const request = await API.getAction(
        "api/Contracts/BusinessUnitAvailable",
        query
      );
      const results = request.data[0];
      // console.log(results);
      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = parsedResults.JSONData;

      if (recordResult) {
        setBusinessUnitRecords(recordResult);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const bindDataRemoteBusinessUnitAssigned = async (
    selectLastRecord,
    lastRecord
  ) => {
    try {
      const query = `token=${user.Token}&IdUser=${user.IdUser}&GUIDContract=${props.contractGUID}`;
      const request = await API.getAction(
        "api/Contracts/BusinessUnitAssigned",
        query
      );
      const results = request.data[0];
      // console.log(results);
      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = parsedResults.JSONData;

      if (recordResult) {
        setRecords(recordResult);

        //This is to select the new record selected

        if (selectLastRecord && recordResult.length > 0) {
          console.log("items record", lastRecord, recordResult);

          let theRecord = recordResult.filter(
            (x) => x.IdBusinessUnit === lastRecord.Id
          )[0];

          setRecord(theRecord);
        }
      } else {
        setRecords([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const bindRatePeriods = async () => {
    try {
      if (!record || !record.GUID) {
        return;
      }
      const query = `token=${user.Token}&GUIDBusinessUnits=${record.GUID}&IdUser=${user.IdUser}`;
      const request = await API.getAction(
        "api/Contracts/RatePeriodRecords",
        query
      );
      const results = request.data[0];
      console.log(results);
      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = parsedResults.JSONData;

      if (recordResult) {
        setRatePeriodRecords(recordResult);
      } else {
        setRatePeriodRecords([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const bindRatePeriodDetails = async (obj) => {
    try {
      const query = `token=${user.Token}&RecordId=${obj.GUID}&IdUser=${user.IdUser}`;
      const request = await API.getAction(
        "api/Contracts/RatePeriodDetails",
        query
      );
      const results = request.data[0];
      console.log(results);
      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = parsedResults.JSONData;

      if (recordResult) {
        setRatePeriodRecord(recordResult);
      } else {
        setRatePeriodRecord(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const addToList = async () => {
    setIsLoading(true);
    let item = businessUnitRecords.filter(
      (x) => x.Id === businessUnitRecordsSelected * 1
    )[0];

    if (records.find((x) => x.IdBusinessUnit === item.Id)) {
      toast({
        type: "error",
        message: t("The Business Unit is already assigned to this contract"),
        width: 500,
      });
      setIsLoading(false);
      return;
    }
    try {
      let query = `@GUIDContract='${props.contractGUID}',@GUIDBusinessUnit='${item.GUID}', @IdActiveUser=${user.IdUser}`;
      let queryData = {
        Data: query,
      };

      const request = await API.postAction(
        "api/Contracts/BusinessUnitAssignedAdd",
        queryData
      );
      console.log("the request to add the record", request);
      await bindDataRemoteBusinessUnitAssigned(true, item);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeFromList = async (e, item) => {
    e.stopPropagation();
    let queryData = `Token=${user.Token}&GUID=${item.GUID}&IdUser=${user.IdUser}`;
    const request = await API.getAction(
      "api/Contracts/BusinessUnitAssignedDelete",
      queryData
    );
    console.log("the request to add the record", request);
    await bindDataRemoteBusinessUnitAssigned();
    setRecord(null);
  };

  const deleteBusinessUnit = async (e, item) => {
    confirmAlert({
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => removeFromList(e, item),
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  return (
    <>
      {showAddEditRate ? (
        <RatePeriodDetails
          businessUnitSelected={record}
          Contract={props.Contract}
          record={ratePeriodRecord}
          clearSelection={() => setRatePeriodRecord(null)}
          toggle={toggleRate}
          show={showAddEditRate}
          actionMode={actionMode}
          setActionMode={setActionMode}
          refreshRatePeriodsTable={bindRatePeriods}
          allRates={ratePeriodRecords}
        />
      ) : null}
      <div className="border">
        <Row className="mx-0" style={{ minHeight: "200px" }}>
          <Col xs={4} className="bg-light border-end py-4">
            <div className="d-flex flex-column">
              <div className="d-flex gx-3 align-items-center justify-content-end px-2 mb-2">
                {isLoading ? (
                  <Placeholder
                    className="w-100 rounded"
                    style={{ height: "2rem" }}
                    animation="wave"
                    bg="secondary"
                  />
                ) : (
                  <select
                    className="form-select"
                    onChange={(e) =>
                      setBusinessUnitRecordsSelected(e.target.value)
                    }
                    name="businessUnit"
                    id="businessUnit"
                    value={businessUnitRecordsSelected}
                  >
                    <option value="">-{t("Assign Business Unit")}-</option>
                    {businessUnitRecords.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                )}
                <button
                  className="btn btn-primary btn-sm mx-2"
                  type={"button"}
                  onClick={addToList}
                  disabled={!businessUnitRecordsSelected || isLoading}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
              <ul className="list-group px-2">
                {records &&
                  records.map((item, index) => {
                    // console.log(item)
                    return (
                      <li
                        key={index}
                        className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                          record && item.GUID === record.GUID ? "active" : ""
                        }`}
                        onClick={() => {
                          console.log("Rate record  selected", item);
                          //     props.selection(item);
                          setRecord(item);
                        }}
                      >
                        {item.Name}
                        <button
                          className="btn btn-sm text-danger"
                          type={"button"}
                          onClick={(e) => deleteBusinessUnit(e, item)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Col>
          <Col xs={8} className="gap-2 py-4 bg-white">
            {record ? (
              <Row>
                <Col>
                  <table className=" table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>{t("Code")}</th>
                        <th>{t("Rate Type")}</th>
                        <th>{t("Description")}</th>
                        <th>{t("Date Start")}</th>
                        <th>{t("Date End")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!record ? (
                        <tr>
                          <td colSpan={5} className="text-center py-4">
                            {t("Select a Business Unit")}
                          </td>
                        </tr>
                      ) : null}
                      {ratePeriodRecords && ratePeriodRecords.length === 0 ? (
                        <tr>
                          <td colSpan={5} className="text-center py-4">
                            {t("No data")}
                          </td>
                        </tr>
                      ) : null}
                      {ratePeriodRecords &&
                        ratePeriodRecords.map((item, index) => {
                          // console.log(item, businessUniteSelection);
                          const dateStart = new Date(item.DateStart);
                          const formattedDateStart = new Intl.DateTimeFormat(
                            navigator.language
                          ).format(dateStart);
                          const dateEnd = new Date(item.DateEnd);
                          const formattedDateEnd = new Intl.DateTimeFormat(
                            navigator.language
                          ).format(dateEnd);
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                setRatePeriodRecord(item);
                              }}
                              className={
                                ratePeriodRecord &&
                                ratePeriodRecord.GUID === item.GUID
                                  ? "active"
                                  : ""
                              }
                            >
                              <td>{item.Code}</td>
                              <td className="text-nowrap">
                                <span className={'w-100 badge'}style={CellHighlight(item.RateTypeBackgroundColor, item.RateTypeTextColor)}>
                                  {item.RateTypeDescription}
                                </span>
                              </td>
                              <td>{item.Description}</td>
                              <td>{formattedDateStart}</td>
                              <td>{formattedDateEnd}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Col>
                <Col xs={"auto"} className=" gap-2">
                  <button
                    className={`btn btn-primary btn-sm ${
                      !record && "disabled"
                    }`}
                    onClick={() => {
                      setRatePeriodRecord(null);
                      toggleRate(null, "I", true);
                    }}
                    type="button"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  {ratePeriodRecord && (
                    <>
                      <br />
                      <button
                        className={`btn btn-sm`}
                        onClick={() => {
                          toggleRate(ratePeriodRecord, "U", true);
                        }}
                        type="button"
                      >
                        <i className="fas fa-pencil"></i>
                      </button>
                      <br />
                      <button
                        className={`btn btn-sm text-danger `}
                        onClick={() =>
                          deleteConfirmRatePeriod(ratePeriodRecord)
                        }
                        type="button"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </>
                  )}
                </Col>
              </Row>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <p className="h4 text-center text-muted">
                  {t("Please add and select a new Business Unit")}
                </p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RatePeriodContractTab;
