import React, { useEffect, useState } from "react";
import { Container, Dropdown, Pagination, Row, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import useFavorite from "../../hooks/useFavorite";
import { parsedUser } from "../../utils/GetCurrentUser";
import CustomBreadcrumbs from "../breadcrumb";
import ReservationRestrictionsDetails from "./ReservationRestrictionsDetails";
import { dateFormater } from "../../utils/date-formater";

const ReservationRestrictions = () => {
  const { Token, IdUser } = parsedUser();
  const toast = useToast();
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const allowedPageSizes = [10, 20, 50, 100];
  // const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [tableHeader] = useState([
    { FieldName: "Description", FieldTitle: "Description" },
    { FieldName: "RestrictionTypeDescription", FieldTitle: "Restriction Type" },
    { FieldName: "RestrictionScopeDescription", FieldTitle: "Restriction Scope" },
    { FieldName: "MarketSegment", FieldTitle: "Market Segment" },
    { FieldName: "StartDate", FieldTitle: "Start Date" },
    { FieldName: "EndDate", FieldTitle: "End Date" },
    { FieldName: "Active", FieldTitle: "Active" }
  ]);

  const toggleSelectItem = (record) => {
    setSelectedItem(record);
  };

  const bindDataRemote = async () => {
    setIsLoading(true);
    try {
      const payload = {
        Data: `@Accion='L', @PageNumber='${currentPage}', @RowsOfPage='${RecordsQuantity}', @SortingCol='${sortBy}', @SortType='${sortByOrder}'`
      }
      let queryParams = `token=${Token}&IdUser=${IdUser}`;
      let request = await API.postAction(`api/Restrictions/CRUDpmsReservationRestrictions?${queryParams}`, payload);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecords(parsedResults.JSONData || []);
      if (parsedResults.JSONData) {
        // console.log(parsedResults.JSONData.length);
        setTotalPages(Math.ceil(parsedResults.JSONData.length / RecordsQuantity))
        // setTotalPages(Math.ceil(recordCount / RecordsQuantity))
        // setTotalPages(parsedResults.JSONData.length);
      }

      if (parsedResults.Error) {
        throw new Error(parsedResults.Msg);
      }

    } catch (error) {
      if (error instanceof Error) {
        toast({ type: "error", message: error.message, width: 'auto', height: 'auto' });
        return;
      }
      toast({ type: "error", message: t("Error loading data"), width: 'auto', height: 'auto' });
      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, [sortBy, sortByOrder, currentPage, RecordsQuantity]);

  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              const payload = {
                Data: `@Accion='D', @GUID='${selectedItem.GUID}'`
              }
              let queryParams = `token=${Token}&IdUser=${IdUser}`;
              await API.postAction(`api/Restrictions/CRUDpmsReservationRestrictions?${queryParams}`, payload);
              await bindDataRemote();
              setSelectedItem(null);
            } catch (error) {
              if (error instanceof Error) {
                toast({ type: "error", message: error.message });
                return;
              }
              toast({ type: "error", message: t("Error deleting record") });
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  const section = {
    FormPath: "Reservation Restrictions",
    FormTitle: "Reservation Restrictions",
    FormIcon: "fas fa-calendar-alt",
  };

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const toggleOpen = async (action, reload) => {
    // console.log("🚀 ~ toggleOpen ~ selectedItem:", selectedItem)
    setActionMode(action);
    if (reload) {
      await bindDataRemote();
    }

    if (action === "U") {
      setShowModal(!showModal);
    }

    setShowModal(!showModal);
  };


  const handleClose = async () => {
    setSelectedItem(null);
    setShowModal(false);
    await bindDataRemote();
  }

  return (
    <Container fluid>
      {section?.FormPath && <CustomBreadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section.FormTitle}</h5>
                  </div>
                </div>
              </React.Fragment>
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="w-auto d-flex align-items-center">
                <button
                  className="btn btn-sm btn-primary my-1 me-2"
                  title={t("Create")}
                  disabled={isLoading}
                  onClick={() => toggleOpen("I")}
                >
                  <i className="fa fa-plus"></i>
                </button>
                {!isSearching && (
                  <button
                    className={`btn btn-sm  my-1 me-2`}
                    title={t("Search")}
                    onClick={() => setIsSearching(true)}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}
                {selectedItem && (
                  <button
                    className="btn btn-sm  my-1 me-2"
                    title={t("Update")}
                    disabled={isLoading}
                    onClick={() => toggleOpen("U")}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                )}

                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {isPinned ? (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        {t("Remove from favorites")}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        {t("Add to favorites")}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item onClick={() => deleteConfirm()}>
                        {t("Delete")}
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <Container>
              <Row className="mx-2">
                <div className="table-responsive">
                  <Table hover size="xl" bordered className="border shadow-sm">
                    <thead className="table-secondary">
                      <tr>
                        {headerWithoutAuditFields().map((item, index) => {
                          return (
                            <th
                              key={index}
                              className={`fw-bold clickable`}
                              onClick={() => {
                                setSortBy(item.FieldName);
                                if (item.FieldName === sortBy) {
                                  if (sortByOrder === "ASC") {
                                    setSortByOrder("DESC");
                                  } else {
                                    setSortByOrder("ASC");
                                  }
                                } else {
                                  setSortByOrder("ASC");
                                }
                              }}
                            >
                              {item.FieldTitle}{" "}
                              {sortBy === item.FieldName &&
                                sortByOrder === "ASC" ? (
                                <i className="fas fa-sort-up"></i>
                              ) : sortBy === item.FieldName &&
                                sortByOrder === "DESC" ? (
                                <i className="fas fa-sort-down"></i>
                              ) : null}{" "}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {records && records.length === 0 && (
                        <tr>
                          <td
                            colSpan={tableHeader.length}
                            className="text-center py-4"
                          >
                            {t("No data")}
                          </td>
                        </tr>
                      )}
                      {records &&
                        records.map((item, index) => {
                          // console.log("item", item, "header", tableHeader);
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                toggleSelectItem(item);
                              }}
                              className={
                                selectedItem && selectedItem.GUID === item.GUID
                                  ? "active"
                                  : ""
                              }
                            >
                              {headerWithoutAuditFields().map((column, index2) => {
                                return (
                                  <td key={index2} className={` `}>
                                    {typeof item[column.FieldName] === "boolean" ? (
                                      <input
                                        type="checkbox"
                                        checked={item[column.FieldName]}
                                        className=""
                                      />
                                    ) : (
                                      // validate if the field is a date
                                      column.FieldName === "StartDate" || column.FieldName === "EndDate" ? dateFormater(item[column.FieldName]) :
                                      item[column.FieldName]
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {records && records.length > 0 && (
                    <div className="d-flex align-items-center gap-2">
                      <p className="m-0">{t("Records per page")}</p>
                      <div>
                        <select
                          className="form-control form-control-sm"
                          value={RecordsQuantity}
                          onChange={(e) => {
                            setRecordsQuantity(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          {allowedPageSizes.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                  {records.length > 0 && (
                    <Pagination className="my-0">
                      <Pagination.First
                        disabled={isLoading}
                        onClick={() => setCurrentPage(1)}
                      />
                      <Pagination.Prev
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage <= 1 || isLoading}
                      />
                      <Pagination.Item disabled={isLoading} active>
                        {currentPage}
                      </Pagination.Item>
                      {currentPage + 1 > totalPages ? null : (
                        <Pagination.Item
                          disabled={isLoading}
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          {currentPage + 1}
                        </Pagination.Item>
                      )}
                      {currentPage + 2 >= totalPages ? null : (
                        <Pagination.Item
                          disabled={isLoading}
                          onClick={() => setCurrentPage(currentPage + 2)}
                        >
                          {currentPage + 2}
                        </Pagination.Item>
                      )}
                      <Pagination.Next
                        disabled={currentPage >= totalPages || isLoading}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      />
                      <Pagination.Last
                        disabled={isLoading}
                        onClick={() => setCurrentPage(totalPages)}
                      />
                    </Pagination>
                  )}
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </Container>
      {showModal && <ReservationRestrictionsDetails show={showModal} onHide={handleClose} action={actionMode} record={selectedItem} />}
    </Container>
  )
}

export default ReservationRestrictions
