/* export const GetDataOfJSON = (requestAPI, positionArray) => {
  let results = requestAPI.data[positionArray];

  return JSON.parse(results.JSONData)[0];
};
 */

export const GetDataOfJSON = (
  requestAPI,
  arrayName,
  positionArray,
  subArrayName,
  subPositionArray
) => {
  // Verificar si el arrayName existe en el objeto requestAPI
  if (!requestAPI.hasOwnProperty(arrayName)) {
    console.error(`El array "${arrayName}" no existe en los resultados`);
  }

  // Obtener el array correspondiente
  let results = requestAPI[arrayName];

  // Verificar si el positionArray existe y obtener los resultados correspondientes
  if (positionArray !== undefined && !results[positionArray]) {
    console.error(
      `La posición "${positionArray}" no existe en el array "${arrayName}"`
    );
  } else if (positionArray !== undefined) {
    results = results[positionArray];
  }

  // Verificar si subArrayName existe en los resultados
  if (!results.hasOwnProperty(subArrayName)) {
    console.error(`El subarray "${subArrayName}" no existe en los resultados`);
    return results;
  }

  // Obtener los datos del subarray
  let subArrayData = results[subArrayName];

  try {
    // Intentar analizar subArrayData como JSON
    let parsedData = JSON.parse(subArrayData);
    // Si la cadena es un JSON válido, devolver el elemento en la posición subPositionArray
    return parsedData[subPositionArray];
  } catch (error) {
    // Si ocurre un error, devolver el mensaje de error y devolver subArrayData
    console.error(error.message);
    return subArrayData;
  }
};
