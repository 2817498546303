import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import FieldSelector from "../../customComponents/FieldSelector";
import DataEntryHeaderFirst from "../fields/DataEntryHeaderFirst";

import { useTranslation } from "react-i18next";

const ReservationDetailsHeader = ({ selectedReservation }) => {
  const { t } = useTranslation();

  const [fields] = useState(DataEntryHeaderFirst);
  const [record, setRecord] = useState(null);

  const updateField = (fieldName, fieldValue) => {};

  useEffect(() => {
    console.log(selectedReservation);
    /* if (selectedReservation) {
      setRecord(selectedReservation);
    } */
    setRecord(selectedReservation);
  }, [selectedReservation]);

  if (!record) {
    return <></>;
  }
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
      }}
      className="mb-4"
    >
      <Row>
        {fields.map((item, index) => {
          // console.log(item, record);
          return (
            <Col
              className={item.cssClasss + (!item.Visible ? " d-none" : "")}
              key={index}
            >
              <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
              {item.Required ? (
                <i className="required-asterisk ms-1 text-danger">*</i>
              ) : null}
              <br />

              <FieldSelector
                actionMode={"I"}
                model={item}
                key={index}
                obj={record}
                updateField={updateField}
                value={record[item.FieldName]}
                IsReadOnly
              />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default ReservationDetailsHeader;
