import React, { useEffect, useState } from 'react'

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";

import RoomStatus from '../../components/DashboardHousekeeping/RoomStatus';
import RoomCleaning from '../../components/DashboardHousekeeping/RoomCleaning';
import RoomCleaningStatusOC from '../../components/DashboardHousekeeping/RoomCleaningStatusOC';
import RoomCleaningStatusVC from '../../components/DashboardHousekeeping/RoomCleaningStatusVC';
import HousekeepingTaskState from '../../components/DashboardHousekeeping/HousekeepingTaskState';
import IncidentByPriority from '../../components/DashboardHousekeeping/IncidentByPriority';
import IncidentAdicionalServices from '../../components/DashboardHousekeeping/IncidentAdicionalServices';
import TaskStatusByArea from '../../components/DashboardHousekeeping/TaskStatusByArea';
import DailyIncidentsPendingIncidentCompleted from '../../components/DashboardHousekeeping/DailyIncidentsPendingIncidentCompleted';
import { SelectBox } from 'devextreme-react';

const DashboardHousekeeping = () => {
  const [data, setData] = useState({
    roomStatus: [],
    roomCleaning: [],
    roomCleaningStatus: [],
    housekeepingTaskState:[],
    incidentByPriority:[],
    incidentAditionalServices:[],
    taskStatusByArea:[],
    dailyIncidentsPendingCompleted:[]
  });
  const user = parsedUser();

  const request = async () => {
    try {
      let objSPVersion = ` `;

      // if (actionMode === 'U') {

      //   objSPVersion += `, @GUID='${selectedItem.GUID}'`
      // }

      let queryData = {
        Data: objSPVersion,
      };

      let query = `token=${user.Token}&IdUser=${user.IdUser}`;

      let requestAPI = await API.postAction(
        "/api/DashboardGuestComplaint/All?" + query,
        queryData
      );

      console.log(requestAPI);

      if (requestAPI.status === 200) {
        let onlyData = JSON.parse(requestAPI.data[0].JSONData);
        let final = onlyData ? onlyData[0] : {};
        console.log(final);

        setData({
          ...data,
          roomStatus: final.roomStatus,
          roomCleaning: final.roomCleaning,
          roomCleaningStatus: final.roomCleaningStatus,
          housekeepingTaskState: final.housekeepingTaskState,
          incidentByPriority: final.incidentByPriority,
          incidentAditionalServices: final.incidentAditionalServices,
          taskStatusByArea: final.taskStatusByArea,
          dailyIncidentsPendingCompleted: final.dailyIncidentsPendingCompleted
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <>
      <Breadcrumbs pathTitle={"Housekeeping / Dashboard"} />
      <div className="container px-3">
        <div className="row">
          <div className="col-12 mb-2">
            <div className='row'>
              <div className="col-sm-12 col-md-6 mb-3">
                <h4 style={{ fontWeight: 800 }}>Dashboard</h4>
              </div>

              <div className="col-sm-12 col-md-6 ">
                <div className="d-flex justify-content-end">
                  <div className="dx-field-label text-end " style={{ fontSize:"18px" }}>Business unit</div>
                  <SelectBox
                    id="custom-templates"
                    dataSource={{}}
                    displayExpr="Name"
                    //inputAttr={{}}
                    valueExpr="ID"
                    //fieldRender={{}}
                    //itemRender={""}
                  />
                  <button className="btn ms-2">
                    <i
                      className="fa-solid fa-rotate"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
   

          {/* <div className="col-10">
            <div
              className="btn-group mb-2"
              role="group"
              aria-label="Button group"
            >
              <button className="buttonStyle">WEEK</button>
              <button className="buttonStyle">2 WEEKS</button> 
              <button className="buttonStyle">MONTH</button>
              <button className="buttonStyle">YEAR</button>
            </div>
          </div> */}
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
           <RoomStatus viewTotalValue={true} data={data.roomStatus} />       
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <RoomCleaning viewTotalValue={true} data={data.roomCleaning} />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <RoomCleaningStatusVC viewTotalValue={true} data={data.roomCleaningStatus} />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <RoomCleaningStatusOC viewTotalValue={true} data={data.roomCleaningStatus} />
          </div>
          
          <div className="col col-md-6 col-lg-4 col-xl-4 my-2">
            <HousekeepingTaskState viewTotalValue={false} data={data.housekeepingTaskState}/>
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-4 my-2">
            <IncidentByPriority viewTotalValue={false} data={data.incidentByPriority} />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-4 my-2">
            <IncidentAdicionalServices viewTotalValue={false} data={data.incidentAditionalServices} />
          </div>
          <div className='col col-md-12 col-lg-6 my-2'>
            <TaskStatusByArea />
          </div>
          <div className='col col-md-12 col-lg-6 my-2'>
            <DailyIncidentsPendingIncidentCompleted  data={data.dailyIncidentsPendingCompleted}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHousekeeping
