import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { GenericFields } from "../group-reservation/GenericFields";
import { RestaurantReservationInfoFields } from "./fields/RestaurantReservationInfoFields";

const ReservationInfo = ({ errors, values }) => {
  const [infoFields] = useState(RestaurantReservationInfoFields);

  const updateField = async (fieldName, fieldValue, dontUpdate) => {};
  console.log(values);

  return (
    <>
      <Card className="bg-light">
        <Card.Body className="pt-1 pb-3">
          <Row>
            <GenericFields
              fields={infoFields}
              values={values}
              errors={errors}
              updateFields={updateField}
            />

            {/* <Col xs={12} className="mt-3">
              <label>Business Unit</label>
              <SelectBox searchEnabled={true} showClearButton={true} />
            </Col>
            <Col xs={6} className="mt-3">
              <label className="fs-4 fw-bolder">Room</label>
              <SelectBox showSpinButtons={true} showClearButton={true} />
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReservationInfo;
