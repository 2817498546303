import React, { useEffect, useState } from "react";
import { Col, Card, Row, Container } from "react-bootstrap";

import { useFormik } from "formik";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import API from "../../../../api/api";
import { useToast } from "../../../../contexts/toast";
import { parsedUser } from "../../../../utils/GetCurrentUser";

import { useTranslation } from "react-i18next";

const SupplementsGuestTypes = (props) => {
  const { t } = useTranslation();

  const [validationsSchema, setValidationSchema] = useState(null);
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  const user = parsedUser();
  const toast = useToast();

  const selectedItem = props.selectedItem;
  const actionMode = props.actionMode;
  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj);

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to save this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let objSPVersion = `@GUIDContract='${props.contractGUID}', @IdContractPolicy=${obj.IdContractPolicy}`;

              if (props.actionMode === "U") {
                console.log("selected item", selectedItem);
                objSPVersion += `, @GUID='${selectedItem.GUID}'`;
              }
              let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/contracts/ContractToContractPoliciesAddEdit" +
                  "?" +
                  queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  //	setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                message: t("Record saved successfully"),
              });

              //	toggleOpen(null, true);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };
  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const [records, setRecords] = useState([]);
  const bindRecordsRemote = async () => {
    if (props.contractGUID == null) {
      return;
    }
    // First start loading the model

    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.selectedItem.GUID}`;
      // if (search.length > 0 && cleanFilter == null) {
      //   query += "&Search=" + search;
      // }

      let request = await API.getAction(
        "api/contracts/SupplementToGuestTypeRecords",
        query
      );
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
    }
  };
  const [applyAll, setApplyAll] = useState(false);
  const toggleApplyAll = () => {
    setApplyAll(!applyAll);
  };

  const updateSelectedGuestType = async (ocupation, amount, status) => {
    console.log("updateSelectedGuestType", amount, status);
    // console.log("updateSelectedGuestType", ocupation);
    let objToSend = `@Amount=${amount},@Status=${status},@GUID='${ocupation.GUID}'`;

    let queryData = {
      Data: objToSend,
    };
    let query = `?IdUser=${user.IdUser}&token=${user.Token}&ActionMode=U`;
    var request = await API.postAction(
      "api/Contracts/SupplementToGuestTypeAddEdit" + query,
      queryData
    );
    // console.log("updateSelectedGuestType", request);
  };

  useEffect(() => {
    bindRecordsRemote();
  }, []);
  return (
    <Container>
      <Row className="mx-0">
        <Col className="px-0">
          <table className=" table table-bordered shadow-sm">
            <thead>
              <tr>
                <th className="text-center"></th>
                <th>{t("Guest Type")}</th>
                <th>{t("Amount")}</th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 &&
                records?.map((item, index) => {
                  return (
                    <CustomTableRow
                      applyAll={applyAll}
                      key={index}
                      GuestType={item.Description}
                      record={item}
                      callback={(amount, status) =>
                        updateSelectedGuestType(item, amount, status)
                      }
                    />
                  );
                })}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default SupplementsGuestTypes;

const CustomTableRow = ({ applyAll, GuestType, callback, record }) => {
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setAmount(record?.Amount || 0);
    setStatus(record?.Status || false);
    console.log("record Status", record?.Status);
  }, []);

  const handleBlur = () => {
    callback(+amount, status);
  };

  return (
    <tr>
      <td>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="CheckDefault4"
            checked={status}
            onChange={(e) => {
              setStatus(e.target.checked);
              if (!e.target.checked) {
                setAmount(0);
              }
            }}
            name="Status"
            onBlur={handleBlur}
            disabled={
              amount === 0 ||
              amount === null ||
              amount === undefined ||
              amount === ""
            }
          />
        </div>
      </td>
      <td>{GuestType}</td>

      <td>
        <input
          type="number"
          className="form-control"
          name="Rate"
          min="0"
          step="0.00"
          onChange={(e) => {
            setAmount(e.target.value);
            if (e.target.value === "" || e.target.value <= 0) {
              setStatus(false);
            } else {
              setStatus(true);
            }
          }}
          value={amount}
          onKeyUp={handleBlur}
        />
      </td>
    </tr>
  );
};
