import { t } from "i18next";

export const FilterFieldset = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "LastName",
    FieldType: "varchar",
    FieldTitle: t("Last Name"),
    Position: 1,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400013,
    FieldName: "ArrivalFrom",
    FieldType: "datetime",
    FieldTitle: t("Arrival From"),
    Position: 3,
    cssClasss: "col-12 col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400013,
    FieldName: "ArrivalTo",
    FieldType: "datetime",
    FieldTitle: t("to"),
    Position: 3,
    cssClasss: "col-12 col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "AllHotels",
    FieldType: "bit",
    FieldTitle: t("Search all hotels"),
    Position: 2,
    cssClasss: "col-12  d-flex gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSAll",
    FieldType: "bit",
    FieldTitle: t("All"),
    Position: 2,
    cssClasss:
      "col-12  d-flex gap-1 align-items-baseline flex-row-reverse justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 1,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "FirstName",
    FieldType: "varchar",
    FieldTitle: t("First Name"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400013,
    FieldName: "DepartureDate",
    FieldType: "datetime",
    FieldTitle: t("Departure"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "PrimaryGuestOnly",
    FieldType: "bit",
    FieldTitle: t("Primary Guest Only"),
    Position: 2,
    cssClasss: "col-12 d-flex gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 1,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSInhouse",
    FieldType: "bit",
    FieldTitle: t("Inhouse"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400003,
    FieldName: "ReservationNumber",
    FieldType: "varchar",
    FieldTitle: t("Reservation No."),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdCustomer",
    FieldType: "bigint",
    FieldTitle: t("Customer"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "VIPsOnly",
    FieldType: "bit",
    FieldTitle: t("VIPs Only"),
    Position: 2,
    cssClasss: "col-12  d-flex gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSReserved",
    FieldType: "bit",
    FieldTitle: t("Reserved"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400003,
    FieldName: "Room",
    FieldType: "varchar",
    FieldTitle: t("Room"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdRoomType",
    FieldType: "bigint",
    FieldTitle: t("Room Type"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSCheckedOut",
    FieldType: "bit",
    FieldTitle: t("Checked Out"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdBraceletType",
    FieldType: "bigint",
    FieldTitle: t("Bracelet Type"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"70dac7f49dac7c213f428facdb0af765ab5b54ac11f21aa31704821ec640d1fd"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSNoShow",
    FieldType: "bit",
    FieldTitle: t("No show"),
    Position: 2,
    cssClasss:
      "col-12  d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "BraceletId",
    FieldType: "varchar",
    FieldTitle: t("Bracelet Id"),
    Position: 1,
    cssClasss: "col-md-6 col-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400003,
    FieldName: "bookingNumber",
    FieldType: "varchar",
    FieldTitle: t("Booking No."),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSCanceled",
    FieldType: "bit",
    FieldTitle: t("Canceled"),
    Position: 2,
    cssClasss:
      "col-12 col-md-2 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },

  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FSBumpedOut",
    FieldType: "bit",
    FieldTitle: t("Bumped Out"),
    Position: 2,
    cssClasss:
      "col-12 d-flex flex-row-reverse gap-1 align-items-baseline justify-content-end",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: 0,
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 2,
  },
];
