import React from "react";
import { Col, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";

export const RoomsColorSet = ({ colorValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t("Rooms")}</h4>
      {colorValues
        // .filter(color => color.colorset === 'rooms')
        .map((value, index) => {
          return (
            <Row
              key={index}
              className="d-flex justify-content-evenly align-items-center mt-2"
            >
              <Col className="d-flex justify-content-start gap-4 align-items-center">
                <input
                  className="form-control form-control-color"
                  type="color"
                  id={value.Code}
                  title={t("Background color")}
                  name="background"
                  value={value.BackgroundColor}
                  readOnly
                  disabled
                />
                <p className="fw-bold">{value.Description}</p>
              </Col>
            </Row>
          );
        })}
    </>
  );
};
