import React from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Font,
  Margin,
  Title,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import Loading from "../Loading";

const palleteColor = ["#46a6ff", "#b7ddfe", "#2ab400"];

const dataTest =[{
  title:"Clean Rooms",
  total:"80",
}, {
  title:"Dirty Rooms",
  total:"20"
}]

const dataTestRequest =[{
  title:"New",
  total:"80",
}, {
  title:"In Time",
  total:"10"
}, {
  title:"Out Of Time",
  total:"2"
}, {
  title:"Finished",
  total:"8"
}]

const IncidentStatus = ({ data, viewTotalValue, title }) => {
  console.log("Incident Status",data);

  const customizeLabel = (e) => {
    return `${e.valueText}%`;
  };

  const pies = () => (
    <PieChart
      dataSource={data}
      resolveLabelOverlapping="shift"
      palette={palleteColor}
      innerRadius={0.65}
      centerRender={(e) => CenterTemplate(e, viewTotalValue)}
      type="doughnut"
    >
      <Series argumentField="title" valueField="total">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
          backgroundColor="none"
        >
          <Font size={16} />
          <Connector visible={true}></Connector>
        </Label>
      </Series>

      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Title text={title ? title : "Incident Status"} verticalAlignment="top">
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </PieChart>
  );

  return (
    <div className="bg-white shadow p-3 text-center" style={{ borderRadius: 25 }}>
      {/* {data && data.length > 0 ? pies() : <Loading />} */}
       {pies()}
    </div>
  );
};

export default IncidentStatus;
