import {
  CheckinPage,
  GroupReservationPage,
  RoomMapPage,
  RoomOccupationPage,
  HomePage,
  IncidentTrackingPage,
  IncidentTrackingHouseKeepingPage,
  IncidentTrackingMaintenancePage,
  ProfilePage,
  ReportSimple,
  TasksPage,
  UserRolesPage,
  UsersPage,
  modelManagement,
  CheckInPage,
  DashboardGuestComplaint,
  DashboardHistoricalGuestComplaint,
  DashboardHouseKeeping,
  DashboardHistoricalHouseKeeping,
  DashboardMaintenance,
  DashboardHistoricalMaintenance,
  VirtualRoomPage,
  CashierPage,
  RestaurantReservation,
  TasksAssigmentDetailsPage,
  RoomOverviewPage,
  RoomBlockPage,
  CashClosingPage,
  NightClosingPage,
  ReservationRestrictionsPage,
} from "../pages";

import {
  IncidentByCategoryReport,
  IncidentByCriticalityReport,
  IncidentByDateReport,
  IncidentByHotelsReport,
  ReservationForecastRerport,
  BraceletReport,
  HouseKeepingDiscrepancyReport,
  AvailabilityReport,
  IncidentListReport,
  ArrivalsReport,
  DeparturesReport,
  InHouseReport,
  NoShowReport,
  RegistrationCard,
  RoomChangesReport,
  DailyAdditionsReport,
  BlockedRoomReport,
  DailyAccommodationReport,
  MonthlyAccommodationReport,
  DailyCancellationReport,
  OverBookingReport,
  ReservationsChartReport,
  WeeklyReservationChartReport,
  TasksListReport,
  TaskByHotelsReport,
  TaskByCategoryReport,
  TasksByAssignmentReport,
  TaskByDateReport,
  ListOfPaxDiscrepanciesReport,
  ChargesReport,
  MaintenanceTasksListReport,
  MaintenanceTaskByHotelsReport,
  MaintenanceTaskByCategoryReport,
  MaintenanceTasksByAssignmentReport,
  MaintenanceTaskByDateReport,
  CustomersReport,
  RatesReport,
  AverageRateReport,
  IncomesSummaryReport,
  CityLedgerTransferReport,
  GuestLedgerReport,
  GuestLedgerControlReport,
  FolioTransferenceReport,
  ClosingBalanceReport,
  TrialBalanceReport,
  StatisticsReport,
  PaymentsReport,
  PaidOutReport,
  AdjustmentsReport,
  DailyControlReport,
  FoodBeverageTaskByAssignmentReport,
  FoodBeverageTaskByCategoryReport,
  FoodBeverageTaskByDateReport,
  FoodBeverageTaskByHotelsReport,
  FoodBeverageTasksListReport,
  ReservationRestaurantHistoryReservationReport,
  SummaryReservationByRestaurantReport,
  Incomes607Report,
  RestaurantReservationSummaryReport,
} from "../pages/reports";

import CRUDTest from "../pages/crud/CRUDTest";

import CRUDMaster from "../pages/crud/CRUDMaster";
// import CRUDRateManager from "../pages/crud/CRUDRateManager";
import CRUDSimple from "../pages/crud/CRUDSimple";

import Login from "../pages/login/login";
//import RestaurantReservationsPage from "../pages/restaurantReservations/RestaurantReservationsPage";
import CustomerPage from "../pages/customer/CustomerPage";
import ContractsV3 from "../pages/contractsV3";
import ReservationPage from "../pages/reservations/reservationPage";
import ContractsReport from "../pages/reports/ContractsReport";
import ContractPoliciesPage from "../pages/ContractPolicies/ContractPoliciesPage";
import DashboardFoodAndBeverage from "../pages/DashboardFoodAndBeverage/DashboardFoodAndBeverage";
import IncidentTrackingFoodandBeverage from "../pages/IncidentTrackingFoodAndBeverage/IncidentTrackingFoodandBeverage";
import RoomsPage from "../pages/Rooms/RoomsPage";
import CustomerTypePage from "../pages/CustomerTypePage";
import AmadeusLogFilesContainer from "../components/AmadeusLogFiles/AmadeusLogFilesContainer";

const ROUTES = {
  WithSidebar: [
    {
      path: "process/cashClosing",
      element: CashClosingPage,
      clean: "process/cashClosing",
    },
    {
      path: "process/nightClosing",
      element: NightClosingPage,
      clean: "process/nightClosing",
    },
    {
      path: "/tasks",
      element: TasksPage,
      clean: "/tasks",
    },
    {
      path: "/process/CustomerTypes",
      element: CustomerTypePage,
      clean: "/process/CustomerTypes",
    },
    {
      path: "/checkin",
      element: CheckinPage,
      clean: "/checkin",
    },
    {
      path: "/profile",
      element: ProfilePage,
      clean: "/profile",
    },
    {
      path: "/users",
      element: UsersPage,
      clean: "/users",
    },

    {
      path: "/userroles",
      element: UserRolesPage,
      clean: "/userroles",
    },

    {
      path: "/crud/:id",
      element: CRUDSimple,
      clean: "/crud/",
    },
    {
      path: "/crudDynamic/:id/:idModule",
      element: CRUDSimple,
      clean: "/crudDynamic/",
    },
    {
      path: "/process/Rates",
      element: ContractsV3,
      clean: "/process/Rates",
    },
    {
      path: "/crudm/:id",
      element: CRUDMaster,
      clean: "/crudm/",
    },

    {
      path: "/process/contractsV3",
      element: ContractsV3,
      clean: "/process/contractsV3",
    },
    {
      path: "/process/customer",
      element: CustomerPage,
      clean: "/process/customer",
    },
    {
      path: "/process/room-occupation",
      // element: RoomMapPage,
      element: RoomOccupationPage,
      clean: "/process/room-occupation",
    },
    {
      path: "/process/room-map",
      element: RoomMapPage,
      // element: RoomOccupationPage,
      clean: "/process/room-map",
    },
    {
      path: "/process/group-reservations",
      element: GroupReservationPage,
      clean: "/process/group-reservations",
    },
    {
      path: "/process/check-in",
      element: CheckInPage,
      clean: "/process/check-in",
    },
    {
      path: "/test",
      element: CRUDTest,
      clean: "/test",
    },
    {
      path: "/modelManagement",
      element: modelManagement,
      clean: "/modelManagement",
    },
    {
      path: "/reportsm/HouseKeepingDiscrepancy",
      clean: "/reportsm/HouseKeepingDiscrepancy",
      element: HouseKeepingDiscrepancyReport,
    },
    {
      path: "/reportsm/IncidentReport",
      clean: "/reportsm/IncidentReport",
      element: IncidentListReport,
    },
    {
      path: "/reportsm/IncidentByHotelsReport",
      clean: "/reportsm/IncidentByHotelsReport",
      element: IncidentByHotelsReport,
    },
    {
      path: "/reportsm/IncidentByCategoryReport",
      clean: "/reportsm/IncidentByCategoryReport",
      element: IncidentByCategoryReport,
    },
    {
      path: "/reportsm/IncidentByCriticalityReport",
      clean: "/reportsm/IncidentByCriticalityReport",
      element: IncidentByCriticalityReport,
    },
    {
      path: "/reportsm/IncidentByDateReport",
      clean: "/reportsm/IncidentByDateReport",
      element: IncidentByDateReport,
    },
    {
      path: "/reportsm/TaskListReport",
      clean: "/reportsm/TaskListReport",
      element: TasksListReport,
    },
    {
      path: "/reportsm/TaskByHotelsReport",
      clean: "/reportsm/TaskByHotelsReport",
      element: TaskByHotelsReport,
    },
    {
      path: "/reportsm/TaskByCategoryReport",
      clean: "/reportsm/TaskByCategoryReport",
      element: TaskByCategoryReport,
    },
    {
      path: "/reportsm/TasksByAssignmentReport",
      clean: "/reportsm/TasksByAssignmentReport",
      element: TasksByAssignmentReport,
    },
    {
      path: "/reportsm/TaskByDateReport",
      clean: "/reportsm/TaskByDateReport",
      element: TaskByDateReport,
    },
    {
      path: "/reportsm/MaintenanceTaskListReport",
      clean: "/reportsm/MaintenanceTaskListReport",
      element: MaintenanceTasksListReport,
    },
    {
      path: "/reportsm/MaintenanceTaskByHotelsReport",
      clean: "/reportsm/MaintenanceTaskByHotelsReport",
      element: MaintenanceTaskByHotelsReport,
    },
    {
      path: "/reportsm/MaintenanceTaskByCategoryReport",
      clean: "/reportsm/MaintenanceTaskByCategoryReport",
      element: MaintenanceTaskByCategoryReport,
    },
    {
      path: "/reportsm/MaintenanceTasksByAssignmentReport",
      clean: "/reportsm/MaintenanceTasksByAssignmentReport",
      element: MaintenanceTasksByAssignmentReport,
    },
    {
      path: "/reportsm/MaintenanceTaskByDateReport",
      clean: "/reportsm/MaintenanceTaskByDateReport",
      element: MaintenanceTaskByDateReport,
    },
    {
      path: "/reportsm/ReservationForecastReport",
      clean: "/reportsm/ReservationForecastReport",
      element: ReservationForecastRerport,
    },
    {
      path: "/reportsm/AvailabilityReport",
      clean: "/reportsm/AvailabilityReport",
      element: AvailabilityReport,
    },
    {
      path: "/reportsm/BraceletReport",
      clean: "/reportsm/BraceletReport",
      element: BraceletReport,
    },
    {
      path: "/reportsm/ArrivalsReport",
      clean: "/reportsm/ArrivalsReport",
      element: ArrivalsReport,
    },
    {
      path: "/reportsm/DeparturesReport",
      clean: "/reportsm/DeparturesReport",
      element: DeparturesReport,
    },
    {
      path: "/reportsm/InHouseReport",
      clean: "/reportsm/InHouseReport",
      element: InHouseReport,
    },
    {
      path: "/reportsm/NoShowReport",
      clean: "/reportsm/NoShowReport",
      element: NoShowReport,
    },
    {
      path: "/reportsm/RoomChangesReport",
      clean: "/reportsm/RoomChangesReport",
      element: RoomChangesReport,
    },
    {
      path: "/reportsm/RestaurantReservationSummaryReport",
      clean: "/reportsm/RestaurantReservationSummaryReport",
      element: RestaurantReservationSummaryReport,
    },
    {
      path: "/VirtualRoom",
      clean: "/VirtualRoom",
      element: VirtualRoomPage,
    },
    {
      path: "/reportsm/test",
      clean: "/reportsm/test",
      element: ContractsReport,
    },
    {
      path: "/amadeus",
      clean: "/amadeus",
      element: AmadeusLogFilesContainer,
    },
    {
      path: "/reportsm/RegistrationCard",
      clean: "/reportsm/RegistrationCard",
      element: RegistrationCard,
    },
    {
      path: "/reportsm/DailyAdditionsReport",
      clean: "/reportsm/DailyAdditionsReport",
      element: DailyAdditionsReport,
    },
    {
      path: "/reportsm/BlockedRoomReport",
      clean: "/reportsm/BlockedRoomReport",
      element: BlockedRoomReport,
    },
    {
      path: "/reportsm/DailyAccommodationReport",
      clean: "/reportsm/DailyAccommodationReport",
      element: DailyAccommodationReport,
    },
    {
      path: "/reportsm/MonthlyAccommodationReport",
      clean: "/reportsm/MonthlyAccommodationReport",
      element: MonthlyAccommodationReport,
    },
    {
      path: "/reportsm/DailyCancellationReport",
      clean: "/reportsm/DailyCancellationReport",
      element: DailyCancellationReport,
    },
    {
      path: "/reportsm/OverBookingReport",
      clean: "/reportsm/OverBookingReport",
      element: OverBookingReport,
    },
    {
      path: "/reportsm/ReservationsChartReport",
      clean: "/reportsm/ReservationsChartReport",
      element: ReservationsChartReport,
    },
    {
      path: "/reportsm/WeeklyReservationChartReport",
      clean: "/reportsm/WeeklyReservationChartReport",
      element: WeeklyReservationChartReport,
    },
    {
      path: "/reportsm/PaxDiscrepanciesReport",
      clean: "/reportsm/PaxDiscrepanciesReport",
      element: ListOfPaxDiscrepanciesReport,
    },
    {
      path: "/reportsm/ChargesReport",
      clean: "/reportsm/ChargesReport",
      element: ChargesReport,
    },
    {
      path: "/reportsm/CustomersReport",
      clean: "/reportsm/CustomersReport",
      element: CustomersReport,
    },
    {
      path: "/reportsm/RatesReport",
      clean: "/reportsm/RatesReport",
      element: RatesReport,
    },
    {
      path: "/reportsm/AverageRateReport",
      clean: "/reportsm/AverageRateReport",
      element: AverageRateReport,
    },
    {
      path: "/reportsm/IncomesSummaryReport",
      clean: "/reportsm/IncomesSummaryReport",
      element: IncomesSummaryReport,
    },
    {
      path: "/reportsm/CityLedgerTransferReport",
      clean: "/reportsm/CityLedgerTransferReport",
      element: CityLedgerTransferReport,
    },
    {
      path: "/reportsm/GuestLedgerReport",
      clean: "/reportsm/GuestLedgerReport",
      element: GuestLedgerReport,
    },
    {
      path: "/reportsm/GuestLedgerControlReport",
      clean: "/reportsm/GuestLedgerControlReport",
      element: GuestLedgerControlReport,
    },
    {
      path: "/reportsm/FolioTransferenceReport",
      clean: "/reportsm/FolioTransferenceReport",
      element: FolioTransferenceReport,
    },
    {
      path: "/reportsm/ClosingBalanceReport",
      clean: "/reportsm/ClosingBalanceReport",
      element: ClosingBalanceReport,
    },
    {
      path: "/reportsm/TrialBalanceReport",
      clean: "/reportsm/TrialBalanceReport",
      element: TrialBalanceReport,
    },
    {
      path: "/reportsm/StatisticsReport",
      clean: "/reportsm/StatisticsReport",
      element: StatisticsReport,
    },
    {
      path: "/reportsm/PaymentsReport",
      clean: "/reportsm/PaymentsReport",
      element: PaymentsReport,
    },
    {
      path: "/reportsm/PaidOutReport",
      clean: "/reportsm/PaidOutReport",
      element: PaidOutReport,
    },
    {
      path: "/reportsm/AdjustmentsReport",
      clean: "/reportsm/AdjustmentsReport",
      element: AdjustmentsReport,
    },
    {
      path: "/reportsm/DailyControlReport",
      clean: "/reportsm/DailyControlReport",
      element: DailyControlReport,
    },
    {
      path: "/reportsm/FoodBeverageTaskByAssignmentReport",
      clean: "/reportsm/FoodBeverageTaskByAssignmentReport",
      element: FoodBeverageTaskByAssignmentReport,
    },
    {
      path: "/reportsm/FoodBeverageTaskByCategoryReport",
      clean: "/reportsm/FoodBeverageTaskByCategoryReport",
      element: FoodBeverageTaskByCategoryReport,
    },
    {
      path: "/reportsm/FoodBeverageTaskByDateReport",
      clean: "/reportsm/FoodBeverageTaskByDateReport",
      element: FoodBeverageTaskByDateReport,
    },
    {
      path: "/reportsm/FoodBeverageTaskByHotelsReport",
      clean: "/reportsm/FoodBeverageTaskByHotelsReport",
      element: FoodBeverageTaskByHotelsReport,
    },
    {
      path: "/reportsm/FoodBeverageTasksListReport",
      clean: "/reportsm/FoodBeverageTasksListReport",
      element: FoodBeverageTasksListReport,
    },
    {
      path: "/reportsm/ReservationRestaurantHistoryReservationReport",
      clean: "/reportsm/ReservationRestaurantHistoryReservationReport",
      element: ReservationRestaurantHistoryReservationReport,
    },
    {
      path: "/reportsm/SummaryReservationByRestaurant",
      clean: "/reportsm/SummaryReservationByRestaurant",
      element: SummaryReservationByRestaurantReport,
    },
    {
      path: "/reportsm/Incomes607Report",
      clean: "/reportsm/Incomes607Report",
      element: Incomes607Report,
    },

    {
      path: "/reports/:id?",
      element: ReportSimple,
      clean: "/reports",
    },
    {
      path: "/process/reservations",
      element: ReservationPage,
      clean: "/process/reservations",
    },
    {
      path: "/housekeeping/process/automatic-tasks-assigment",
      element: TasksAssigmentDetailsPage,
      clean: "/housekeeping/process/automatic-tasks-assigment",
    },
    {
      path: "/housekeeping/process/room-overview",
      element: RoomOverviewPage,
      clean: "/housekeeping/process/room-overview",
    },
    {
      path: "/guestcomplaint/process/incident-tracking",
      element: IncidentTrackingPage,
      clean: "/guestcomplaint/process/incident-tracking",
    },
    {
      path: "/housekeeping/process/incident-tracking",
      element: IncidentTrackingHouseKeepingPage,
      clean: "/housekeeping/process/incident-tracking",
    },
    {
      path: "/maintenance/process/incident-tracking",
      element: IncidentTrackingMaintenancePage,
      clean: "/maintenance/process/incident-tracking",
    },

    {
      path: "/foodAndBeverage/process/incident-tracking",
      element: IncidentTrackingFoodandBeverage,
      clean: "/foodAndBeverage/process/incident-tracking",
    },

    {
      path: "/guestcomplaint/Dashboard",
      element: DashboardGuestComplaint,
      clean: "/guestcomplaint/Dashboard",
    },
    {
      path: "/guestcomplaint/historicalDashboard",
      element: DashboardHistoricalGuestComplaint,
      clean: "/guestcomplaint/historicalDashboard",
    },
    {
      path: "/housekeeping/Dashboard",
      element: DashboardHouseKeeping,
      clean: "/housekeeping/Dashboard",
    },
    {
      path: "/housekeeping/historicalDashboard",
      element: DashboardHistoricalHouseKeeping,
      clean: "/housekeeping/historicalDashboard",
    },
    {
      path: "/foodAndBeverage/Dashboard",
      element: DashboardFoodAndBeverage,
      clean: "/foodAndBeverage/Dashboard",
    },
    {
      path: "/maintenance/Dashboard",
      element: DashboardMaintenance,
      clean: "/maintenance/Dashboard",
    },
    {
      path: "/maintenance/historicalDashboard",
      element: DashboardHistoricalMaintenance,
      clean: "/maintenance/historicalDashboard",
    },

    {
      path: "/process/ContractPolicies",
      clean: "/process/ContractPolicies",
      element: ContractPoliciesPage,
    },
    {
      path: "/process/rooms",
      clean: "/process/rooms",
      element: RoomsPage,
    },
    {
      path: "/process/room-block",
      clean: "/process/room-block",
      element: RoomBlockPage,
    },

    {
      //Posible to Changes
      path: "/Cashier",
      element: CashierPage,
      clean: "/Cashier",
    },

    {
      path: "/restaurantReservation/process/restaurantReservation",
      element: RestaurantReservation,
      clean: "/restaurantReservation/process/restaurantReservation",
    },
    {
      path: "/process/reservationRestrictions",
      element: ReservationRestrictionsPage,
      clean: "/process/reservationRestrictions",
    },
  ],

  Default: {
    Path: "login",
    Clean: "/login",
    Page: Login,
  },
  DefaultWithSideBar: {
    Path: "dashboard",
    Clean: "/dashboard",
    Page: HomePage,
  },
};

export default ROUTES;
