import * as yup from "yup";
const yupTypeValidator = (item) => {
  if (item.HtmlType === "selectobj" || item.HtmlType === "radioobj") {
    return yup.string().label(item.FieldTitle).required();
  } else if (item.HtmlType === "select" || item.HtmlType === "radio") {
    return yup.string().label(item.FieldTitle).required();
  } else
    if (item.FieldType === "string" || item.FieldType === "varchar" || item.FieldType === "datetime") {
      return yup.string().label(item.FieldTitle).required();
    } else if (item.FieldType === "bool" || item.FieldType === "bit") {
      return yup.boolean().label(item.FieldTitle).required();
    } else if (item.FieldType === "int" || item.FieldType === "bigint" || item.FieldType === "decimal" || item.FieldType === "money") {
      return yup.number().label(item.FieldTitle).required();
    } else if (item.FieldType === "date") {
      return yup.date().label(item.FieldTitle).required();
    }
};

export default yupTypeValidator;