import React, { useEffect, useState } from "react";
import { GenericFields } from "../../../group-reservation/GenericFields";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";

import yupTypeValidator from "../../../../utils/YupTypeValidator";

import { HeaderTasksAssignmentPlanningFields } from "../../fields/HeaderTasksAssignmentPlanningFields";
import { parsedUser } from "../../../../utils/GetCurrentUser";
import API from "../../../../api/api";
import { GetDataOfJSON } from "../../../../utils/GetDataOfJSON";
import { useToast } from "../../../../contexts/toast";

const HeaderTasksAssignmentPlanning = ({
  taskSelected,
  getTasksDetails,
  getTasksCard,
  action,
}) => {
  const [validationsSchema, setValidationSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = parsedUser();

  const toast = useToast();

  const fields = HeaderTasksAssignmentPlanningFields;

  let customLabel = {
    className: "col-2 text-nowrap",
    status: true,
  };

  const requestCollaboratorsDetails = async (obj) => {
    try {
      setIsLoading(true);
      let queryString = `IdUser=${user.IdUser}&Token=${
        user.Token
      }&ActionMode=${"P"}`;

      let objSPVersion = "@GUIDAutomaticTaskAssignment='" + obj.GUID + "'";

      let queryData = {
        Data: objSPVersion,
      };

      let requestAPI = await API.postAction(
        "/api/AutomaticTasksAssigments/AutomaticTasksAssignmentsDetailAddEdit?" +
          queryString,
        queryData
      );

      if (requestAPI.status === 200) {
        console.log(GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0));
        let dataDetails = GetDataOfJSON(
          requestAPI,
          "data",
          0,
          "JSONData",
          0
        ).JSONData;
        let data = GetDataOfJSON(
          requestAPI,
          "data",
          0,
          "JSONData",
          0
        ).JSONDataDetail;

        console.log(dataDetails);
        console.log(data);

        getTasksDetails(dataDetails);
        getTasksCard(data);

        changeToOnlyReadFields();
      } else setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const changeToOnlyReadFields = () => {
    fields.forEach((item) => {
      item.ReadOnly = true;
    });
  };

  const handleSubmit = async (obj) => {
    console.log(obj);

    /* obj.preventDefault(); */

    let objSPVersion = "";

    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      // if (item.HtmlType === 'select') {
      //   debugger;
      // }
      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
          obj[item.FieldName] === null ||
          obj[item.FieldName] === ""
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : obj[item.FieldName] ?? "null";
      } else if (typeof item === "boolean") {
        objSPVersion += obj[item.FieldName] === true ? 1 : 0;
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
          obj[item.FieldName] === null ||
          obj[item.FieldName].length === 0 ||
          obj[item.FieldName] === "Invalid date"
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    console.log(objSPVersion);
    console.log(obj);

    let queryData = {
      Data: objSPVersion,
    };

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${action}`;

    console.log(queryString);

    console.log(queryData);

    try {
      let requestAPI = await API.postAction(
        "/api/automaticTasksAssigments/AddEdit?" + queryString,
        queryData
      );

      console.log(requestAPI);

      if (requestAPI.status === 200) {
        let data = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).JSONData;

        requestCollaboratorsDetails(data);
      } else {
        toast({
          type: "error",
          message: "An error occurred while trying to create it!",
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: "An error occurred while trying to create it!",
      });
    }
  };

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        item.ReadOnly = false;
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    console.log(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };

  const formik = useFormik({
    initialValues: taskSelected ? taskSelected : {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Container className="w-75 mx-0">
          <Row>
            <GenericFields
              updateFields={updateField}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              fields={fields}
              customLabel={customLabel}
            />
          </Row>
        </Container>

        <Row>
          <Col xs="12 text-end">
            {!isLoading ? (
              <Button
                size="sm"
                className="px-4 py-2"
                variant="primary"
                type="submit"
              >
                {taskSelected ? "SAVE ASSIGMENTS" : "CREATE ASSIGMENTS"}
              </Button>
            ) : null}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default HeaderTasksAssignmentPlanning;
