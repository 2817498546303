import { t } from "i18next";

export const RateInfoFields = [
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBillingBusinessUnit",
    FieldType: "spinner",
    FieldTitle: t("Billing Business Unit"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdRoomType",
    FieldType: "varchar",
    FieldTitle: t("Rate Room Type"),
    Position: 3,
    cssClasss: " col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"6cbf801d549b261dd16d9a34c6cfb96f77dddc04846999ece71d62cbef864851"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
    dependOf: "IdBillingBusinessUnit",
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCustomer",
    FieldType: "varchar",
    FieldTitle: t("Customer"),
    Position: 1,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "ArrivalDate",
    FieldType: "datetime",
    FieldTitle: t("Arrival"),
    Position: 2,
    cssClasss: "col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Nights",
    FieldType: "varchar",
    FieldTitle: t("Nights"),
    Position: 2,
    cssClasss: "col-md-4",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "1",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    Min: 1,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "DepartureDate",
    FieldType: "datetime",
    FieldTitle: t("Departure"),
    Position: 2,
    cssClasss: "col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
    minValueDependOf: "ArrivalDate",
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdMealPlan",
    FieldType: "bit",
    FieldTitle: t("Meal Plan"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"4bf2a611eaf1b54c557e672a9976de2d180972861db45c396f5a7c95f2819576"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "AdultQuantity",
    FieldType: "int",
    FieldTitle: t("Adults"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdSpecialCode",
    FieldType: "bigint",
    FieldTitle: t("Special Code"),
    Position: 3,
    cssClasss: " col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"78b1523e6fa39d70f4a0333d0d13722d00025027ceffe056d5677ec1d8b67331"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Child1Quantity",
    FieldType: "int",
    FieldTitle: t("Children 1"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Child2Quantity",
    FieldType: "int",
    FieldTitle: t("Children 2"),
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "RateType",
    FieldType: "bit",
    FieldTitle: t("Rate Type"),
    Position: 3,
    cssClasss: "col-md-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Child3Quantity",
    FieldType: "int",
    FieldTitle: "Children 3",
    Position: 3,
    cssClasss: "col-md-2",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "BookingDate",
    FieldType: "datetime",
    FieldTitle: t("Booking Date"),
    Position: 3,
    cssClasss: "col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
    maxDateToday: true,
  },
  {
    FieldName: "IdRateCategoryType",
    FieldType: "int",
    FieldTitle: t("Rate Category Type"),
    Position: 3,
    cssClasss: "col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"97cf9a0606f82ed6e8885961e5fbce5cdd82909e2343e3f5b2e38fc31807ae95"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: "RateAssigned",
    FieldName: "RateAssigned",
    FieldType: "int",
    FieldTitle: t("Rate Assigned"),
    Position: 3,
    cssClasss: "col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    CustomFormat: "#,##0.00",
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "SeniorQuantity",
    FieldType: "int",
    FieldTitle: t("Senior"),
    Position: 3,
    cssClasss: "col-md-2 col-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "TotalStay",
    FieldType: "int",
    FieldTitle: t("Stay Total"),
    Position: 3,
    cssClasss: "col-md-6 col-12",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    CustomFormat: "#,##0.00",
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "",
    FieldType: "",
    FieldTitle: t("Warranty Information"),
    Position: 3,
    cssClasss: " col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 3,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "CreditLimit",
    FieldType: "varchar",
    FieldTitle: t("Credit Limit"),
    Position: 3,
    cssClasss: "col-md-4 col-12",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 3,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "Voucher",
    FieldType: "varchar",
    FieldTitle: t("Voucher"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 3,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "BookingNumber",
    FieldType: "varchar",
    FieldTitle: t("Booking Number"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 3,
  },
];
