export const CommentField = [
  {
    IdAppForm: 63,
    Id: 6300004,
    FieldName: "Comment",
    FieldType: "varchar",
    FieldTitle: "Comments",
    Position: 4,
    cssClasss: "col-12",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
