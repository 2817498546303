import React, { useEffect, useState } from "react";
import { Col, Card, Row, Container } from "react-bootstrap";

import { useFormik } from "formik";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import API from "../../../../api/api";
import { useToast } from '../../../../contexts/toast';
import { parsedUser } from "../../../../utils/GetCurrentUser";
import { DateBox } from "devextreme-react";
import moment from "moment";


const RatePeriodsSpecialBookingArrival = (props) => {

	const user = parsedUser();
	const [actionMode, setActionMode] = useState(null);
	const [records, setRecords] = useState([])
	const bindRecordsRemote = async () => {
		try {
			let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.selectedItem.GUID}`;
			let request = await API.getAction('api/contracts/ContractsToRatePeriodArrivalRecords', query);
			let results = request.data[0];
			if (!results.JSONData) {
				return;
			}
			let parsedResults = JSON.parse(results.JSONData)[0];

			let recordResult = parsedResults.JSONData;
			if (recordResult === null) {
				setRecords([])
				return;
			}
			setRecords(recordResult
			)
		} catch (error) {
			console.error(error);
			setRecords({
				Rows: []
			});
		} finally {

		}
	}

	useEffect(() => {
		console.log("RECORD on RATEPERIODSPECIALBOOKINGARRIVAL =>",props.record)
		bindRecordsRemote();
	}, [])


	const addRecord = () => {
		if (records.length > 0) {
			let lastRecord = records[records.length - 1];
			if (lastRecord.GUID === null ) {
				return;
			}
		}

		const record = {
			GUID: null,
			DateStartArrival: null,
			DateEndArrival: null,
			DateStartBooking: null,
			DateEndBooking: null
		}
		setActionMode('I');
		setRecords([...records, record]);
	}

	return (<Container>
		<Row className="mx-0">
			<Col className="px-0">
				<table className=' table table-bordered shadow-sm'>
					<thead>
						<tr>
							<th>Date Start Arrival</th>
							<th>Date End Arrival</th>
							<th>Date Start Booking</th>
							<th>Date End Booking</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{records && records.length === 0 ?
							<tr>
								<td colSpan={5} className='text-center py-4'>
									No data
								</td>
							</tr>
							: null}
						{records && records.map((item, index) => {
							return (
								<CustomTableRow
									Contract={props.Contract}
									SpecialRate={props.SpecialRate}
									key={index}
									actionMode={actionMode}
									recordsList={records}
									record={item}
									requestReload={bindRecordsRemote}
								/>
							)
						})}

					</tbody>
				</table>
			</Col>
			<Col xs={'auto pt-3'}>
				<button className="btn btn-sm btn-primary" onClick={() => addRecord()}>
					<i className="fa fa-plus"></i>
				</button>
			</Col>
		</Row>
	</Container>)
}

export default RatePeriodsSpecialBookingArrival


const CustomTableRow = props => {
	const toast = useToast();
	// console.log("info parent", props)
	const [model, setModel] = useState(props.record)
	const [recordList, setRecordList] = useState(props.recordsList)
	const [isLoading, setIsLoading] = useState(false);
	let actionMode = props.actionMode
	const maxBookingDate = props.SpecialRate.DateEnd
	const [greatestDatesObj, setGreatestDatesObj] = useState({
    DateStartArrival: '',
    DateEndArrival: '',
    DateStartBooking: '',
    DateEndBooking: ''
  })
	const user = parsedUser();

	const generateGreaterDates = () => {
		// console.log('Recordlist on Custom Table Row =>',recordList);
		const dataset = recordList.map((item) => {
			const { DateStartArrival, DateEndArrival, DateStartBooking, DateEndBooking } = item;
			const newData = {
				DateStartArrival: DateStartArrival,
				DateEndArrival: DateEndArrival,
				DateStartBooking: DateStartBooking,
				DateEndBooking: DateEndBooking
			};
			return newData;
		});
		
		const greaterDates = dataset.reduce((acc, curr) => {
			for (const key in curr) {
				if (!acc[key] || moment(curr[key]).isAfter(acc[key])) {
					acc[key] = curr[key];
				}
			}
			return acc;
		}, {});

		const newDates = Object.keys(greaterDates).reduce((acc, curr) => {
			acc[curr] = moment(greaterDates[curr]).add(1, 'days').format();
			return acc;
		}, {});
		// console.log("New Dates =>", newDates)
		setGreatestDatesObj(newDates);
	}

	useEffect(() => {
		generateGreaterDates();
	},[])

	useEffect(() => {
		generateGreaterDates();
	},[recordList, props.record])

	//Delete the record
	const deleteConfirm = async () => {
		confirmAlert({
			closeOnClickOutside: false,
			message: "Are you sure you want to delete this record?",
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						// console.log(props.record.GUID)
						if (props.record.GUID === null) {
							await props.requestReload();
							// props.setSelectedItem(null);
							return;
						}
						try {
							
							let query = `token=${user.Token}&RecordId=${props.record.GUID}`;
							await API.getAction('api/contracts/ContractsToRatePeriodArrivalDelete', query);
							await props.requestReload();
							toast({
								type: "success",
								message: "Record deleted successfully",
							})
						} catch (error) {
							console.error(error)
						} finally {
							generateGreaterDates();
						}
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const handleSubmit = async (values) => {
		// setIsLoading(true);
		if (values.DateStartArrival === null && values.DateEndArrival === null && values.DateStartBooking === null && values.DateEndBooking === null) {
			toast({
				type: "error",
				message: "Please fill at least one of the required dates",
			})
			return;
		}

		if (model.GUID && model.GUID.length > 0) {
			actionMode = 'U'
		}


		try {
			setIsLoading(true);
			let objSPVersion = `@GuidContractsToRatePeriod='${props.SpecialRate.GUID}'`;
			if (model.GUID) {
				objSPVersion += `,@GUID='${model.GUID}' `;
			}
			if (model.DateStartArrival) {
				objSPVersion += `,@DateStartArrival='${model.DateStartArrival}'`;
			} else {
				objSPVersion += `,@DateStartArrival=null`;
			}
			if (model.DateEndArrival  && model.DateEndArrival !== null) {
				objSPVersion += `,@DateEndArrival='${model.DateEndArrival}'`;
			} else {
				objSPVersion += `,@DateEndArrival=null`;
			}
			if (model.DateStartBooking) {
				objSPVersion += `,@DateStartBooking='${model.DateStartBooking}'`;
			} else {
				objSPVersion += `,@DateStartBooking=null`;
			}

			if (model.DateEndBooking) {
				objSPVersion += `,@DateEndBooking='${model.DateEndBooking}'`;
			} else {
				objSPVersion += `,@DateEndBooking=null`;
			}


			if (actionMode === 'U') {
				console.log("selected item", model)
				//	objSPVersion += `, @GUID='${selectedItem.GUID}'`
			}
			let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

			// debugger
			let queryData = {
				Data: objSPVersion,
			};


			let request = await API.postAction(
				'api/contracts/ContractsToRatePeriodArrivalAddEdit' + "?" + queryString,
				queryData
			);

			if (request.status === 200) {
				toast({
					type: "success",
					"message": "Record saved successfully",
				})
			}


			// props.setStep(props.step + 1)

			//	toggleOpen(null, true);
		} catch (error) {
			console.error(error);
		}
		finally {
			props.requestReload();
			setIsLoading(false);
			//		props.setRequestSubmitGeneral(false);
		}




	};

	return (<tr>

		<td>

			<DateBox

				name={"DateStartArrival"}
				isValid={true}

				value={model.DateStartArrival && model.DateStartArrival !== 'Invalid date' ? model.DateStartArrival : ""}
				onValueChanged={(e) => {
					if (e.value === null) {
						return;
					}
					let format = moment(e.value).format("YYYY-MM-DD");
					let newModel = {
						...model,
						DateStartArrival: format
					}
					setModel(newModel)
					//handleSubmit(newModel);
					//	    props.updateField(model.FieldName, format);
				}}
				min={(greatestDatesObj.DateEndArrival && greatestDatesObj.DateEndArrival !== 'Invalid date') ? greatestDatesObj.DateEndArrival : props.SpecialRate.DateStart}
				max={maxBookingDate}
			/>
		</td>
		<td>
			<DateBox
				// disabled={model.DateStartArrival === null || model.DateStartArrival === ''}
				name={"DateEndArrival"}
				isValid={true}
				value={model.DateEndArrival && model.DateEndArrival !== 'Invalid date' ? model.DateEndArrival : ""}

				onValueChanged={(e) => {
					if (e.value === null) {
						return;
					}
					let format = moment(e.value).format("YYYY-MM-DD");
					let newModel = {
						...model,
						DateEndArrival: format
					}
					setModel(newModel)
					//	handleSubmit(newModel);

				}}
				min={model.DateStartArrival && model.DateStartArrival !== 'Invalid date' ? model.DateStartArrival : (greatestDatesObj.DateEndArrival && greatestDatesObj.DateEndArrival !== 'Invalid date') ? greatestDatesObj.DateEndArrival : props.SpecialRate.DateStart}
				max={maxBookingDate}
				></DateBox>
		</td>
		<td>

			<DateBox

				name={"DateStartBooking"}
				isValid={true}

				value={model.DateStartBooking && model.DateStartBooking !== 'Invalid date' ? model.DateStartBooking : ""}
				onValueChanged={(e) => {
					if (e.value === null) {
						return;
					}
					let format = moment(e.value).format("YYYY-MM-DD");
					
					let newModel = {
						...model,
						DateStartBooking: format
					}
					setModel(newModel)
					//	handleSubmit(newModel);
					//	    props.updateField(model.FieldName, format);
					// console.log("Any changes? ", model.DateStartBooking);
				}}
				min={(greatestDatesObj.DateEndBooking && greatestDatesObj.DateEndBooking !== 'Invalid date') ? greatestDatesObj.DateEndBooking : props.SpecialRate.DateStart}
				max={maxBookingDate}
			/>
		</td>
		<td>
			<DateBox
				// disabled={model.DateStartBooking === null || model.DateStartBooking.length === 0}
				name={"DateEndBooking"}
				isValid={true}
				value={model.DateEndBooking && model.DateEndBooking !== 'Invalid date' ? model.DateEndBooking : ""}

				onValueChanged={(e) => {
					if (e.value === null) {
						return;
					}
					let format = moment(e.value).format("YYYY-MM-DD");
					let newModel = {
						...model,
						DateEndBooking: format
					}
					setModel(newModel)
					//		handleSubmit(newModel);
				}}
				min={model.DateStartBooking && model.DateStartBooking !== 'Invalid date' ? model.DateStartBooking : (greatestDatesObj.DateEndBooking && greatestDatesObj.DateEndBooking !== 'Invalid date') ? greatestDatesObj.DateEndBooking : props.SpecialRate.DateStart}
				// min={(greatestDatesObj.DateEndBooking && greatestDatesObj.DateEndBooking !== 'Invalid date') ? greatestDatesObj.DateEndBooking : props.SpecialRate.DateStart}
				max={maxBookingDate}
				></DateBox>
		</td>
		<td>
			<div className="btn-group btn-sm">
				<button className="btn btn-sm btn-primary" onClick={() => {
					handleSubmit(model);
				}} disabled={isLoading}>
					<i className="fa fa-floppy-disk"></i>
				</button>

				<button className="btn btn-sm btn-danger" onClick={() => {
					deleteConfirm();
				}} disabled={isLoading}>
					<i className="fa fa-trash"></i>
				</button>

			</div>
		</td>


	</tr>)
}