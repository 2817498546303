import React, { useCallback, useState } from 'react'
import { Col, Container, Dropdown, Row, Table } from 'react-bootstrap'
import logo from './LHVR.jpg';
import { CustomBreadcrumbs as Breadcrumbs, ReportFilter } from '../../../components';
import useFavorite from '../../../hooks/useFavorite';
import { parsedUser } from '../../../utils/GetCurrentUser';
import { useLocation, useParams } from 'react-router-dom';
import modeljson from '../ReportDataFake/RegistrationCardReport/RegistrationCardModel.json';
import recordsJson from '../ReportDataFake/RegistrationCardReport/RegistrationCardRecords.json';
import { exportToPDF, pdfExporter } from '../../../utils/export-to-pdf';
import { PrintOutlined } from '@mui/icons-material';
import API from '../../../api/api';
import Sha256 from '../../../utils/sha256';

export default function RegistrationCard() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [filters] = useState(modeljson.FilterFieldSet);
  const [records, setRecords] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [formIcon, setFormIcon] = useState(null);
  const [formPath, setFormPath] = useState(null);
  const [formTitle, setFormTitle] = useState(modeljson.ReportTitle);

  const [filterBy, setFilterBy] = useState([]);
  const [headers, setHeaders] = useState(recordsJson.Headers);

  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();

  const bindDataRemote = async (filter) => {

    setIsLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(`/api/Reports/RegistrationCard?${query}`, queryData);
      let results = await request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      let recordResult = parsedResults?.Records || [];
      console.log("🚀 ~ file: index.js:66 ~ bindDataRemote ~ recordResult:", recordResult)
      
      setRecords(recordResult[0]);

    } catch (error) {
      console.error(error);

    } finally {
      setIsLoading(false);

    }

  };

   //TODO This method will be used to submit the filter data for the grid report.
   const generateReport = useCallback(async (obj) => {
    const ReportCriteria = filters.filter(item => item.ReportCriteria).map(item => {
      return {
        name: item.FieldTitle,
        value: obj[item.FieldName],
        fieldType: item.HtmlType
      }
    })
    setFilterBy(ReportCriteria);

    try {
      // await bindDataRemote();
      handleFilter(obj);
      setHeaders(recordsJson.Headers);
      // setSummary(recordsJson.Summary);
      setShowReport(true);
    } catch (error) {
      console.log(error);
    }
  },[filterBy]);

  const handleFilter = async (obj) => {
    let objSPVersion = "";
    filters.forEach((item) => {

      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
            : obj[item.FieldName] ?? "null";

      } else if (item.HtmlType === "password") {
        let passwordEncrypted = null;
        if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
          passwordEncrypted =
            obj["UserName"].toUpperCase() + obj[item.FieldName];
          passwordEncrypted = Sha256.hash(passwordEncrypted);
          ;
        }
        objSPVersion +=
          obj[item.FieldName] === undefined
            ? "null"
            : "'" + passwordEncrypted + "'"
      } else
        if (item.HtmlType === 'radio') {

          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
    });
    await bindDataRemote(objSPVersion);
  }

  const clearReport = () => {
    try {
      setShowReport(false);
    } catch (error) {
      console.log(error);
    }
  }

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${formIcon}`,
      path: pathname,
      formPath: formPath,
      title: formTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const generatePDF = () => {
    const container = document.getElementById("contentContainer");
    exportToPDF(container, "portrait");
  }

  return (
    <Container>
      {formPath && <Breadcrumbs pathTitle={formPath} />}
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
            {isLoading ? (
              <>
                <div className="">
                  <div
                    className="w-25 mx-auto my-1"
                    style={{ paddingTop: "" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center my-1">
                  <i className={formIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{formTitle}</h5>
                  </div>
                </div>
              </>
            )}
            <div className="w-auto d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle variant="button" id="dropdown-basic">
                  <i className="fa fa-ellipsis-v" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => addFavorite()}>
                    {isPinned ? "Remove from" : "Add to"} favorites
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* FILTERS */}
          <ReportFilter
            filterFields={filters}
            isLoading={isLoading}
            generateReport={generateReport}
            clearReport={clearReport}
          />
          
        </div>
      </div>
      {/* Report */}
      <div className='d-flex justify-content-center flex-column align-items-center '>
      {(showReport && records) && <>
      <div className='mx-3 d-flex justify-content-end' style={{width: "1480px", minWidth: "1480px"}}>
        <button className={'btn btn-secondary mx-2 mb-2'} onClick={() => generatePDF()}>
          <PrintOutlined /> Export to PDF
        </button>
      </div>
      <div style={{backgroundColor: "#fcfeff", paddingRight: "7rem", paddingLeft: "7rem", width: "1480px", minWidth: "1480px"}} className='mx-3' id="contentContainer" >
      <Row className='pt-2'>
        {/* logo */}
        <Col md={4} className='py-5'>
          <img src={logo} alt="logo" width={'65%'} />
        </Col>
        {/* title */}
        <Col md={4} className='d-flex justify-content-center align-items-center flex-column' style={{fontWeight: "bolder", fontSize: '2.4rem'}}>
          <span>Registration Card</span>
          <span>Tarjeta de Registro</span>
        </Col>
        {/* date */}
        <Col md={4} className='d-flex justify-content-center align-items-end flex-column'>
            <h4 className='fw-bold'>Folio / Reserva</h4>
            <h4>{records?.folio}</h4>
        </Col>
      </Row>
      <Row className='d-flex' style={{fontSize: '1.4rem'}}>
        <Col md={12} className='p-0'>
        <Table bordered className='border-black border-opacity-100 border-1'>
        <tbody>
            <tr>
              <th className='border-1 w-25'>Member Name</th>
              <td className='border-1 w-25'>{records?.memberName}</td>
              <th className='border-1 w-25'>Room / Habitacion</th>
              <td className='border-1 w-25'>{records?.Room}</td>
            </tr>
            <tr>
              <th className='border-1'>Guest Name</th>
              <td className='border-1'>{records?.guestName}</td>
              <th className='border-1'>Adults / Adultos</th>
              <td className='border-1'>{records?.AdultQuantity}</td>
            </tr>
            <tr>
              <th className='border-1'>T.O/T.A./Compañia</th>
              <td className='border-1'>{records?.company}</td>
              <th className='border-1'>Children / Niños</th>
              <td className='border-1'>{records?.children}</td>
            </tr>
            <tr>
              <th className='border-1'>Membership</th>
              <td className='border-1'>{records?.membership}</td>
              <th className='border-1'>Infants / Infantes</th>
              <td className='border-1'>{records?.infants}</td>
            </tr>
            <tr>
              <th className='border-1'>Arrival / Llegada</th>
              <td className='border-1'>{records?.ArrivalDate}</td>
              <th className='border-1'>Country / Pais</th>
              <td className='border-1'>{records?.Country}</td>
            </tr>
            <tr>
              <th className='border-1'>Departure / Salida</th>
              <td className='border-1'>{records?.DepartureDate}</td>
              <th className='border-1'>Passport</th>
              <td className='border-1'>{records?.IdentificationNumber}</td>
            </tr>
            <tr>
              <th className='border-1'>Nights / Noches</th>
              <td className='border-1'>{records?.Nights}</td>
              <th className='border-1'>Phone</th>
              <td className='border-1'>{records?.phone}</td>
            </tr>
            <tr>
              <th className='border-1'>Room Type / Tipo Hab.</th>
              <td className='border-1'>{records?.RoomType}</td>
              <th className='border-1'>Email</th>
              <td className='border-1'>{records?.Email}</td>
            </tr>
            <tr>
            <th className='border-1' colSpan={4}>Comments</th>
              </tr>
              <tr>
              <td className='border-1' colSpan={4}>{records?.Comments || "No Comments"}</td>
              </tr>
            </tbody>
        </Table>
        </Col>
      </Row>
      <Row>
        <section className='mb-5' style={{fontSize: '1.4rem'}}>
          <div>
          <p className='fw-bold'>Important information</p>
          <ul>
            <li>The hotel cannot be held responsible for the loss of valuables not property secured in the safety deposit box which is provided in each accommodation at a minimum charge. The hotel is not responsible for loss of damage to any valuables left in public areas.</li>
            <li>Hotel rooms and services are for the exclusive use of our registered clients. Additional guests in the room without a prior reservation will be subject to a daily charge of US$200.00 per person.</li>
            <li>Guests are responsible for the damages caused to the property and the loss or damage of the operational equipment and active assets of the resort. These will be charged to the guest’s account at replacement cost.</li>
            <li>The management reserves the right of admission for guests that do not comply with the established policies. It is forbidden to be involved in any activity that could inconvenience other guests. Pets are not allowed.</li>
            <li>Hotel bracelet -for lost or not returned bracelet during check out you will be charged by Reception US$ 200 for all-inclusive and US$ 50 for NON-all-inclusive bracelet. If damaged- pass by reception and your bracelet will be exchanged for a new one.</li>
            <li>You have been added to our mailing list which means you will be notified of money saving offers and promotions from time to time.</li>
            </ul>
          </div>
          <div>
            <p className='fw-bold'>Informacion importante</p>
            <ul>
            <li>El hotel no se hace responsable de la pérdida de objetos de valor que no estén guardados en la caja fuerte que se proporciona en cada alojamiento por un cargo mínimo. El hotel no se hace responsable de la pérdida o daño de objetos de valor dejados en zonas públicas.</li>
            <li>Las habitaciones y servicios del hotel son para uso exclusivo de nuestros clientes registrados. Los huéspedes adicionales en la habitación sin reserva previa estarán sujetos a un cargo diario de 200,00 USD por persona.</li>
            <li>Los huéspedes son responsables de los daños causados a la propiedad y de la pérdida o deterioro de los equipos operativos y activos del complejo. Éstos se cargarán a la cuenta del huésped al coste de reposición.</li>
            <li>La dirección se reserva el derecho de admisión de los huéspedes que no cumplan las políticas establecidas. Está prohibido participar en cualquier actividad que pueda incomodar a otros huéspedes. No se admiten animales de compañía.</li>
            <li>Pulsera del hotel -en caso de pérdida o no devolución de la pulsera durante el check-out, la recepción le cobrará 200 US$ por la pulsera con todo incluido y 50 US$ por la pulsera SIN todo incluido. En caso de deterioro, pase por recepción y se le cambiará la pulsera por una nueva.</li>
            <li>Se le ha añadido a nuestra lista de correo, lo que significa que se le notificarán ofertas y promociones de ahorro de dinero de vez en cuando.</li>
            </ul>
          </div>
          <div>
            <p className='fw-bold'>Informations importantes</p>
            <ul>
            <li>L'hôtel ne peut être tenu responsable de la perte d'objets de valeur qui n'ont pas été placés dans le coffre-fort mis à disposition dans chaque chambre moyennant un supplément. L'hôtel n'est pas responsable de la perte ou de l'endommagement d'objets de valeur laissés dans les parties communes.</li>
            <li>Les chambres et les services de l'hôtel sont réservés à l'usage exclusif de nos clients enregistrés. Les personnes supplémentaires séjournant dans la chambre sans réservation préalable devront s'acquitter d'une taxe journalière de 200 dollars américains par personne.</li>
            <li>Les clients sont responsables des dommages causés à la propriété et de la perte ou de l'endommagement de l'équipement opérationnel et des actifs du centre de villégiature. Ceux-ci seront facturés sur le compte du client au prix de remplacement.</li>
            <li>La direction se réserve le droit d'admettre les hôtes qui ne respectent pas les politiques établies. Il est interdit de participer à toute activité susceptible d'incommoder les autres hôtes. Les animaux domestiques ne sont pas autorisés.</li>
            <li>Bracelet de l'hôtel - en cas de perte ou de non-retour du bracelet lors du check-out, la réception vous facturera 200 USD pour un bracelet tout compris et 50 USD pour un bracelet non tout compris. S'il est endommagé, passez à la réception et votre bracelet sera échangé contre un nouveau.</li>
            <li>Vous avez été ajouté à notre liste de diffusion, ce qui signifie que vous serez informé de temps à autre d'offres et de promotions permettant de réaliser des économies.</li>
            </ul>
          </div>
        </section>
        <section id='Signatures' className='mt-5'>
          <div className='d-flex justify-content-between align-items-center' style={{fontSize: '1.2rem'}}>
            <div className='d-flex flex-column align-items-center'>
              <div className='border-3 border-bottom border-black ' style={{width: '275px'}}></div>
              <p className='fw-bold'>Guest Signature</p>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div className='border-3 border-bottom border-black ' style={{width: '275px'}}></div>
              <p className='fw-bold'>Guest Signature</p>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div className='border-3 border-bottom border-black ' style={{width: '275px'}}></div>
              <p className='fw-bold'>Receptionist</p>
            </div>
          </div>
        </section>
      </Row>
      </div></>}
      </div>
    </Container>
  )
}
