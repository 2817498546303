import React, { useEffect, useState } from "react";
import { Col, Card, Row, Container } from "react-bootstrap";
import { useFormik } from "formik";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import API from "../../../../api/api";
import { useToast } from "../../../../contexts/toast";
import { parsedUser } from "../../../../utils/GetCurrentUser";
import CHARGESDISCOUNTFIELDS from "../../Fields/RatePeriodChargesDiscountsFields";

const SpecialRateRoomTypes = (props) => {
  const [validationsSchema, setValidationSchema] = useState(null);
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  const user = parsedUser();
  const toast = useToast();

  const selectedItem = props.selectedItem;
  const actionMode = props.actionMode;
  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj);

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let objSPVersion = `@GUIDContract='${props.contractGUID}', @IdContractPolicy=${obj.IdContractPolicy}`;

              if (props.actionMode === "U") {
                console.log("selected item", selectedItem);
                objSPVersion += `, @GUID='${selectedItem.GUID}'`;
              }
              let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/contracts/ContractToContractPoliciesAddEdit" +
                  "?" +
                  queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  //	setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                message: "Record saved successfully",
              });

              //	toggleOpen(null, true);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const specialRateSelectAll = async () => {
    // First start loading the model
    console.log(props);

    //First load records
    try {
      console.log(props);
      let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.selectedItem.GUID}`;
      await API.getAction("api/contracts/SpecialRateCheckAll", query);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      await bindRecordsRemote();
    }
  };

  const specialRateUnselectAll = async () => {
    //First load records
    try {
      console.log(props);
      let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.selectedItem.GUID}&`;

      await API.getAction("api/contracts/SpecialRateUnCheckAll", query);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      await bindRecordsRemote();
    }
  };

  const [records, setRecords] = useState([]);
  const bindRecordsRemote = async () => {
    // First start loading the model

    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&ParentGUID=${props.selectedItem.GUID}`;
      // if (search.length > 0 && cleanFilter == null) {
      //   query += "&Search=" + search;
      // }

      let request = await API.getAction(
        "api/contracts/SpecialRateToRoomTypeRecords",
        query
      );
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
    }
  };
  const updateSelectedRoomType = async (ocupation, status) => {
    console.log(ocupation);
    let objToSend = `@Status=${status},@GUID='${ocupation.GUID}'`;

    let queryData = {
      Data: objToSend,
    };
    let query = `?IdUser=${user.IdUser}&token=${user.Token}&ActionMode=U`;
    var request = await API.postAction(
      "api/Contracts/SpecialRateToRoomTypeAddEdit" + query,
      queryData
    );

    console.log(request);
  };

  useEffect(() => {
    bindRecordsRemote();
  }, []);

  return (
    <Container>
      <Row className="mx-0">
        <Col>
          <button
            className="btn btn-primary me-2"
            onClick={() => {
              specialRateSelectAll();
            }}
          >
            Select all
          </button>
          <button
            className="btn btn-secondary me-2"
            onClick={() => {
              specialRateUnselectAll();
            }}
          >
            Unselect all
          </button>
        </Col>
      </Row>

      <Row className="mx-0">
        <Col className="px-0">
          <table className=" table table-bordered shadow-sm">
            <thead>
              <tr>
                <th>Business Unit</th>
                <th>Room Type</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.length > 0 &&
                records.map((item, index) => {
                  return (
                    <CustomTableRow
                      key={index}
                      GuestType={item.Code}
                      // OccupationType={record.OccupationType}
                      record={item}
                      callback={(status) =>
                        updateSelectedRoomType(item, status)
                      }
                    />
                  );
                })}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default SpecialRateRoomTypes;

const CustomTableRow = ({ RoomType, callback, record }) => {
  console.log(record);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setStatus(record?.Status || false);
  }, [record.Status]);

  const handleBlur = () => {
    callback(status);
  };

  return (
    <tr>
      <td>{record.BusinessUnit}</td>
      <td>{record.Description}</td>
      <td>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
            name="Status"
            onBlur={handleBlur}
          />
        </div>
      </td>
    </tr>
  );
};
