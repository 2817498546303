import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
const NumberReservations = (props) => {
  const [guestTypes, setGuestTypes] = useState([]);
  useEffect(() => {
    setGuestTypes(props.totalGuests);
  }, [props.totalGuests]);

  // console.log(props);
  let total = 0;
  return (
    <>
      <Row>
        {/* <Col xs={5}></Col>
          <Col xs={7} className="text-center">
            <label>Restaurant Reservation</label>
          </Col> */}
        <Col>
          <table className="table table-bordered table-striped mt-4">
            <thead>
              <tr>
                <th>Guest Type</th>
                <th className="text-end">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {guestTypes && guestTypes.length > 0 ? (
                guestTypes.map((item, index) => {
                  total += item.quantity;
                  return (
                    <tr key={index}>
                      <td>{item.GuestType}</td>
                      <td className="text-end">{item.quantity}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border-1 text-center" colSpan={2}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className="text-end">
                  <b>Total: {total}</b>
                </td>
              </tr>
            </tfoot>
          </table>
        </Col>
        {/* <GenericFields
            fields={numbersReservationsFields}
            values={values}
            touched={null}
            errors={errors}
            updateFields={updateField}
            customLabel={customLabel}
          /> */}
      </Row>
    </>
  );
};

export default NumberReservations;
