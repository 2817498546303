// import { numberifyText } from 'numberify-text';
import numberToText from 'number-to-text';
import 'number-to-text/converters/en-us';

export const HeaderSpecialRateOccupationTable = ({maxChildren, maxOccupation}) => {

  return (
    <thead>
    {/* <col />
    <colgroup span="2"></colgroup>
    <colgroup span="3"></colgroup> */}
    <tr>
      <th scope="colgroup" colSpan={2} className='text-center'>Special Rate</th>
      <th scope="colgroup" className='text-center'></th>
      <th scope="colgroup" className='text-center'></th>
      <th colSpan={maxOccupation} scope="colgroup" className='text-center'>Ocupation Type</th>
      <th colSpan={maxChildren + 2} scope="colgroup" className='text-center'>Guest Type</th>
      <th scope="colgroup" className='text-center'></th>

    </tr>
    <tr>
      <th className='text-center' scope="col">Start Date</th>
      <th className='text-center' scope="col">End Date</th>
      <th className='text-center' scope="col">Room Type</th>
      <th className='text-center' scope="col">%</th>
      {occupationTypeSet(maxOccupation)}
      {guestTypeSet(maxChildren)}
      <th className='text-center' scope="col">Extra Pax</th>
    </tr>
  </thead>
  )
}


const occupationTypeSet = (OccupationTypeAmount) => {
  let occupationType = [];
  for (let i = 1; i <= OccupationTypeAmount; i++) {
    let text = numberToText.convertToText(i, {language: 'en-us'});
    if (text === 'One') text = 'Single';
    if (text === 'Two') text = 'Double';
    if (text === 'Three') text = 'Triple';
    occupationType.push(
      <th scope="col" className='text-center' key={i}>{text}</th>
    )
  }
  return occupationType;
}

const guestTypeSet = (GuestTypeAmount) => {
  let guestType = [];
  guestType.push(
    <th scope="col" className='text-center' key={'a'}>Adult</th>
  )
  for (let i = 1; i <= GuestTypeAmount; i++) {
    guestType.push(
      <th scope="col" className='text-center' key={i}>{`C${i}`}</th>
    )
  }
  guestType.push(
    <th scope="col" className='text-center' key={'i'}>Infant</th>
  )
  return guestType;
}