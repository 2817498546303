import React from "react";
import { LoginForm } from "../../components";

const Login = (props) => {
  return (
    <React.Fragment>
      <LoginForm></LoginForm>
    </React.Fragment>
  );
};

export default Login;
