import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row, Table } from "react-bootstrap";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";

import { useTranslation } from "react-i18next";

const ReservationLogModal = (props) => {
  const { t } = useTranslation();

  const { show, handleClose } = props;
  const user = parsedUser();
  const [showReport, setShowReport] = useState(true);
  const [logRecords, setLogRecords] = useState([]);
  const [statusRecords, setStatusRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const bindDataRemote = async () => {
    setIsLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let reservation = props.GUIDReservation;
      let request = await API.getAction(
        `/api/Reservations/ReservationLog?${query}${
          reservation ? `&GUIDReservation=${reservation}` : ""
        }`
      );
      let results = await request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log("🚀 ~ bindDataRemote ~ parsedResults:", parsedResults);
      console.log(
        "🚀 ~ bindDataRemote ~ parsedResults:",
        parsedResults?.RecordsLog
      );
      setLogRecords(parsedResults?.RecordsLog);
      setStatusRecords(parsedResults?.RecordsStatus);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
    return () => setShowReport(false);
  }, []);

  useEffect(() => {
    if (logRecords) {
      setShowReport(true);
    }
    return () => setShowReport(false);
  }, [logRecords]);

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      dialogClassName="modal-90w"
      className="modalRight z-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Reservation Log")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-2">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <h3>{t("Loading")}...</h3>
            </div>
          ) : (
            <Card className="bg-light">
              <Card.Body>
                <Tabs
                  defaultActiveKey="status"
                  variant="tabs"
                  // fill
                >
                  <Tab
                    eventKey="status"
                    title={t("Status")}
                    className="border border-1 border-black px-2"
                    tabClassName="bg-light text-black-25"
                  >
                    <Row className="my-0">
                      <Col md={12} className="py-2">
                        <Table
                          hover
                          bordered
                          responsive
                          className="shadow-sm table-striped"
                        >
                          <thead className="table-secondary">
                            <tr>
                              <th>{t("Status")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("User")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {statusRecords &&
                              statusRecords.map(
                                (status, index) =>
                                  statusRowTable(status, index) || (
                                    <tr key={index}>
                                      <td colSpan="6">
                                        {t("No records found")}
                                      </td>
                                    </tr>
                                  )
                              )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="log"
                    title={t("Log")}
                    className="border border-1 border-black px-2"
                    tabClassName="bg-light text-black-25"
                  >
                    <Row className="my-0">
                      <Col md={12} className="py-2">
                        <Table
                          hover
                          size="xl"
                          bordered
                          className="border shadow-sm table-striped"
                        >
                          <thead className="table-secondary">
                            <tr>
                              <th>{t("Action")}</th>
                              <th>{t("Field Changed")}</th>
                              <th>{t("Prior State")}</th>
                              <th>{t("Later State")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("User")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {logRecords &&
                              logRecords.map(
                                (log, index) =>
                                  logsRowTable(log, index) || (
                                    <tr key={index}>
                                      <td colSpan="6">
                                        {t("No records found")}
                                      </td>
                                    </tr>
                                  )
                              )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => handleClose(false)}
        >
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationLogModal;

const logsRowTable = (log, index) => {
  return (
    <tr key={index}>
      <td>{log.Action}</td>
      <td>{log.FieldChanged}</td>
      <td>{log.PriorState}</td>
      <td>{log.LaterState}</td>
      <td>{moment(log.ActionDate).format("MM/DD/YYYY h:mm:ss a")}</td>
      <td>{log.UserName}</td>
    </tr>
  );
};

const statusRowTable = (status, index) => {
  return (
    <tr key={index}>
      <td>{status.State}</td>
      <td>{moment(status.ActionDate).format("MM/DD/YYYY h:mm:ss a")}</td>
      <td>{status.UserName}</td>
    </tr>
  );
};
