import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import PrintInvoice from "./PrintInvoice";
import { dateFormater } from "../../../utils/date-formater";


const RelatedInvoice = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const getListInvoice = async () => {
    setIsLoading(true);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@Accion = 'I', @IdReservationToFolioDetail='${Number(props.selectedFolioDetails.IdReservationToFolioDetail)}'`,
      };
      let request = await API.postAction(
        `api/cashier/findInvoices?${query}`,
        queryData
      );
      const AllList = JSON.parse(request?.data[0]?.JSONData)[0]?.Record;
      console.log("🚀 ~ getListInvoice ~ AllList:", AllList)
      
      if (!AllList) {
        toast({
          title: "Error",
          message: "No records found",
        })
        return;
      }
      setRecords(AllList || []);
      console.log("🚀 ~ getListInvoice ~ AllList:", AllList)

    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        message: "Error loading the list of invoices",
      })
    } finally {
      setIsLoading(false);
    }
  }

  const togglePrintInvoice = () => {
    setShowPrintInvoice(!showPrintInvoice);
  }

  useEffect(() => {
    getListInvoice();
  },[])

  return (
    <>
    {showPrintInvoice && (
        <PrintInvoice
          show={true}
          toggle={togglePrintInvoice}
          selectedFolioDetails={selectedInvoice}
        />
      )}
    <Modal
      show={props.show}
      onHide={props.close}
      backdrop="static"
      keyboard
      size={"md"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Related Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Invoice Number</th>
                <th className="text-center">Date</th> 
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {records && records.length > 0 ? records.map((record, index) => (
                <tr style={{cursor: 'pointer'}} className={`${selectedInvoice?.IdBillingDocument === record.IdBillingDocument ? "active" : ""}`} key={index} onClick={() => setSelectedInvoice(record)}>
                  <td className="text-center text-nowrap">{index + 1}</td>
                  <td className="text-center text-nowrap">{record.IdBillingDocument}</td>
                  <td className="text-center text-nowrap">{dateFormater(record.DocumentDate)}</td>
                  <td className="text-center text-nowrap">{record.DocumentStatus}</td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    {isLoading ? <h4>Loading...</h4> : <h4>No records found</h4>}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          <button
            className="btn me-2"
            type="button"
            onClick={props.close}
            disabled={isLoading}
          >
            Close
          </button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => togglePrintInvoice(selectedInvoice)}
            disabled={isLoading || !selectedInvoice}
          >
            <i className="fa-solid fa-print me-2"></i>
            Print
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default RelatedInvoice
