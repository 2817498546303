import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  Col,
  Container,
  Form,
  Modal,
  Placeholder,
  Row,
} from "react-bootstrap";

import { parsedUser } from "../../../../utils/GetCurrentUser";
import API from "../../../../api/api";
import { useToast } from "../../../../contexts/toast";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Step } from "semantic-ui-react";

import SupplementsGeneral from "./SupplementsGeneral";
import SupplementsRoomTypes from "./SupplementsRoomTypes";
import SupplementsGuestTypes from "./SupplementsGuestTypes";

import { useTranslation } from "react-i18next";

const SupplementsTab = (props) => {
  const { t } = useTranslation();
  // PROPERTIES
  const generalRef = useRef(null);
  const selectedItemRef = useRef(null);
  const user = parsedUser();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [supplementGeneralInfo, setSupplementGeneralInfo] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [records, setRecords] = useState([]);
  const [generalError, setGeneralError] = useState(null);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [model, setModel] = useState(props.page);

  const [step, setStep] = useState(0);

  const [requestSubmitGeneral, setRequestSubmitGeneral] = useState(false);

  // METHODS

  const toggleSelectItem = (obj) => {
    console.log(obj);
    setSelectedItem(obj);
  };

  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);

  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `token=${user.Token}&RecordId=${selectedItem.GUID}&IdUser=${user.IdUser}&ParentGUID=${props.ParentGUID}`;
              await API.getAction("api/contracts/SupplementsDelete", query);
              await bindDataRecordRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  // THIS METHOD IS USED TO BRING THE DETAILS OF THE SECTION USED
  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      let request = await API.getAction(
        "api/contracts/SupplementsDetails",
        query
      );
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData;
      //fillFormikObject(finalResult);
      //setSelectedItem(null)
      //Fill object with new values
    } catch (error) {
      toast({ type: "error", message: t("Error loading data") });
      return;
    }
  };

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRecordRemote = async (cleanFilter) => {
    if (props.ParentGUID == null) {
      return;
    }
    // First start loading the model
    setLoading(true);

    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      // if (search.length > 0 && cleanFilter == null) {
      //   query += "&Search=" + search;
      // }

      let request = await API.getAction(
        "api/contracts/SupplementsRecords",
        query
      );
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      setLoading(false);
    }
  };

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}`;
      let requestSections = await API.getAction(
        `api/contracts/SupplementsHeader`,
        querySections
      );
      console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      console.log(recordResultSection);
      //setFormSize(recordResultSection[0].FormSize ?? 'md');

      //! If grid data stays null, we use the data from the API

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const setActiveStep = (step) => {
    generalRef.current?.submit();
    bindDataRecordRemote();
    setStep(step);
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
    } else {
    }

    if (action === "I") {
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setShowAddEdit(!showAddEdit);
    if (reload || (!action && !reload)) {
      await bindDataRecordRemote();
    }
  };

  useEffect(() => {
    bindDataHeaderRemote();
    bindDataRecordRemote();
  }, []);

  return (
    <Container id="tab-grid-body" fluid className=" ">
      <Modal
        show={showAddEdit}
        onHide={() => toggleOpen(null)}
        backdrop="static"
        keyboard={false}
        size={props.modalSize ?? "md"}
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{model.PageTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid className="overflow-auto">
            <Row className="my-4">
              <Col xs={12} className="px-0">
                <Step.Group widths={3}>
                  <Step active={step === 0} onClick={() => setStep(0)}>
                    {/* <Icon name='truck' /> */}
                    <Step.Content>
                      <Step.Title>{t("General")}</Step.Title>
                    </Step.Content>
                  </Step>
                  <Step
                    active={step === 1}
                    disabled={step === 0 && actionMode === "I"}
                    onClick={() => setActiveStep(1)}
                  >
                    {/* <Icon name='truck' /> */}
                    <Step.Content>
                      <Step.Title>{t("Guest Types")}</Step.Title>
                    </Step.Content>
                  </Step>
                  <Step
                    active={step === 2}
                    disabled={step === 0 && actionMode === "I"}
                    onClick={() => setActiveStep(2)}
                  >
                    {/* <Icon name='truck' /> */}
                    <Step.Content>
                      <Step.Title>{t("Room Types")}</Step.Title>
                    </Step.Content>
                  </Step>
                </Step.Group>
              </Col>
              <Col xs={12}>
                <Row>
                  {step === 0 ? (
                    <SupplementsGeneral
                      ref={generalRef}
                      requestSubmitGeneral={requestSubmitGeneral}
                      setRequestSubmitGeneral={setRequestSubmitGeneral}
                      setActiveStep={setActiveStep}
                      actionMode={actionMode}
                      setActionMode={setActionMode}
                      step={step}
                      contractGUID={props.ParentGUID}
                      setStep={setStep}
                      selectedItem={selectedItem}
                      Contract={props.Contract}
                      setSelectedITem={setSelectedItem}
                    ></SupplementsGeneral>
                  ) : null}
                  {step === 1 ? (
                    <SupplementsGuestTypes
                      contractGUID={props.ParentGUID}
                      selectedItem={selectedItem}
                    ></SupplementsGuestTypes>
                  ) : null}
                  {step === 2 ? (
                    <SupplementsRoomTypes
                      contractGUID={props.ParentGUID}
                      selectedItem={selectedItem}
                    ></SupplementsRoomTypes>
                  ) : null}
                </Row>
              </Col>
            </Row>

            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            <button className="btn me-2" type={"button"} onClick={toggleOpen}>
              {t("Close")}
            </button>
            {step > 0 && (
              <button
                className="btn btn-secondary me-2"
                type={"button"}
                onClick={() => setStep(step - 1)}
              >
                {t("Prev")}
              </button>
            )}
            {step < 2 && (
              <button
                className="btn btn-primary me-2"
                type={"button"}
                onClick={() => {
                  console.log(step);
                  if (step === 0) {
                    //	setRequestToSaveGeneral(!requestToSaveGeneral);
                    //	props.refreshRatePeriodsTable();
                    //setStep(step + 1)
                    setRequestSubmitGeneral(true);
                    //Means we need to save the current record first and then move to next one
                  } else {
                    setStep(step + 1);
                  }
                }}
              >
                {t("Next")}
              </button>
            )}
            {step === 2 ? (
              <button
                className="btn btn-primary me-2"
                type={"button"}
                onClick={() => {
                  toggleOpen();
                }}
              >
                {t("Finish")}
              </button>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
      <Card>
        <Card.Body className="p-0">
          <Row className="mx-0">
            <Col className="p-0">
              <table className=" table table-bordered table-striped m-0">
                <thead>
                  <tr>
                    {tableHeader &&
                      tableHeader.map((item, index) => {
                        console.log(item);
                        return (
                          <th key={index} className={`fw-bold `}>
                            {item.FieldTitle ?? item.FieldName}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {records && records.length === 0 ? (
                    <tr>
                      <td
                        colSpan={tableHeader.length}
                        className="text-center py-4"
                      >
                        {t("No data")}
                      </td>
                    </tr>
                  ) : null}
                  {records &&
                    records.map((item, index) => {
                      console.log(item);
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            toggleSelectItem(item);
                          }}
                          className={
                            selectedItem && selectedItem.GUID === item.GUID
                              ? "active"
                              : ""
                          }
                        >
                          {tableHeader &&
                            tableHeader.map((column, index2) => {
                              return (
                                <td key={index2}>
                                  {typeof item[column.FieldName] ===
                                  "boolean" ? (
                                    <input
                                      type="checkbox"
                                      checked={item[column.FieldName]}
                                      className=""
                                    />
                                  ) : (
                                    item[column.FieldName]
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
            <Col xs={"auto"} className=" pt-2 gap-2">
              {console.log(selectedItem)}
              <button
                className={`btn btn-primary btn-sm ${
                  selectedItem === "null" && "disabled"
                }`}
                onClick={() => {
                  toggleOpen("I");
                }}
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
              {selectedItem && (
                <>
                  <br />
                  <button
                    className={`btn btn-sm  `}
                    onClick={() => toggleOpen("U")}
                    type="button"
                  >
                    <i className="fas fa-pencil"></i>
                  </button>
                  <br />
                  <button
                    className={`btn btn-sm text-danger `}
                    onClick={() => deleteConfirm(selectedItem)}
                    type="button"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SupplementsTab;
