import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import GuestInfoFields from "../fields/GuestInfoFields";
import { useFormik } from "formik";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";

const GuestInfoAddEdit = (props) => {
  const { t } = useTranslation();

  const fields = GuestInfoFields;
  const toast = useToast();
  const user = parsedUser();
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const selectedReservation = props.selectedReservation;
  const [generalError, setGeneralError] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [primaryGuest, setPrimaryGuest] = useState(null);
  const [currentGuest, setCurrentGuest] = useState(null);

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    // console.log('[🙍🏽‍♂️🙍🏽‍♂️🙍🏽‍♂️]',props.currentGuestList)
    // console.log('Guest info has the following reservation: ', props.selectedReservation)
    const primaryGuest = props.currentGuestList.find(
      (person) => person.PrimaryGuest === true
    );
    const currentGuest = props.currentGuestList.find(
      (person) => person?.GUID === props.selectedGuest?.GUID
    );
    // console.log({primaryGuest})
    // console.log({currentGuest})
    // console.log(primaryGuest?.GUID === currentGuest?.GUID)
    // console.log({actionMode})
    // setValidatePrimaryGuest(primaryGuest?.GUID === currentGuest?.GUID)
    setCurrentGuest(currentGuest);
    setPrimaryGuest(primaryGuest);
  }, []);

  const checkForExistingGuest = async () => {
    let exist =
      formik.values.LastName?.length > 0 ||
      formik.values.FirstName?.length > 0 ||
      formik.values.IdentificationNumber?.length;
    // console.log(exist)
    if (exist) {
      let queryObj = `@FirstName='${
        formik.values.FirstName ?? ""
      }',@LastName='${formik.values.LastName ?? ""}', @IdentificationNumber='${
        formik.values.IdentificationNumber ?? ""
      }'`;
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

      let queryData = {
        Data: queryObj,
      };

      let request = await API.postAction(
        `api/reservations/ReservationsGuestCoincidences?${queryString}`,
        queryData
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];

      setRecords(recordResult);
    }
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    // console.log("object to save", obj);
    // if (actionMode === 'K') {
    // 	if (primaryGuest?.GUID && obj.PrimaryGuest) {
    // 		toast({
    // 			type: "error",
    // 			"message": "There is already a primary guest for this reservation",
    // 		})
    // 		return;
    // 	}
    // }
    if (actionMode === "I") {
      if (primaryGuest?.GUID && obj.PrimaryGuest) {
        toast({
          type: "error",
          message: t("There is already a primary guest for this reservation"),
        });
        return;
      }
    }

    if ((actionMode === "I" || actionMode === "K") && primaryGuest) {
      const isNotValid = primaryGuest?.GUID !== currentGuest?.GUID;

      if (isNotValid && obj.PrimaryGuest) {
        toast({
          type: "error",
          message: t("There is already a primary guest for this reservation"),
        });
        return;
      }
    }

    try {
      let objSPVersion = "";
      if (actionMode === "U" || actionMode === "K") {
        // console.log(selectedRecord);
        // debugger;
        obj.GUID = selectedRecord.GUID;
        obj.Active = true;
      }
      fields.forEach((item) => {
        if (item.HtmlType === "date") {
          if (obj[item.FieldName] === "Invalid date") {
            obj[item.FieldName] = null;
          }
        }

        if (
          item.ReadOnly ||
          item.HtmlType === "separator" ||
          item.FieldName === "IdCustomer" ||
          item.FieldName === "IdContract" ||
          item.FieldName === "FillBtn"
        )
          return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });
      if (actionMode === "U" || actionMode === "K") {
        if (!objSPVersion.includes("@GUIDReservation")) {
          objSPVersion += `, @GUIDReservation='${selectedReservation.GUID}'`;
        }
      }
      if (props.ParentField) {
        console.log(props);
        if (props.ParentFieldType === "int") {
          objSPVersion += `, @${props.ParentField}=${props.ParentFieldValue}`;
        } else {
          objSPVersion += `, @${props.ParentField}='${props.ParentFieldValue}'`;
        }
      }
      objSPVersion += ", @IdReservation=" + props.selectedReservation.Id;

      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}&ParentGUID=${props.selectedReservation.GUID}`;

      let queryData = {
        Data: objSPVersion,
      };

      console.log(queryData);

      console.log(objSPVersion);
      let request = await API.postAction(
        `api/reservations/ReservationsGuestAddEdit?${queryString}`,
        queryData
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };
  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };
  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  //Bring all the details for the selected record
  const bindDataDetailsRemote = async (item) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${item.GUID}&ParentGUID=${props.selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/Reservations/ReservationsGuestDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);

      setSelectedRecord(recordResult);
      fillFormikObject(recordResult);

      //   let record = DATAMODEL;
      //   //Now we need to go over the entire result and assign values to the record
      //   Object.keys(recordResult).forEach(item => {

      //     record[item] = recordResult[item];
      //   })
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const FillAddressInformationFromPrimaryGuest = () => {
    // First we need to check if the current guest have addressinformation already filled.
    // If so, we need to ask the user if he wants to overwrite the information
    // If not, we just fill the information
    if (
      formik.values.Address1 ||
      formik.values.Address2 ||
      formik.values.City ||
      formik.values.IdCountry ||
      formik.values.IdCountryState ||
      formik.values.Zip
    ) {
      confirmAlert({
        message: t("Do you want to overwrite the current address information?"),
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              if (primaryGuest) {
                formik.setFieldValue("Address1", primaryGuest.Address1);
                formik.setFieldValue("Address2", primaryGuest.Address2);
                formik.setFieldValue("City", primaryGuest.City);
                formik.setFieldValue("IdCountry", primaryGuest.IdCountry);
                formik.setFieldValue(
                  "IdCountryState",
                  primaryGuest.IdCountryState
                );
                formik.setFieldValue("Zip", primaryGuest.Zip);
              }
              toast({
                type: "info",
                message: t("Address information filled from principal guest."),
              });
            },
          },
          { label: t("No") },
        ],
      });
    } else {
      if (primaryGuest) {
        formik.setFieldValue("Address1", primaryGuest.Address1);
        formik.setFieldValue("Address2", primaryGuest.Address2);
        formik.setFieldValue("City", primaryGuest.City);
        formik.setFieldValue("IdCountry", primaryGuest.IdCountry);
        formik.setFieldValue("IdCountryState", primaryGuest.IdCountryState);
        formik.setFieldValue("Zip", primaryGuest.ZipCode);
      }
      toast({
        type: "info",
        message: t("Address information filled from principal guest."),
      });
    }
  };

  useEffect(() => {
    if (props.selectedGuest) {
      bindDataDetailsRemote(props.selectedGuest);
    }
  }, [props.selectedGuest]);
  useEffect(() => {
    if (formik.values) {
      setTimeout(() => {
        checkForExistingGuest();
      }, 1000);
    }
  }, [formik.values]);

  useEffect(() => {
    prepareFormikObject();
  }, []);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "xl"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Guest Information")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Container fluid className="overflow-auto">
            <Row className=" mb-4 mt-2">
              <Col xs={12} md={6}>
                <Row>
                  {fields &&
                    fields
                      .filter(
                        (x) =>
                          x.FieldTitle !== "Audit Information" &&
                          x.FieldName !== "Id" &&
                          x.FieldName !== "IdCustomer" &&
                          x.FieldName !== "DateAdd" &&
                          x.FieldName !== "File" &&
                          x.FieldName !== "DateMod"
                      )
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={`col-${item.FieldName}-${index}`}
                          >
                            {item.HtmlType === "button" ? (
                              <button
                                className="btn btn-outline-primary fw-light "
                                type="button"
                                disabled={formik.values.PrimaryGuest}
                                onClick={() => {
                                  FillAddressInformationFromPrimaryGuest();
                                }}
                              >
                                <i className="fas fa-arrow-down me-2"></i>
                                {t(item.FieldTitle)}
                              </button>
                            ) : (
                              <>
                                <label
                                  className={`me-2 mt-2 ${
                                    item.HtmlType === "separator" && "fw-bolder"
                                  }`}
                                >
                                  {t(item.FieldTitle)}
                                  {item.Required ? (
                                    <i className="required-asterisk ms-1 text-danger">
                                      *
                                    </i>
                                  ) : null}
                                </label>
                                <br />
                                <FieldSelector
                                  actionMode={actionMode}
                                  model={{
                                    ...item,
                                    ReadOnly:
                                      props.actionMode === "K" &&
                                      item.FieldName === "PrimaryGuest",
                                  }}
                                  obj={formik.values}
                                  key={`field-${item.FieldName}`}
                                  updateField={updateField}
                                  value={formik.values[item.FieldName]}
                                ></FieldSelector>
                              </>
                            )}
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm my-1">
                                {formik.errors[item.FieldName]}
                              </div>
                            ) : null}
                          </Col>
                        );
                      })}
                </Row>
              </Col>
              <Col className="bg-white border">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("Last Name")}</th>
                      <th>{t("First Name")}</th>
                      <th>{t("Identification Number")}</th>
                      <th>{t("Gender")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.length > 0 &&
                      records.map((item, index) => {
                        return (
                          <tr
                            className={` clickable ${
                              item.Id === selectedRecord?.Id ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setSelectedRecord(item);
                              fillFormikObject(item);
                              //bindDataDetailsRemote(item);
                            }}
                          >
                            <td>{item.LastName}</td>
                            <td>{item.FirstName}</td>
                            <td>{item.IdentificationNumber}</td>
                            <td>{item.Gender}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
            </Row>

            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            {Object.values(formik.errors).length > 0 && (
              <span className="invalid me-2">
                {t("Please check the forms for errors")}
              </span>
            )}
            <button className="btn me-2" type="button" onClick={props.toggle}>
              {t("Close")}
            </button>
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={() => [formik.submitForm()]}
            >
              {t("Save")}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GuestInfoAddEdit;
