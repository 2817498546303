import React from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Title,
  Font,
  Margin,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import Loading from "../Loading";

const dataTest = [
  { title: "High", total: 350 },
  { title: "Medium", total: 180 },
  { title: "Low", total: 123 },
  // Agrega más objetos según sea necesario
];

const palleteColor = ["#46a6ff", "#ff3333", "#00b500"];
const IncidentByPriority = ({ data, viewTotalValue }) => {
  const customizeLabel = (e) => {
    return `${e.valueText}`;
  };

  const pies = () => (
    <PieChart
      id="pie-chart"
      key={1}
      dataSource={dataTest}
      resolveLabelOverlapping="shift"
      palette={palleteColor}
      innerRadius={0.65}
      centerRender={(e) => CenterTemplate(e, viewTotalValue)}
      type="doughnut"
    >
      <Series argumentField="title" valueField="total">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
        >
          <Connector visible={true}></Connector>
        </Label>
      </Series>

      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>

      <Title text="Incident by Priority" verticalAlignment="top">
        <Font size={24} />
        <Margin top={25} bottom={0} />
      </Title>
    </PieChart>
  );

  return (
    <div className="bg-white p-3 shadow text-center" style={{ borderRadius: 25 }}>
      {/* {data && data.length > 0  ? pies() : <Loading />} */}
      {pies()}
    </div>
  );
};

export default IncidentByPriority;
