import React, { useEffect, useState } from "react";
import API from "../../api/api";
import LogFileDetails from "./LogFileDetail/LogFileDetails";
import CustomBreadcrumbs from "../breadcrumb";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { CleanExtension } from "../../utils/CleanExtencion";

import { useTranslation } from "react-i18next";

const AmadeusLogFilesContainer = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const [logDB, setLogDB] = useState([]);

  const [selectedLog, setSelectedLog] = useState(null);

  const [logFiles, setLogFiles] = useState([]);

  const RequestLogFiles = async () => {
    try {
      let requestAPI = await API.getActionExternal(
        "https://lifestylepmsapi.valegos.com/api/TestSP/AmadeusReceiveFiles"
      );

      if (requestAPI.status === 200 && requestAPI.data.length > 0) {
        setLogFiles(requestAPI.data);
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const RequestLogDB = async () => {
    try {
      let requestAPI = await API.getActionExternal(
        "https://lifestylepmsapi.valegos.com/api/TestSP/AmadeusDB"
      );

      if (requestAPI.status === 200 && requestAPI.data.length > 0) {
        setLogDB(requestAPI.data);
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const RequestReceivedFile = async (id) => {
    try {
      let requestAPI = await API.getActionExternal(
        "https://lifestylepmsapi.valegos.com/api/TestSP/AmadeusReceiveFile?id=" +
          id
      );

      if (requestAPI.status === 200 && requestAPI.data.length > 0) {
        setFileContent(requestAPI.data);
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };


  const [fileContent, setFileContent] = useState(null);
  const RequestDBFile = async (id) => {
    try {
      let requestAPI = await API.getActionExternal(
        "https://lifestylepmsapi.valegos.com/api/TestSP/AmadeusDBFile?id=" +
          id
      );

      if (requestAPI.status === 200 && requestAPI.data.length > 0) {
        setFileContent(JSON.stringify(requestAPI.data));
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    RequestLogFiles();
    RequestLogDB();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <b className="d-inline">AMADEUS RECEIVED</b>
              <button
                className="ms-2 btn btn-sm btn-primary"
                onClick={RequestLogFiles}
              >
                <i className="fa fa-refresh"></i>
              </button>
            </Card.Header>
            <Card.Body style={{ maxHeight: "30vh", overflow: "auto" }}>
              <table className="table table-bordered shadow-sm table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-nowrap">{t("Log Name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {logFiles && logFiles.length > 0 ? (
                    logFiles.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={` clickable ${
                            item === selectedLog ? " active" : ""
                          }`}
                          onClick={() => {
                            RequestReceivedFile(item);
                            setSelectedLog(item);
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{item}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="border-1 text-center">
                        {t("No Records Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card.Body>
          </Card>

          <Card className="my-4">
            <Card.Header>
              <b className="d-inline">FROM DB</b>
              <button
                className="ms-2 btn btn-sm btn-primary"
                onClick={RequestLogDB}
              >
                <i className="fa fa-refresh"></i>
              </button>
            </Card.Header>
            <Card.Body style={{ maxHeight: "30vh", overflow: "auto" }}>
              <table className="table table-bordered shadow-sm table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-nowrap">{t("Log Name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {logDB && logDB.length > 0 ? (
                    logDB.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={` clickable `}
                          onClick={() => {
                            RequestDBFile(item);
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{item}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="border-1 text-center">
                        {t("No Records Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <textarea
            value={fileContent}
            className="form-control"
            style={{ height: "80vh", overflow: "auto" }}
          ></textarea>
        </Col>
      </Row>
    </Container>
  );
};

export default AmadeusLogFilesContainer;
