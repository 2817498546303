import moment from "moment/moment";
import { useEffect, useState } from "react";

const Reloj = ({onClick}) =>{
    const [time, setTime] = useState(moment());

    useEffect(() => {
        const timer = setInterval(() => {
        setTime(moment());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

//width:"78.3rem"

    return (
        <div className="shadow d-flex justify-content-center align-items-center rounded w-100 mb-3 " style={{ cursor:"pointer" }} onClick={onClick}>
          <span className="h4 medium py-2 m-0"><i class="fa-solid fa-clock"></i> {time.format('HH:mm:ss')}</span>
        </div>
    )
}

export default Reloj;