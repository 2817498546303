import React, { useEffect, useState } from "react";
import {
  CircularGauge,
  Scale,
  MinorTick,
  RangeContainer,
  ValueIndicator,
} from "devextreme-react/circular-gauge";
import API from "../../api/api";
import Loading from "../Loading";

const styles = {
  description: {
    filter: "drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16))",
  },
};
const dataTest = {
  value: 8, // Valor actual para el indicador
  start: 0, // Valor de inicio para la escala
  end: 10, // Valor de fin para la escala
};

const CenterTemplate = (gauge) => {
  return (
    <svg>
      <rect y="0" x="0" width="200" height="200" fill="transparent"></rect>

      <g transform="translate(50 0)">
        <rect x="0" y="0" width="100" height="70" rx="8" fill="#f2f2f2"></rect>
        <text textAnchor="middle" y="27" x="50" fill="#000" fontSize="20">
          <tspan x="50">{gauge.valueText}</tspan>
          <tspan x="50" dy="30">
            kg
          </tspan>
        </text>
      </g>

      <g transform="translate(43 140)">
        <rect
          style={styles.description}
          x="0"
          y="0"
          width="114"
          height="56"
          rx="8"
          fill="#fff"
        ></rect>
        <text textAnchor="start" y="23" x="15" fill="#000" fontSize="12">
          <tspan x="15">Capacity: {gauge.scale.endValue} kg</tspan>
          <tspan x="15" dy="20">
            Graduation: {gauge.scale.tickInterval} g
          </tspan>
        </text>
      </g>
    </svg>
  );
};


const DailyResolvedIncident = ({ data, title }) => {
  console.log(data);
  const pies = () => (
    <CircularGauge
      value={dataTest?.value ? dataTest.value : 0}
      centerRender={(e) => {
        CenterTemplate(e);
      }}
    >
      <Scale
        startValue={dataTest?.start ? dataTest.start : 0}
        endValue={dataTest?.end ? dataTest?.end : 0}
        tickInterval={1}
      >
        <MinorTick visible />
      </Scale>
      <RangeContainer backgroundColor="#03a9f4" />
      <ValueIndicator color="#03a9f4" />
    </CircularGauge>
  );
  return (
    <div
      className="bg-white p-3 shadow text-center"
      /* style={
        dataTest && dataTest.length > 0
          ? { borderRadius: 25, height: 400 }
          : { borderRadius: 25 }
      } */

      style={
{ borderRadius: 25, height: 400 }}
    >
      
        <div className="row" style={{ height: "100%" }}>
          <div className="col-12 text-center mt-4">
            <span className="h4 font-weight-bold">{title ? title : "Daily Resolved Incident"}</span>
          </div>
          <div className="col-12 w-100">{pies()}</div>
        </div>
     
    </div>
  );
};

export default DailyResolvedIncident;
