import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Dropdown, Row, Table } from 'react-bootstrap'
import logo from './LHVR.jpg';
import { CustomBreadcrumbs as Breadcrumbs, ReportFilter } from '../../../components';
import useFavorite from '../../../hooks/useFavorite';
import { parsedUser } from '../../../utils/GetCurrentUser';
import { useLocation, useParams } from 'react-router-dom';
// import modeljson from '../ReportDataFake/RegistrationCardReport/RegistrationCardModel.json';
import recordsJson from '../ReportDataFake/RegistrationCardReport/RegistrationCardRecords.json';
import { exportPageToPDF, exportToPDF, pdfExporter } from '../../../utils/export-to-pdf';
import { PrintOutlined } from '@mui/icons-material';
import API from '../../../api/api';
import Sha256 from '../../../utils/sha256';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import recordJson from './ContractRecord.json';
import { CustomBusinessUnitTable } from './BusinessUnitTable/CustomBusinessUnitTable';
import { SuplementsTable } from './SuplementsTable/SuplementsTable';
import { CommentsTable } from './CommentsTable/CommentsTable';
import { CustomSpecialRateArrivalBookingTable } from './SpecialRateArrivalBooking/CustomSpecialRateArrivalBookingTable';
import { CustomSpecialRateOccupationTable } from './SpecialRateBUTable/CustomSpecialRateOccupationTable';

export default function ContractsReport() {
  // PROPERTIES
  const [records, setRecords] = useState(recordJson);

  useEffect(() => {
    console.log(records);
  }, [records]);

  const {
    RegistrationDate,
    Reference,
    Customers,
    Currency,
    Status,
    MealPlan,
    MealPlanPer,
    ContractStartDate,
    ContractEndDate,
    ContratRateByBusinessUnit
  }
  = records;

  // const user = parsedUser();
  // const { pathname } = useLocation();
  // const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  // const [filters] = useState(modeljson.FilterFieldSet);
  // const [records, setRecords] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [formIcon, setFormIcon] = useState(null);
  const [formPath, setFormPath] = useState(null);
  const [formTitle, setFormTitle] = useState('Contract');

  const [filterBy, setFilterBy] = useState([]);

  //TODO This method will be used to submit the filter data for the grid report.
  const generateReport = useCallback(async (obj) => {
    try {
      // await bindDataRemote();
      // setHeaders(recordsJson.Headers);
      // setSummary(recordsJson.Summary);
      setShowReport(true);
    } catch (error) {
      console.log(error);
    }
  }, [filterBy]);

  // const generatePDF = () => {
  //   // const container = document.getElementById("contentContainer");
  //   exportPageToPDF();
  // }

  const generatePDF = async () => {
    const content = document.getElementById('pdf-content');

    // Configura las opciones de html2pdf
    const options = {
      margin: 2,
      filename: 'contract_report.pdf',
      image: { type: 'jpeg', quality: 2 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    html2pdf().from(content).set(options).save();
  };

  return (
    <Container>
      {formPath && <Breadcrumbs pathTitle={formPath} />}
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
            {isLoading ? (
              <>
                <div className="">
                  <div
                    className="w-25 mx-auto my-1"
                    style={{ paddingTop: "" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center my-1">
                  <i className={formIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{formTitle}</h5>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Report */}
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className="mx-3 d-flex justify-content-end">
          <button className="btn btn-secondary mx-2 mb-2" onClick={() => generatePDF()}>
            <PrintOutlined /> Export to PDF
          </button>
        </div>
        <div style={{ backgroundColor: "#fcfeff" }} className='mx-3 px-2' id="pdf-content" >
          <Row className="my-2">
            {/* Logo */}
            <Col md={6} className="py-3">
              <img src={logo} alt="logo" width={"45%"} />
            </Col>
            {/* Date */}
            <Col md={6} className="d-flex justify-content-end align-items-center flex-row">
              <p className='fw-bold'>Registration Date: {RegistrationDate}</p>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={6}>
              <p className='fw-bold'>Reference: <span className='fw-normal'>{Reference}</span></p>
            </Col>
            <Col md={6} className='d-flex justify-content-end align-items-center flex-row'>
              <p className='fw-bold'>Status: <span className='fw-normal'>{Status}</span></p>
            </Col>
          </Row>

          <Row className='mb-2 '>
            <Col md={6} className=''>
              <p className='fw-bold'>Customers: <span className='fw-normal'>{Customers}</span></p>
            </Col>
            <Col md={6} className='d-flex justify-content-end align-items-center flex-row'>
              <p className='fw-bold'>Currency: <span className='fw-normal'>{Currency}</span></p>
            </Col>
          </Row>

          <Row className='mb-2 '>
            <Col md={2} className=''>
              <p className='fw-bold'>Meal Plans: <span className='fw-normal'>{MealPlan}</span></p>
            </Col>
            <Col md={10} className='d-flex justify-content-start flex-row'>
              <input type='checkbox' disabled defaultChecked={true} className='me-2 ms-4' /><label>{MealPlanPer}</label>
            </Col>
          </Row>

          <Row className='mb-2 '>
            <Col md={4} className=''>
              <p className='fw-bold'>Contract Start Date:<span className='fw-normal'>{ContractStartDate}</span></p>
            </Col>
            <Col md={8} className='d-flex justify-content-start flex-row'>
              <p className='fw-bold'>Contract End Date:<span className='fw-normal'>{ContractEndDate}</span></p>
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col md={12}>
              <div className='w-100 bg-info-subtle border border-2 text-center border-black '>
                <p className='fw-bold'>Contract Rate by Business Unit</p>
              </div>
            </Col>
          </Row>
          {ContratRateByBusinessUnit.map((item, index) => (
            <>
            <Row className='mb-2' key={index}>
              <Col md={12} className='mb-2'>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Business Unit: {item.BusinessUnit}</p>
                </div>
              </Col>
              <Col>
                <CustomBusinessUnitTable businesssUnitData={item.RatePeriods} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Suplement</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <SuplementsTable supplementsData={item.Suplements} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Default Contract Policies</p>
                </div>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>Deposit: <span className='fw-normal'>-</span></p>
              </Col>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>Cancellation: <span className='fw-normal'>-</span></p>
              </Col>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>No Show: <span className='fw-normal'>-</span></p>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Contract Comments</p>
                </div>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12} className='mb-2'>
                <CommentsTable commentData={item.ContractsComment} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Special Rate</p>
                </div>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col md={4} className='mb-2'>
                <p className='fw-bold'>Special Start Date: <span className='fw-normal'>-</span></p>
              </Col>
              <Col md={8} className='mb-2'>
                <p className='fw-bold'>Contarct End Date: <span className='fw-normal'>-</span></p>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col md={12}>
                <CustomSpecialRateArrivalBookingTable SpecialRateArrivalBookingData={item.SpecialRateArrivalBooking} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12}>
                <CustomSpecialRateOccupationTable SpecialRateBUTable={item.SpecialRateBUData} />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Exception Policies</p>
                </div>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>Deposit: <span className='fw-normal'>-</span></p>
              </Col>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>Cancellation: <span className='fw-normal'>-</span></p>
              </Col>
              <Col md={12} className='mb-2'>
                <p className='fw-bold'>No Show: <span className='fw-normal'>-</span></p>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col md={12}>
                <div className='w-100 bg-secondary-subtle text-center'>
                  <p className='fw-bold'>Contract Comments</p>
                </div>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12} className='mb-2'>
                <CommentsTable commentData={item.ContractsComment} />
              </Col>
            </Row>
            </>
          ))}

        </div>
      </div>
    </Container>
  )
}