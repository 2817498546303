import React, { useEffect, useState } from 'react'

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";

import IncidentStatus from "../../components/DashboardGuestComplain/IncidentStatus";
import IncidentByPriority from "../../components/DashboardGuestComplain/IncidentByPriority";
import DailyResolvedIncident from "../../components/DashboardGuestComplain/DailyResolvedIncident";
import IncidentCategory from "../../components/DashboardGuestComplain/IncidentCategory";
import DailyIncidents from "../../components/DashboardGuestComplain/DailyIncidents";
import ReportedIncidents from "../../components/DashboardGuestComplain/ReportedIncidents";
import DailyIncidentsLine from "../../components/DashboardGuestComplain/DailyIncidentsLine";

import { DateBox, SelectBox } from 'devextreme-react';

const dateRanges = [
    { ID: 'lastDay', Name: 'Last Day' },
    { ID: 'lastWeek', Name: 'Last Week' },
    { ID: 'lastMonth', Name: 'Last Month' },
    { ID: 'last3Months', Name: 'Last 3 Months' },
    { ID: 'last6Months', Name: 'Last 6 Months' },
    { ID: 'last9Months', Name: 'Last 9 Months' },
    { ID: 'lastYear', Name: 'Last Year' },
    // Puedes agregar más opciones según tus necesidades
  ];


const DashboardHistoricalMaintenance = () => {
  const [data, setData] = useState({
    roomStatus: [],
    roomCleaning: [],
    roomCleaningStatus: [],
    housekeepingTaskState:[],
    incidentByPriority:[],
    incidentAditionalServices:[],
    taskStatusByArea:[],
    dailyIncidentsPendingCompleted:[]
  });
  const user = parsedUser();
  const now = new Date();
  const request = async () => {
    try {
      let objSPVersion = ` `;

      

      // if (actionMode === 'U') {

      //   objSPVersion += `, @GUID='${selectedItem.GUID}'`
      // }

      let queryData = {
        Data: objSPVersion,
      };

      let query = `token=${user.Token}&IdUser=${user.IdUser}`;

      let requestAPI = await API.postAction(
        "/api/DashboardGuestComplaint/All?" + query,
        queryData
      );

      console.log(requestAPI);

      if (requestAPI.status === 200) {
        let onlyData = JSON.parse(requestAPI.data[0].JSONData);
        let final = onlyData ? onlyData[0] : {};
        console.log(final);

        setData({
          ...data,
          roomStatus: final.roomStatus,
          roomCleaning: final.roomCleaning,
          roomCleaningStatus: final.roomCleaningStatus,
          housekeepingTaskState: final.housekeepingTaskState,
          incidentByPriority: final.incidentByPriority,
          incidentAditionalServices: final.incidentAditionalServices,
          taskStatusByArea: final.taskStatusByArea,
          dailyIncidentsPendingCompleted: final.dailyIncidentsPendingCompleted
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <>
      <Breadcrumbs pathTitle={"Maintenance / Historical Dashboard"} />
      <div className="container px-3">
        <div className="row">
          <div className="col-12 mb-2">
  <div className='row'>
    <div className="col-sm-12 col-md-2 col mb-2">
      <h4 style={{ fontWeight: 800 }}>Dashboard</h4>
    </div>
    <div className='col-sm-12 col-md-5 col-lg-3 col-xl-2 mb-2'>
      <div className="dx-field">
            <div className="dx-field-label text-end" style={{ fontSize:"18px" }}>From</div>
            <div className="dx-field-value">
              <DateBox
                defaultValue={now}
                inputAttr={{ 'aria-label': 'date' }}
                type="date"
              />
            </div>
          </div>
    </div>
    <div className='col-sm-12 col-md-5 col-lg-3 col-xl-2 mb-2'>
      <div className="dx-field">
            <div className="dx-field-label text-end" style={{ fontSize:"18px" }}>Until</div>
            <div className="dx-field-value">
              <DateBox
                defaultValue={now}
                inputAttr={{ 'aria-label': 'date' }}
                type="date"
              />
            </div>
          </div>
    </div>

    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                <div className="d-md-flex justify-content-md-end">
                  <div className="dx-field-label text-end" style={{ fontSize:"18px" }}>Date Range</div>
                  <SelectBox
                    id="custom-templates"
                    dataSource={dateRanges}
                    displayExpr="Name"
                    valueExpr="ID"
                  />
                </div>
              </div>
  
     <div className="col-sm-12 col-md-6 col-lg-12 col-xl-3 mb-2">
                <div className="d-flex justify-content-end">
                  <div className="dx-field-label text-end " style={{ fontSize:"18px" }}>Business unit</div>
                  <SelectBox
                    id="custom-templates"
                    dataSource={{}}
                    displayExpr="Name"
                    //inputAttr={{}}
                    valueExpr="ID"
                    //fieldRender={{}}
                    //itemRender={""}
                  />
                  <button className="btn ms-2">
                    <i
                      className="fa-solid fa-rotate"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                </div>
              </div>
  </div>
</div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <IncidentStatus data={data.incidentStatus} viewTotalValue={true} />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <IncidentByPriority
              data={data.incidentpriority}
              viewTotalValue={false}
            />
          </div>
          <div className="col col-md-6 col-lg-4 col-xl-3 my-2">
            <DailyResolvedIncident data={data.dailyresolvedincident} />
          </div>
          <div className="col col-md-6 col-lg-6 col-xl-3 my-2">
            <IncidentCategory
              data={data.incidentcategory}
              viewTotalValue={false}
            />
          </div>
          <div className="col col-md-6 col-lg-6 my-2">
            <DailyIncidents data={data.dailyincidents} />
          </div>
          <div className="col col-md-6 col-lg-12 col-xl-6 my-2">
            <ReportedIncidents data={data.reportedincidents} />
          </div>
          <div className="col-12 my-2">
            <DailyIncidentsLine data={data.dailyincidentsline} />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHistoricalMaintenance
