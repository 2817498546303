import React from "react";
import RoomOverviewContainer from "../../../components/Housekeeping/RoomOverview/RoomOverviewContainer";

const RoomOverview = () => {
  return (
    <RoomOverviewContainer
      routeName={"Housekeeping / Process / Room Overview"}
    />
  );
};

export default RoomOverview;
