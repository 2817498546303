// import './style.css';
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { ReservationsColorSet } from "./ReservationsColorSet";
import { RoomsColorSet } from "./RoomsColorSet";
import React, { useEffect, useState } from "react";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";

import { useTranslation } from "react-i18next";

export const ModalColorPicker = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const [colorValues, setColorValues] = useState([
    {
      code: "VD",
      Status: "VC Dirty",
      background: "#008002",
      colorset: "rooms",
    },
    {
      code: "VC",
      Status: "VC Clean",
      background: "#00E800",
      colorset: "rooms",
    },
    {
      code: "OD",
      Status: "OC Dirty",
      background: "#B50000",
      colorset: "rooms",
    },
    {
      code: "OC",
      Status: "OC Clean",
      background: "#EE7623",
      colorset: "rooms",
    },
    {
      code: "DD",
      Status: "Do Not Disturb",
      background: "#7D7D7F",
      colorset: "rooms",
    },
    {
      code: "SO",
      Status: "Sleeping Out",
      background: "#003088",
      colorset: "rooms",
    },
    {
      code: "MA",
      Status: "Maintenance",
      background: "#0F0809",
      colorset: "rooms",
    },

    {
      code: "T",
      Status: "Tentative",
      background: "#03FAFF",
      colorset: "reservations",
    },
    {
      code: "C",
      Status: "Confirmed",
      background: "#7DB9E8",
      colorset: "reservations",
    },
    {
      code: "I",
      Status: "Inhouse",
      background: "#3614DA",
      colorset: "reservations",
    },
    {
      code: "O",
      Status: "Check-out",
      background: "#7D7D7F",
      colorset: "reservations",
    },
    {
      code: "N",
      Status: "No show",
      background: "#f4b084",
      colorset: "reservations",
    },
    {
      code: "X",
      Status: "Canceled",
      background: "#0F0809",
      colorset: "reservations",
    },
    {
      code: "B",
      Status: "Bumped out",
      background: "#ffc000",
      colorset: "reservations",
    },
  ]);
  const user = parsedUser();
  const [reservations, setReservations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const bindRemote = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

    let request = await API.getAction(
      `api/Reservations/ReservationColorScheme`,
      queryString
    );
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    // console.log(parsedResults)
    setReservations(parsedResults.ColorSchemeReservations);
    setRooms(parsedResults.ColorSchemeRooms);
  };

  useEffect(() => {
    bindRemote();
  }, []);

  return (
    <Modal show={show} onHide={() => handleClose()} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Color Scheme")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Card className="px-2 py-4">
          <Form>
            <Card.Body>
              <Form.Group controlId="exampleColorInput">
                <Row>
                  <Col md={6}>
                    <RoomsColorSet colorValues={rooms} />
                  </Col>
                  <Col md={6}>
                    <ReservationsColorSet colorValues={reservations} />
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Form>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
