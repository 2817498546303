import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";
import { SpecialRequestFields } from "../ReservationFields";
import CRUDPageGRIDCustom from "../../../pages/crud/CRUDPageGridCustom";

import { useTranslation } from "react-i18next";

const SpecialRequestTab = (props) => {
  const { t } = useTranslation();

  const fields = SpecialRequestFields;
  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (obj) => {
    if (props.actionMode === "I") {
    }
    formik.validateForm();
    if (!formik.isValid) {
      return;
    }
    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });
  };

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }
  };

  useEffect(() => {
    if (props.selectedReservation) {
      fillFormikObject(props.selectedReservation);
    }
  }, [props.selectedReservation]);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const updateField = (fieldName, fieldValue) => {
    props.updateReservation(fieldName, fieldValue);
    formik.setFieldValue(fieldName, fieldValue);
  };

  if (!props.selectedReservation) {
    return <></>;
  }

  return (
    <Row className="mx-0">
      {fields.map((item, index) => {
        if (props.actionMode === "K" || props.actionMode === "VIEW") {
          item.ReadOnly = true;
        } else {
          item.ReadOnly = false;
        }
        return (
          <Col
            className={item.cssClasss + (!item.Visible ? " d-none" : "")}
            key={index}
          >
            <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
            {item.Required ? (
              <i className="required-asterisk ms-1 text-danger">*</i>
            ) : null}
            <br />
            <FieldSelector
              actionMode={props.actionMode}
              model={item}
              key={index}
              obj={formik.values}
              updateField={updateField}
              value={formik.values[item.FieldName]}
            />
            {formik.errors[item.FieldName] && (
              <div className="invalid text-sm">
                {formik.errors[item.FieldName]}
              </div>
            )}
          </Col>
        );
      })}

      <Col xs={12}>
        <Row className="mt-4 h-100">
          <Col>
            <CRUDPageGRIDCustom
              getEndpoint="api/Reservations/SpecialRequestRecords"
              modelEndpoint="api/Reservations/SpecialRequestModel"
              addEditEndpoint="api/Reservations/SpecialRequestAddEdit"
              deleteEndpoint="api/Reservations/SpecialRequestDelete"
              detailsEndpoint="api/Reservations/SpecialRequestDetails"
              headerEndpoint="api/Reservations/SpecialRequestHeader"
              disableEdit
              id="specialRequest"
              disableAdd={
                props.actionMode === "K" || props.actionMode === "VIEW"
              }
              disableDel={
                props.actionMode === "K" || props.actionMode === "VIEW"
              }
              ParentGUID={props.selectedReservation.GUID}
              Parent={props.selectedReservation}
              ParentField={"IdReservation"}
              ParentFieldValue={props.selectedReservation?.Id}
              ParentFieldType={"int"}
              page={{
                PageIndex: 2,
                PageTitle: t("Special Request"),
                PageIcon: "fa-solid fa-file-lines",
                MultiRecord: true,
                Active: true,
              }}
            ></CRUDPageGRIDCustom>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SpecialRequestTab;
