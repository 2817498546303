import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useState } from "react";

import { formatDate } from "devextreme/localization";

import Timer from "../../../utils/Timer";

import alert_dealline from "../../../assets/icons/alert_deadline.svg";
import fire_solid2 from "../../../assets/icons/fire_solid2.svg";

import pencil from "../../../assets/icons/pencil.svg";

import "./TaskKanbanCard.scss";

import IncidentDetails from "../IncidentDetails";

import { useTranslation } from "react-i18next";

const onClick = (task) => (e) => {
  e.event.stopPropagation();
  notify(`Edit '${task.text}' card event`);
};

export const TaskKanbanCard = ({ task, openForEdit, expanded, updateKey }) => {
  const { t } = useTranslation();

  const [singleExpanded, setSingleExpanded] = useState(false);
  const [viewMoreDescription, setViewMoreDescription] = useState(false);

  const hadleExpandedClick = () => setSingleExpanded(!singleExpanded);
  const toggleDescription = (e) => {
    e.stopPropagation();
    setViewMoreDescription(!viewMoreDescription);
  };
  useEffect(() => {
    setSingleExpanded(expanded);
  }, [expanded]);

  /* const setDetailsByPriority = (priority) => {
    switch (priority) {
      case "High":
        return {
          background: "bgc-high",
          colorTimer: "bgc-timer-high",
          icon: null, //<img src={fire_solid2} alt="Alert Icon" />,
        };
      case "Normal":
        return {
          background: "bgc-normal",
          colorTImer: "bgc-timer-normal",
          icon: null, //<img src={alert_dealline} alt="Alert Icon" />,
        };
      case "Low":
        return {
          background: "bgc-low",
          colorTimer: "bgc-timer-low",
          icon: null, //<img src={alert_dealline} alt="Alert Icon" />,
        };

      default:
        return {
          background: "bgc-default",
          colorTimer: "bgc-timer-default",
          icon: null,
        };
    }
  }; */

  return (
    <>
      <div
        className={`kanban-card dx-card dx-theme-text-color dx-theme-background-color ${
          expanded ? "shadow" : "shadow"
        }`}
        style={{ cursor: "pointer", width: "14rem", height: "100%" }}
        onClick={hadleExpandedClick}
        onDoubleClick={() => {
          openForEdit(task);
        }}
      >
        <div
          className={`card-wrapper p-1 priority-${task.Priority.toLowerCase()}`}
        >
          <div className="card-content p-0">
            <div
              className={`card-subject text-center rounded mb-2`}
              style={{ backgroundColor: task.BackgroundColor }}
            >
              <div className="row">
                <div className="col-12">
                  <div
                    className="row align-items-center mt-1 mb-1"
                    style={{ marginLeft: "0.1rem" }}
                  >
                    <div className="col-7 px-0">
                      {/*                       <span
                        className={`${task.Priority === "High" ? "" : ""}`}
                        style={{ fontSize: "20px" }}
                      >
                        {setDetailsByPriority(task.Priority).icon}
                      </span> */}
                      <span className="date dx-theme-text-color ms-1 ps-0">
                        {t("CREATED:")}
                        <b>{formatDate(new Date(task.DateAdd), "HH:mm")}</b>
                      </span>
                    </div>
                    <div className="col-3 ms-1 pe-0 me-1 ps-1">
                      <div
                        className="rounded px-2 rounded-pill"
                        style={{ backgroundColor: task.BackgroundColor }}
                      >
                        <span className="date dx-theme-text-colorps-0 fw-bolder serif text-center ">
                          <Timer
                            dateExecution={task.ExecutionDate}
                            dateCreation={task.DateAdd}
                            updateKey={updateKey}
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-1 me-1 px-0 me-0"
                      onClick={() => {
                        openForEdit(task);
                      }}
                    >
                      <span>
                        <img src={pencil} alt="Alert Icon" className="me-1" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-evenly">
                    <div className="bg-white rounded text-center ms-1 me-1 mb-1 w-100">
                      {t("Room")} - {task.Room ?? "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/**here down will be the body of the task */}
            <div
              className={`card-data mx-1 ${
                singleExpanded ? "d-block" : "d-none m-0 p-0"
              }`}
            >
              <div className="mb-2 card-subject text-wrap">
                {task.Description &&
                task.Description.length > 24 &&
                !viewMoreDescription ? (
                  <>
                    {task.Description.substring(0, 24) + "..."}
                    <a onClick={toggleDescription}>{t("View more")}</a>
                  </>
                ) : task.Description && task.Description.length > 24 ? (
                  <>
                    {task.Description}{" "}
                    <a onClick={toggleDescription}>{t("View less")}</a>
                  </>
                ) : task.Description ? (
                  task.Description
                ) : null}
              </div>
              <hr className="my-2" />
            </div>
            <div className="card-assignee my-0">
              <div>
                <span className="fw-bolder text-wrap">{t(task.Category)}</span>
                {/* {task.departments &&
                  task.departments.map((e) => {
                    return (
                      <>
                        <span style={{ marginLeft: "3px" }}>
                          {getIconsByDepartments(e)}
                        </span>
                      </>
                    );
                  })} */}
              </div>
              <div className="serif fs-5 me-1">
                {/* {task.text.length > 0 ? (
                <img src={Comments} alt="Alert Icon" className="me-1" />
              ) : null} */}
                <b>
                  {formatDate(new Date(task.ExecutionDate), "HH:mm")}{" "}
                  <i class="fa-solid fa-hourglass-half"></i>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
