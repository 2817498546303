import { Col, Container, Row } from "react-bootstrap";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { useToast } from "../../../contexts/toast";
import API from "../../../api/api";
import React, { useEffect, useState } from "react";

const ListGuest = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const [guest, setGuest] = useState([]);

  useEffect(() => {
    setGuest(props.guest);
  }, [props.guest]);

  const handleCheckboxChange = async (item) => {
    let guestToEdit = item;
    guestToEdit.Apply = !guestToEdit.Apply;

    //Go over the entire obj
    let objSPVersion = `@GUIDRestaurantsReservation='${props.selectedReservation.GUID}', @GUID='${item.GUID}', @Apply=${guestToEdit.Apply}`;
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=U`;
    let queryData = {
      Data: objSPVersion,
    };

    let uri = "api/RestaurantReservation/AddEditGuest?";
    let request = await API.postAction(uri + queryString, queryData);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        toast({
          title: "Error",
          message: response.Error,
          type: "error",
        });
        return;
      }

      props.reloadTables();
    }
  };

  const selectAll = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${props.selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/RestaurantReservation/SelectAllGuest",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;

      props.reloadTables();
      if (recordResult === null) {
        throw new Error("No data found");
      }
      // console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const unSelectAll = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${props.selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/RestaurantReservation/UnSelectAllGuest",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      console.log(recordResult, results, parsedResults);
      props.reloadTables();
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} className="mb-2">
            <button
              className="btn btn-sm me-2"
              style={{ backgroundColor: "#419CED", color: "white" }}
              onClick={() => selectAll()}
              type="button"
            >
              Select All
            </button>
            <button
              className="btn btn-sm "
              style={{ backgroundColor: "#32496D", color: "white" }}
              onClick={() => unSelectAll()}
              type="button"
            >
              Unselect All
            </button>
          </Col>
          <Col xs={12}>
            <div className="w-100">
              <div
                className="wrapper"
                style={{ overflowY: "auto", maxHeight: "400px" }}
              >
                <table className="table table-bordered shadow-sm table-striped">
                  <thead>
                    <tr>
                      <th className="sticky-col first-col">Select</th>
                      <th className="sticky-col second-col">First Name</th>
                      <th>Last Name</th>
                      <th>Guest Type</th>
                      <th>Bracelet Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guest && guest.length > 0 ? (
                      guest.map((item, index) => {
                        return (
                          <tr key={index} className={`clickable`}>
                            <td>
                              <input
                                type="checkbox"
                                checked={item.Apply}
                                onChange={() => handleCheckboxChange(item)}
                              />
                            </td>
                            <td>{item.FirstName}</td>
                            <td>{item.LastName}</td>
                            <td>{item.GuestType}</td>
                            <td>{item.BraceletType}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="border-1 text-center" colSpan={12}>
                          No Records Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListGuest;
