export const HeaderSuplementsTable = () => {

  return (
    <thead>
      <tr>
        <th className='text-center'>Start Date</th>
        <th className='text-center'>End Date</th>
        <th className='text-center'>Adult</th>
        <th className='text-center'>Children</th>
        <th className='text-center'>Room Type</th>
      </tr>
    </thead>
  )
}
