import React from "react";

import AutoSize from "./AutoSize";

const formatNumber = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
}).format;

function calculateTotal(pieChart) {
  return formatNumber(
    pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0)
  );
}

export default function TooltipTemplate(pieChart, viewTotalValue) {
  const scale = {
    xsm: 10,
    sm: 15,
    md: 25,
    lg: 30,
    xl: 50,
    xxl: 60,
  };

  //const radius = pieChart.getInnerRadius() - 30;

  const fontSize = AutoSize(
    scale.xsm,
    scale.sm,
    scale.md,
    scale.lg,
    scale.xl,
    scale.xxl
  );
  // Adjust this value according to your needs
  const smallerFontSize = fontSize / 3; // Adjust this value according to your needs
  const spaceY = fontSize / 1.8;

  return (
    <svg>
      <text textAnchor="middle" x="100" y="120" style={{ fill: "#494949" }}>
        {viewTotalValue ? (
          <>
            <tspan
              x="100"
              style={{ fontWeight: 600, fontSize: fontSize + "px" }}
            >
              {calculateTotal(pieChart)}%
            </tspan>
            <tspan
              x="100"
              dy={spaceY}
              style={{ fontWeight: 600, fontSize: smallerFontSize + "px" }}
            >
              {"TOTAL"}
            </tspan>
          </>
        ) : null}
      </text>
    </svg>
  );
}
