export const paymentFields = [
  {
    FieldName: "IdCustomerType",
    FieldType: "varchar",
    FieldTitle: "Customer Type",
    cssClasss: "col-3",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "G",
    OptionValues: '[{"id": "C","text": "Customer"},{"id": "G","text": "Guest"},{"id": "O","text": "Other"}]',
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    FieldName: "IdCustomer",
    FieldType: "varchar",
    FieldTitle: "Customer",

    cssClasss: "col-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    FieldName: "Guest",
    FieldType: "varchar",
    FieldTitle: "Guest",
    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    FieldName: "Name",
    FieldType: "varchar",
    FieldTitle: "Name",
    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    FieldName: "IdNCFType",
    FieldType: "varchar",
    FieldTitle: "NCF Type",

    cssClasss: "col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"b0f336a758fe4e570ba9f624cc7b3e6f0c88c76f7c6ecd7b555ac2ad53930c90"}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },

  {
    FieldName: "RNC",
    FieldType: "varchar",
    FieldTitle: "RNC",

    cssClasss: "col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: false,
    ReadOnly: true,
    GroupBy: 0,
  },

  {
    FieldName: "Comment",
    FieldType: "varchar",
    FieldTitle: "Comment",

    cssClasss: "col-12 ",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 2,
  },
];