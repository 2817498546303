import { t } from "i18next";

const SPECIALRATEFIELDS = [
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-12",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Id",
    FieldType: "int",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    FieldName: "DateStart",
    FieldType: "datetime",
    FieldTitle: t("Date Start"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "DateEnd",
    FieldType: "datetime",
    FieldTitle: t("Date End"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    minValueDependOf: "DateStart",
  },

  {
    FieldName: "IdSpecialCode",
    FieldType: "bigint",
    FieldTitle: t("Special Code"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource:
      '{"HashID":"78b1523e6fa39d70f4a0333d0d13722d00025027ceffe056d5677ec1d8b67331"}',

    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Amount",
    FieldType: "money",
    FieldTitle: t("Percentage"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "ActionType",
    FieldType: "char",
    FieldTitle: t("Action Type"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: null,
    DefaultValue: "P",
    OptionValues: `[{"id": "P","text": 
      "Percentage"
    },{"id": "A","text": "Amount"}]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    cssClasss: "col-12",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: 1,
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": 
      "Yes"
    }]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },

  {
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
];
export default SPECIALRATEFIELDS;
