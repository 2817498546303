import React from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Font,
  Margin,
  Title,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import Loading from "../Loading";

const palleteColor = ["#00e800", "#008c00"];

const dataTestVC = [
  { title: "VC Clean", total: 80 },
  { title: "VC Dirty", total: 20 },
];


const RoomCleaningStatusVC = ({ data, viewTotalValue }) => {
  const customizeLabel = (e) => {
    return `${e.valueText}%`;
  };

  const pies = () =>(

      <PieChart
        dataSource={dataTestVC}
        resolveLabelOverlapping="shift"
        palette={palleteColor}
        innerRadius={0.65}
        centerRender={(e) => CenterTemplate(e, viewTotalValue)}
        type="doughnut"
      >
        <Series argumentField="title" valueField="total">
          <Label
            visible={true}
            format="fixedPoint"
            customizeText={customizeLabel}
            backgroundColor="none"
          >
            <Font size={16} />
            <Connector visible={true}></Connector>
          </Label>
        </Series>
        <Legend
          visible={true}
          verticalAlignment="center"
          horizontalAlignment="center"
          itemTextPosition="right"
        >
          <Font size={16} />
        </Legend>
       <Title text="Room Cleaning Status VC" verticalAlignment="top">
        <Font size={24} />
        <Margin top={30} />
      </Title> 
      </PieChart>

  );

  return (
    <div className="bg-white shadow p-3 text-center" style={{ borderRadius: 25 }}>
      {dataTestVC && dataTestVC.length > 0 ? pies() : <Loading />}
    </div>
  );
};

export default RoomCleaningStatusVC;
