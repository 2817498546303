export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

//Get current user
const GetCurrentUser = () => {
  let user = localStorage.getItem("LogedUser");
  return user;
};

export const parsedUser = () => {
  let user = GetCurrentUser();
  let parsed = parseJwt(user);
  return parsed;
};
export default GetCurrentUser;
