import { t } from "i18next";

export const ReservationAvailabiltyFilterFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "StartDate",
    FieldType: "timestamp",
    FieldTitle: t("Start Date"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "EndDate",
    FieldType: "timestamp",
    FieldTitle: t("End Date"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdBusinessUnit",
    FieldType: "bit",
    FieldTitle: t("Room View Detail"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: false,
    DataSource:
      '{"HashID":"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    OptionValues: "",
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];

export const ReservationAvailabilityDataSetNoDetailed = {
  Headers: [
    {
      FieldName: "RoomType",
      FieldType: "varchar",
      FieldTitle: t("Room Type"),
      HtmlType: "text",
      groupIndex: 0,
      FormatValue: "",
    },
    {
      FieldName: "Description",
      FieldType: "varchar",
      FieldTitle: t("Description"),
      HtmlType: "text",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/01/2024",
      FieldType: "int",
      FieldTitle: "1",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/02/2024",
      FieldType: "int",
      FieldTitle: "2",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/03/2024",
      FieldType: "int",
      FieldTitle: "3",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/04/2024",
      FieldType: "int",
      FieldTitle: "4",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/05/2024",
      FieldType: "int",
      FieldTitle: "5",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/06/2024",
      FieldType: "int",
      FieldTitle: "6",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/07/2024",
      FieldType: "int",
      FieldTitle: "7",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/08/2024",
      FieldType: "int",
      FieldTitle: "8",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/09/2024",
      FieldType: "int",
      FieldTitle: "9",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "04/10/2024",
      FieldType: "int",
      FieldTitle: "10",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "Total",
      FieldType: "int",
      FieldTitle: t("Total"),
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
  ],
  Summary: {
    Group: [],
    Total: [],
  },
  Records: [
    {
      "04/01/2024": "40",
      "04/02/2024": "25",
      "04/03/2024": "50",
      "04/04/2024": "50",
      "04/05/2024": "15",
      "04/06/2024": "50",
      "04/07/2024": "20",
      "04/08/2024": "20",
      "04/09/2024": "20",
      "04/10/2024": "30",
      RoomType: "Standard",
      Description: "Sold",
      Total: "320",
    },
    {
      "04/01/2024": "50",
      "04/02/2024": "20",
      "04/03/2024": "50",
      "04/04/2024": "50",
      "04/05/2024": "50",
      "04/06/2024": "50",
      "04/07/2024": "50",
      "04/08/2024": "50",
      "04/09/2024": "50",
      "04/10/2024": "50",
      RoomType: "Standard",
      Description: "Daily inventory",
      Total: "470",
    },
    {
      "04/01/2024": "10",
      "04/02/2024": "-5",
      "04/03/2024": "10",
      "04/04/2024": "10",
      "04/05/2024": "35",
      "04/06/2024": "0",
      "04/07/2024": "30",
      "04/08/2024": "30",
      "04/09/2024": "30",
      "04/10/2024": "20",
      RoomType: "Standard",
      Description: "Rooms available",
      Total: "170",
    },
    {
      "04/01/2024": "80%",
      "04/02/2024": "125%",
      "04/03/2024": "100%",
      "04/04/2024": "100%",
      "04/05/2024": "30%",
      "04/06/2024": "100%",
      "04/07/2024": "40%",
      "04/08/2024": "40%",
      "04/09/2024": "40%",
      "04/10/2024": "60%",
      RoomType: "Standard",
      Description: "Occupancy %",
      Total: "68%",
    },
    {
      "04/01/2024": "30",
      "04/02/2024": "15",
      "04/03/2024": "15",
      "04/04/2024": "15",
      "04/05/2024": "15",
      "04/06/2024": "30",
      "04/07/2024": "15",
      "04/08/2024": "15",
      "04/09/2024": "15",
      "04/10/2024": "15",
      RoomType: "Superior",
      Description: "Sold",
      Total: "180",
    },
    {
      "04/01/2024": "30",
      "04/02/2024": "30",
      "04/03/2024": "30",
      "04/04/2024": "30",
      "04/05/2024": "30",
      "04/06/2024": "30",
      "04/07/2024": "30",
      "04/08/2024": "30",
      "04/09/2024": "30",
      "04/10/2024": "30",
      RoomType: "Superior",
      Description: "Daily inventory",
      Total: "300",
    },
    {
      "04/01/2024": "0",
      "04/02/2024": "15",
      "04/03/2024": "15",
      "04/04/2024": "15",
      "04/05/2024": "15",
      "04/06/2024": "0",
      "04/07/2024": "15",
      "04/08/2024": "15",
      "04/09/2024": "15",
      "04/10/2024": "15",
      RoomType: "Superior",
      Description: "Rooms available",
      Total: "120",
    },
    {
      "04/01/2024": "100%",
      "04/02/2024": "50%",
      "04/03/2024": "50%",
      "04/04/2024": "50%",
      "04/05/2024": "50%",
      "04/06/2024": "100%",
      "04/07/2024": "50%",
      "04/08/2024": "50%",
      "04/09/2024": "50%",
      "04/10/2024": "50%",
      RoomType: "Superior",
      Description: "Occupancy %",
      Total: "60%",
    },
    {
      "04/01/2024": "15",
      "04/02/2024": "20",
      "04/03/2024": "20",
      "04/04/2024": "20",
      "04/05/2024": "10",
      "04/06/2024": "23",
      "04/07/2024": "20",
      "04/08/2024": "7",
      "04/09/2024": "7",
      "04/10/2024": "7",
      RoomType: "Jr. Suite",
      Description: "Sold",
      Total: "149",
    },
    {
      "04/01/2024": "20",
      "04/02/2024": "20",
      "04/03/2024": "20",
      "04/04/2024": "20",
      "04/05/2024": "20",
      "04/06/2024": "20",
      "04/07/2024": "20",
      "04/08/2024": "20",
      "04/09/2024": "20",
      "04/10/2024": "20",
      RoomType: "Jr. Suite",
      Description: "Daily inventory",
      Total: "200",
    },
    {
      "04/01/2024": "5",
      "04/02/2024": "0",
      "04/03/2024": "0",
      "04/04/2024": "0",
      "04/05/2024": "10",
      "04/06/2024": "-3",
      "04/07/2024": "0",
      "04/08/2024": "13",
      "04/09/2024": "13",
      "04/10/2024": "13",
      RoomType: "Jr. Suite",
      Description: "Rooms available",
      Total: "51",
    },
    {
      "04/01/2024": "75%",
      "04/02/2024": "100%",
      "04/03/2024": "100%",
      "04/04/2024": "100%",
      "04/05/2024": "50%",
      "04/06/2024": "115%",
      "04/07/2024": "100%",
      "04/08/2024": "35%",
      "04/09/2024": "35%",
      "04/10/2024": "35%",
      RoomType: "Jr. Suite",
      Description: "Occupancy %",
      Total: "75%",
    },
  ],
};

export const ReservationAvailabilityDataSetDetailed = {
  Headers: [
    {
      FieldName: "RoomType",
      FieldType: "varchar",
      FieldTitle: t("Room Type"),
      HtmlType: "text",
      groupIndex: 0,
      FormatValue: "",
    },
    {
      FieldName: "ViewDetail",
      FieldType: "varchar",
      FieldTitle: t("View Detail"),
      HtmlType: "text",
      groupIndex: 1,
      FormatValue: "",
    },
    {
      FieldName: "Description",
      FieldType: "varchar",
      FieldTitle: t("Description"),
      HtmlType: "text",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "1",
      FieldType: "int",
      FieldTitle: "1",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "2",
      FieldType: "int",
      FieldTitle: "2",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "3",
      FieldType: "int",
      FieldTitle: "3",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "4",
      FieldType: "int",
      FieldTitle: "4",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "5",
      FieldType: "int",
      FieldTitle: "5",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "6",
      FieldType: "int",
      FieldTitle: "6",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "7",
      FieldType: "int",
      FieldTitle: "7",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "8",
      FieldType: "int",
      FieldTitle: "8",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "9",
      FieldType: "int",
      FieldTitle: "9",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "10",
      FieldType: "int",
      FieldTitle: "10",
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
    {
      FieldName: "Total",
      FieldType: "int",
      FieldTitle: t("Total"),
      HtmlType: "number",
      groupIndex: null,
      FormatValue: "",
    },
  ],
  Summary: {
    Group: [],
    Total: [],
  },
  Records: [
    {
      1: "40",
      2: "40",
      3: "40",
      4: "40",
      5: "40",
      6: "40",
      7: "40",
      8: "40",
      9: "40",
      10: "40",
      RoomType: "Standard",
      ViewDetail: null,
      Description: t("Total Sold"),
      Total: "400",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Standard",
      ViewDetail: "OV",
      Description: t("Sold"),
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Standard",
      ViewDetail: "OV",
      Description: t("Avalilable"),
      Total: "80",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Standard",
      ViewDetail: "PV",
      Description: t("Sold"),
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Standard",
      ViewDetail: "PV",
      Description: t("Avalilable"),
      Total: "80",
    },
    {
      1: "50",
      2: "50",
      3: "50",
      4: "50",
      5: "50",
      6: "50",
      7: "50",
      8: "50",
      9: "50",
      10: "50",
      RoomType: "Standard",
      ViewDetail: null,
      Description: t("Daily inventory"),
      Total: "500",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Standard",
      ViewDetail: null,
      Description: t("Rooms available"),
      Total: "100",
    },
    {
      1: "80%",
      2: "80%",
      3: "80%",
      4: "80%",
      5: "80%",
      6: "80%",
      7: "80%",
      8: "80%",
      9: "80%",
      10: "80%",
      RoomType: "Standard",
      ViewDetail: null,
      Description: t("Occupancy") + " %",
      Total: "8",
    },
    {
      1: "40",
      2: "40",
      3: "40",
      4: "40",
      5: "40",
      6: "40",
      7: "40",
      8: "40",
      9: "40",
      10: "40",
      RoomType: "Superior",
      ViewDetail: null,
      Description: t("Total Sold"),
      Total: "400",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Superior",
      ViewDetail: "OV",
      Description: "Sold",
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Superior",
      ViewDetail: "OV",
      Description: "Avalilable",
      Total: "80",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Superior",
      ViewDetail: "PV",
      Description: "Sold",
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Superior",
      ViewDetail: "PV",
      Description: "Avalilable",
      Total: "80",
    },
    {
      1: "50",
      2: "50",
      3: "50",
      4: "50",
      5: "50",
      6: "50",
      7: "50",
      8: "50",
      9: "50",
      10: "50",
      RoomType: "Superior",
      ViewDetail: null,
      Description: "Daily inventory",
      Total: "500",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Superior",
      ViewDetail: null,
      Description: "Rooms available",
      Total: "100",
    },
    {
      1: "80%",
      2: "80%",
      3: "80%",
      4: "80%",
      5: "80%",
      6: "80%",
      7: "80%",
      8: "80%",
      9: "80%",
      10: "80%",
      RoomType: "Superior",
      ViewDetail: null,
      Description: "Ocumancy %",
      Total: "8",
    },
    {
      1: "40",
      2: "40",
      3: "40",
      4: "40",
      5: "40",
      6: "40",
      7: "40",
      8: "40",
      9: "40",
      10: "40",
      RoomType: "Jr. Suite",
      ViewDetail: null,
      Description: "Total Sold",
      Total: "400",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Jr. Suite",
      ViewDetail: "OV",
      Description: "Sold",
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Jr. Suite",
      ViewDetail: "OV",
      Description: "Avalilable",
      Total: "80",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Jr. Suite",
      ViewDetail: "PV",
      Description: "Sold",
      Total: "100",
    },
    {
      1: "8",
      2: "8",
      3: "8",
      4: "8",
      5: "8",
      6: "8",
      7: "8",
      8: "8",
      9: "8",
      10: "8",
      RoomType: "Jr. Suite",
      ViewDetail: "PV",
      Description: "Avalilable",
      Total: "80",
    },
    {
      1: "50",
      2: "50",
      3: "50",
      4: "50",
      5: "50",
      6: "50",
      7: "50",
      8: "50",
      9: "50",
      10: "50",
      RoomType: "Jr. Suite",
      ViewDetail: null,
      Description: "Daily inventory",
      Total: "500",
    },
    {
      1: "10",
      2: "10",
      3: "10",
      4: "10",
      5: "10",
      6: "10",
      7: "10",
      8: "10",
      9: "10",
      10: "10",
      RoomType: "Jr. Suite",
      ViewDetail: null,
      Description: "Rooms available",
      Total: "100",
    },
    {
      1: "80%",
      2: "80%",
      3: "80%",
      4: "80%",
      5: "80%",
      6: "80%",
      7: "80%",
      8: "80%",
      9: "80%",
      10: "80%",
      RoomType: "Jr. Suite",
      ViewDetail: null,
      Description: "Ocumancy %",
      Total: "8",
    },
  ],
};
