import { t } from "i18next";

const GuestInfoSimpleFields = [
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "GUID",
    FieldType: "varchar",
    FieldTitle: t("Guest Type"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",

    Required: false,
    Visible: false,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "",
    FieldType: "bit",
    FieldTitle: t("General Information"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "separator",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "LastName",
    FieldType: "varchar",
    FieldTitle: t("Last Name"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "FirstName",
    FieldType: "varchar",
    FieldTitle: t("First Name"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
  {
    FieldName: "PrimaryGuest",
    FieldType: "bit",
    FieldTitle: t("Is this the principal guest?"),
    Position: 3,
    cssClasss: "col-6 d-flex align-items-baseline gap-2",
    HtmlType: "checkbox",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",

    Required: false,
    Visible: true,
    ReadOnly: false,
    GroupBy: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "IdGuestType",
    FieldType: "bigint",
    FieldTitle: t("Guest Type"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"4d8534f864fae064935e9b1787252d68463e1cd28013ea426fd9a131249bc00e"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    GroupBy: 0,
  },
];

export default GuestInfoSimpleFields;
