import React from 'react'
import { IncidentTrackingContainer } from '../../components'


const IncidentTrackingFoodandBeverage = () => {
  return (
    <>
      <IncidentTrackingContainer routeName={'Food & Beverage / Process / Incident Tracking'}
        module="foodAndBeverage" />
    </>
  )
}

export default IncidentTrackingFoodandBeverage
