import React from "react";
import CustomerTypeContainer from "../components/customerTypes/CustomerTypeContainer";

const CustomerTypePage = (props) => {
  return (
    <>
      <CustomerTypeContainer />
    </>
  );
};

export default CustomerTypePage;
