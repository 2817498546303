import React from "react";
import { Container, Modal } from "react-bootstrap";
import CRUDPageGRIDCustom from "../../pages/crud/CRUDPageGridCustom";

import { useTranslation } from "react-i18next";

const CommentsList = ({ show, toggle, selectedItem }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        backdrop="static"
        keyboard={false}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Comments List")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <CRUDPageGRIDCustom
              getEndpoint="api/Reservations/ReservationsCommentHistoryRecords"
              modelEndpoint="api/Reservations/ReservationsCommentHistoryModel"
              addEditEndpoint="api/Reservations/ReservationsCommentHistoryAddEdit"
              deleteEndpoint="api/Reservations/ReservationsCommentHistoryDelete"
              detailsEndpoint="api/Reservations/ReservationsCommentHistoryDetails"
              headerEndpoint="api/Reservations/ReservationsCommentHistoryHeader"
              ParentGUID={selectedItem.GUID}
              disableEdit={false}
              disableAdd={false}
              Parent={selectedItem}
              ParentField={"GUIDReservation"}
              ParentFieldValue={selectedItem?.GUID}
              ParentFieldType={"string"}
              page={{
                PageIndex: 2,
                PageTitle: t("Comments"),
                PageIcon: "fa-solid fa-file-lines",
                MultiRecord: true,
                Active: true,
              }}
            ></CRUDPageGRIDCustom>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommentsList;
