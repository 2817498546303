import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";

import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import { confirmAlert } from "react-confirm-alert";
import { GenericFields } from "../../group-reservation/GenericFields";

import { useTranslation } from "react-i18next";

const TravelingWith = (props) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const fields = [
    {
      FieldName: "Search",
      FieldType: "varchar",
      FieldTitle: t("Search Reservation Number"),

      cssClasss: "col-10",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,

      Required: false,
      Visible: true,
      ReadOnly: false,
    },
  ];

  const [validationsSchema, setValidationSchema] = useState(null);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState(null);
  const [recordsSearch, setRecordsSearch] = useState([]);
  const [isGroupReservation, setIsGroupReservation] = useState(false);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const toggle = () => {
    setShowAddEdit(!showAddEdit);
  };
  useEffect(() => {
    prepareFormikObject();
  }, []);

  const bindDataSearch = async () => {
    console.log(formik.values);
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&Search=${formik.values.Search}`;
      let prefix = "Reservations";
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.getAction(
        `api/${prefix}/TravelingWithSearch`,
        query
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log(parsedResults);
      let recordResult = parsedResults.JSONData || [];

      setRecordsSearch(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const bindDataRemote = useCallback(
    async (cleanFilter) => {
      //First load records
      try {
        let query = `IdUser=${user.IdUser}&token=${user.Token}&ParentGUID=${props.selectedReservation.GUID}`;
        let prefix = "Reservations";
        // let prefix = idModule ? "CRUDDynamic" : "CRUD";
        let request = await API.getAction(
          `api/${prefix}/TravelingWithRecords`,
          query
        );
        let results = request.data[0];
        let parsedResults = JSON.parse(results.JSONData)[0];
        // console.log(parsedResults)
        let recordResult =
          (Array.isArray(parsedResults.JSONData)
            ? parsedResults.JSONData
            : [parsedResults.JSONData]) || [];
        console.log("🚀 ~ recordResult:", recordResult);
        if (recordResult[0].Type === "G") {
          setIsGroupReservation(true);
        } else {
          setIsGroupReservation(false);
        }

        setRecords(recordResult);
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    [props.selectedReservation]
  );

  useEffect(() => {
    console.log("🚀 ~ records:", records);
  }, [records]);

  const handleSubmit = async (obj) => {};

  const saveRelationship = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}&GUID=${props.selectedReservation.GUID}&GUIDParent=${recordsSearch[0].GUID}`;
    let prefix = "Reservations";
    await API.getAction(`api/${prefix}/TravelingWithAdd`, query);
    toggle();
    setRecordsSearch([]);
    formik.setFieldValue("Search", "");
    await bindDataRemote();
  };

  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `IdUser=${user.IdUser}&token=${user.Token}&GUID=${record.GUID}`;
              await API.getAction(
                `api/reservations/TravelingWithDelete`,
                query
              );
              await bindDataRemote();
              setRecord(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  const filterFields = () =>
    fields &&
    fields.filter(
      (x) =>
        x.FieldTitle !== "Audit Information" &&
        x.FieldName !== "Id" &&
        x.FieldName !== "IdCustomer" &&
        x.FieldName !== "DateAdd" &&
        x.FieldName !== "File" &&
        x.FieldName !== "DateMod"
    );

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const reservationColor = (item) => {
    console.log("🚀 ~ reservationColor ~ item:", item);
    return {
      backgroundColor: item.ReservationStatusBackgroundColor || "gray",
      color: item.ReservationStatusTextColor,
    };
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  useEffect(() => {
    if (props.selectedReservation) {
      bindDataRemote();
    }
  }, [props.selectedReservation]);

  return (
    <>
      {showAddEdit ? (
        <Modal
          show={showAddEdit}
          onHide={() => toggle()}
          backdrop="static"
          keyboard={false}
          size={props.modalSize ?? "md"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Traveling With")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container fluid className="overflow-auto">
              <Row className="mb-4 mt-2">
                <GenericFields
                  section={null}
                  updateFields={updateField}
                  fields={filterFields()}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  customLabel={null}
                />

                <Col xs={2} className="mt-4">
                  <button
                    className="btn btn-secondary mt-3"
                    onClick={() => bindDataSearch()}
                  >
                    {t("Search")}
                  </button>
                </Col>
                <Col xs={12} className="mt-3">
                  <Card>
                    <Card.Body>
                      <table className="table table-striped mt-4">
                        <thead>
                          <tr>
                            {/* <td>Reservation</td> */}
                            <td>{t("Room")}</td>
                            <td>{t("First Name")}</td>
                            <td>{t("Last Name")}</td>
                          </tr>
                        </thead>
                        <tbody>
                          {recordsSearch && recordsSearch.length > 0 ? (
                            recordsSearch
                              .filter((x) => x !== null)
                              .map((item, index) => {
                                console.log(item);
                                return (
                                  <tr key={index}>
                                    {/* <td>{item.ReservationNumber}</td> */}
                                    <td>{item.room}</td>
                                    <td>{item.FirstName}</td>
                                    <td>{item.LastName}</td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td className="border-1 text-center" colSpan={12}>
                                {t("No Records Found")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {generalError ? (
                <Row>
                  <Col xs={12}>
                    <div className="alert alert-danger" role="alert">
                      {generalError}
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gx-3 align-items-center justify-content-end">
              {Object.values(formik.errors).length > 0 && (
                <span className="invalid me-2">
                  {t("Please check the forms for errors")}
                </span>
              )}
              <button
                className="btn me-2"
                type="button"
                onClick={() => toggle(null)}
              >
                {t("Close")}
              </button>
              <button
                className="btn btn-primary me-2"
                type="button"
                onClick={() => saveRelationship()}
                disabled={recordsSearch.length === 0}
              >
                {t("Add")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Row className="mx-0">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Row className="mt-4 h-100">
                <Col md={11}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("Reservation Number")}</th>
                        <th>{t("Room")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? (
                        records
                          .filter((x) => x !== null)
                          .map((item, index) => {
                            // console.log(item)
                            return (
                              <tr
                                key={index}
                                className={`clickable ${
                                  record && item.GUID === record?.GUID
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setRecord(item);
                                }}
                              >
                                <td>{item.ReservationNumber}</td>
                                <td>{item.Room}</td>
                                <td>
                                  <span
                                    className="badge w-100"
                                    style={reservationColor(item)}
                                  >
                                    {item.Status}
                                  </span>
                                </td>
                                <td>{`${item.FirstName} ${item.LastName}`}</td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td className="border-1 text-center" colSpan={4}>
                            {t("No Records Found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
                <Col md={1}>
                  <div className="d-flex flex-column gap-2 align-content-center align-items-baseline">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        toggle();
                      }}
                      type="button"
                      disabled={props.actionMode === "VIEW"}
                    >
                      <i className="fa-solid fa-plus" />
                    </button>
                    {record && !isGroupReservation ? (
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => deleteConfirm()}
                        type="button"
                        disabled={props.actionMode === "VIEW"}
                      >
                        <i className="fa-solid fa-trash" />
                      </button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TravelingWith;
