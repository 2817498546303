import React, { useEffect, useState } from "react";

import {
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Carousel,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logoHotel from "../../assets/logo-clt.png";
import logo from "../../assets/auth-logo-dark.png";
import logoSignos from "../../assets/SignosFrameworkLogo.png";

import img01 from "../../assets/bgLogin01.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import ROUTES from "../../utils/routes";
import { useAuth } from "../../contexts/auth";
import { useFormik } from "formik";
import * as yup from "yup";
import Sha256 from "../../utils/sha256";

import { v4 as uuidv4 } from "uuid";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { db } from "../../utils/db";

import { useTranslation } from "react-i18next";

const LoginForm = (props) => {
  const { t } = useTranslation();

  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    UI: {
      validated: false,
      Error: null,
    },
  });

  const validationSchema = yup.object({
    username: yup.string().required(t("Required")),
    password: yup.string().required(t("Required")),
  });

  useEffect(() => {
    // ClearUser();
  }, []);

  const handleSubmit = async (event) => {
    try {
      let user = Sha256.hash(formik.values.username.toUpperCase());
      let passwordParsed = Sha256.hash(
        formik.values.username.toUpperCase() + formik.values.password
      );
      let query = {
        username: user,
        password: (uuidv4() + passwordParsed + uuidv4()).replace("-", ""),
      };
      setIsLoading(true);
      var request = await API.postAction("api/account/canlogin", query);

      console.log({ request });
      if (request.data.status === "error") {
        alert(request.data.response);
        return;
      }
      localStorage.setItem("LogedUser", request.data.response);
      let theUser = parsedUser();
      let token = theUser.Token;

      //Now we need to request the menu the user has access to.
      let queryMenu = "Token=" + token;
      //  var requestMenu = await API.getAction("api/menu", queryMenu);
      var requestMenu = await API.getAction("api/menu/GetV1", queryMenu);
      console.log({ requestMenu });
      let final = null;

      var parsedMenu = JSON.parse(requestMenu.data[0].JSONData);
      final = parsedMenu[0].JSONData;
      console.log("the final menu ", final.Menu);

      //Now let's save the record
      await db.menu.put(
        formik.values.username.toUpperCase() === "CONTRACTDEMO"
          ? final.Menu.filter((x) => x.text === "Rates Management")
          : final.Menu,
        "menu"
      );

      //Now let's create the favorites record if the user doesn't have any

      let hasFavorites = await db.favorites
        .where("username")
        .equals(theUser.Username)
        .first();
      if (!hasFavorites)
        await db.favorites.add({ username: theUser.Username, values: [] });

      window.location = ROUTES.DefaultWithSideBar.Path;
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  const isDev = false; //window.location.href.includes('localhost') || window.location.href.includes('dev.')
  return (
    <main>
      <section className="d-flex align-items-center p-0 m-0">
        <Container className="full p-0">
          <Row>
            <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center justify-content-center p-0 m-0 bg-white ">
              <div className=" p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  {}
                  <img
                    src={isDev ? logoSignos : logoHotel}
                    alt=""
                    className="img-fluid mb-4"
                    style={{ maxWidth: "90%" }}
                  />
                  <br />
                  {isDev ? null : (
                    <img
                      src={logo}
                      alt=""
                      className="img-fluid mb-4"
                      style={{ maxWidth: "40%" }}
                    />
                  )}
                  {state.UI.Error !== null ? (
                    <small className=" text-danger">
                      {t("Invalid username or password")}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <Form className="mt-4" onSubmit={formik.handleSubmit}>
                  <Form.Group id="Username" className="mb-4">
                    <Form.Label>{t("Username")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        className="text-uppercase"
                        required
                        type="text"
                        placeholder={t("Username")}
                        id="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                      />
                    </InputGroup>
                    {formik.errors.username ? (
                      <div className="text-danger text-sm">
                        <br />
                        {formik.errors.username}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>{t("Password")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder={t("Password")}
                          id="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.password ? (
                        <div className="text-danger text-sm">
                          <br />
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4 text-right">
                      {/* <Card.Link
                        className="small text-end"
                        as={Link}
                        // to={Routes.ForgotPassword.path}
                      >
                        Olvidó su contraseña?
                      </Card.Link> */}
                    </div>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm me-4"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    {t("Sign In")}
                  </Button>
                </Form>

                <hr className="mt-4" />
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    {t("Forgot password?")}
                    <Card.Link
                      as={Link}
                      // to={Routes.Register.path}
                      className="fw-bold btn btn-link"
                    >
                      {t("Reset Password")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 d-none d-md-block SliderContainer p-0">
              <Carousel>
                <Carousel.Item
                  style={{ backgroundImage: `url('${img01}')` }}
                ></Carousel.Item>
              </Carousel>
            </div>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default LoginForm;
