import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import GuestInfoSimpleFields from "../fields/GuestInfoSimpleFields";

import { useTranslation } from "react-i18next";
import { RequestHash } from "./RequestHash";

const GuestInfoSimpleAddEdit = (props) => {
  const { t } = useTranslation();

  const fields = GuestInfoSimpleFields;
  const toast = useToast();
  const user = parsedUser();

  const [selectedRecord, setSelectedRecord] = useState(null);
  const selectedReservation = props.selectedReservation;
  const [generalError, setGeneralError] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [primaryGuest, setPrimaryGuest] = useState(null);
  const [currentGuest, setCurrentGuest] = useState(null);

  const updateField = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue);
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    console.log(
      "Guest info has the following reservation: ",
      props.selectedReservation
    );

    console.log(
      "Guest info has the following reservation: ",
      props.selectedReservation
    );
    const primaryGuest = props.currentGuestList.find(
      (person) => person.PrimaryGuest === true
    );
    const currentGuest = props.currentGuestList.find(
      (person) => person?.GUID === props.selectedGuest?.GUID
    );

    // const primaryGuest = props.currentGuestList.find(
    //   (person) => person.PrimaryGuest === true
    // );
    // const currentGuest = props.currentGuestList.find(
    //   (person) => person?.GUID === props.selectedGuest?.GUID
    // );
    // console.log({primaryGuest})
    // console.log({currentGuest})
    // console.log(primaryGuest?.GUID === currentGuest?.GUID)
    // console.log({actionMode})
    // setValidatePrimaryGuest(primaryGuest?.GUID === currentGuest?.GUID)
    setActionMode(props.actionMode);
    setCurrentGuest(currentGuest);
    setPrimaryGuest(primaryGuest);
  }, []);

  const [validationsSchema, setValidationSchema] = useState(null);

  const guestType = async (guestTypeSelectedId) => {
    let children = ["Child 1", "Child 2", "Child 3"];

    let guestType = await RequestHash(
      "4d8534f864fae064935e9b1787252d68463e1cd28013ea426fd9a131249bc00e",
      "JSONData",
      true
    );

    let selectedGuestType = guestType.find(
      (item) => item.Id === guestTypeSelectedId
    );

    let status = children.includes(selectedGuestType.Description);

    return status;
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    // console.log("object to save", obj);

    let validatedChildren = await guestType(obj.IdGuestType);

    if (validatedChildren) {
      toast({
        type: "error",
        message: t("A child cannot be a primary guest"),
      });
      return;
    } else if (actionMode === "I") {
      if (primaryGuest?.GUID && obj.PrimaryGuest) {
        toast({
          type: "error",
          message: t("There is already a primary guest for this reservation"),
        });
        return;
      }
    } else if (actionMode === "U" && primaryGuest) {
      const isNotValid = primaryGuest?.GUID !== currentGuest?.GUID;

      if (isNotValid && obj.PrimaryGuest) {
        toast({
          type: "error",
          message: t("There is already a primary guest for this reservation"),
        });
        return;
      }
    }

    try {
      let objSPVersion = "";
      if (actionMode === "U") {
        // console.log(selectedRecord);
        debugger;
        obj.GUID = selectedRecord.GUID; //selectedRecord.GUIDReservationToGuests;
        obj.Active = true;
      }
      fields.forEach((item) => {
        if (
          item.ReadOnly ||
          item.HtmlType === "separator" ||
          item.FieldName === "IdCustomer" ||
          item.FieldName === "IdContract"
        )
          return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });
      if (actionMode === "U") {
      }
      if (!objSPVersion.includes("@GUIDReservation")) {
        objSPVersion += `, @GUIDReservation='${selectedReservation.GUID}'`;
      }
      if (props.ParentField) {
        console.log(props);
        if (props.ParentFieldType === "int") {
          objSPVersion += `, @${props.ParentField}=${props.ParentFieldValue}`;
        } else {
          objSPVersion += `, @${props.ParentField}='${props.ParentFieldValue}'`;
        }
      }

      //   objSPVersion += ", @IdReservation=" + props.selectedReservation.Id;
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}&ParentGUID=${props.selectedReservation.GUID}`;

      let queryData = {
        Data: objSPVersion,
      };
      console.log(objSPVersion);
      let request = await API.postAction(
        `api/reservations/ReservationsGuestAddEdit?${queryString}`,
        queryData
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };
  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };
  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  //Bring all the details for the selected record
  const bindDataDetailsRemote = async (item) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${item.GUID}&ParentGUID=${props.selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/Reservations/ReservationsGuestDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      console.log(recordResult);

      //  setSelectedRecord(recordResult);
      fillFormikObject(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    console.log(props);
    if (props.currentGuestList.length === 0) {
      updateField("PrimaryGuest", 1);
    }
    setSelectedRecord(props.selectedGuest);
    setActionMode(props.actionMode);
  }, [props.selectedGuest, props.actionMode]);
  useEffect(() => {
    if (selectedRecord) {
      bindDataDetailsRemote(props.selectedGuest);
    }
  }, [selectedRecord]);
  // useEffect(() => {
  //   if (formik.values) {
  //     setTimeout(() => {
  //       checkForExistingGuest();
  //     }, 1000);
  //   }
  // }, [formik.values]);

  useEffect(() => {
    prepareFormikObject();
  }, []);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "md"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Guest Information")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Container fluid className="overflow-auto">
            <Row className=" mb-4 mt-2">
              <Col xs={12}>
                <Row>
                  {fields &&
                    fields
                      .filter(
                        (x) =>
                          x.FieldTitle !== "Audit Information" &&
                          x.FieldName !== "Id" &&
                          x.FieldName !== "IdCustomer" &&
                          x.FieldName !== "DateAdd" &&
                          x.FieldName !== "File" &&
                          x.FieldName !== "DateMod"
                      )
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={`col-${item.FieldName}-${index}`}
                          >
                            <label className="me-2 mt-2">
                              {t(item.FieldTitle)}
                              {item.Required ? (
                                <i className="required-asterisk ms-1 text-danger">
                                  *
                                </i>
                              ) : null}
                            </label>
                            <br />

                            <FieldSelector
                              actionMode={actionMode}
                              model={item}
                              obj={formik.values}
                              key={`field-${item.FieldName}`}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            ></FieldSelector>
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm my-1">
                                {formik.errors[item.FieldName]}
                              </div>
                            ) : null}
                          </Col>
                        );
                      })}
                </Row>
              </Col>
            </Row>

            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            {Object.values(formik.errors).length > 0 && (
              <span className="invalid me-2">
                {t("Please check the forms for errors")}
              </span>
            )}
            <button className="btn me-2" type="button" onClick={props.toggle}>
              {t("Close")}
            </button>
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={() => [formik.submitForm()]}
            >
              {t("Save")}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GuestInfoSimpleAddEdit;
