import { t } from "i18next";

const CommentHistoryFields = [
  {
    IdAppForm: 63,
    Id: 6300003,
    FieldName: "IdCommentType",
    FieldType: "bigint",
    FieldTitle: t("Comment Type"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"742569e0cbb969dacce83b236db6e01493e1474d1f613368346782d07ef0e7c4"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 63,
    Id: 6300004,
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    Position: 4,
    cssClasss: "col-12",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
];

export default CommentHistoryFields;
