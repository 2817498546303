import React, { useEffect, useState } from "react";

import Chart, {
  Legend,
  Series,
  ValueAxis,
  Label,
  Title,
  Font,
  Margin,
  Tick,
} from "devextreme-react/chart";
import { LoadingIndicator } from "devextreme-react/pie-chart";
import Loading from "../Loading";

const dataTest = [
  { Day: "Monday", total: 15 },
  { Day: "Tuesday", total: 25 },
  // Agrega más objetos según sea necesario
];

const DailyIncidents = ({ data, title }) => {
  
  const dataTestTotal = () => {
    const valor = dataTest.reduce((accum, item) => accum + item.total, 0);

    const nameTotal = title ? "Total Request" : "Total Incidents";

    return  valor > 0 ? nameTotal +": " + valor : nameTotal + ": - -";
  }

  console.log(data);
  const pies = () => (
    <>
      <Chart dataSource={data} rotated={true}>
        <ValueAxis>
          <Tick visible={true} />
          <Label visible={true} />
        </ValueAxis>

        <Series
          valueField="total"
          argumentField="Day"
          name={
            dataTestTotal()
          }
          type="bar"
          color="#42AAFF"
        >
          <Label visible={true} />
          <Font size={24} />
        </Series>

        <Legend
          visible={true}
          verticalAlignment="center"
          horizontalAlignment="center"
          itemTextPosition="right"
        >
          <Font size={12} />
        </Legend>

        <Title
          text={title ? title : "Daily Incidents"}
          horizontalAlignment="center"
          verticalAlignment="top"
        >
          <Font size={24} />
          <Margin top={25} />
        </Title>
      </Chart>
    </>
  );
  return (
    <>
      <div
        className="bg-white p-3 shadow text-center"
        style={{ borderRadius: 25 }}
      >
        {/* {data && data.length > 0 ? pies() : <Loading />} */}
        {pies()}
      </div>
    </>
  );
};

export default DailyIncidents;
