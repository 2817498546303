import { t } from "i18next";

export const GroupReservationTableFields = [
  {
    FieldName: "ReservationGroupNumber",
    FieldTitle: t("Res No."),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "GroupName",
    FieldTitle: t("Group Name"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "ArrivalDate",
    FieldTitle: t("Arrival"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "DepartureDate",
    FieldTitle: t("Departure"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Customer",
    FieldTitle: t("Customer"),
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "Status",
    FieldTitle: t("Status"),
    FieldType: "varchar",
    HtmlType: "text",
  },
];
