import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

/**
 * Export the content of an HTML element to a PDF file.
 * @param {HTMLElement} container - The HTML element whose content you want to export.
 */
export const exportToPDF = async (container, orientation = "landscape", filename = 'data') => {
  try {
    if (!container) {
      throw new Error("Container element is missing.");
    }

    // Get the dimensions of the container
    const containerHeight = container.clientHeight;
    const containerWidth = container.clientWidth;
    const ratio = containerHeight / containerWidth;

    // Use html2canvas to capture the content in the container
    const canvas = await html2canvas(
      container,
      { 
        scale: 4,
       } // Increase the scale of the canvas for better quality
    );

    // Create a new PDF document in landscape orientation
    const pdf = new jsPDF(orientation);

    // Calculate the height of the PDF based on the aspect ratio
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdfWidth * ratio;

    // Add the captured image to the PDF
    const imgData = canvas.toDataURL("image/jpeg");
    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    // Save the PDF with a specific filename
    pdf.save(`${filename}.pdf`);
    // window.open(pdf.output("bloburl"));

    // Log a success message if the export is successful
    console.log("PDF file 'data.pdf' exported successfully.");
  } catch (error) {
    // Log an error message if there's an issue during export
    console.error("Error exporting to PDF:", error);
  }
};

export const exportPageToPDF = async () => {
  let doc = new jsPDF('p', 'px', 'letter');
  var htmlElement = document.getElementById('ContractContainer');
  // you need to load html2canvas (and dompurify if you pass a string to html)
  const opt = {
      callback: function (jsPdfObj) {
          // jsPdf.save("Test.pdf");
          // to open the generated PDF in browser window
          window.open(jsPdfObj.output('bloburl'));
      },
      margin: [2, 2, 2, 2],
      autoPaging: true,
      html2canvas: {
          allowTaint: true,
          dpi: 600,
          letterRendering: true,
          logging: false,
          scale: 1
      }
  };

  doc.html(htmlElement, opt);
};

export const exportPageToPDF2 = async (container, orientation = "portrait") => {
  try {
    if (!container) {
      throw new Error("Container element is missing.");
    }

    // Get the dimensions of the container
    const containerHeight = container.clientHeight;
    const containerWidth = container.clientWidth;
    const ratio = containerHeight / containerWidth;

    // Use html2canvas to capture the content in the container
    const canvas = await html2canvas(
      container,
      { 
        scale: 4,
       } // Increase the scale of the canvas for better quality
    );

    // Create a new PDF document in landscape orientation
    const pdf = new jsPDF(orientation);

    // Calculate the height of the PDF based on the aspect ratio
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdfWidth * ratio;

    // Add the captured image to the PDF
    const imgData = canvas.toDataURL("image/jpeg");
    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    // Save the PDF with a specific filename
    // pdf.save("data.pdf");
    window.open(pdf.output("bloburl"));

    // Log a success message if the export is successful
    console.log("PDF file 'data.pdf' exported successfully.");
  } catch (error) {
    // Log an error message if there's an issue during export
    console.error("Error exporting to PDF:", error);
  }
}