export const RestaurantReservationInfoFields = [
  {
    IdAppForm: 2,
    Id: 1,
    FieldName: "ReservationNumber",
    FieldType: "varchar",
    FieldTitle: "Restaurant Reservation No.",
    Position: 1,
    cssClasss: "col-md-3 col-6 mt-0",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 2,
  },
  {
    IdAppForm: 1,
    Id: 0,
    FieldName: "ReservationState",
    FieldType: "varchar",
    FieldTitle: "Reservation State",
    Position: 1,
    cssClasss: "col-md-3 col-6 mt-0",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 2,
  },
];
