import { PrintOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Table } from "react-bootstrap";
import API from "../../api/api";
import logo from "../../assets/LHVR.jpg";
import { parsedUser } from "../../utils/GetCurrentUser";
import { exportToPDF } from "../../utils/export-to-pdf";
import { dateFormater } from "../../utils/date-formater";

const PrintRestaurantReservation = (props) => {
  const user = parsedUser();
  const { show, handleClose } = props;
  const [showReport, setShowReport] = useState(true);
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("props.GUIDReservation 🚀🚀🚀", props.GUIDReservation);
  }, [props.GUIDReservation]);

  const bindDataRemote = async () => {
    setIsLoading(true);

    try {
      let reservation = props.GUIDReservation;
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${
        reservation ?? ""
      }`;
      let request = await API.getAction(
        `/api/RestaurantReservation/Print`,
        query
      );
      let results = await request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      let recordResult = parsedResults || [];
      console.log("🚀 ~ bindDataRemote ~ recordResult:", recordResult.JSONData);
      setRecord(recordResult.JSONData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
    return () => setShowReport(false);
  }, []);

  useEffect(() => {
    if (record) {
      setShowReport(true);
    }
    return () => setShowReport(false);
  }, [record]);

  const generatePDF = () => {
    const container = document.getElementById("contentContainer");
    exportToPDF(container, "portrait");
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
      // dialogClassName="modal-90w"
      className="z-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-2">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <h3>Loading, please wait...</h3>
            </div>
          ) : (
            <div className="d-flex justify-content-center flex-column ">
              {showReport && record ? (
                <>
                  <div
                    className="d-flex justify-content-start"
                    style={{ width: "auto", minWidth: "350px" }}
                  >
                    <button
                      className={"btn btn-secondary mx-2 mb-2"}
                      onClick={() => generatePDF()}
                      disabled={isLoading}
                    >
                      <PrintOutlined /> PDF
                    </button>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#fcfeff",
                      paddingRight: "7rem",
                      paddingLeft: "7rem",
                      width: "auto",
                      minWidth: "350px",
                      paddingTop: "4rem",
                      paddingBottom: "4rem",
                    }}
                    className="mx-3"
                    id="contentContainer"
                  >
                    <Row className="pt-2">
                      <h5 className="text-center fw-bold">
                        LIFESTYLE HOLIDAYS
                      </h5>
                      <h5 className="text-center fw-bold">
                        {record?.Restaurant}
                      </h5>
                    </Row>
                    <Row>
                      <Table borderless className="w-50">
                        <tbody>
                          <tr>
                            <th>Res. #</th>
                            <td>{record?.Id || "#"}</td>
                            {/* <td>{'IN83830'}</td> */}
                          </tr>
                          <tr>
                            <th>Date:</th>
                            <td>
                              {record?.ReservationDate &&
                                dateFormater(record?.ReservationDate)}
                            </td>
                          </tr>
                          <tr>
                            <th>Time:</th>
                            <td>{record?.Hours || "-:--"}</td>
                          </tr>
                          <tr>
                            <th>Room #</th>
                            <td>{record?.Rooms || "-"}</td>
                          </tr>
                          <tr>
                            <th>Name:</th>
                            <td>{record?.Name || "-"}</td>
                          </tr>
                          <tr>
                            <th>Total Pax:</th>
                            <td>{record?.PaxQuantity || "-"}</td>
                          </tr>
                          <tr>
                            <th>Adults:</th>
                            <td>{record?.AdultQuantity || "0"}</td>
                          </tr>
                          <tr>
                            <th>Children 1:</th>
                            <td>{record?.Child1Quantity || "0"}</td>
                          </tr>
                          <tr>
                            <th>Children 2:</th>
                            <td>{record?.Child2Quantity || "0"}</td>
                          </tr>
                          <tr>
                            <th>Chidlren 3:</th>
                            <td>{record?.Child3Quantity || "0"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <p className="text-center">{record?.TicketNote}</p>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <h1>No Records Found</h1>
                </div>
              )}
            </div>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => handleClose(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintRestaurantReservation;
