import React, { useState } from 'react'
import { CustomBreadcrumbs as Breadcrumbs, IncidentTrackingContainer } from '../../components'


const IncidentTrackingPage = () => {



  return (<IncidentTrackingContainer routeName={'Guest Complaint / Process / Incident Tracking'} module={'guestComplain'} />

  )
}

export default IncidentTrackingPage