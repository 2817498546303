import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import API from "../../../api/api";
import { CleanExtension } from "../../../utils/CleanExtencion";

import { useTranslation } from "react-i18next";

const LogFileDetails = ({ show, handleClose, selectedLog }) => {
  const { t } = useTranslation();

  const [logFile, setLogFile] = useState(null);
  const RequestLogFile = async () => {
    try {
      let requestAPI = await API.getAction(
        `/api/TestSP/LogFile?id=${selectedLog}`,
        null
      );

      if (requestAPI.status === 200) {
        setLogFile(requestAPI.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadLogFile = () => {
    const elementoA = document.createElement("a");
    const archivo = new Blob([logFile], { type: "text/plain" });
    elementoA.href = URL.createObjectURL(archivo);
    elementoA.download = selectedLog;
    document.body.appendChild(elementoA);
    elementoA.click();
    document.body.removeChild(elementoA);
  };

  useEffect(() => {
    RequestLogFile();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modalRight"
        dialogClassName="modal-90w"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Reading Log:")}{" "}
            {selectedLog ? CleanExtension(selectedLog, ".txt") : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="overflow-auto">
            <pre>{logFile}</pre>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="lg"
            onClick={downloadLogFile}
            disabled={!logFile}
          >
            {t("Download Log")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogFileDetails;
