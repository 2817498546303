import React, { Fragment, useState } from "react";
import RoomOccupation from "../../components/reservations/roomOccupation";
import { CustomBreadcrumbs as Breadcrumbs } from '../../components'
import { Card, Col, Container, Row } from 'react-bootstrap'
import useFavorite from "../../hooks/useFavorite";
import { useLocation } from "react-router-dom";
import { ReservationsColorMap, RoomsColorMap, getReservationColorStatus, getRoomColorStatus } from "../../components/reservations/room-status-util";

const RoomOccupationPage = (props) => {
  const { pathname } = useLocation();
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const addFavorite = async () => {
    const newFavorite = {
      icon: 'fa-solid fa-home',
      path: pathname,
      formPath: 'Front-Desk / Front-Desk / Room Occupation',
      title: 'Room Ocupation',
    }
    UpdateFavorites(newFavorite)
  }

  return (
    <React.Fragment>
      <Breadcrumbs pathTitle={'Front-Desk / Front-Desk / Room Occupation'} />
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Room Occupation</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">

                {/* {actionMode === null ? (
                  <>
                    <button className="btn btn-lg my-1 me-2" title="Export to PDF" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-file-pdf"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Export to Excel" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-file-excel"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send email" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-envelope"></i>
                    </button>
                    <button className="btn btn-lg my-1 me-2" title="Send to print" disabled={isLoading} onClick={() => { }}>
                      <i class="fa-solid fa-print"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-sm  my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null} */}
              </div>
            </div>
            <RoomOccupation />
            <Card>
            <Card.Body>
            <Row>
              <Col >
            <div className='border-0 border-end pe-4'>
              <h5 className="fw-bold">Rooms</h5>
              <div className='d-flex justify-content-between align-items-center'>
                {Object.keys(RoomsColorMap).map( item => {
                  return <><div className="d-flex"><div class="rounded me-1" style={{ height: '20px', width: '20px',backgroundColor: getRoomColorStatus(item) }} /><span className="me-3" style={{fontSize: '1rem'}}>{item}</span></div></>
                })}
              </div>
            </div>
            </Col>
            <Col >
            <div className=''>
              <h5 className="fw-bold">Reservations</h5>
              <div className='d-flex justify-content-between align-items-center'>
              {Object.keys(ReservationsColorMap).map( item => {
                  return <><div className="d-flex"><div class="rounded me-1" style={{ height: '20px', width: '20px',backgroundColor: getReservationColorStatus(item) }} /><span className="me-3" style={{fontSize: '1rem'}}>{item}</span></div></>
                })}
              </div>
            </div>
            </Col>
            </Row>
            </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default RoomOccupationPage;