// db.js
import Dexie from "dexie";

export const db = new Dexie("pms_db");
db.version(1).stores({
  preferences: "++id",
  menu: "++id",


});

db.version(2).stores({
  preferences: "++id",
  menu: "++id",
  favorites: "++id, &username",
})

db.version(3).stores({
  preferences: "++id",
  menu: "++id",
  favorites: "++id, &username",
  contracts: "GUID",
  
})