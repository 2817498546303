import moment from 'moment';
import React, { useEffect, useState } from 'react'

const Timer = ({dateExecution, dateCreation, updateKey}) => {
     const [time, setCurrentTime] = useState(moment());
  const dateDead = new Date(dateCreation);
  const [timer, setTimer] = useState({
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const count = () => {
    setCurrentTime(moment());

    const totalYears = moment(time).diff(dateDead, "years");
    const totalMonths = moment(time).diff(dateDead, "months");
    const totalDays = moment(time).diff(dateDead, "days", true);
    const totalWeeks = moment(time).diff(dateDead, "weeks", true);
    const totalWeeksToWeeks = moment(time).date();

    setTimer({
      years: totalYears,
      months: totalMonths - totalYears * 12,
      weeks: Math.ceil(totalWeeksToWeeks / 7),
      days: Math.round((totalDays % 7) - Math.floor(totalWeeks / 7)),
      hours: moment(time).diff(dateDead, "hours") % 24,
      minutes: moment(time).diff(dateDead, "minutes") % 60,
      seconds: moment(time).diff(dateDead, "seconds") % 60,
    });
  };

  /* useEffect(() => {
    const timer = setInterval(() => {
      count();
    }, 1000);
    return () => clearInterval(timer);
  }, [time]); */

  useEffect(() => {
    count();
  }, [updateKey])
  

  const validatedTmer = ()=>{
    
    let newTime = null;
    if(timer.hours > 0){
      
      newTime = timer.hours + "hrs"
    }
    else if(timer.hours < 1 && timer.minutes > 0){
        newTime = timer.minutes + "min"
    }
    else if(timer.hours < 1 && timer.minutes < 1 && timer.seconds > 0){
      newTime = timer.seconds + "seg"
    }
    else{
        newTime = "0 seg"
    }

    return newTime
  }

  return (<>{validatedTmer()}</>)
}

export default Timer