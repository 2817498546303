import { utils as SheetUtils, writeFile as writeExcel } from "xlsx";

/**
 * Export data to an Excel (.xlsx) file.
 * @param {Array} dataRow - The data to be exported in an array of objects.
 * @param {string} title - The title for the Excel file (used for the filename).
 */
export const exportToExcel = async (dataRow = [], title = '') => {

  // Check if there's data to export
  if (dataRow.length === 0) {
    console.error("No data to export to Excel.");
    return;
  }

  // Convert the data to an Excel worksheet
  const worksheet = SheetUtils.json_to_sheet(dataRow);

  // Create a new Excel workbook
  const workbook = SheetUtils.book_new();

  // Add the worksheet to the workbook (in a sheet named "Sheet1")
  SheetUtils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Define the Excel file name based on the specified title
  const excelFileName = `${title}.xlsx`;

  try {
    // Write the workbook data to an Excel file
    writeExcel(workbook, excelFileName);

    // Log a success message if the export is successful
    console.log(`Excel file "${excelFileName}" exported successfully.`);
  } catch (error) {
    // Log an error message if there's an issue during export
    console.error(`Error when exporting to Excel: ${error}`);
  }
};
