import React, { useEffect, useState } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Title,
  Tooltip,
  Font,
  Margin,
} from "devextreme-react/chart";
import { Card, Col, Container, Modal, Row, ProgressBar } from "react-bootstrap";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import moment from "moment";

const dataTest = [
  { name: "Restaurant I", reserved: 150, availability: 305 },
  { name: "Restaurant II", reserved: 200, availability: 205 },
  { name: "Restaurant III", reserved: 100, availability: 200 },
  { name: "Restaurant IV", reserved: 190, availability: 40 },
  { name: "Restaurant V", reserved: 305, availability: 100 },
  { name: "Restaurant VI", reserved: 150, availability: 150 },
  { name: "Restaurant VII", reserved: 80, availability: 300 },
  // Agrega más objetos según sea necesario
];

const Graphic = ({ data, title }) => {
  const [processedData, setProcessedData] = useState([])
  console.log(data)
  const customizeTooltip = (arg) => {
    return {
      text: `${arg.seriesName} years: ${arg.valueText}`,
    };
  };

  useEffect(() => {
    if (data) {
      let r = data.map(x => {
        let re = (x.reserved / x.availability) * 100
        if (isNaN(re)) re = 0
        console.log(re);
        return ({ name: x.name, reserved: re, availability: 100 })
      })
      setProcessedData(r);
    }
  }, [data])

  return (
    <>
      <div className="d-flex justify-content-center">
        <Card className="my-3 w-100">
          <Card.Body>
            <h4>{moment(new Date()).format("dddd - MM/DD/YYYY")}</h4>
            <Chart id="chart" dataSource={processedData}>
              <CommonSeriesSettings argumentField="name" type="stackedBar" />

              <Series
                valueField={"reserved"}
                name={"Reserved"}
                color="#FB5452"
              />
              <Series
                valueField={"availability"}
                name={"Availability"}
                color="#02B406"
              />

              <Legend
                visible={true}
                verticalAlignment="center"
                horizontalAlignment="center"
                itemTextPosition="right"
              >
                <Font size={16} />
              </Legend>
              <Tooltip
                enabled={true}
                location="edge"
                customizeTooltip={(e) => customizeTooltip(e)}
              />
              <Title
                text={title}
                horizontalAlignment="middle"
                verticalAlignment="top"
              >
                <Font size={24} />
                <Margin top={30} />
              </Title>
            </Chart>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const CardHouraryRestaurants = (props) => {
  const { data, title } = props;

  console.log(data);
  const calculatePercent = (quantity, maxQuantity) =>
    (quantity / maxQuantity) * 100;
  return (
    <>
      <Card className="my-3">
        <Card.Body>
          <Row>
            <Col xs={12} className="text-center mb-3">
              <span>
                <b>{title}</b>
              </span>
            </Col>
            <Col xs={12}>
              <div className="w-100">
                <div
                  className="wrapper"
                  style={{ overflowY: "auto", minHeight: "148px" }}
                >
                  <table className="table table-bordered shadow-sm table-striped">
                    <thead>
                      <tr>
                        <th className="second-col">Hour</th>
                        <th>Percent</th>
                        <th className="second-col">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item, index) => {
                          console.log(item);
                          let totalPercent = calculatePercent(
                            item.Quantity,
                            item.maxQuantity
                          ).toFixed(0);
                          return (
                            <tr key={index} className={`clickable`}>
                              {/* <td>{item.Hour}</td> */}
                              <td>{moment(item?.Hour, "HH:mm:ss").format("hh:mm A")}</td>
                              <td>
                                <ProgressBar
                                  style={{
                                    backgroundColor: "#A9A9A9",
                                    color: "red",
                                  }}
                                  min={0}
                                  variant="info"
                                  now={totalPercent}
                                  max={item.maxQuantity}
                                  label={`${totalPercent}%`}
                                />
                              </td>
                              <td>
                                {item.Quantity}/{item.maxQuantity}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const useApiData = () => {
  const [hourariesRestaurants, setHourariesRestaurants] = useState([
    {
      title: "Restaurant I",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant II",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant III",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
        {
          Hour: "07:30 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
        {
          Hour: "08:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
        {
          Hour: "08:30 PM",
          Quantity: 100,
          maxQuantity: 100,
        },
        {
          Hour: "09:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
        {
          Hour: "09:30 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant IV",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant V",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant VI",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
    {
      title: "Restaurant VII",
      data: [
        {
          Hour: "06:00 PM",
          Quantity: 50,
          maxQuantity: 100,
        },
        {
          Hour: "06:30 PM",
          Quantity: 80,
          maxQuantity: 255,
        },
        {
          Hour: "07:00 PM",
          Quantity: 20,
          maxQuantity: 100,
        },
      ],
    },
  ]);

  return { hourariesRestaurants };
};

const AvailabilityPreview = ({ show, toggle, selectedReservation }) => {
  const { hourariesRestaurants } = useApiData();
  const user = parsedUser();
  const [records, setRecords] = useState([]);

  const requestData = async () => {
    try {
      let reservationDate = selectedReservation.ReservationDate ?? moment().format("YYYY-MM-DD");
      console.log(reservationDate);
      let query = `IdUser=${user.IdUser}&token=${user.Token}&Date=${reservationDate}`;
      let request = await API.getAction("api/RestaurantReservation/GeneralAvailability", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      let recordResult = parsedResults;
      console.log(recordResult, results, parsedResults);

      setRecords({
        RestaurantList: parsedResults.RestaurantList,
        ChartData: parsedResults.chartData
      });
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    requestData();
  }, [])

  return (
    <Modal
      show={show}
      onHide={() => toggle(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      dialogClassName="modal-90w"
      className="z-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>Restaurant Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={4}>
            <div className="bg-white shadow-sm">
              <Graphic
                data={records.ChartData}
                title={"Restaurant Reservation Availability Quantity"}
              />
            </div>
          </Col>
          <Col xs={8}>
            <div className="bg-white shadow-sm" >
              <div className="row mx-2 overflow-auto no-wrap flex-nowrap" style={{ height: "78vh" }}>
                {records.RestaurantList && Array.isArray(records.RestaurantList) && records.RestaurantList.map((item, index) => {
                  console.log(item);
                  return (
                    <div
                      className="col-xs-2"
                      style={{ width: "20rem", height: "100%" }}
                      key={index}
                    >
                      <CardHouraryRestaurants
                        data={item.data}
                        title={item.title}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  );
};

export default AvailabilityPreview;
