import React, { useState } from "react";
// import { maintenanceGeneralData } from "../../../../fakeData/maintenanceGeneralData";
//import { maintenanceGuestData } from "../../../../fakeData/maintenanceGuestData";
import { AddBox, Search } from "@mui/icons-material";

import maintenanceUserRolesData from "../../fakeData/maintenanceUserRolesData";
import "devextreme/data/odata/store";

import Maintenance from "../../components/customComponents/Maintenance";
import { Card, Col, Row } from "react-bootstrap";
import { DataGrid } from "devextreme-react";
import { Pager, Paging } from "devextreme-react/data-grid";
import FieldSelector from "../../components/customComponents/FieldSelector";

export default function UserRoles() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState({
    title: "User Roles",
    sections: [
      maintenanceUserRolesData,
      //maintenanceGuestData,
      //  maintenanceRoomsData,
      // maintenanceChargesData,
      //maintenanceFilesData,
      //maintenanceHistoryData
    ],
    data: {
      Title: "User Roles",
      Filters: [
        {
          title: "Parent Reservation #",
          value: "",
          htmlType: "text",
          required: true,
          cssClass: "col-12 col-md-4",
          placeholder: "Description",
          name: "customer",
        },
      ],

      Rows: [
        {
          Description: "Regular",

          Comments: "This User is used for specific locations",
          Active: "Yes",
        },
        {
          Description: "Admin",

          Comments: "This type of user can operate all the sections",
          Active: "No",
        },
      ],
    },
  });

  const allowedPageSizes = [8, 12, 20];
  const dataUI = () => {
    return (
      <div className="">
        <Card className="mb-4">
          <Card.Header>
            <Card.Title>Filters</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              {model.data.Filters.map((item, index) => {
                return (
                  <Col className={item.cssClass} key={index}>
                    <FieldSelector model={item} key={index}></FieldSelector>
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
          <Card.Footer className="text-end">
            <button className="btn btn-sm btn-primary">
              <Search />
            </button>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title>{model.data.Title}</Card.Title>
          </Card.Header>
          <Card.Body>
            <DataGrid
              dataSource={model.data.Rows}
              columnAutoWidth={true}
              showBorders={true}
            >
              <Paging defaultPageSize={12} />
              <Pager
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </div>
    );
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className="row mx-0">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2">
            <div className="row">
              <div className="col">
                <h5 className={"mt-2"}>{model.title}</h5>
              </div>
              <div className="col text-end">
                <button className="btn">
                  <Search />
                </button>
                <button className="btn" onClick={() => toggleOpen()}>
                  <AddBox />
                </button>
              </div>
            </div>
          </div>
          {open ? (
            <div className="card">
              <div className="card-header">
                <h4>{model.title}</h4>
              </div>
              <div className="card-body p-4">
                <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                  {model.sections.map((item, index) => {
                    return (
                      <li key={index} className="nav-item">
                        <button
                          className={
                            "nav-link " +
                            (index === selectedTab ? "active" : "")
                          }
                          onClick={() => {
                            setSelectedTab(index);
                          }}
                        >
                          {item.title}
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <div class="tab-content" id="myTabContent">
                  {model.sections.map((item, index) => {
                    return (
                      <div
                        class={
                          "tab-pane fade " +
                          (index === selectedTab ? "show active" : "")
                        }
                        key={index}
                      >
                        <Maintenance model={item} toggle={toggleOpen} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            dataUI()
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
