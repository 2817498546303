import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";

import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useFormik } from "formik";
const MakeAjustment = (props) => {
  const user = parsedUser();
  const [isLoading, setIsLoading] = useState(false);
  // const [amount, setAmount] = useState(0);

  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);

  const fields = [
    {
      FieldName: "Amount",
      FieldType: "number",
      FieldTitle: "Amount",
      cssClasss: "col-12 ",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "DetailReferences",
      FieldType: "text",
      FieldTitle: "Comments",
      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Details",
      FieldType: "text",
      FieldTitle: "Details",
      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleSubmit = async (obj, event) => {
    setIsLoading(true);
    const amount = obj.Amount;
    const detailReference = obj.DetailReferences;

    try {
      if (amount * 1 > props.selectedFolioDetail.Amount) {
        confirmAlert({
       //   title: "",
          message: "The amount must be less than or equal to the folio amount",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return;
      }
      if (amount * 1 === 0) {
        confirmAlert({
//          title: "",

          message: "The amount must be greater than zero",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return;
      }

      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
console.log(props)
      let queryData = {
        Data: `@Details=${
          obj.Details ? "'" + obj.Details + "'" : "null"
        },@DetailReferences=${
          detailReference ? "'" + detailReference + "'" : "null"
        }, @IdBillingService=${
          obj.IdBillingService ? obj.IdBillingService : "null"
        }, @Amount=${amount ?? "0"},@GUIDReservationToFolio='${
          props.parent.GUIDReservationToFolio
        }',@IdReservationToFolio=${
          props.selectedFolioDetail.IdReservationToFolioDetail
        },@GUIDParentFolioDetail='${props.selectedFolioDetail.GUIDReservationToFolioDetail}'`,
      };

      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/MakeAjustment?` + query,
        queryData ?? ""
      );

      console.log(request);

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];

        console.log(response);
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      Amount: 0,
      DetailReferences: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      const errors = {};
      if (!values.Amount) {
        errors.Amount = "Amount is required";
      }
      if (!values.DetailReferences) {
        errors.DetailReferences = "Comments is required";
      }
      return errors;
    },
  });

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "sm"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Adjustment</Modal.Title>
      </Modal.Header>

      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Container fluid className="overflow-auto table-responsive">
            <Row>
              {fields &&
                fields
                  .filter((x) => x.GroupBy === 0)
                  .map((item, index) => {
                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={`col-${item.FieldName}-${index}`}
                      >
                        <label className="me-2 mt-2">
                          {item.FieldTitle}
                          {item.Required ? (
                            <i className="required-asterisk ms-1 text-danger">
                              *
                            </i>
                          ) : null}
                        </label>
                        <br />

                        <FieldSelector
                          actionMode={""}
                          model={item}
                          obj={formik.values}
                          key={`field-${item.FieldName}`}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm my-1">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            <button
              className="btn me-2"
              type="button"
              onClick={props.toggle}
              disabled={isLoading}
            >
              Close
            </button>
            <button
              disabled={isLoading}
              className="btn btn-primary me-2"
              type="submit"
              // onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MakeAjustment;
