const RoomsColorMap = {
  'VC Dirty': '#018000',
  'VC Clean': '#01FF03',
  'OC Dirty': '#BE0000',
  'OC Clean': '#FF7D00',
  'Do Not Disturb': '#81817F',
  'Sleeping Out': '#6365E6'
};

const ReservationsColorMap = {
  'Room Block': '#68EC57',
  'Tentative': '#FE8A02',
  'Confirmed': '#7EB9EC',
  'Inhouse': '#3713E1',
  'Maintenance': '#000003',
};

function getRoomColorStatus(word) {
  const wordList = [
    'VC Dirty',
    'VC Clean',
    'OC Dirty',
    'OC Clean',
    'Do Not Disturb',
    'Sleeping Out'
  ];

  if (wordList.includes(word)) {
    return RoomsColorMap[word];
  } else {
    return '#FFFFFF'; // default color if word is not found in the list (white)
  }
}

function getReservationColorStatus(word) {
  const wordList = [
    'Room Block',
    'Tentative',
    'Confirmed',
    'Inhouse',
    'Maintenance',
  ];

  if (wordList.includes(word)) {
    return ReservationsColorMap[word];
  } else {
    return '#FFFFFF'; // default color if word is not found in the list (white)
  }
}

module.exports = {
  getRoomColorStatus,
  getReservationColorStatus,
  RoomsColorMap,
  ReservationsColorMap,
}