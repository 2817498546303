import React from 'react'

const ItemGroup = ({ item, select, setSelect }) => {

    return (
        <React.Fragment>
            <li className={`list-group-item ${select.isActive && select.name === item.Description ? 'active' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelect({ isActive: true, name: item.Description, id: item.Id })}>
                {item.Description}
            </li>
        </React.Fragment>
    )
}

export default ItemGroup