import React from "react";
import TasksAssigmentDetailsContainer from "../../../components/Housekeeping/TasksAssigmentDetails/TasksAssigmentDetailsContainer";

const TasksAssigmentDetails = () => {
  return (
    <>
      <TasksAssigmentDetailsContainer
        routeName={"Housekeeping / Process / Automatic Tasks Assigment"}
      />
    </>
  );
};

export default TasksAssigmentDetails;
