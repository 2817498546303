import { t } from "i18next";

export const AuditFields = [
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdBusinessUnit", // Cambiado de IdBillingBusinessUnit a IdBusinessUnit
    FieldType: "bigbit",
    FieldTitle: t("Business Unit"),
    Position: 3,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID": "be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: true,
    GroupBy: 0,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "UserAudit",
    FieldType: "varchar",
    FieldTitle: t("User Audit"),
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Status",
    FieldType: "varchar",
    FieldTitle: t("Status"),
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "LastDateAudit",
    FieldType: "datetime",
    FieldTitle: t("Last Date Audit"),
    Position: 8,
    cssClasss: "col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "AuditDate",
    FieldType: "date",
    FieldTitle: t("Current Date Audit"),
    Position: 8,
    cssClasss: "col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "Comment",
    FieldType: "varchar",
    FieldTitle: t("Comment"),
    cssClasss: "col-12",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];
