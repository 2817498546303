import { PrintOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Table } from "react-bootstrap";
import API from "../../api/api";
import logo from "../../assets/LHVR.jpg";
import { parsedUser } from "../../utils/GetCurrentUser";
import { exportToPDF } from "../../utils/export-to-pdf";
// import RegistrationCardRecords from '../../pages/reports/ReportDataFake/RegistrationCardReport/RegistrationCardRecords.json';
import { ReservationFormData } from "./FakeData";
import { dateFormater } from "../../utils/date-formater";

import { useTranslation } from "react-i18next";

const ReservationFormModal = (props) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const { show, handleClose } = props;
  const [showReport, setShowReport] = useState(true);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reservationPolicies, setReservationPolicies] = useState([]);

  useEffect(() => {
    console.log("props.GUIDReservation 🚀🚀🚀", props.GUIDReservation);
  }, [props.GUIDReservation]);

  const bindDataRemote = async () => {
    setIsLoading(true);

    try {
      let reservation = props.GUIDReservation;
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${
        reservation ?? ""
      }`;
      let request = await API.getAction(
        `/api/Reservations/ReservationForm`,
        query
      );
      let results = await request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setReservationPolicies(parsedResults?.ReservationPolicies);
      let recordResult = parsedResults?.Records || [];
      console.log("🚀 ~ bindDataRemote ~ recordResult:", recordResult[0]);
      setRecord(recordResult[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
    return () => setShowReport(false);
  }, []);

  useEffect(() => {
    if (record) {
      setShowReport(true);
    }
    return () => setShowReport(false);
  }, [record]);

  const generatePDF = () => {
    const container = document.getElementById("contentContainer");
    exportToPDF(container, "portrait");
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      dialogClassName="modal-90w"
      className="modalRight z-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Reservation Form")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-2">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <h3>{t("Loading")}...</h3>
            </div>
          ) : (
            <div className="d-flex justify-content-center flex-column align-items-center ">
              {showReport && record ? (
                <>
                  <div
                    className="mx-3 d-flex justify-content-end"
                    style={{ width: "1480px", minWidth: "1480px" }}
                  >
                    <button
                      className={"btn btn-secondary mx-2 mb-2"}
                      onClick={() => generatePDF()}
                      disabled={isLoading}
                    >
                      <PrintOutlined /> {t("PDF")}
                    </button>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#fcfeff",
                      paddingRight: "7rem",
                      paddingLeft: "7rem",
                      width: "1480px",
                      minWidth: "1480px",
                    }}
                    className="mx-3"
                    id="contentContainer"
                  >
                    <Row className="pt-2">
                      {/* logo */}
                      <Col md={4} className="py-5">
                        <img src={logo} alt={t("Logo")} width={"65%"} />
                      </Col>
                      {/* title */}
                      <Col
                        md={4}
                        className="d-flex justify-content-center align-items-center flex-column"
                        style={{ fontWeight: "bolder", fontSize: "2.4rem" }}
                      >
                        <h3>{t("Reservation Form")}</h3>
                      </Col>
                      {/* date */}
                      <Col
                        md={4}
                        className="d-flex justify-content-center align-items-end flex-column"
                      >
                        {/* <h4 className='fw-bold'>Folio / Reserva</h4>
            <h4>{records?.folio}</h4> */}
                      </Col>
                    </Row>
                    <Row className="d-flex" style={{ fontSize: "1.2rem" }}>
                      <Col md={6} className="p-0">
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-1"
                            style={{ fontSize: "1.3rem" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className="border-1 w-auto bg-body-secondary text-center"
                                  colSpan={2}
                                >
                                  {t("Reservation Details")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="border-1 w-25">
                                  {t("Reservation Number")}
                                </th>
                                <td className="border-1 w-25">
                                  {record?.ReservationNumber || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Link Folio#")}</th>
                                <td className="border-1">
                                  {record?.LinkFolio || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">
                                  {t("Booking Date")}
                                </th>
                                <td className="border-1">
                                  {(record?.BookingDate &&
                                    dateFormater(record?.BookingDate)) ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">
                                  {t("Booking Window")}
                                </th>
                                <td className="border-1">
                                  {record?.BookingWindow || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Guest Name")}</th>
                                <td className="border-1">
                                  {record?.GuestName || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">
                                  {t("Reservation Status")}
                                </th>
                                <td className="border-1">
                                  {record?.ReservationsStatus || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Stay")}</th>
                                <td className="border-1">
                                  {record?.Stay || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Nigths")}</th>
                                <td className="border-1">
                                  {record?.Nights || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Room Type")}</th>
                                <td className="border-1">
                                  {record?.RoomType || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">
                                  {t("Repeat Times")}
                                </th>
                                <td className="border-1">
                                  {record?.RepeatTimes || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Plan")}</th>
                                <td className="border-1">
                                  {record?.MealPlan || "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-0"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="border-1 w-auto bg-body-secondary text-center"
                                  colSpan={2}
                                >
                                  {t("Credit Card")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="border-1 w-25">
                                  {t("Card Type")}
                                </th>
                                <td className="border-1 w-25">
                                  {record?.CardType || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Card Number")}</th>
                                <td className="border-1">
                                  {record?.CreditCardNumber || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Expiration")}</th>
                                <td className="border-1">
                                  {record?.ExpirationDate || "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-1"
                          >
                            <thead>
                              <tr>
                                <th className="border-1 w-auto bg-body-secondary">
                                  {t("Date")}
                                </th>
                                <th className="border-1 w-auto bg-body-secondary">
                                  {t("User")}
                                </th>
                                <th className="border-1 w-auto bg-body-secondary">
                                  {t("Source")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="border-1 w-25">
                                  {dateFormater(record?.Date)}
                                </th>
                                <td className="border-1 w-25">
                                  {record?.User}
                                </td>
                                <td className="border-1 w-25">
                                  {record?.Source}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-0"
                          >
                            {/* <thead>
                          <tr>
                            <th className='border-1 w-auto bg-body-secondary' colSpan={2}>PAX</th>
                            <th className='border-1 w-auto bg-body-secondary'>VIP Member Information</th>
                          </tr>
                        </thead> */}
                            <tbody>
                              <tr>
                                <th className="border-1">{t("Adults")}</th>
                                <td className="border-1">
                                  {record?.AdultQuantity || 0}
                                </td>
                                <th className="border-1">{t("Children")}</th>
                                <td className="border-1">
                                  {record?.ChildQuantity || 0}
                                </td>
                                <th className="border-1">{t("Infants")}</th>
                                <td className="border-1">
                                  {record?.InfantQuantity || 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-0"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="border-1 w-auto bg-body-secondary text-center"
                                  colSpan={2}
                                >
                                  {t("Account")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="border-1 w-25">
                                  {t("Rate Group")}
                                </th>
                                <td className="border-1 w-25">
                                  {record?.RateGroup || 0}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">
                                  {t("Transfer Rate")}
                                </th>
                                <td className="border-1 w-25">
                                  {record?.TransferRate || 0}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Daily rate")}</th>
                                <td className="border-1">
                                  {record?.DailyRate || 0}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Total Stay")}</th>
                                <td className="border-1">
                                  {record?.TotalStay || 0}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-1">{t("Total Rate")}</th>
                                <td className="border-1">
                                  {record?.TotalRate || 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="w-100 mb-4">
                        <div className="w-100 bg-body-secondary">
                          <h5 className="fw-bold text-center">
                            {t("Remarks & Special Requests")}
                          </h5>
                        </div>
                      </Col>
                      <Col md={12} className="w-100 mb-4">
                        <div className="w-100 bg-body-secondary mb-2">
                          <h5 className="fw-bold text-center">
                            {t("Flight Information")}
                          </h5>
                        </div>
                        <Table
                          bordered
                          className="border-black border-opacity-100 border-0"
                        >
                          <tbody>
                            <tr>
                              <th className="border-1">{t("Arrival Date")}</th>
                              <td className="border-1">
                                {record?.ArrivalDate || "N/A"}
                              </td>
                              <th className="border-1">
                                {t("Departure Date")}
                              </th>
                              <td className="border-1">
                                {record?.DepartureDate || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-1">
                                {t("Arrival Airline/Flight No.")}
                              </th>
                              <td className="border-1">
                                {record?.ArrivalFlight || "N/A"}
                              </td>
                              <th className="border-1">
                                {t("Departure Airline/Flight No.")}
                              </th>
                              <td className="border-1">
                                {record?.DepartureFlight || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-1">{t("Time")}</th>
                              <td className="border-1">
                                {record?.Time || "N/A"}
                              </td>
                              <th className="border-1">{t("Time")}</th>
                              <td className="border-1">
                                {record?.Time || "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <section>
                          <p>
                            <span className="fw-bold">
                              {t("Airport Transfers:")}
                            </span>{" "}
                            {t(
                              "If you have purchased airport transfers you must" +
                                "complete filight information 72 hours prior to" +
                                "travel. We are not responsible for mised or delayed" +
                                "Transfers due to lack flight information. This will" +
                                "result in non-refendable transfer charge and" +
                                "possible additional expeses for extra transfers."
                            )}
                          </p>
                        </section>
                      </Col>
                      <Col md={12} className="w-100 mb-4">
                        <div className="w-100 bg-body-secondary mb-2">
                          <h5 className="fw-bold text-center">
                            {t("Reservation Policies")}
                          </h5>
                        </div>
                        <div className="mb-2">
                          <Table
                            bordered
                            className="border-black border-opacity-100 border-1"
                          >
                            <thead>
                              <tr>
                                <th className="border-1 bg-body-secondary">
                                  {t("Date Range")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("Cancelation")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("Early Departure")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("No Show")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("Early C/In Late C/Out")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("Kids")}
                                </th>
                                <th className="border-1 bg-body-secondary">
                                  {t("Non Guarantee Rooms Hold")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {reservationPolicies &&
                              reservationPolicies.length > 0 ? (
                                reservationPolicies.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <th className="border-1">
                                        {item.DateRange}
                                      </th>
                                      <td className="border-1">
                                        {item.Cancelation}
                                      </td>
                                      <td className="border-1">
                                        {item.EarlyDeparture}
                                      </td>
                                      <td className="border-1">
                                        {item.NoShow}
                                      </td>
                                      <td className="border-1">
                                        {item.EarlyCInLateCOut}
                                      </td>
                                      <td className="border-1">{item.Kids}</td>
                                      <td className="border-1">
                                        {item.NonGuaranteeRoomsHold}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    className="border-1 text-center"
                                    colSpan={7}
                                  >
                                    {t("No Records Found")}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          <section>
                            <p>
                              {t(
                                "A signature by the client or the Travel" +
                                  "Agent/Wholesaler is necessary to confirm a" +
                                  "reservation. If signed by the travel" +
                                  "agent/Wholesalers this will confirm that the" +
                                  "client has been advised of, and agrees to all of" +
                                  "the terms and conditions here in. Yours signed" +
                                  "confirmation must be returned to our office within" +
                                  "7 days of booking. If not received within that" +
                                  "time period, your reservation is not guaranteed."
                              )}
                            </p>
                          </section>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <h1>{t("No Records Found")}</h1>
                </div>
              )}
            </div>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => handleClose(false)}
        >
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationFormModal;
