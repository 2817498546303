import React from "react";
import CashClosingContainer from "../../components/CashClosing/CashClosingContainer";

const CashClosingPage = () => {
  return (
    <CashClosingContainer
      routeName={"Front-Desk / Cashier / Cash Management"}
    />
  );
};

export default CashClosingPage;
