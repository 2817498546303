import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AlertDialog = ({
  title,
  body,
  primaryButtonText,
  onPrimaryButtonClick,
  primaryButtonVariant = 'primary',
  show,
  onHide,
  secondaryButtonText,
  onSecondaryButtonClick,
  secondaryButtonVariant = 'secondary',
  icon, // New prop for the icon
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {icon && <span className="me-2">{icon}</span>}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {secondaryButtonText && (
          <Button
            variant={secondaryButtonVariant}
            onClick={() => {
              if (onSecondaryButtonClick) onSecondaryButtonClick();
              onHide();
            }}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          variant={primaryButtonVariant}
          onClick={() => {
            onPrimaryButtonClick();
            onHide();
          }}
        >
          {primaryButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertDialog;
