import React, { useState } from "react";
import {
  CustomBreadcrumbs as Breadcrumbs,
  GroupReservationContainer,
} from "../../components";
import { Button, Container } from "react-bootstrap";
import useFavorite from "../../hooks/useFavorite";
import { useLocation } from "react-router-dom";
import GroupReservationDetails from "../../components/group-reservation/GroupReservationDetails";
import CancelReservation from "../../components/group-reservation/CancelReservation";
import UnCancelReservation from "../../components/group-reservation/UnCancelReservation";
import { useToast } from "../../contexts/toast";

const GroupReservationPage = () => {
  const { pathname } = useLocation();
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const [isLoading, setIsLoading] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const [showCancelReservation, setShowCancelReservation] = useState(false);
  const [showUnCancelReservation, setShowUnCancelReservation] = useState(false);
  const [showGroupCreation, setShowGroupCreation] = useState(false);

  const toggleGroupCreation = () => setShowGroupCreation(!showGroupCreation);
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState(null);

  const addFavorite = async () => {
    const newFavorite = {
      icon: "fa-solid fa-home",
      path: pathname,
      formPath: "Front-Desk / Front-Desk / Group Reservation",
      title: "Group Reservation",
    };
    UpdateFavorites(newFavorite);
  };

  const toggleAddEdit = (action) => {
    if (action === "I") {
      setSelectedItem(null);
      setActionMode(action);
      setShowGroupCreation(!showGroupCreation);
    } else {
      setActionMode(action);
      setShowGroupCreation(!showGroupCreation);
    }
  };

  const toggleCancelReservation = () => {
    if (selectedItem.NoCancelAllowed) {
      toast({
        type: "warning",
        message: "This group reservation has no cancellations allowed.",
        width: 500,
      });
      return;
    }
    setShowCancelReservation(!showCancelReservation);
  };

  const toggleUnCancelReservation = () => {
    setShowUnCancelReservation(!showUnCancelReservation);
  };

  const handleCloseGroupReservationDetails = () => {
    setShowGroupCreation(false);
    setSelectedItem(null);
  };

  const principalButtonList = [
    {
      id: 1,
      title: "Edit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B"],
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: 2,
      title: "New",
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleAddEdit("I"),
    },
    {
      id: 3,
      title: "Cancel",
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => toggleCancelReservation(),
    },
    {
      id: 4,
      title: "Uncancel",
      variant: "outline-danger",
      className: "",
      icon: "fa-solid fa-rotate-left",
      ifSelectedItem: true,
      reservationCode: ["X"],
      onClick: () => toggleUnCancelReservation(),
    },
    {
      id: "checkInBtn",
      title: "Check In",
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: ["C", "N", "T", "P"],
      onClick: () => {
        toggleAddEdit("K");
        console.log("PRESSED CHECKIN BUTTON");
      },
    },
    {
      id: "checkOutBtn",
      title: "Check Out",
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: ["I", "H"],
      onClick: () => {
        toggleAddEdit("O");
        console.log("PRESSED CHECKOUT BUTTON");
      },
    },
    {
      id: 6,
      title: "Undo Check-In",
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-out",
      ifSelectedItem: true,
      reservationCode: ["I"],
      onClick: () => {
        console.log("PRESSED Undo Check-In Button");
      },
    },

    {
      id: 8,
      title: "Re Check-In",
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: [],
      onClick: () => {
        console.log("PRESSED Re Check-In Button");
      },
    },
    {
      id: 9,
      title: "Bump Out",
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-hotel",
      ifSelectedItem: true,
      reservationCode: ["C", "I"],
      onClick: () => {
        console.log("PRESSED Bump Out Button");
      },
    },

    {
      id: 10,
      title: "Undo Checkout",
      variant: "outline-secondary",
      className: "",
      icon: "",
      ifSelectedItem: true,
      reservationCode: ["O"],
      onClick: () => {
        console.log("PRESSED Undo Checkout Button");
      },
    },
  ];

  return (
    <>
      {showCancelReservation ? (
        <CancelReservation
          reservation={selectedItem}
          show={showCancelReservation}
          toggle={toggleCancelReservation}
        />
      ) : null}
      {showUnCancelReservation ? (
        <UnCancelReservation
          reservation={selectedItem}
          show={showUnCancelReservation}
          toggle={toggleUnCancelReservation}
        />
      ) : null}
      {showGroupCreation ? (
        <GroupReservationDetails
          show={showGroupCreation}
          // toggle={toggleGroupCreation}
          selectedGroup={selectedItem}
          toggle={toggleAddEdit}
          actionMode={actionMode}
          clearSelection={() => setSelectedItem(null)}
          handleClose={handleCloseGroupReservationDetails}
        />
      ) : null}
      <Breadcrumbs pathTitle={"Front-Desk / Front-Desk / Group Reservation"} />
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Group Reservation</h5>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
              {principalButtonList.map((item) => {
                // console.log(selectedItem);
                if (item.ifSelectedItem && selectedItem) {
                  //Validate if checking is pressed
                  if (item.id === "checkInBtn" && !selectedItem.IdRoom) {
                    return <></>;
                  }
                  if (item.id === "assignRoom" && selectedItem.IdRoom) {
                    return <></>;
                  }

                  return item.reservationCode.length > 0 &&
                    item.reservationCode.includes(
                      selectedItem.ReservationStatusCode
                    ) ? (
                    <>
                      <Button
                        key={item}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    </>
                  ) : (
                    <></>
                  );
                } else if (!item.ifSelectedItem) {
                  return (
                    <>
                      <Button
                        key={item}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    </>
                  );
                } else return <></>;
              })}
            </div>
            {/* <ButtonsOptions
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              toggleAddEdit={toggleAddEdit}
            /> */}
            <GroupReservationContainer
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showCancelReservation={showCancelReservation}
              showUnCancelReservation={showUnCancelReservation}
              showAddEdit={showGroupCreation}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default GroupReservationPage;
