import enUs from "./en-US.json";
import esDo from "./es-DO.json";

export const resources = {
  en: {
    translation: enUs,
  },
  es: {
    translation: esDo,
  },
};
