
import { withNavigationWatcher } from "./contexts/navigation";
import ROUTES from "./utils/routes";

const routes = ROUTES.WithSidebar;

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
