import React, { useCallback, useEffect, useRef, useState } from "react";
import { Search, CleaningServices } from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import * as yup from "yup";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useParams } from "react-router-dom";
import { TextBox } from "devextreme-react";
import FieldSelector from "../customComponents/FieldSelector";
import { useFormik } from "formik";
import { useToast } from "../../contexts/toast";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToPDF as GeneratePDF } from "../../utils/export-to-pdf";
import { exportToExcel as GenerateXLS } from "../../utils/export-to-excel";
import { CustomBreadcrumbs as Breadcrumbs } from "..";
import useFavorite from "../../hooks/useFavorite";
import Pagination from "../Pagination/Pagination";

export default function RoomsContainer() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  // const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const checkAudit = localStorage.getItem("AuditInformation");

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [section, setSection] = useState(null);
  const [fields, setFields] = useState([]);
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const selectedItemRef = useRef(null);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [filters, setFilters] = useState([]);
  // END PROPERTIES

  //METHODS

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (filter) => {
    setLoading(true);

    try {
      console.log("===>", currentPage);
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}&Quantity=${quantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: filter,
      };
      console.log(query);
      let request = await API.postAction(
        "/api/Rooms/RoomsRecords?" + query,
        queryData ?? ""
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setModel({
          ...model,

          data: {
            ...model.data,

            Rows: [],
          },
        });

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];
      // console.log("Results from record", recordResult);

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

      await bindDataCountRemote();

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const [features, setFeatures] = useState([]);
  const bindDataFeaturesRemote = async (filter) => {
    setLoading(true);

    try {
      console.log("===>", currentPage);
      if (selectedItem === null) return;

      let query = `IdUser=${user.IdUser}&token=${user.Token}&ParentGUID=${selectedItem.GUID}`;

      console.log(query);
      let request = await API.postAction("/api/Rooms/features?" + query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      console.log("features", parsedResults);
      let recordResult = parsedResults;

      if (recordResult !== null) {
        console.log("o.o es que devuelve null?");
        setFeatures(recordResult);

        return;
        // throw new Error("No data found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTableHeader([]);
    bindDataRemote();
    setSelectedItem(null);
  }, [params.id, idModule]);

  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [currentPage]);

  const bindDataCountRemote = async (cleanFilter) => {
    // First start loading the model

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;

      query = query + `&Quantity=${RecordsQuantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: "",
      };

      let request = await API.postAction(
        `api/Rooms/RoomsCount?${query}`,
        queryData
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const bindDataModelRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `/api/Rooms/RoomsModel`,
        querySections
      );
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection);
      setSection(recordResultSection[0]);
      let fields = recordResultSection[0].Fields.filter(
        (item) => !titles.includes(item.FieldTitle)
      );
      setFields(fields);

      //Now try to fill the filters
      let fieldsFilter = fields.filter((x) => x.isFilter === true);
      setFilters(fieldsFilter);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const [tableHeader, setTableHeader] = useState([]);

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `api/Rooms/RoomsHeader`,
        querySections
      );
      // console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      // console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      // console.log(recordResultSection);

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      console.log(recordResultSection);

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, [checkAudit]);

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${
        user.Token
      }&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(`api/Rooms/RoomsDetails`, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      setSelectedItem(null);
    } catch (error) {
      console.error(error);
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      setFeatures([]);
      formik.resetForm();
    }

    if (action === "I") {
      formik.resetForm();
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);

      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${
              selectedItem.GUID
            }${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/Rooms/RoomsDelete`, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", section);
    if (section && fields && fields.length > 0) {
      fields.forEach((item) => {
        if (item && item.FieldName.length > 0) {
          console.log(
            "field name",
            item.FieldName,
            "RecordValue ",
            record[item.FieldName]
          );
          formik.setFieldValue(item.FieldName, record[item.FieldName]);
        }
      });
      bindDataFeaturesRemote();
    }

    //Now
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${
      actions[actionMode]
    }${idModule ? "&IdModule=" + idModule : ""}`;
    let queryData = {
      Data: objSPVersion,
    };

    let request = await API.postAction(
      `api/Rooms/RoomsAddEdit?${queryString}`,
      queryData
    );

    console.log("DATA", request);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        setGeneralError(response.Msg);
        return;
      } else {
        setSelectedItem(response.JSONData);

        fillFormikObject(response.JSONData);
      }
    }
    setGeneralError(null);

    if (actionMode === "I") {
      setActionMode("U");
    } else {
      toggleOpen(null, true);
    }

    toast({
      type: "success",
      message: "Record saved successfully",
    });
    //    toggleOpen(null, true);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${section.FormTitle}.xlsx`);
  }, [model.data.Rows]);

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, []);

  const updateFeature = async (item, apply) => {
    console.log(item, apply, selectedItem);
    if (formik.values === null) return;
    let query = `IdUser=${user.IdUser}&token=${user.Token}&ParentGUID=${formik.values.GUID}`;
    let queryData = {
      Data: `@IdRoom=${formik.values.Id}, @IdRoomFeature=${item.IdRoomFeature}, @Apply=${apply}`,
    };

    await API.postAction("/api/Rooms/Applyfeatures?" + query, queryData);
    let f = features.map((x) => {
      if (x.IdRoomFeature === item.IdRoomFeature) {
        x.Apply = apply;
      }
      return x;
    });
    // f.filter((x) => x.IdRoomFeature === item.IdRoomFeature)[0].Apply = apply;

    setFeatures(f);
    //bindDataFeaturesRemote();
  };
  // END METHODS

  // UI METHODS

  useEffect(() => {
    console.log("entro", new Date());
    bindDataRemote();
  }, [sortBy, sortByOrder]);

  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                title="Close"
                type="button"
                onClick={() => {
                  setIsSearching(false);
                }}
                style={{
                  padding: "0px 8px",
                  lineHeight: "23px",
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="bg-light ">
                <Row>
                  {filters.map((item, index) => {
                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={index}
                      >
                        <Row>
                          <Col xs={3}>
                            <label
                              className={
                                item.HtmlType === "separator"
                                  ? `me-2 ${
                                      index !== 0 ? "mt-4 pt-4" : "mt-2"
                                    }  separatorTitle`
                                  : "me-2 mt-3 "
                              }
                            >
                              {item.FieldTitle}
                              {item.Required ? (
                                <i className="required-asterisk ms-1 text-danger">
                                  *
                                </i>
                              ) : null}
                            </label>
                          </Col>
                          <Col>
                            <FieldSelector
                              actionMode={actionMode}
                              model={item}
                              key={index}
                              obj={formik.values}
                              // limit longitudes
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            ></FieldSelector>
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm">
                                {formik.errors[item.FieldName]}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        {/* <br /> */}
                      </Col>
                    );
                  })}
                </Row>
                {/* <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="Search by description"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row> */}
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  title="Clear"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  title="Search"
                  type="submit"
                >
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )}

        <Container>
          <Row className="mx-2">
            <div className="table-responsive">
              <Table hover size="xl" bordered className="border shadow-sm">
                <thead className="table-secondary">
                  <tr>
                    {headerWithoutAuditFields().map((item, index) => {
                      // console.log(item);
                      return (
                        <th
                          key={index}
                          className={`fw-bold clickable`}
                          onClick={() => {
                            // console.log(item, sortBy, sortByOrder)
                            setSortBy(item.FieldName);
                            if (item.FieldName === sortBy) {
                              if (sortByOrder === "ASC") {
                                setSortByOrder("DESC");
                              } else {
                                setSortByOrder("ASC");
                              }
                            } else {
                              setSortByOrder("ASC");
                            }
                          }}
                        >
                          {item.FieldTitle}{" "}
                          {sortBy === item.FieldName &&
                          sortByOrder === "ASC" ? (
                            <i className="fas fa-sort-up"></i>
                          ) : sortBy === item.FieldName &&
                            sortByOrder === "DESC" ? (
                            <i className="fas fa-sort-down"></i>
                          ) : null}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {model?.data &&
                    model.data.Rows.map((item, index) => {
                      // console.log("item", item, "header", tableHeader);
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedItem(item);
                          }}
                          className={
                            selectedItem && selectedItem.GUID === item.GUID
                              ? "active"
                              : ""
                          }
                        >
                          {headerWithoutAuditFields().map((column, index2) => {
                            return (
                              <td key={index2} className={` `}>
                                {typeof item[column.FieldName] === "boolean" ? (
                                  <input
                                    type="checkbox"
                                    checked={item[column.FieldName]}
                                    className=""
                                  />
                                ) : (
                                  item[column.FieldName]
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <Pagination
              RecordsQuantity={RecordsQuantity}
              currentPage={currentPage}
              isLoading={loading}
              recordCount={recordCount}
              setCurrentPage={setCurrentPage}
              setRecordsQuantity={setRecordsQuantity}
              totalPages={totalPages}
            />
          </Row>
        </Container>
      </>
    );
  };

  //EVENTS

  useEffect(() => {
    setOpen(false);
    setSelectedItem(null);
    setActionMode(null);
    setGeneralError(null);
    setModel(initialModel);
    checkIfPinned();
    setIsSearching(false);
    setSearch("");
    bindDataModelRemote();
    bindDataHeaderRemote();
    //bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    // console.log("section updated", model);
    prepareFormikObject();
  }, [section, fields]);
  // END EVENTS
  useEffect(() => {
    // console.log("entro record quantity");
    bindDataRemote();
  }, [RecordsQuantity]);

  useEffect(() => {
    // console.log("Pages", Math.ceil(recordCount / RecordsQuantity));
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount]);

  useEffect(() => {
    if (open) {
      //Search features
      console.log("ESTA DENTRO");
      bindDataFeaturesRemote();
    }
  }, [open, selectedItem]);

  if (!section) {
    return <></>;
  }
  return (
    <React.Fragment>
      {open ? (
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={"xl"}
          className={"modalRight "}
        >
          <Modal.Header closeButton>
            <Modal.Title>{section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Row>
                    {fields.map((item, index) => {
                      return (
                        <Col
                          className={
                            item.cssClasss + (!item.Visible ? " d-none" : "")
                          }
                          key={index}
                        >
                          <label
                            className={
                              item.HtmlType === "separator"
                                ? `me-2 ${
                                    index !== 0 ? "mt-4 pt-4" : "mt-2"
                                  }  separatorTitle`
                                : "me-2 mt-3 "
                            }
                          >
                            {item.FieldTitle}
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                          </label>
                          <br />

                          <FieldSelector
                            actionMode={actionMode}
                            model={item}
                            key={index}
                            obj={formik.values}
                            // limit longitudes
                            updateField={updateField}
                            value={formik.values[item.FieldName]}
                          ></FieldSelector>
                          {formik.errors[item.FieldName] ? (
                            <div className="invalid text-sm">
                              {formik.errors[item.FieldName]}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col className="border-left">
                  {/* TABS */}

                  <Row>
                    <Col>
                      <Card>
                        <Card.Header>
                          <Card.Title>Features</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Apply</th>
                              </tr>
                            </thead>
                            <tbody>
                              {features &&
                                features.map((item, index) => {
                                  if (index === 0) {
                                    console.log(item);
                                  }
                                  return (
                                    <tr key={index}>
                                      <td>{item.RoomFeature}</td>
                                      <td>
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.Apply}
                                            onChange={(e) => {
                                              console.log(e.target.checked);
                                              updateFeature(
                                                item,
                                                e.target.checked
                                              );
                                            }}
                                            name="Status"
                                            // onBlur={handleBlur}
                                          />
                                        </div>
                                        {item.Applied}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}
                <button
                  className="btn  me-2"
                  type="button"
                  onClick={() => toggleOpen(null)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary "
                  type="button"
                  onClick={() => [formik.submitForm()]}
                >
                  {actionMode === "I" ? "Save & Continue" : "Save"}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
      {section?.FormPath && <Breadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section.FormTitle}</h5>
                  </div>
                </div>
              </React.Fragment>
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="w-auto d-flex align-items-center">
                {actionMode === null ? (
                  <>
                    <button
                      className="btn btn-sm btn-primary my-1 me-2"
                      title="Create"
                      disabled={loading}
                      onClick={() => toggleOpen("I")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button
                        className={`btn btn-sm  my-1 me-2`}
                        title="Search"
                        onClick={() => setIsSearching(true)}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="Update"
                      disabled={loading}
                      onClick={() => toggleOpen("U")}
                      aria
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="View"
                      disabled={loading}
                      onClick={() => toggleOpen("C")}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </>
                ) : null}

                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Remover de favoritos
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Agregar a favoritos
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item
                        onClick={() => {
                          toggleOpen("D");
                        }}
                      >
                        Duplicar
                      </Dropdown.Item>
                    ) : null}

                    {actionMode === null ? (
                      <Dropdown.Item
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    ) : null}
                    {actionMode === "C" ? (
                      <Dropdown.Item onClick={() => exportToPDF()}>
                        Export to PDF
                      </Dropdown.Item>
                    ) : null}

                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item onClick={() => deleteConfirm()}>
                        Delete
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
