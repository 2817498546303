import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CheckBox } from "devextreme-react";

const RestaurantSecFilter = ({ filterBy }) => {
  const [filter, setFilter] = useState({
    AllStatus: true,
    ReservedStatus: true,
    CanceledStatus: true,
  });

  useEffect(() => {
    filterBy(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleFilter = (name, checked) => {
    // const { name, checked } = e.target

    // Select all
    if (name === "AllStatus") {
      setFilter({
        AllStatus: checked,
        ReservedStatus: checked,
        CanceledStatus: checked,
      });
      return;
    }

    // Unselect 'all' if any other is unselected
    if (name !== "AllStatus" && filter.AllStatus) {
      setFilter({
        ...filter,
        AllStatus: false,
        [name]: checked,
      });
      return;
    }

    // select all if all are selected and the default case.
    setFilter((prev) => {
      const updatedFilter = {
        ...prev,
        [name]: checked,
      };

      if (
        name !== "AllStatus" &&
        Object.keys(updatedFilter)
          .filter((key) => key !== "AllStatus")
          .every((key) => updatedFilter[key]) &&
        !updatedFilter.AllStatus
      ) {
        updatedFilter.AllStatus = true;
      }

      return updatedFilter;
    });
  };

  const Checkboxes = [
    {
      label: "All",
      name: "AllStatus",
    },

    {
      label: "Reserved",
      name: "ReservedStatus",
    },

    {
      label: "Canceled",
      name: "CanceledStatus",
    },
  ];

  return (
    <Row className="mx-0 p-2 border">
      <Col>
        {Checkboxes.map((item, index) => (
          <Col key={index}>
            <Row>
              <Col>
                <label className="me-2 mt-2">{item.label}</label>
              </Col>
              <Col className="d-flex justify-content-end ">
                <CheckBox
                  // className='mt-2'
                  name={item.name}
                  value={filter[item.name]}
                  onValueChange={(e) => {
                    handleFilter(item.name, e);
                  }}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Col>
    </Row>
  );
};

export default RestaurantSecFilter;
