import { t } from "i18next";

const DataEntryHeaderFirst = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReservationNumber",
    FieldType: "varchar",
    FieldTitle: t("Reservation Number"),
    Position: 1,
    cssClasss: "col-md-2 col-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "7654321",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400006,
    FieldName: "IdReservationsStatus",
    FieldType: "varchar",
    FieldTitle: t("Status"),
    Position: 2,
    cssClasss: "col-md-2 col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"9d06a2238d72263e39bbe39ba6a168e09daa587349c40e7beb8224dc77640684"}',
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400007,
    FieldName: "ArrivalDate",
    FieldType: "datetime",
    FieldTitle: t("Arrival"),
    Position: 3,
    cssClasss: "col-md-2 col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "DepartureDate",
    FieldType: "datetime",
    FieldTitle: t("Departure"),
    Position: 5,
    cssClasss: "col-md-2 col-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "Nights",
    FieldType: "int",
    FieldTitle: t("Nights"),
    Position: 4,
    cssClasss: "col-md-1 col-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "IdCustomer",
    FieldType: "varchar",
    FieldTitle: t("Customer"),
    Position: 3,
    cssClasss: "col-md-3 col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "MemberName",
    FieldType: "varchar",
    FieldTitle: t("Member Name"),
    Position: 3,
    cssClasss: "col-md-3 col-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "MembershipType",
    FieldType: "varchar",
    FieldTitle: t("Membership"),
    Position: 3,
    cssClasss: "col-md-3 col-6",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400010,
    FieldName: "TotalStay",
    FieldType: "int",
    FieldTitle: t("Stay Total"),
    Position: 3,
    cssClasss: "col-md-3 col-12",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    CustomFormat: "#,##0.00",
  },
];

export default DataEntryHeaderFirst;
