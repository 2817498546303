import React from "react";
//import incidentsInfo from "../../fakeData/DailyIncidentsLineData";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Margin,
  Title,
  Grid,
  ValueAxis,
  Tick,
  Label,
  Font,
} from "devextreme-react/chart";
import Loading from "../Loading";

const dataTest = [
  { hour: 1, progress: 10, total: 20 },
  { hour: 2, progress: 15, total: 25 },
  // Agrega más objetos según sea necesario
];

const DailyIncidentsLine = ({ data, title }) => {
  console.log(data);
  const pies = () => (
    <Chart palette="Violet" dataSource={dataTest}>
      <CommonSeriesSettings argumentField="hour" type="line" />

      <Series valueField="progress" name="Progress" color="#f5564a">
        <Label visible={true} />
      </Series>

      <Series valueField="total" name={title ? "Request by Hours" : "Incident by Hours"} color="#42AAFF">
        <Label visible={true} />
      </Series>

      <Margin bottom={20} />
      <ArgumentAxis
        valueMarginsEnabled={true}
        discreteAxisDivisionMode="crossLabels"
      >
        <Grid visible={true} />
      </ArgumentAxis>

      <ValueAxis>
        <Tick visible={true} />
        <Label visible={true} />
      </ValueAxis>
      <Legend
        visible={true}
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Title
        text={title ? title :"Daily Incidents"}
        horizontalAlignment="center"
        verticalAlignment="top"
      >
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </Chart>
  );

  return (
    <div className="bg-white p-3 shadow text-center" style={{ borderRadius: 25 }}>
      {/* {data && data.length > 0  ? pies() : <Loading />} */}
      {pies()}
    </div>
  );
};

export default DailyIncidentsLine;
