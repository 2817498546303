import React, { useEffect, useState } from "react";
import { CleaningServices, Search } from "@mui/icons-material";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FieldSelector from "../../customComponents/FieldSelector";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import GuestInfoFields from "../fields/GuestInfoFields";

import { useTranslation } from "react-i18next";

const GuestInfo = (props) => {
  const { t } = useTranslation();

  const fields = GuestInfoFields;
  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (obj) => {
    if (props.actionMode === "I") {
    }
    //console.log(obj, record);
    formik.validateForm();
    if (!formik.isValid) {
      return;
    }
    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });
  };

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        // console.log(
        //   "field name",
        //   item.FieldName,
        //   "RecordValue ",
        //   record[item.FieldName]
        // );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };
  useEffect(() => {
    if (props.selectedGuest) {
      fillFormikObject(props.selectedGuest);
    }
  }, [props.selectedGuest]);
  return (
    <>
      <Row className="py-0">
        {/* GENERAL INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            {fields
              .filter((x) => x.GroupBy === 0)
              .map((item, index) => (
                <Col
                  className={item.cssClasss + (!item.Visible ? " d-none" : "")}
                  key={index}
                >
                  <label
                    className={`me-2 mt-2 ${
                      item.HtmlType === "separator" && "fw-bolder"
                    }`}
                  >
                    {t(item.FieldTitle)}
                  </label>
                  {item.Required ? (
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  ) : null}
                  <br />
                  <FieldSelector
                    actionMode={props.actionMode}
                    model={{
                      ...item,
                      ReadOnly: true,
                    }}
                    IsReadOnly
                    key={index}
                    obj={props.selectedGuest}
                    updateField={updateField}
                    value={formik.values[item.FieldName]}
                  />
                  {formik.errors[item.FieldName] && (
                    <div className="invalid text-sm">
                      {formik.errors[item.FieldName]}
                    </div>
                  )}
                </Col>
              ))}
          </Row>
        </Col>
        {/* ADDRESS INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            {fields
              .filter((x) => x.GroupBy === 1 && x.HtmlType !== "button")
              .map((item, index) => {
                return (
                  <Col
                    className={
                      item.FieldTitle === "Address Information"
                        ? "col-12 mt-4"
                        : item.FieldTitle === "Reservation Information"
                        ? "col-12"
                        : item.cssClasss + (!item.Visible ? " d-none" : "")
                    }
                    key={index}
                  >
                    <label
                      className={`me-2 mt-2 ${
                        item.HtmlType === "separator" && "fw-bolder"
                      }`}
                    >
                      {t(item.FieldTitle)}
                    </label>
                    {item.Required ? (
                      <i className="required-asterisk ms-1 text-danger">*</i>
                    ) : null}
                    <br />

                    <FieldSelector
                      actionMode={props.actionMode}
                      model={{
                        ...item,
                        ReadOnly: true,
                        cssClasss:
                          item.FieldTitle === "Address Information"
                            ? "col-12"
                            : item.cssClasss,
                      }}
                      IsReadOnly
                      key={index}
                      obj={props.selectedGuest}
                      updateField={updateField}
                      value={formik.values[item.FieldName]}
                    />
                    {formik.errors[item.FieldName] && (
                      <div className="invalid text-sm">
                        {formik.errors[item.FieldName]}
                      </div>
                    )}
                  </Col>
                );
              })}
          </Row>
        </Col>

        {/* CONTACT INFORMATION */}
        <Col xs={12} md={6}>
          <Row className="mx-0">
            {fields
              .filter((x) => x.GroupBy === 2)
              .map((item, index) => (
                <Col
                  className={item.cssClasss + (!item.Visible ? " d-none" : "")}
                  key={index}
                >
                  <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                  {item.Required ? (
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  ) : null}
                  <br />

                  <FieldSelector
                    actionMode={props.actionMode}
                    model={item}
                    key={index}
                    IsReadOnly
                    obj={props.selectedGuest}
                    updateField={updateField}
                    value={formik.values[item.FieldName]}
                  />
                  {formik.errors[item.FieldName] && (
                    <div className="invalid text-sm">
                      {formik.errors[item.FieldName]}
                    </div>
                  )}
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default GuestInfo;
