import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { GenericFields } from "../group-reservation/GenericFields";

import { useFormik } from "formik";
import * as yup from "yup";
import { ChangeBusinessUnitFields } from "./fields/ChangeBusinessUnitFields";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";

import { useTranslation } from "react-i18next";

const TransferBusinessUnit = ({ show, toggle, reservation }) => {
  const { t } = useTranslation();

  const fields = ChangeBusinessUnitFields;

  const [validationsSchema, setValidationSchema] = useState(null);

  const toast = useToast();

  const user = parsedUser();

  useEffect(() => {
    prepareFormikObject();
    requestAllBusinessUnit();
  }, []);

  const [allBusinessUnit, setAllBusinessUnit] = useState([]);
  const requestAllBusinessUnit = async () => {
    let query = `token=${user.Token}&id=be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef`;
    let request = await API.getAction("api/selector", query);
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];

    if (parsedResults.Error) {
      console.error(parsedResults.Msg);
      return;
    }
    let recordResult = JSON.parse(parsedResults.JSONData);
    if (recordResult) {
      let parsedItems = recordResult.map((item) => ({
        id: item.Id,
        text: item.Description,
      }));

      setAllBusinessUnit(parsedItems);
    }
  };
  const handleSubmit = async (obj) => {
    console.log(obj);

    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&GUIDReservation=${reservation.GUID}&IdBusinessUnit=${obj.IdBusinessUnit}&Comment=${obj.Comments}`;

      console.log(queryString);

      /* let queryData = {
        Data: obj,
      }; */

      let requestAPI = await API.getAction(
        `/api/Reservations/TransferToBusinessUnit`,
        queryString
      );

      console.log(requestAPI);

      if (requestAPI.status === 200) {
        let response = GetDataOfJSON(
          requestAPI,
          "data",
          0,
          "JSONData",
          0
        ).JSONData;

        console.log(response);
      }

      // toast({
      //   type: "success",
      //   message: "Record saved successfully",
      // });

      let selectedBusinessUnit = allBusinessUnit.find(
        (item) => item.id === obj.IdBusinessUnit
      );

      toggle(selectedBusinessUnit, obj);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        backdrop="static"
        keyboard={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Transfer Business Unit")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Container>
              <Row>
                <GenericFields
                  updateFields={updateField}
                  errors={formik.errors}
                  values={formik.values}
                  touched={formik.touched}
                  fields={fields}
                />
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" size="lg" onClick={toggle}>
              {t("Close")}
            </Button>
            <Button type="submit" size="lg">
              {t("Approve Change")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default TransferBusinessUnit;
