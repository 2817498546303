import React, { useEffect, useState } from "react";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Title,
  Font,
  Margin,
  Size,
} from "devextreme-react/pie-chart";

import CenterTemplate from "../../utils/CenterTemplate";
import API from "../../api/api";
import Loading from "../Loading";

const palleteColor = ["#46a6ff", "#f5564a", "#dfd6d8"];

const dataTest =[{
  title:"Clean Rooms",
  total:"80",
}, {
  title:"Dirty Rooms",
  total:"20"
}]

const dataTestRequest =[{
  title:"Events & Catering",
  total:"20",
}, {
  title:"Staff food services",
  total:"20"
}, {
  title:"Beverages",
  total:"30"
}, {
  title:"Foods",
  total:"30"
}]

const IncidentCategory = ({ data, viewTotalValue, title }) => {
  const customizeLabel = (e) => {
    return `${e.valueText}`;
  };

  const pies = () => (
    <PieChart
      id="pie-chart"
      dataSource={data}
      resolveLabelOverlapping="shift"
      innerRadius={0.65}
      centerRender={(e) => CenterTemplate(e, viewTotalValue)}
      palette={palleteColor}
    >
      <Series argumentField="title" valueField="total">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
        >
          <Font size={16} />
          <Connector visible={true} />
        </Label>
      </Series>

      <Legend
        visible={true}
        verticalAlignment="center"
        horizontalAlignment="center"
        itemTextPosition="right"
      >
        <Font size={16} />
      </Legend>
      <Title
        text={title ? title :"Incident Category"}
        horizontalAlignment="center"
        verticalAlignment="top"
      >
        <Font size={24} />
        <Margin top={30} />
      </Title>
    </PieChart>
  );

  return (
    <div className="bg-white shadow p-3 text-center" style={{ borderRadius: 25 }}>
     {/*  {data && data.length > 0 ? pies() : <Loading />} */}
     {pies()}
    </div>
  );
};

export default IncidentCategory;
