import Scheduler, { Resource, Scrolling } from "devextreme-react/scheduler";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import "./custom-scheduler-style.css";
import {
  // dataChart,
  // roomData,
  RoomOccupationJsonFile,
} from "./exampleData";
import {
  // getReservationColorStatus,
  getRoomColorStatus,
} from "./room-status-util";

import { useTranslation } from "react-i18next";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";

const views = [
  { name: "Months", type: "timelineMonth", intervalCount: 2 },
  {
    name: "Weeks",
    type: "timelineWeek",
    intervalCount: 5,
  },
];

const RoomOccupation = () => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [roomsData, setRoomsData] = useState(RoomOccupationJsonFile.rooms);
  const [search, setSearch] = useState("");
  const [currentView, setCurrentView] = useState(views[0]);

  const groups = ["room"];

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (search === "") {
      await bindDataRemote();
      return;
    }
    setRoomsData(
      roomsData &&roomsData.filter((val) => {
        return val.text.toLowerCase().includes(search.toLowerCase());
      })
    );
   
  };

  useEffect(() => {
    bindDataRemote();
  },[])

  const bindDataRemote = async () => {
    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: "@IdBusinessUnit=1, @StartDate=null, @EndDate=null",
      };
      let request = await API.postAction(
        "/api/Rooms/RoomOccupation?" + queryString,
        queryData
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      let recordResult = parsedResults.Records[0];
      setRoomsData(recordResult?.rooms);
      setRecords(recordResult?.dataChart);
      setRecords(recordResult);
      if (recordResult === null) {
        toast({
          title: "No data found",
          status: "error",
        });
        throw new Error("No data found");
      }
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const appointmentTemplate = (model) => {
    // console.log(model);
    const { appointmentData } = model;
    return (
      <div
        className=""
        style={{
          backgroundColor: appointmentData.reservationColor,
        }}
      >
        <div className="">{appointmentData.text}</div>
        <div className="">{appointmentData.room} </div>
      </div>
    );
  };

  const renderGroupCell = (props) => {
    return (
      <div className="d-flex gap-0 w-175 h-100 align-items-center">
        <div
          className="py-4 px-3 d-flex aling-items-center justify-content-center"
          style={{
            backgroundColor: getRoomColorStatus(props.data.roomColorStatus),
          }}
        >
          {props.data.text}
        </div>
        <div className="ms-1">{props.data.roomType}</div>
      </div>
    );
  };

  const renderDateCell = useCallback((itemData) => {
    const isWeekend =
      itemData.date.getDay() === 0 || itemData.date.getDay() === 6;
    const classCustom = isWeekend
      ? "pt-3 w-100 fw-bolder text-dark"
      : "text-dark";
    const styleCustom = isWeekend ? { backgroundColor: "#FFB0B0" } : {};
    const date = itemData.date;

    // Obtener el nombre del día (por ejemplo, 'Lunes')
    const dayName = new Intl.DateTimeFormat("en", {
      weekday: "short",
    }).format(date);

    // Obtener el número del día del mes
    const dayNumber = date.getDate();

    /* <div className={backgroundColor}>
        <div>{itemData.text}</div>
      </div> */

    return (
      <>
        <div className={classCustom} style={styleCustom}>
          <span class="dx-scheduler-header-panel-cell-date ">{dayName}</span>
          <span class="dx-scheduler-header-panel-cell-date fs-3">
            {dayNumber}
          </span>
        </div>
      </>
    );
  }, []);

  return (
    <>
      <Card bg="transparent" border="0">
        <Card.Body className="ps-0 pt-0 pb-3">
          <Row>
            <Col xs={12} sm={6} xl={9}>
              <form onSubmit={handleSubmit}>
                <div className="w-75 d-flex gap-2 align-items-end">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Filter")}
                    onChange={handleChange}
                  />
                  <button type="submit" className="btn btn-primary mt-2" disabled={isLoading}>
                    {t("Search")}
                  </button>
                </div>
              </form>
            </Col>
            <Col xs={12} sm={6} xl={3} className="text-end">
              <button className="btn btn-lg">
                <i class="fa-solid fa-circle-question"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-cash-register"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-people-group"></i>
              </button>

              <button className="btn btn-lg border-end">
                <i class="fa-solid fa-folder-tree"></i>
              </button>

              <button className="btn btn-lg btn-link" type="button">
                <i class="fa-solid fa-rotate"></i>
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {!isLoading && <>
      <Scheduler
        timeZone="America/Los_Angeles"
        dataSource={Array.isArray(records) ? records : []}
        views={views}
        currentDate={new Date()}
        defaultCurrentView={"Weeks"}
        height={'auto'}
        groups={groups}
        cellDuration={1440}
        firstDayOfWeek={0}
        groupByDate={false}
        timeCellComponent={null}
        resourceCellRender={renderGroupCell}
        showAllDayPanel={false}
        appointmentRender={appointmentTemplate}
        showCurrentTimeIndicator={false}
        maxAppointmentsPerCell={1}
        shadeUntilCurrentTime={false}
        dateCellRender={renderDateCell}
      >
        <Resource
          fieldExpr="room"
          allowMultiple={true}
          dataSource={roomsData ? roomsData : []}
          label={t("Room")}
          useColorAsDefault={false}
        />

        <Scrolling mode="virtual" />
      </Scheduler>
      </>}
    </>
  );
};

export default RoomOccupation;
