import Sha256 from "./sha256";

export const getFilterCriteria = (obj, filters) => {
  const filterCriteria = filters.filter(item => item.ReportCriteria).map(item => {
    return {
      name: item.FieldTitle,
      value: obj[item.FieldName],
      fieldType: item.HtmlType
    }
  })
  return filterCriteria;
}

export const handleFilter = async (obj, filters) => {
  let objSPVersion = "";
  filters.forEach((item) => {

    if (item.ReadOnly || item.HtmlType === "separator") return;

    if (item.HtmlType === "date") {
      if (obj[item.FieldName] === 'Invalid date') {
        obj[item.FieldName] = null;
      }
    }

    if (item.HtmlType !== "separator") {
      objSPVersion +=
        (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
    }
    if (
      item.FieldType === "int" ||
      item.FieldType === "bool" ||
      item.FieldType === "bit" ||
      item.FieldType === "bigint"
    ) {
      objSPVersion +=
        obj[item.FieldName] === undefined || obj[item.FieldName] === null || obj[item.FieldName] === "null" || obj[item.FieldName] === ""
          ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
          : obj[item.FieldName] ?? "null";

    } else if (item.HtmlType === "password") {
      let passwordEncrypted = null;
      if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
        passwordEncrypted =
          obj["UserName"].toUpperCase() + obj[item.FieldName];
        passwordEncrypted = Sha256.hash(passwordEncrypted);
        ;
      }
      objSPVersion +=
        obj[item.FieldName] === undefined
          ? "null"
          : "'" + passwordEncrypted + "'"
    } else
      if (item.HtmlType === 'radio') {

        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
  });

  return objSPVersion;
  
}